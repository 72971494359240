import { NodeDataRpcResponse, NodeDataRpcQuery } from '@generated';
import { sendGraphQLRequest } from '@utils';
import NodeRPC from './queries/NodeRPC.graphql';

/**
 * Search using node RPC.
 * @returns {Promise<NodeDataRpcResponse>} The node rpc response.
 * @throws {Error} If the request fails.
 */
export const nodeRPC = async (args: {
    encodedPk: string;
    type: string;
}): Promise<NodeDataRpcResponse> => {
    try {
        const response = await sendGraphQLRequest<NodeDataRpcQuery>(NodeRPC, {
            encodedPk: args.encodedPk,
            type: args.type,
        });

        if (!response.data.nodeDataRPC) {
            throw Error('No node data returned');
        }

        return response.data.nodeDataRPC;
    } catch (error) {
        throw Error('Failed node data rpc: ' + error);
    }
};
