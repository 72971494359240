import React from 'react';
import { useFloating, offset, autoUpdate, flip } from '@floating-ui/react';
import { LayerSettingsMenu } from '@keypro/2nd-xp';
import { isLayerFavourite, setFavouriteLayer } from './layerHelpers';
import { t } from 'i18next';

interface LayerSettingsFloatingProps {
    activeNodeId: string | null;
    handleClose: () => void;
    referenceElement: HTMLElement | null;
    setFavouriteLayersChanged: React.Dispatch<React.SetStateAction<boolean>>;
}

export const LayerSettingsFloating = ({
    activeNodeId,
    handleClose,
    referenceElement,
    setFavouriteLayersChanged,
}: LayerSettingsFloatingProps) => {
    const { floatingStyles, refs } = useFloating({
        placement: 'right-start',
        middleware: [offset(6), flip()],
        whileElementsMounted: autoUpdate,
        elements: { reference: referenceElement },
    });

    const handleFavouriteChange = (value: boolean) => {
        setFavouriteLayer(activeNodeId!, value);
        setFavouriteLayersChanged(true);
    };

    return activeNodeId ? (
        <div ref={refs.setFloating} style={floatingStyles}>
            <LayerSettingsMenu
                layerName={activeNodeId}
                handleClose={handleClose}
                headerTitle={t('layerSettings')}
                opacityTitle={t('layerOpacity')}
                favourite={isLayerFavourite(activeNodeId)}
                onFavourite={handleFavouriteChange}
                favouriteTitle={t('showInFavourites')}
                favouriteDescription={t('showLayerInFavouritesDescription')}
            />
        </div>
    ) : null;
};
