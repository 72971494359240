import { NodeChildData } from '@generated';
import { Node } from './Node';
import { DiagramOptions } from '../types';

export class Duct extends Node {
    ductType: number;
    ductNumber: string | null;
    cableCount: number;

    cableFillColor: string = '#0000FF';
    cableCountTextColor: string = '#FFFFFF';

    fontSize: number = 200;

    /**
     * ducts are all scale to a bigger size for manhole diagram
     */
    scale: number = 1;

    lineWidth: number;
    lineColor: string;

    /**
     * Constructor.
     * @param {Object} paper - Raphael paper object, on which the duct will be drawn.
     * @param {Object} data - Duct data.
     */
    constructor(options: DiagramOptions, data: NodeChildData) {
        super(options, data);

        this.ductType = data.ductType;
        this.ductNumber = data.ductNumber ?? null;
        this.cableCount = data.cableCount;
        this.scale = data.scale;

        /*Set duct color based on duct type. If duct type has sheath_colour as null, we define color by state*/
        const duct_type = options.ductTypes[data.type];
        const duct_state = data.stateId;
        const colorService = options.colorService;

        if (duct_type?.sheathColour) {
            const color =
                options.ductColors[duct_type.sheathColour.id].txtValue;
            this.lineColor = colorService.makeRGB(color!);
        } else {
            this.lineColor = colorService.makeRGB(
                colorService.getColorStringFromState(duct_state),
            );
        }

        /**
         * Try to preserve the conduit profile styles (line-width, font-size) while scaling up for diagram
         * In conduit profile, lines are in base unit, and duct number are proportional to the square root
         * of the actual diameter in milimetre
         */
        this.lineWidth = this.scale;
        this.fontSize = Math.floor(
            Math.sqrt((this.radius / this.scale) * 2) * 2 * this.scale,
        );
    }

    render() {
        this.renderDuctShape();
        this.renderDuctNumber();
        if (this.cableCount > 0) {
            this.renderDuctCable();
        }

        super.render();
    }

    renderDuctShape() {
        switch (this.ductType) {
            case 0:
                this.renderRegularDuct();
                break;
            case 1:
                this.renderPartitionDuct();
                break;
            case 2:
                this.renderGrilleDuct();
                break;
            case 3:
                this.renderPlankDuct();
                break;
            case 4:
                this.renderChuteDuct();
                break;
            default:
                this.renderRegularDuct();
        }
    }

    /**
     * Regular duct is a circle. Finnish name 'Tavallinen'.
     */
    renderRegularDuct() {
        /* svg requires a closed shape has a fill color in order to make the element clickable */
        const ductCircle = this.paper
            .circle(this.cx, this.cy, this.radius)
            .attr({
                stroke: this.lineColor,
                fill: '#FFFFFF',
                'fill-opacity': 0,
                'stroke-width': this.lineWidth,
            });
        this.elemSet.push(ductCircle);
    }

    /**
     * Partition duct is is a circle with additional three lines from center to circle. Finnish name 'Mersu'.
     */
    renderPartitionDuct() {
        const circle = this.paper.circle(this.cx, this.cy, this.radius);
        circle.attr({
            stroke: this.lineColor,
            fill: '#FFFFFF',
            'fill-opacity': 0,
            'stroke-width': this.lineWidth,
        });

        const x0 = this.cx,
            y0 = this.cy + this.radius;
        const x1 = this.cx + this.radius * Math.cos(Math.PI / 6),
            y1 = this.cy - this.radius * Math.sin(Math.PI / 6);
        const x2 = this.cx - this.radius * Math.cos(Math.PI / 6),
            y2 = y1;

        const pathStr0 = 'M' + this.cx + ',' + this.cy + 'L' + x0 + ',' + y0;
        const pathStr1 = 'M' + this.cx + ',' + this.cy + 'L' + x1 + ',' + y1;
        const pathStr2 = 'M' + this.cx + ',' + this.cy + 'L' + x2 + ',' + y2;

        const path0 = this.paper.path(pathStr0).attr({
            stroke: this.lineColor,
            'stroke-width': this.lineWidth,
        });
        const path1 = this.paper.path(pathStr1).attr({
            stroke: this.lineColor,
            'stroke-width': this.lineWidth,
        });
        const path2 = this.paper.path(pathStr2).attr({
            stroke: this.lineColor,
            'stroke-width': this.lineWidth,
        });

        this.elemSet.push(circle, path0, path1, path2);
    }

    /**
     * Grille duct is half of a rect open to top. Finnish name 'Arina'.
     */
    renderGrilleDuct() {
        const x0 = this.cx - this.radius,
            y0 = this.cy;
        const x1 = this.cx - this.radius,
            y1 = this.cy + this.radius;
        const x2 = this.cx + this.radius,
            y2 = this.cy + this.radius;
        const x3 = this.cx + this.radius,
            y3 = this.cy;

        const pathString =
            'M' +
            x0 +
            ',' +
            y0 +
            'L' +
            x1 +
            ',' +
            y1 +
            'L' +
            x2 +
            ',' +
            y2 +
            'L' +
            x3 +
            ',' +
            y3;
        const ductPath = this.paper.path(pathString);
        ductPath.attr({
            stroke: this.lineColor,
            'stroke-width': this.lineWidth,
        });
        this.elemSet.push(ductPath);
    }

    /**
     * Plank duct is a rectangle on top (drawn as a thick line). Finnish name 'Lauta'.
     */
    renderPlankDuct() {
        const lineWidth = 0.3 * this.radius;
        const x0 = this.cx - this.radius,
            y0 = this.cy - this.radius + lineWidth / 2;
        const x1 = this.cx + this.radius,
            y1 = this.cy - this.radius + lineWidth / 2;

        const pathString = 'M' + x0 + ',' + y0 + 'L' + x1 + ',' + y1;
        const ductPath = this.paper.path(pathString);
        ductPath.attr({
            stroke: this.lineColor,
            'stroke-width': lineWidth,
        });

        this.elemSet.push(ductPath);
    }

    /**
     * Chute duct is an elliptical arc. Finnish name 'Kouru'.
     */
    renderChuteDuct() {
        const x0 = this.cx - 0.8 * this.radius,
            y0 = this.cy + this.radius;
        const x1 = this.cx + 0.8 * this.radius,
            y1 = this.cy + this.radius;
        const pathString =
            'M' +
            x0 +
            ',' +
            y0 +
            'A' +
            this.radius +
            ',' +
            1.25 * this.radius +
            ',0,1,1,' +
            x1 +
            ',' +
            y1;
        const ductPath = this.paper.path(pathString);
        ductPath.attr({
            stroke: this.lineColor,
            'stroke-width': this.lineWidth,
        });
        this.elemSet.push(ductPath);
    }

    /**
     * Render duct cable at given location.
     */
    renderDuctCable() {
        const cableRadius = this.radius / 2;
        const cableCircle = this.paper.circle(this.cx, this.cy, cableRadius);
        cableCircle.attr('fill', this.cableFillColor);

        this.elemSet.push(cableCircle);

        if (this.cableCount > 1) {
            const cableCountText = this.paper.text(
                this.cx,
                this.cy,
                this.cableCount.toString(),
            );
            cableCountText.attr({
                'font-size': this.fontSize,
                fill: this.cableCountTextColor,
            });
            this.elemSet.push(cableCountText);
        }
    }

    /**
     * Render duct number text. Note that the given location is the duct center. Notice
     * that the duct number element is saved to its own variable to simplify center
     * calculation.
     */
    renderDuctNumber() {
        const ductNumber = this.paper
            .text(
                this.cx - this.radius,
                this.cy - this.radius,
                this.ductNumber?.toString() ?? '',
            )
            .attr({
                'font-size': this.fontSize,
            });
        this.elemSet.push(ductNumber);
    }
}
