import { GetWaterPumpingStationsQuery, WaterPumpingStation } from '@generated';
import GetWaterPumpingStations from './queries/GetWaterPumpingStations.graphql';
import { sendGraphQLRequest } from '@utils';
import { GraphQLFilter, isFilterEmpty } from '@apis/utils';

/**
 * Gets the water pumping stations.
 * @param filter The GraphQL filter to apply to the query.
 * @returns The water pumping stations.
 * @throws {Error} If the request fails.
 */
export const getWaterPumpingStations = async (filter: GraphQLFilter) => {
    try {
        const payload = !isFilterEmpty(filter)
            ? { filter: filter }
            : { filter: null, limit: 1 };
        const response = await sendGraphQLRequest<GetWaterPumpingStationsQuery>(
            GetWaterPumpingStations,
            payload,
        );

        if (!response.data.getWaterPumpingStations) {
            throw Error('No data returned');
        }

        return response.data.getWaterPumpingStations.waterPumpingStations?.filter(
            Boolean,
        ) as WaterPumpingStation[];
    } catch (error) {
        throw Error('Failed to get water pumping stations: ' + error);
    }
};
