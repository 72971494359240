import { getSewerManholes } from '@apis/keyduct';
import { FormConfig } from '../types';
import { SewerManholeIcon } from './icons';

const config: FormConfig = {
    gqlType: 'SewerManhole',
    model: 'SnSewerManhole',
    modelAliases: ['snsewermanhole'],
    icon: <SewerManholeIcon />,
    groups: [
        {
            name: 'generalInformation',
            fields: ['feature', 'buildYear'],
        },
    ],
    functions: {
        get: getSewerManholes,
    },
    queryCollection: 'sewerManholes',
};

export default config;
