query getCustomers($filter: CustomerFilter $filterCPCustomer: ConsumerPointCustomerFilter) {
    getCustomers(filter: $filter) {
        customers {
            id
            customerName
            identification
            customerNameExt
            customerNumber
            identification
            customerRestrictedType {id}
            customerType {id}
            
            address {
                id
                address {
                    id
                    streetno
                    streetname
                    streetnameAlt
                    district
                    city
                    province
                    municipality
                    country
                    zipcode
                    location
                }
            }
            
            customerPhone
            customerEmail
            customerLanguage  {id}
            twoSmsNumbers
            phone2
            customerState {id}
            isEditedByCustomer
            
            invoicingAddress {
                id
                address {
                    id
                    streetno
                    streetname
                    streetnameAlt
                    district
                    city
                    province
                    municipality
                    country
                    zipcode
                    location
                }
            }
            invoicingCountry
        }
    }
    getConsumerPointCustomers(filter: $filterCPCustomer) {
        consumerPointCustomers {
            consumerPoint {
                consumerNumber
                address {
                    id
                    address {
                        id
                        streetname
                        streetno
                        zipcode
                        city
                        country
                    }
                }
                cpType {
                    txt
                }
            }
            customerRole {
                txt
            }
        }
    }
}