import { RestStringFiltersInput } from '@generated';

/**
 * Generic query variables.
 */
export interface QueryVariables<T> {
    filter?: T;
    offset?: number;
    limit?: number;
}

/**
 * Generic GraphQL filter.
 */
export type GraphQLFilter =
    | { [key: string]: RestStringFiltersInput }
    | undefined;

/**
 * Check if a given filter is undefined or an empty object.
 * @param filter Filter to check.
 * @returns True if the filter is empty, false otherwise.
 */
export const isFilterEmpty = (filter: GraphQLFilter): boolean => {
    return (
        filter === undefined || // Check if undefined
        (typeof filter === 'object' && Object.keys(filter).length === 0) // Check if it's an empty object
    );
};

/**
 * Get the name of the GraphQL query from the given query string.
 * @param query Query string to extract the name from.
 * @returns Name of the query.
 */
export const getQueryName = (query: string) =>
    query
        .replace(/^query\s*/, '')
        .split(/[({]/)[0]
        .trim();
