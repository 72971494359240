import { helpUrl } from '@components/AppToolbar/Common';
import { Button, Icons, styled, Wrapper } from '@keypro/2nd-xp';
import { t } from 'i18next';
import { Trans } from 'react-i18next';

const ErrorWrapper = styled(Wrapper)`
    & > * {
        margin: auto auto;
    }
`;

const Container = styled.div`
    padding: 16px;
    text-align: center;

    color: ${({ theme }) => theme.colors.neutral['80']};
    ${({ theme }) => theme.fonts['14px Regular']}
    font-size: 20px;
    line-height: 28px;

    & > * {
        margin-bottom: 40px;
    }

    & > p:first-of-type {
        ${({ theme }) => theme.fonts['14px Bold']}
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 20px;
    }

    a {
        color: ${({ theme }) => theme.colors.accents.blue['10']};
        text-decoration: none;
    }
`;

const Header = styled.h1`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    flex-flow: row wrap;

    color: ${({ theme }) => theme.colors.accents.red['10']};
    ${({ theme }) => theme.fonts['14px Medium']}
    font-size: 40px;
    line-height: 48px;

    svg {
        position: relative;
        top: -1px;

        width: 36px;
        height: 36px;
    }
`;

/**
 * Page displayed when an unhandled fatal error occurs in the application.
 */
export const ErrorPage = () => {
    return (
        <ErrorWrapper>
            <Container>
                <Header>
                    <Icons.Alert />
                    <span>{t('errorPage.title')}</span>
                </Header>

                <p>{t('errorPage.somethingWentWrong')}</p>

                <p>
                    <Trans i18nKey="errorPage.instructions">
                        Try reloading the page. If the issue persist,{' '}
                        <a href={helpUrl}>contact support</a>.
                    </Trans>
                </p>

                <p>
                    <Button
                        kind="primary"
                        label={t('reloadPage')}
                        onClick={() => {
                            window.location.reload();
                        }}
                    />
                </p>
            </Container>
        </ErrorWrapper>
    );
};
