// Libraries
import {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { MapBrowserEvent } from 'ol';
import * as olExtent from 'ol/extent';
import { fromExtent } from 'ol/geom/Polygon';
import proj4 from 'proj4';

// Component Library
import {
    MapContext,
    Icons,
    InfoToolSelectionEvent,
    ObjectDivider,
    useIsMobile,
    Coordinates,
    Button,
    useToast,
    InfoToolSelectionMode,
} from '@keypro/2nd-xp';

// Generated
import { InfoToolResult, Maybe, SearchArea, TxtConstant } from '@generated';

// Stores
import {
    useCoordinatesSystem,
    useLeftMenu,
    useMobileMenu,
    useProductStore,
    useRightMenu,
    useSearchStore,
    useTxtConstants,
} from '@stores';

// Components
import { InfoObject } from '@components/InfoObject';
import {
    convertInfoToolResultToSearchResult,
    FullSearch,
    getCoordinateSystemDecimalPrecision,
    pointToPolygon,
    sortResultsByDistance,
} from '@components';
import { MoveableContainer } from '@components/MoveableContainer';

// Form Configs
import {
    getFormConfig,
    getGenericLabel,
    getModelIcon,
    getTypeByModel,
    isEmpty,
    isModelSupported,
} from '@form-configs';

// Styles
import {
    DraggableHeaderCloseBtn,
    StyledDraggableHeader,
    StyledSimplePopup,
    StyledSimplePopupItem,
    ToolbarPopupButtons,
    ToolbarPopupCopyBtn,
    ToolbarPopupDraggableFooter,
    ToolbarPopupFirstListResult,
    ToolbarPopupListResultContainer,
    ToolbarPopupLoading,
    ToolbarPopupNearbyObjects,
    ToolbarPopupNoResult,
} from './ObjectOverlay.styles';

// Constants
import {
    HOVER_TIME,
    MAX_HOVER_DISPLAY_SCALE,
    POINT_DISPLAY_SCALE_MULTIPLIER,
    PRIORITY_ORDER,
    TOOL_BAR_SIZE,
} from './ObjectOverlay.consts';
import { DEFAULT_COORDINATES_SYSTEM_TXT_VALUE } from '../AppFooter/CoordinatesTool/CoordinatesTool.consts';

// Utils
import { getCoordinatesSystemPrefixes } from '@utils';

// Hooks
import {
    useDraggableHandle,
    useGetInfoToolResults,
    useHighlightMapObject,
} from '@hooks/map';

// Contexts
import { AuthContext } from '@providers';
import { FloatingToolbar } from '@components/AppToolbar/AppToolbar';

/**
 * The x,y (top left) and width,height (bottom right) of the visible map.
 */
interface VisibleMapSpace {
    x: number;
    y: number;
    width: number;
    height: number;
}

type Coordinate = [number, number];

declare global {
    interface Window {
        NUI: { infoToolOnHover: boolean };
    }
}

// Set the global flag for the hover event
window.NUI = window.NUI || {};
window.NUI.infoToolOnHover = true;

/**
 * The ObjectOverlay component displays content on top of the map.
 * The popup have two different display modes:
 * - Toolbar popup: Shown popup with toolbar when the user uses infoTool or click on a map object.
 * - Simple popup: Shown simple popup when the user hovers on the zoomed in map (< MAX_HOVER_DISPLAY_SCALE).
 * The onHover behavior can be disabled by setting the global flag `NUI.infoToolOnHover = false`.
 * @returns The ObjectOverlay component
 */
export const ObjectOverlay = () => {
    const isMobile = useIsMobile();
    const {
        setMenuContent: setMobileMenuContent,
        isMenuOpen: isMobileMenuOpen,
        detailedMenu: detailedMobileMenu,
        menuHeight,
    } = useMobileMenu();
    const { setSearchResults } = useSearchStore();
    const { product } = useProductStore();
    const { setMenuContent: setRightMenuContent, isMenuOpen: isRightMenuOpen } =
        useRightMenu();
    const {
        setMenuContent: setLeftMenuContent,
        isMenuOpen: isLeftMenuOpen,
        detailedMenu: detailedLeftMenu,
    } = useLeftMenu();
    const { selectedCoordinatesSystem, isPickLocationEnabled } =
        useCoordinatesSystem();

    const { getTxtConstantsBy } = useTxtConstants();

    const coordinatesSystemList = useMemo(
        () =>
            getTxtConstantsBy({
                groupname: 'KEYCORE_IMPORT_SRS',
                onlyWithAdditionalData: true,
            }),
        [getTxtConstantsBy],
    );

    let setMenuContent = setRightMenuContent;
    let isMenuOpen = isLeftMenuOpen;
    let detailedMenu = detailedLeftMenu;

    if (isMobile) {
        setMenuContent = setMobileMenuContent;
        isMenuOpen = isMobileMenuOpen;
        detailedMenu = detailedMobileMenu;
    }

    const toast = useToast();
    const { t } = useTranslation();
    const controller = useContext(MapContext)!;

    // Is the toolbar popup currently open.
    const [isToolbarPopup, setIsToolbarPopup] = useState<boolean>(false);
    // Should the popup save the last clicked position on the map.
    const [shouldSavePosition, setShouldSavePosition] =
        useState<boolean>(false);
    // Is the user currently selecting an area by clicking on the map.
    const [isClickSelect, setIsClickSelect] = useState<boolean>(false);
    // The selected coordinate. Displayed in the footer of the toolbar popup.
    const [coordinate, setCoordinate] = useState<Coordinate>([0, 0]);
    // Position for the object popup to attach to. Default will be [72, 66] for MapView.
    const [position, setPosition] = useState<Coordinate>([72, 66]);
    // Offset for the toolbar popup predefined position (map view).
    const [offset, setOffset] = useState<number>(0);
    // Is the infoToolResults currently loading.
    const [isLoading, setIsLoading] = useState<boolean>(true);
    // Is the result coming from the MapView. Used to override the position to the predefined position.
    const [isInMapView, setIsInMapView] = useState<boolean>(false);
    // Filter for the info tool. It will be used in the `useGetInfoToolResults` hook.
    const [infoToolFilter, setInfoToolFilter] = useState<
        | {
              layers: string[];
              searchArea: SearchArea;
          }
        | undefined
    >();
    const [defaultCoordinatesSystem, setDefaultCoordinatesSystem] = useState<
        TxtConstant | undefined
    >();

    const { user } = useContext(AuthContext);

    // Timeout id for the hover event
    const hoverTimeoutId = useRef<number>(0);

    const infoToolResults = useGetInfoToolResults({
        layers: infoToolFilter?.layers,
        searchArea: infoToolFilter?.searchArea,
    });

    const headerResult = useMemo(() => {
        return infoToolResults.data?.results?.[0];
    }, [infoToolResults.data]);

    const isSimplePopup = useMemo(() => {
        return !!(
            infoToolResults.data?.results &&
            !isToolbarPopup &&
            controller?.displayScale <= MAX_HOVER_DISPLAY_SCALE
        );
    }, [infoToolResults.data, isToolbarPopup, controller]);

    const { highlightMapObject } = useHighlightMapObject(
        controller,
        isSimplePopup || isToolbarPopup,
        'toolbarPopup',
    );

    /**
     * Get the visible map space based on `center-menu` and `right-menu`.
     * If `isMobile` is true, based on the `root` element `isMenuOpen`.
     */
    const getVisibleMapSpace = useCallback((): VisibleMapSpace => {
        if (isMobile) {
            const centerMenu = document.getElementById('root')!;
            const { x, y, width, height } = centerMenu.getBoundingClientRect();
            const availableMapSpace = { x, y, width, height };

            if (isMenuOpen) {
                availableMapSpace.height =
                    (availableMapSpace.height * (100 - menuHeight)) / 100;
            }
            return availableMapSpace;
        }

        const centerMenu = document.getElementById('center-menu')!;
        const { x, y, width, height } = centerMenu.getBoundingClientRect();
        const availableMapSpace = { x, y, width, height };

        if (!isRightMenuOpen) {
            const rightMenu = document.getElementById('right-menu')!;
            const { width: rightMenuWidth } = rightMenu.getBoundingClientRect();
            availableMapSpace.width += rightMenuWidth;
        }

        return availableMapSpace;
    }, [isMobile, isMenuOpen, menuHeight, isRightMenuOpen]);

    /**
     * Handle the horizontal position of the popup.
     */
    const setHorizontalPosition = useCallback(
        (cursorPosition: Coordinate, simplePopup: boolean = false) => {
            const handleHorizontal = () => {
                let newPosition = cursorPosition;

                const { x, width } = getVisibleMapSpace();
                const [left, top] = cursorPosition;
                const availableWidth = x + width;
                const requiredWidth = simplePopup ? 280 : 348;
                const overflowsRight = left + requiredWidth > availableWidth;
                const overflowsLeft = left - requiredWidth < 0;
                const exceedsAvailableWidth = requiredWidth > availableWidth;

                if (
                    exceedsAvailableWidth ||
                    (overflowsRight && overflowsLeft)
                ) {
                    newPosition = [isMobile ? 5 : TOOL_BAR_SIZE, top];
                } else if (overflowsRight) {
                    newPosition = [left - requiredWidth, top];
                }
                return newPosition;
            };

            setIsLoading(true);
            const newPosition = handleHorizontal();

            setPosition(newPosition);
        },
        [setPosition, getVisibleMapSpace, isMobile, setIsLoading],
    );

    /**
     * Check if the cursor is over a given element.
     * If the elementId is 'map', it calls getVisibleMapSpace().
     * Otherwise, it uses getBoundingClientRect().
     */
    const isCursorOverElement = useCallback(
        (e: MouseEvent, elementId: string): boolean => {
            const check = (element: VisibleMapSpace | DOMRect) => {
                const { x, y, width, height } = element;
                return (
                    e.clientX >= x &&
                    e.clientX <= x + width &&
                    e.clientY >= y &&
                    e.clientY <= y + height
                );
            };

            if (elementId === 'map') {
                return check(getVisibleMapSpace());
            }

            const element = document.getElementById(elementId);
            if (!element) return false;

            return check(element.getBoundingClientRect());
        },
        [getVisibleMapSpace],
    );

    useEffect(() => {
        const coordinatesSystemEPSG3067 = coordinatesSystemList.find(
            (item) =>
                item?.txtValue?.toString() ===
                DEFAULT_COORDINATES_SYSTEM_TXT_VALUE,
        );

        setDefaultCoordinatesSystem(coordinatesSystemEPSG3067);
    }, [coordinatesSystemList]);

    // Save the last clicked position on the map as the initial position for the popup.
    // Also save the coordinate to display in the footer.
    useEffect(() => {
        const onMouseUp = (e: MouseEvent) => {
            if (
                isCursorOverElement(e, 'map') &&
                !isPickLocationEnabled &&
                shouldSavePosition
            ) {
                setHorizontalPosition([e.clientX, e.clientY]);
                const updatedCoordinates =
                    controller.map.getCoordinateFromPixel([
                        e.clientX,
                        e.clientY,
                    ]) as Coordinate;
                setCoordinate(updatedCoordinates);
            }
        };

        document.addEventListener('mouseup', onMouseUp);
        return () => {
            document.removeEventListener('mouseup', onMouseUp);
        };
    }, [
        controller,
        shouldSavePosition,
        setHorizontalPosition,
        isCursorOverElement,
        isPickLocationEnabled,
    ]);

    // After finishing loading the infoToolResults, calculate the vertical position of the popup.
    useEffect(() => {
        if (!infoToolResults.isLoading) {
            const getPopUpHeight = () => {
                if (!isToolbarPopup) return 85;
                let height = 156;
                if (
                    infoToolResults?.data?.results &&
                    infoToolResults.data.results.length > 1
                ) {
                    height += 36; // For nearby objects title
                    height +=
                        Math.min(infoToolResults.data.results.length - 1, 10) *
                        34; // For each nearby objects, max 10
                }
                return height;
            };

            const { y, height } = getVisibleMapSpace();
            const availableHeight = y + height - TOOL_BAR_SIZE;
            const requiredHeight = getPopUpHeight();

            setPosition((prevPosition) => {
                const [prevX, prevY] = prevPosition;
                const overflowsDownward =
                    prevY + requiredHeight > availableHeight;
                const overflowsUpward = prevY - requiredHeight < TOOL_BAR_SIZE;
                const exceedsAvailableHeight =
                    requiredHeight > availableHeight - TOOL_BAR_SIZE;

                let newY = prevY;
                if (
                    exceedsAvailableHeight ||
                    (overflowsUpward && overflowsDownward)
                ) {
                    newY = TOOL_BAR_SIZE + 10;
                } else if (overflowsDownward && !overflowsUpward) {
                    newY = prevY - requiredHeight;
                }

                return [prevX, newY];
            });
            setIsLoading(false);
        }
    }, [
        infoToolResults.isLoading,
        infoToolResults?.data?.results,
        isToolbarPopup,
        getVisibleMapSpace,
    ]);

    // Open ToolbarPopup when the user completes the selection action or uses the MapView option.
    useEffect(() => {
        const handleInfoToolSelection = (event: InfoToolSelectionEvent) => {
            setInfoToolFilter({
                layers: event.layers,
                searchArea: event.feature,
            });
            setIsToolbarPopup(true);
            setIsInMapView(event.mode === InfoToolSelectionMode.MAP_VIEW);
            setIsClickSelect(false);

            setShouldSavePosition(true);
            window.setTimeout(() => {
                setShouldSavePosition(false);
            }, HOVER_TIME);
        };

        controller.on('infoToolSelection', handleInfoToolSelection);

        return () => {
            controller.off('infoToolSelection', handleInfoToolSelection);
        };
    }, [controller]);

    // Handle onClick and onHover on the map.
    useEffect(() => {
        const setFilter = (
            layers: string[],
            coordinates: Coordinates,
            clickSelect: boolean,
        ) => {
            const size =
                controller.displayScale * POINT_DISPLAY_SCALE_MULTIPLIER;
            const polygon = clickSelect
                ? fromExtent(controller.getExtent())
                : pointToPolygon(coordinates, size);

            setInfoToolFilter({
                layers: layers,
                searchArea: {
                    type: 'Feature',
                    geometry: {
                        type: 'Polygon',
                        coordinates: polygon.getCoordinates(),
                    },
                    properties: null,
                },
            });
            setIsClickSelect(clickSelect);
        };

        const resetHoverTimeout = () => {
            if (hoverTimeoutId.current) {
                clearTimeout(hoverTimeoutId.current);
            }
        };

        const shouldFetchPopup = (event: MapBrowserEvent<UIEvent>): boolean => {
            const mouseEvent = event.originalEvent as MouseEvent;
            return (
                isCursorOverElement(mouseEvent, 'map') &&
                !isCursorOverElement(mouseEvent, 'simplePopup') &&
                !isToolbarPopup &&
                controller?.displayScale <= MAX_HOVER_DISPLAY_SCALE
            );
        };

        const filterActiveLayers = (layerNames: string[]): string[] => {
            return layerNames.filter(
                (layerName) => !layerName.includes('area'), // Exclude area layers
            );
        };

        const adjustPopupPosition = (event: MapBrowserEvent<UIEvent>) => {
            const e = event.originalEvent as MouseEvent;
            setHorizontalPosition([e.clientX, e.clientY - TOOL_BAR_SIZE], true); // Somehow the y position is off by the toolbar height
        };

        const handlePopupData = (event: MapBrowserEvent<UIEvent>) => {
            const activeLayerNames = controller.layers.getActiveLayerNames();
            const coordinates: Coordinate = [
                event.coordinate[0],
                event.coordinate[1],
            ];
            const layerList = filterActiveLayers(activeLayerNames);

            setFilter(layerList, coordinates, false);
            adjustPopupPosition(event);
        };

        // On click, toggle ToolbarPopup when the user clicks on the map.
        const onMouseClick = (event: MapBrowserEvent<UIEvent>) => {
            if (isPickLocationEnabled) {
                return;
            }
            const activeLayerNames = controller.layers.getActiveLayerNames();
            const coordinates: Coordinate = [
                event.coordinate[0],
                event.coordinate[1],
            ];

            // Fetch infoTool results and handle horizontal position before turning it on
            if (!isToolbarPopup) {
                setFilter(activeLayerNames, coordinates, true);
                const e = event.originalEvent as MouseEvent; // Use the original event to get the correct position
                setHorizontalPosition([e.clientX, e.clientY]);
                setCoordinate([event.coordinate[0], event.coordinate[1]]);
                setIsToolbarPopup(true);
            } else {
                closeToolbarPopup();
            }
        };

        // On hover, show simple popup
        const onMouseMove = (event: MapBrowserEvent<UIEvent>) => {
            if (!window.NUI.infoToolOnHover) return; // Check the global flag

            resetHoverTimeout();

            if (!shouldFetchPopup(event)) return;

            hoverTimeoutId.current = window.setTimeout(
                () => handlePopupData(event),
                HOVER_TIME,
            );
        };

        controller.on('mouseClick', onMouseClick);
        controller.on('mouseMove', onMouseMove);

        return () => {
            controller.off('mouseClick', onMouseClick);
            controller.off('mouseMove', onMouseMove);

            if (hoverTimeoutId.current) {
                clearTimeout(hoverTimeoutId.current);
            }
        };
    }, [
        controller,
        setHorizontalPosition,
        isToolbarPopup,
        isCursorOverElement,
        isPickLocationEnabled,
    ]);

    // Calculate the offset for the fixed object popup in map view
    useEffect(() => {
        let offSetX = 16;
        const navigationBar = document
            .getElementById('navigation-bar')
            ?.getBoundingClientRect();
        offSetX = offSetX + (navigationBar?.width ?? 0);

        if (isMenuOpen || detailedMenu) {
            const leftMenuRect = document
                .getElementById('left-menu')
                ?.getBoundingClientRect();
            offSetX = offSetX + (leftMenuRect?.width ?? 0);
        }
        setOffset(offSetX);
    }, [isMenuOpen, detailedMenu]);

    // Change the cursor to pointer when the user hovers and the simple popup picked up a result
    useEffect(() => {
        if (isSimplePopup && infoToolResults.data?.results.length !== 0) {
            controller.map.getViewport().style.cursor = 'pointer';
        } else {
            controller.map.getViewport().style.cursor = 'grab';
        }
    }, [isSimplePopup, infoToolResults.data, controller]);

    const closeToolbarPopup = () => {
        setIsToolbarPopup(false);
        setInfoToolFilter(undefined);
        setIsInMapView(false);
    };

    const zoomToAllResults = (allResults: InfoToolResult[]) => {
        if (allResults.length === 0) return;

        let combinedExtent = olExtent.createEmpty();

        allResults.forEach((result: InfoToolResult) => {
            if (!result.location) return;
            const geom = controller.wktToGeometry(result.location);
            const resultExtent = geom.getExtent();
            combinedExtent = combinedExtent
                ? olExtent.extend(combinedExtent, resultExtent)
                : resultExtent;
        });

        if (!combinedExtent) return;

        const [minX, minY, maxX, maxY] = combinedExtent;
        const width = maxX - minX;
        const height = maxY - minY;

        const bufferedExtent = [
            minX - width,
            minY - height,
            maxX + width,
            maxY + height,
        ];

        controller.map.getView().fit(bufferedExtent, {
            duration: 500,
            maxZoom: 15,
        });
    };

    const renderSimplePopup = () => {
        const getGeometryType = (location: string) => {
            const regex =
                /(POINT|LINESTRING|POLYGON|MULTIPOINT|MULTILINESTRING|MULTIPOLYGON|GEOMETRYCOLLECTION)/;
            const match = regex.exec(location);

            return match ? match[0] : null;
        };

        const result = infoToolResults.data?.results
            .filter((result) => result.location && result.model)
            .sort((a, b) => {
                const typeA = getGeometryType(a.location!);
                const typeB = getGeometryType(b.location!);
                return (
                    PRIORITY_ORDER.indexOf(typeA!) -
                    PRIORITY_ORDER.indexOf(typeB!)
                );
            })[0];

        if (!isSimplePopup || !result) return <></>;

        const openForm = () => {
            setMenuContent(
                `InfoObject-${result.model}-${result.pk}`,
                <InfoObject model={result.model!} id={result.pk!} />,
            );
        };

        return (
            <StyledSimplePopup
                style={{ left: position[0], top: position[1] }}
                id="simplePopup"
                data-testid="simplePopup"
            >
                <StyledSimplePopupItem
                    onDoubleClick={openForm}
                    data-testid="simplePopup-item"
                >
                    {renderToolbarPopupResult(result, -1, true)}
                </StyledSimplePopupItem>
                <ToolbarPopupButtons>
                    <Button
                        kind="secondary"
                        data-testid="simplePopup-zoomTo"
                        onClick={() => {
                            zoomToAllResults([result]);
                        }}
                    >
                        {t('zoomTo')}
                    </Button>
                    <Button
                        onClick={openForm}
                        data-testid="simplePopup-openForm"
                    >
                        {t('openForm')}
                    </Button>
                </ToolbarPopupButtons>
            </StyledSimplePopup>
        );
    };

    const transformCoordinates = useCallback(
        (coordinates: Coordinates) => {
            if (
                !coordinates ||
                !selectedCoordinatesSystem?.additionalData ||
                !defaultCoordinatesSystem?.additionalData
            ) {
                return;
            }

            const transformedCoordinates = proj4(
                defaultCoordinatesSystem?.additionalData ?? '',
                selectedCoordinatesSystem?.additionalData ?? '',
                coordinates,
            );

            return transformedCoordinates;
        },
        [defaultCoordinatesSystem, selectedCoordinatesSystem],
    );

    const renderToolbarPopup = () => {
        const { firstPrefix, secondPrefix } = getCoordinatesSystemPrefixes(
            selectedCoordinatesSystem,
        );
        const [x, y] = position;
        const style = {
            width: '348px',
            left: `${x}px`,
            top: `${y}px`,
            transition:
                !isLoading && !isInMapView
                    ? 'top 300ms ease-in-out left 300ms ease-in-out'
                    : 'none',
        };
        const [lon, lat] = coordinate;
        const coordinateSystemDecimalPrecision =
            getCoordinateSystemDecimalPrecision(selectedCoordinatesSystem);
        const firstCoordinate =
            transformCoordinates?.(coordinate)?.[0]?.toFixed(
                coordinateSystemDecimalPrecision,
            ) ?? '';
        const secondCoordinate =
            transformCoordinates(coordinate)?.[1]?.toFixed(
                coordinateSystemDecimalPrecision,
            ) ?? '';
        let coordinateString = `${firstPrefix} ${firstCoordinate}, ${secondPrefix} ${secondCoordinate}`;

        if (isInMapView) {
            style.left = `${offset}px`;
            style.top = '136px';
            coordinateString = `${firstPrefix} - , ${secondPrefix} - `;
        }

        return (
            <MoveableContainer
                style={style}
                id="toolbarPopup"
                data-testid="toolbarPopup"
            >
                <DraggableHeader closeFunction={closeToolbarPopup} />
                {infoToolResults.isLoading ? (
                    <ToolbarPopupLoading data-testid="toolbarPopup-loading">
                        <Icons.Spinner />
                    </ToolbarPopupLoading>
                ) : (
                    renderResults()
                )}
                <ToolbarPopupDraggableFooter data-testid="toolbarPopup-footer">
                    {coordinateString}
                    <ToolbarPopupCopyBtn
                        kind="ghost"
                        disabled={isInMapView}
                        onClick={() => {
                            if (lon && lat) {
                                navigator.clipboard
                                    .writeText(`${lon}, ${lat}`)
                                    .then(() => {
                                        toast.info(t('coordinatesCopied'));
                                    })
                                    .catch((err) => {
                                        console.error(err);
                                        toast.error(t('copyError'));
                                    });
                            }
                        }}
                        data-testid="toolbarPopup-footer-copyButton"
                    >
                        <Icons.Copy />
                    </ToolbarPopupCopyBtn>
                </ToolbarPopupDraggableFooter>
            </MoveableContainer>
        );
    };

    const renderResults = () => {
        if (!headerResult?.location) {
            return (
                <ToolbarPopupNoResult>
                    <div>{t('objectOverlay.noResult')}</div>
                    <div>{t('objectOverlay.tryOtherPlace')}</div>
                </ToolbarPopupNoResult>
            );
        }

        const geometry = controller.wktToGeometry(headerResult.location);
        const extent = geometry.getExtent();
        const center = isClickSelect
            ? coordinate
            : [(extent[0] + extent[2]) / 2, (extent[1] + extent[3]) / 2];

        const results = infoToolResults?.data?.results ?? [];
        const slicedResults = isClickSelect ? results : results.slice(1);

        // If select by clicking, sort ALL the results by distance to the selected point.
        // Otherwise, sort THE REST OF the results by distance to the header result.
        const sortedResults = sortResultsByDistance(
            controller,
            slicedResults,
            center,
        );

        const allResults = isClickSelect
            ? sortedResults
            : [headerResult, ...sortedResults];

        const showFullSearch = () => {
            const checkIfModelIsSupported = (
                model: Maybe<string> | undefined,
            ): boolean => {
                const gqlType =
                    model && isModelSupported(model)
                        ? getTypeByModel(model)
                        : null;
                const form = gqlType ? getFormConfig(gqlType) : null;
                const isSupported = gqlType && form;
                return !!isSupported;
            };

            const convertedResults =
                allResults
                    .filter((result) => checkIfModelIsSupported(result.model))
                    .map((result) =>
                        convertInfoToolResultToSearchResult(result),
                    ) ?? [];

            setSearchResults(convertedResults);

            setLeftMenuContent(`FullSearch`, <FullSearch />, false);
            closeToolbarPopup();
        };

        const [firstListResult, ...listResults] = allResults;
        const nearbyResults = listResults.slice(0, 10);

        return (
            <>
                <ToolbarPopupFirstListResult>
                    {renderToolbarPopupResult(firstListResult, -1, false)}
                </ToolbarPopupFirstListResult>
                {renderToolbarPopupList(nearbyResults)}
                <ToolbarPopupButtons>
                    <Button
                        kind="secondary"
                        data-testid="toolbarPopup-zoomToAll"
                        onClick={() => {
                            zoomToAllResults(allResults);
                        }}
                    >
                        {t('zoomToAll')}
                    </Button>
                    <Button
                        onClick={showFullSearch}
                        data-testid="toolbarPopup-showMore"
                    >
                        {t('showMore')}
                    </Button>
                </ToolbarPopupButtons>
            </>
        );
    };

    const renderToolbarPopupResult = (
        result: InfoToolResult,
        index: number,
        isLastItem?: boolean,
    ) => (
        <div key={result.pk} data-testid={`toolbarPopup-result-${index}`}>
            <ToolbarPopupListResultContainer
                icon={getModelIcon(result.model, product)}
                text={
                    isEmpty(result.identification)
                        ? getGenericLabel(result.model, result.pk!)
                        : result.identification
                }
                onClickAction={() => {
                    if (result.location) {
                        const geometry = controller.wktToGeometry(
                            result.location,
                        );
                        highlightMapObject(geometry);
                    }
                }}
                onLeftButtonAction={() => {
                    setMenuContent(
                        `InfoObject-${result.model}-${result.pk}`,
                        <InfoObject model={result.model!} id={result.pk!} />,
                    );
                }}
                leftButtonIcon={<Icons.Open />}
                rightButtonIcon={<Icons.Modify2 />}
                rightButtonDisabled={user?.permissions?.edit === false}
                alwaysShowButtons={isMobile}
                testIds={{
                    toggleObjectItem: `toolbarPopup-result-item-${index}`,
                    leftButton: `toolbarPopup-result-item-${index}-leftButton`,
                    rightButton: `toolbarPopup-result-item-${index}-rightButton`,
                }}
            />
            {!isLastItem && <ObjectDivider />}
        </div>
    );

    const renderToolbarPopupList = (list: InfoToolResult[]) => {
        if (list.length < 1) return <></>;

        return (
            <>
                <ToolbarPopupNearbyObjects>
                    {t('objectOverlay.nearbyObject')}
                </ToolbarPopupNearbyObjects>
                {list.map((value, i) =>
                    renderToolbarPopupResult(value, i, i === list.length - 1),
                )}
            </>
        );
    };

    return isToolbarPopup ? renderToolbarPopup() : renderSimplePopup();
};

interface DraggableHeaderProps {
    closeFunction?: () => void;
    style?: React.CSSProperties;
    testId?: string;
}

/**
 * Header component that controls the draggable toolbar popup.
 * All the tools are placed in this component.
 */
export const DraggableHeader = ({
    closeFunction,
    style,
    testId = '',
}: DraggableHeaderProps) => {
    const draggableRef = useDraggableHandle();

    return (
        <StyledDraggableHeader
            style={style}
            ref={draggableRef}
            data-testid={`draggableHeader${testId}`}
        >
            <FloatingToolbar />
            {closeFunction && (
                <DraggableHeaderCloseBtn
                    kind="ghost"
                    onClick={closeFunction}
                    data-testid="toolbarPopup-closeButton"
                >
                    <Icons.Cross />
                </DraggableHeaderCloseBtn>
            )}
        </StyledDraggableHeader>
    );
};
