import { Node } from './Node';
import { Shape } from './Shape';
import { RaphaelPath } from 'raphael';
import { circumRadius, pointTurnDir } from '../utils';
import { DiagramData, DiagramOptions } from '../types';

export class Edge extends Shape {
    /** Path elment of the edge */
    path: RaphaelPath | null = null;

    /** Start node that edge is connecting. */
    startNode: Node;

    /** End node that edge is connecting. */
    endNode: Node;

    /**
     * The position where the edge labelElem will be drawn. This position is also used as
     * the third piont, together with startNode and endNode points, to determine the
     * circular curve of the edge.
     */
    labelAnchor: [number, number];

    constructor(
        options: DiagramOptions,
        data: DiagramData,
        startNode: Node,
        endNode: Node,
    ) {
        super(options, data);

        this.startNode = startNode;
        this.endNode = endNode;

        if (data.x && data.y) {
            this.labelAnchor = [data['x'], data['y']];
        } else {
            const sxy = this.startNode.getXY();
            const exy = this.endNode.getXY();
            this.labelAnchor = [(sxy[0] + exy[0]) / 2, (sxy[1] + exy[1]) / 2];
        }
    }

    render() {
        let pathStr = null;
        const s = this.startNode.getXY(),
            m = this.labelAnchor,
            e = this.endNode.getXY();
        const turnDir = pointTurnDir(m, s, e);
        if (turnDir === 0) {
            pathStr = 'M' + s.join(',') + 'L' + e.join(',');
        } else {
            const sweepFlag = turnDir === 1 ? '1' : '0';
            const r = circumRadius(s, m, e);
            pathStr =
                'M' +
                s.join(',') +
                'A' +
                [r, r, 0, 0, sweepFlag, e[0], e[1]].join(',');
        }

        this.path = this.paper.path(pathStr).attr({
            'stroke-width': this.lineWidth,
            stroke: this.lineColor,
        });

        this.labelElem = this.paper.text(m[0], m[1], this.objectLabel).attr({
            'font-size': this.fontSize,
            'text-anchor': 'start',
            fill: this.textColor,
        });

        this.elemSet.push(this.path, this.labelElem);
        super.render();
    }

    getStartNode() {
        return this.startNode;
    }

    getEndNode() {
        return this.endNode;
    }

    /**
     * Get path element
     */
    getPathElem() {
        return this.path;
    }

    /**
     * Get edge anchor xy coordinate
     */
    getAnchorXY() {
        return [this.labelAnchor[0], this.labelAnchor[1]];
    }
}
