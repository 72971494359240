import {
    NodeObjectSelectorRpcResponse,
    NodeObjectSelectorRpcFilterParams,
    NodeObjectSelectorRpcQuery,
} from '@generated';
import { sendGraphQLRequest } from '@utils';
import NodeObjectSelectorRPC from './queries/NodeObjectSelectorRPC.graphql';

/**
 * Search using nodeObjectSelector RPC.
 * @returns {Promise<NodeObjectSelectorRpcResponse>} The node object selector rpc response.
 * @throws {Error} If the request fails.
 */
export const nodeObjectSelectorRPC = async (
    args: NodeObjectSelectorRpcFilterParams,
): Promise<NodeObjectSelectorRpcResponse> => {
    try {
        const response = await sendGraphQLRequest<NodeObjectSelectorRpcQuery>(
            NodeObjectSelectorRPC,
            {
                input: args,
            },
        );

        if (!response.data.nodeObjectSelectorRPC) {
            throw Error('No node object selector data returned');
        }

        return response.data.nodeObjectSelectorRPC;
    } catch (error) {
        throw Error('Failed node object selector rpc: ' + error);
    }
};
