import { useContext, useEffect, useState } from 'react';
import {
    MapContext,
    StateEvent,
    Button,
    Utils,
    DrawShape,
    MEASURE_LAYER,
    useIsMobile,
    Input,
    Icons,
    styled,
    QuickSearchResultCard,
    QuickSearchResultContainer,
} from '@keypro/2nd-xp';
import { Feature } from 'ol';
import Polygon from 'ol/geom/Polygon';
import { Geometry } from 'ol/geom';
import { getArea, getLength } from 'ol/sphere.js';
import { t } from 'i18next';
import { useMobileMenu, useSearchStore } from '@stores';
import { infoToolPolygonHint } from '@components/AppToolbar/Common';
import { Maybe, QuickAddressSearchRpcResult } from '@generated';
import { quickAddressSearchRPC } from '@apis/keycore';
import { pointToPolygon } from '@components/utils';
import { coordinateRegex } from '../utils';
import { useResponsivePan } from '@hooks/map';
import { getTenant } from '@utils';

const LOCATION_LAYER = 'location_search';

const POINT_DISPLAY_SCALE_MULTIPLIER = 0.007;

type ResultCoordinates = Maybe<number> | undefined;

interface SearchResult extends QuickAddressSearchRpcResult {
    isRecent?: boolean;
}

interface RecentLocations {
    recentCoordinate?: SearchResult;
    recentAddress?: SearchResult;
}

/**
 * Component that allows the users to filter search results by location.
 *
 * Users can filter search results by location using one of the following methods:
 * - Drawing a polygon on the map.
 * - Searching for an address. The area around the address will be selected as the location filter.
 * - Typing in exact coordinates. The area around the coordinates will be selected as the location filter.
 *
 * @returns {JSX.Element} The location filter component.
 */
export const LocationFilter = ({ ...rest }): JSX.Element => {
    const controller = useContext(MapContext)!;
    const { locationFilter, setLocationFilter } = useSearchStore();
    const { setMenuHeight, setMenuOverlayContent, menuOverlayContent } =
        useMobileMenu();
    const isMobile = useIsMobile();
    const [active, setActive] = useState<boolean>(false);
    const [displayValue, setDisplayValue] = useState('');
    const [timeoutId, setTimeoutId] = useState<number | null>(null);
    const [qasData, setQasData] = useState<QuickAddressSearchRpcResult[]>([]);
    const [quickAddressSearchActive, setQuickAddressSearchActive] =
        useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const responsivePan = useResponsivePan(controller);

    const recentLocations: RecentLocations = JSON.parse(
        localStorage.getItem(`recentLocations${getTenant(true)}`) ?? '{}',
    );

    useEffect(() => {
        const layerExists = () =>
            controller.layers.findLayerByName(LOCATION_LAYER);

        const createFilterLayer = () => {
            if (!layerExists())
                controller.layers.createVectorLayer(LOCATION_LAYER);
        };

        const onStateChange = (event: StateEvent) => {
            const oldState = event.oldState as DrawShape;
            const newState = event.newState;
            setActive(event.newState?.owner === LocationFilter);

            // Clear the measure layer if the state is changed to LocationFilter state
            if (newState?.owner === LocationFilter) {
                createFilterLayer();
                if (controller.layers.findLayerByName(MEASURE_LAYER)) {
                    controller.layers.clearLayer(MEASURE_LAYER);
                    controller.tooltip.hide();
                }
            }

            if (
                newState !== null ||
                oldState?.isDrawing ||
                (layerExists() &&
                    !controller.layers.getFeatures(LOCATION_LAYER).length)
            ) {
                controller?.layers.clearLayer(LOCATION_LAYER);
                controller?.tooltip.hide();
            }
        };

        controller.on('stateChange', onStateChange);

        return () => {
            controller.off('stateChange', onStateChange);
        };
    }, [controller]);

    useEffect(() => {
        // When canceled with the back button in MobileDetailedToolbar
        if (menuOverlayContent === null) controller.state.deactivate();
    }, [menuOverlayContent, controller]);

    const getAreaAndParameter = (geometry: Geometry) => {
        const area = Utils.formatArea(getArea(geometry));
        const perimeter = Utils.formatLength(getLength(geometry));

        return `${t('measureToolTooltipAreaLabel')}: ${area}, ${t('measureToolTooltipPerimeterLabel')}: ${perimeter}`;
    };

    const clearIfLayerExist = () => {
        const layerExists = controller?.layers.findLayerByName(LOCATION_LAYER);
        if (layerExists) {
            controller?.layers.clearLayer(LOCATION_LAYER);
            controller?.tooltip.hide();
        }
    };

    const clearLocationFilter = () => {
        locationFilter?.clearIfLayerExist();
        setLocationFilter(null);
    };

    const setupMobileMenuContent = () => {
        clearLocationFilter();
        setMenuHeight(0);
        setMenuOverlayContent(<DrawConfirm />);
    };

    const onDrawend = (feature: Feature) => {
        const geometry = feature.getGeometry();
        if (!geometry) return;

        const coords = (geometry as Polygon).getCoordinates()[0]; // Get coordinates of the polygon

        const content = getAreaAndParameter(geometry);
        useSearchStore.setState((state) => ({
            selectedGroups: state.selectedGroups.includes('location')
                ? state.selectedGroups
                : [...state.selectedGroups, 'location'],
            locationFilter: {
                coordinate: coords,
                clearIfLayerExist,
                textDescription: content,
            },
        }));

        // Keeping the selected area visible
        controller?.layers.addFeature(LOCATION_LAYER, feature);
    };

    const activate = () => {
        const state = controller.state.activate(
            'drawPolygon',
            LocationFilter,
            infoToolPolygonHint,
        );

        state.on('end', onDrawend).once('deactivate', () => {
            state.off('end', onDrawend);
        });
    };

    const toggle = () => {
        setQuickAddressSearchActive(false);
        if (!active) {
            activate();
            if (isMobile) {
                setupMobileMenuContent();
            }
        } else {
            controller.state.deactivate();
        }
    };

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const searchValue = event.target.value;
        setDisplayValue(searchValue);

        // Check if the search value is a coordinate
        const coordinateMatches = !!coordinateRegex.exec(searchValue);
        if (coordinateMatches) {
            const [lon, lat] = searchValue
                .split(',')
                .map((coord) => parseFloat(coord));
            locateAndSetLocationFilter(lon, lat, searchValue);
            // save the coordinate to recent locations
            localStorage.setItem(
                `recentLocations${getTenant(true)}`,
                JSON.stringify({
                    ...recentLocations,
                    recentCoordinate: {
                        name: searchValue,
                        lon,
                        lat,
                        isRecent: true,
                    },
                }),
            );
            return;
        }

        if (timeoutId) {
            clearTimeout(timeoutId);
        }
        setLoading(true);

        const newTimeoutId = window.setTimeout(() => {
            quickAddressSearchRPC(searchValue)
                .then((response) => {
                    setQasData(response);
                    setQuickAddressSearchActive(true);
                })
                .catch((error) => {
                    console.error('Quick address search error: ', error);
                })
                .finally(() => {
                    setLoading(false);
                });
        }, 250);

        setTimeoutId(newTimeoutId);
    };

    const onFocus = () => {
        setLocationFilter(null);
        setQuickAddressSearchActive(true);
        controller.state.deactivate();
    };

    const onBlur = () => {
        // Delay hiding the search results to allow the user to click on the results
        window.setTimeout(() => {
            setQuickAddressSearchActive(false);
        }, 200);
    };

    const locateAndSetLocationFilter = (
        lon: ResultCoordinates,
        lat: ResultCoordinates,
        displayName: string,
    ) => {
        if (!lon || !lat || !controller) return;
        clearIfLayerExist();

        if (isMobile) {
            setMenuHeight(50);
        }

        responsivePan([lon, lat], 50);

        const size = controller.displayScale * POINT_DISPLAY_SCALE_MULTIPLIER;
        const polygon = pointToPolygon([lon, lat], size);

        useSearchStore.setState((state) => ({
            selectedGroups: state.selectedGroups.includes('location')
                ? state.selectedGroups
                : [...state.selectedGroups, 'location'],
            locationFilter: {
                coordinate: polygon.getCoordinates()[0],
                clearIfLayerExist,
                textDescription: displayName,
            },
        }));
        const feature = new Feature(polygon);

        controller.layers.createVectorLayer(LOCATION_LAYER);
        controller?.layers.addFeature(LOCATION_LAYER, feature);
        setQuickAddressSearchActive(false);
    };

    const renderQuickAddressSearch = (result: SearchResult, index: number) => {
        const { name, streetname, lat, lon, isRecent } = result;
        const displayName = name ?? streetname ?? '';
        const recentLocationType =
            displayName === recentLocations.recentCoordinate?.name
                ? 'recentCoordinate'
                : 'recentAddress';

        return (
            <QuickSearchResultCard
                key={index}
                name={displayName}
                objectIcon={
                    isRecent ? <Icons.ClockSearch /> : <Icons.AddressPoint />
                }
                locateIcon={
                    <Icons.Locate2 data-tooltip={t('locateAndHighlight')} />
                }
                searchTerm={displayValue}
                onLocateAction={() => lon && lat && responsivePan([lon, lat])}
                data-testid={`quickAddressSearch-result-${index}`}
                onClickAction={() => {
                    locateAndSetLocationFilter(lon, lat, displayName);
                    // save the address or coordinate to recent locations
                    localStorage.setItem(
                        `recentLocations${getTenant(true)}`,
                        JSON.stringify({
                            ...recentLocations,
                            [recentLocationType]: {
                                name: displayName,
                                lon,
                                lat,
                                isRecent: true,
                            },
                        }),
                    );
                }}
                alwaysShowButtons={!!lat && !!lon && !isMobile}
            />
        );
    };

    const renderQuickSearchWrapper = (children: React.ReactNode) => (
        <QuickSearchResultsContainer>
            <QuickSearchResultContainer data-testid="search-results">
                {children}
            </QuickSearchResultContainer>
        </QuickSearchResultsContainer>
    );

    const renderQuickSearchResultsContainer = () => {
        if (loading)
            return renderQuickSearchWrapper(
                <StyledLoadingAndNoResults>
                    <Icons.Spinner />
                </StyledLoadingAndNoResults>,
            );

        const searchResults: SearchResult[] = [
            recentLocations.recentAddress as SearchResult,
            recentLocations.recentCoordinate as SearchResult,
            ...qasData,
        ].filter(Boolean);

        if (searchResults.length === 0 || !quickAddressSearchActive)
            return null;

        return renderQuickSearchWrapper(
            <StyledScrollableResults>
                {searchResults.map((result, i) =>
                    renderQuickAddressSearch(result, i),
                )}
            </StyledScrollableResults>,
        );
    };

    return (
        <StyledGeographicalLocation>
            <StyledControllerContainer>
                <Input
                    inputProps={{
                        value: locationFilter?.textDescription ?? displayValue,
                        placeholder: t('locationFilter.placeholder'),
                        onChange: onChange,
                        onFocus: onFocus,
                        onBlur: onBlur,
                        onKeyDown: (
                            e: React.KeyboardEvent<HTMLInputElement>,
                        ) => {
                            if (e.key === 'Enter') {
                                // Blur the input field to close the virtual keyboard on mobile
                                if (isMobile) {
                                    (e.target as HTMLInputElement).blur();
                                }
                            }
                        },
                    }}
                    rightIcon={
                        locationFilter ? (
                            <EndButton
                                clearLocationFilter={() => {
                                    clearLocationFilter();
                                    setDisplayValue('');
                                }}
                            />
                        ) : undefined
                    }
                />
                {renderQuickSearchResultsContainer()}
                <Button
                    {...rest}
                    onClick={toggle}
                    data-testid="advanced-search-location-filter-button"
                    label={t('locationFilter.manualSelection.btnLabel')}
                    kind={active ? 'primary' : 'secondary'}
                >
                    <Icons.PolygonSelection />
                </Button>
            </StyledControllerContainer>
            <StyledControllerDescription>
                {t('locationFilter.description')}
            </StyledControllerDescription>
        </StyledGeographicalLocation>
    );
};

/**
 * The DrawConfirm appears in the mobile MenuOverlay as confirm button for draw shape.
 * @returns JSX.Element
 */
const DrawConfirm = () => {
    const { locationFilter } = useSearchStore();
    const { setMenuOverlayContent, setMenuHeight } = useMobileMenu();

    if (!locationFilter) return <></>;

    return (
        <StyledOverlayContainer>
            <StyledBtnConfirmLocation
                label={t('locationFilter.drawConfirm.btnLabel')}
                data-testid="advanced-search-location-filter-confirm"
                onClick={() => {
                    setMenuHeight(100);
                    setMenuOverlayContent(null);
                    locationFilter.clearIfLayerExist();
                }}
            />
        </StyledOverlayContainer>
    );
};

interface EndButtonProps {
    clearLocationFilter: () => void;
}
/**
 * The EndButton appears at the end (the right most) of the input field as clear button for selected location.
 * @param clearLocationFilter - The function to handle clearing the selected location (location filter).
 * @returns JSX.Element
 */
const EndButton = ({ clearLocationFilter }: EndButtonProps) => {
    return (
        <StyledEndButtons>
            <StyledCloseButton
                kind="ghost"
                onClick={clearLocationFilter}
                data-testid="clear-location-filter-button"
            >
                <Icons.Cross2 />
            </StyledCloseButton>
        </StyledEndButtons>
    );
};

const StyledBtnConfirmLocation = styled(Button)`
    position: absolute;
    top: -86px;
    width: 142px;
    height: 40px;
    background: #1c2023e5;

    & > div {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
    }
`;

const StyledOverlayContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    position: absolute;
`;

const StyledGeographicalLocation = styled.div`
    padding: 16px;
    gap: 8px;
    display: grid;
`;

const StyledEndButtons = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
`;

const StyledCloseButton = styled(Button)`
    background-color: ${(props) => props.theme.colors.neutral[50]};
    width: 20px;
    height: 20px;
    border-radius: 50%;

    svg {
        width: 9px;
        height: 9px;

        path {
            stroke-width: 4px;
            color: ${(props) => props.theme.colors.neutral[90]};
        }
    }

    &:hover {
        background-color: ${(props) =>
            props.theme.colors.neutral[60]} !important;
    }
`;

const QuickSearchResultsContainer = styled.div<{ $isMobile?: boolean }>`
    position: absolute;
    top: 100%;

    z-index: 100;
    width: 100%;

    border: 1px solid ${(props) => props.theme.colors.neutral[30]};
    border-radius: 8px;

    & > div,
    & > div > div {
        background-color: ${(props) => props.theme.colors.neutral[20]};
    }

    & > div {
        border-top: 1px solid ${(props) => props.theme.colors.neutral[30]};
    }

    & > div:last-of-type {
        border-radius: 0 0 8px 8px;
    }

    ${(props) =>
        props.$isMobile &&
        `
        top: 0;
        background-color: ${props.theme.colors.neutral[20]};
        overflow-y: auto;
    `}
`;

const StyledControllerContainer = styled.div`
    display: flex;
    direction: row;
    gap: 8px;
    position: relative;
`;

const StyledControllerDescription = styled.div`
    ${(props) => props.theme.fonts['12px Regular']}
    color: ${(props) => props.theme.colors.neutral['80']};
    line-height: 16px;
    font-weight: 400;
`;

const StyledLoadingAndNoResults = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
`;

const StyledScrollableResults = styled.div`
    max-height: 320px;
    overflow-y: auto;
`;
