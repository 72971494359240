import { themes, ThemeProvider } from '@keypro/2nd-xp';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';

import './i18n/i18n';
import { LoginPage } from './pages/LoginPage';
import { ErrorPage, MainPage, LoadingPage } from '@pages';
import { AuthContext } from '@providers';
import { ErrorBoundary } from '@components';
import { useProductStore } from '@stores';

Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [],
    enabled: import.meta.env.PROD,
});

const getPageTitle = (product: string) => {
    switch (product) {
        case 'keycom':
            return 'KeyCom Nexus';
        case 'keyaqua':
            return 'KeyAqua Nexus';
        default:
            return 'KeyCom Nexus';
    }
};

export default function App() {
    const { isAuthenticated, isLoading, user, authMethod } =
        useContext(AuthContext);
    const { i18n } = useTranslation();
    const { product } = useProductStore();

    useEffect(() => {
        const storedLanguageCode = localStorage.getItem('language');
        if (storedLanguageCode) {
            i18n.changeLanguage(storedLanguageCode);
        }
    }, [i18n]);

    useEffect(() => {
        document.title = getPageTitle(product ?? '');
    }, [product]);

    if (isLoading && authMethod !== 'default') {
        return <LoadingPage />;
    }

    const urlParams = window.location.search;
    if (urlParams.length > 0) {
        localStorage.setItem('urlParams', urlParams);
    }

    if (isAuthenticated) {
        const urlParams = localStorage.getItem('urlParams');
        if (urlParams) {
            const url = new URL(window.location.href);
            url.search = urlParams;
            window.history.replaceState({}, document.title, url.toString());
            localStorage.removeItem('urlParams');
        }
    }
    return (
        <ThemeProvider theme={themes.darkTheme}>
            <ErrorBoundary fallback={<ErrorPage />}>
                {user && isAuthenticated ? <MainPage /> : <LoginPage />}
            </ErrorBoundary>
        </ThemeProvider>
    );
}
