// External libraries
import {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { t } from 'i18next';
import { Feature } from 'ol';
import { Coordinate } from 'ol/coordinate';
import GeoJSON from 'ol/format/GeoJSON';
import { Polygon } from 'ol/geom';

// Keypro components and hooks
import {
    Button,
    Checkbox,
    Icons,
    MapContext,
    MapController,
    ObjectDivider,
    Select,
    StateEvent,
    styled,
    Table,
    TableRoot,
    ToggleObjectItem,
    useToast,
} from '@keypro/2nd-xp';
import { useHighlightMapObject } from '@hooks/map';

// Internal modules
import { nodeObjectSelectorRPC } from '@apis/keycore/nodeObjectSelectorRPC';
import { nodeRPC } from '@apis/keyduct/nodeRPC';
import { ductRPC } from '@apis/keyduct/ductRPC';
import { infoToolPolygonHint } from '@components/AppToolbar/Common';
import { handleOutsideClick } from '@components/utils';
import { MoveableContainer } from '@components/MoveableContainer';
import { DraggableHeader } from '@components/ObjectOverlay';
import { useToolsStore } from '@stores';
import {
    ToolbarPopupCopyBtn,
    ToolbarPopupDraggableFooter,
    ToolbarPopupLoading,
    ToolbarPopupNoResult,
} from '@components/ObjectOverlay/ObjectOverlay.styles';

// Generated types
import {
    DuctRpcResult,
    NodeObjectSelectorRpcResult,
    NodeRpcResult,
} from '@generated';

// Constants
const LOCATION_LAYER = 'node_selection';
const SEWER_TYPE = ['mixed water', 'sewer', 'storm water'];
const HEADER_HEIGHT = 56;

const getIndicatorColor = (type: 'Sewer' | 'Water' | '') => {
    switch (type) {
        case 'Sewer':
            return 'rgba(123, 66, 37, 1)';
        case 'Water':
            return 'rgba(56, 113, 224, 1)';
        default:
            return 'rgba(0, 0, 0, 0)';
    }
};

/**
 * NodeDuctsAndElevations component.
 * This component is used to display the Node Ducts and Elevations tool.
 * It displays a table with data about ducts and elevations.
 */
export const NodeDuctsAndElevations = (): JSX.Element => {
    const controller = useContext(MapContext)!;
    const toast = useToast();
    const [active, setActive] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(false);
    const [polygonCoordinates, setPolygonCoordinates] = useState<
        Coordinate[][] | null
    >(null);
    const { isNodeDuctsAndElevationsOpen, setNodeDuctsAndElevationsOpen } =
        useToolsStore();
    const [isExportOpen, setIsExportOpen] = useState(false);
    const exportBtnRef = useRef<HTMLDivElement>(null);
    const [isColumnMenuOpen, setIsColumnMenuOpen] = useState(false);
    const columnMenuBtnRef = useRef<HTMLDivElement>(null);
    const [lidElevationChecked, setLidElevationChecked] = useState(false);
    const [btmElevationChecked, setBtmElevationChecked] = useState(false);
    const [sortingColumnKey, setSortingColumnKey] = useState<string | null>(
        null,
    );

    const [sortingColumnDirection, setSortingColumnDirection] = useState<
        'asc' | 'desc' | undefined
    >(undefined);

    const [pinnedColumns, setPinnedColumns] = useState<{
        left: string[];
        right: string[];
    }>({ left: [], right: [] });

    const [openedMoreMenus, setOpenedMoreMenus] = useState<{
        [key: string]: boolean;
    }>({});

    const [objectSelectorList, setObjectSelectorList] = useState<
        NodeObjectSelectorRpcResult[]
    >([]);

    const [selectedNode, setSelectedNode] =
        useState<NodeObjectSelectorRpcResult | null>(null);
    const [nodeData, setNodeData] = useState<NodeRpcResult | null>(null);
    const [ductData, setDuctData] = useState<DuctRpcResult[]>([]);

    const { highlightMapObject } = useHighlightMapObject(
        controller,
        isNodeDuctsAndElevationsOpen,
        'nodetoolbar',
    );

    useEffect(() => {
        const onStateChange = (event: StateEvent) => {
            setActive(event.newState?.owner === NodeDuctsAndElevations);
        };
        controller.on('stateChange', onStateChange);
        return () => {
            controller.off('stateChange', onStateChange);
        };
    }, [controller]);

    const clearIfLayerExist = useCallback(() => {
        if (controller?.layers.findLayerByName(LOCATION_LAYER)) {
            controller?.layers.clearLayer(LOCATION_LAYER);
        }
    }, [controller]);

    const onDrawend = async (feature: Feature) => {
        const geometry = feature.getGeometry() as Polygon;
        if (!geometry) return;

        const coords = geometry.getCoordinates();
        setPolygonCoordinates(coords);
        controller?.layers.createVectorLayer(LOCATION_LAYER);
        controller?.layers.addFeature(LOCATION_LAYER, feature);
        setIsLoading(true);

        try {
            const response = await nodeObjectSelectorRPC({
                id: 1,
                filterIds: ['FILTER_MANHOLE_SURVEY_CARD'],
                geom: {
                    type: 'Feature',
                    properties: { _meta: { activeZLock: true, activeZ: '0' } },
                    geometry: {
                        type: 'Polygon',
                        coordinates: coords,
                    },
                },
                tolerance: calculateTolerance(3, controller),
                maxNumberOfItems: null,
                visibleLayers: controller.layers.getActiveLayerNames(),
            });

            if (response.success) {
                if (response.result) {
                    setObjectSelectorList(
                        response.result.filter(
                            (item): item is NodeObjectSelectorRpcResult =>
                                item !== null,
                        ),
                    );
                }
            }
        } catch (error) {
            console.error('API Error:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const activateDrawing = () => {
        clearIfLayerExist();
        const state = controller.state.activate(
            'drawPolygon',
            NodeDuctsAndElevations,
            infoToolPolygonHint,
        );
        state
            .on('end', onDrawend)
            .once('deactivate', () => state.off('end', onDrawend));
    };

    const toggleDrawing = () => {
        if (!active) {
            activateDrawing();
            handleClose();
        } else {
            controller.state.deactivate();
            clearIfLayerExist();
            setPolygonCoordinates(null);
        }
    };

    const calculateTolerance = (
        pixelTolerance: number,
        controller: MapController,
    ): number => {
        if (!controller?.map) {
            console.error('Map controller is not available');
            return 0;
        }

        const min = controller.map.getCoordinateFromPixel([0, 0]);
        const max = controller.map.getCoordinateFromPixel([
            pixelTolerance,
            pixelTolerance,
        ]);

        return max[0] - min[0];
    };

    const [selectedColumns, setSelectedColumns] = useState([
        'mslink',
        'zCoord',
        'fromLid',
        'zEstimated',
        'ductTypeNetworkTypeTxt',
        'ductTypeDuctTypeTxt',
    ]);

    const columnsMenuOptions = [
        {
            label: 'Duct id',
            value: 'mslink',
        },
        {
            label: 'Elevation',
            value: 'zCoord',
        },
        {
            label: 'From lid',
            value: 'fromLid',
        },
        {
            label: 'Estimated elevation',
            value: 'zEstimated',
        },
        {
            label: 'Net type',
            value: 'ductTypeNetworkTypeTxt',
        },
        {
            label: 'Type',
            value: 'ductTypeDuctTypeTxt',
        },
        {
            label: 'Pressure',
            value: 'ductTypeNetTypeTxt',
        },
        {
            label: 'Material',
            value: 'typeMaterialTxt',
        },
        {
            label: 'Diameter',
            value: 'typeDiameterIdTxt',
        },
        {
            label: 'Construct year',
            value: 'buildYear',
        },
    ];

    // Handle pin column
    const handlePinColumn = (
        column: string,
        position: 'left' | 'right' | 'none',
    ) => {
        setPinnedColumns((prev) => {
            const newPinnedColumns = { ...prev };

            // Delete the column from the current pinned list
            newPinnedColumns.left = newPinnedColumns.left.filter(
                (col) => col !== column,
            );
            newPinnedColumns.right = newPinnedColumns.right.filter(
                (col) => col !== column,
            );

            // Add the column to the new position
            if (position === 'left') {
                newPinnedColumns.left = [...newPinnedColumns.left, column];
            } else if (position === 'right') {
                newPinnedColumns.right = [...newPinnedColumns.right, column];
            }

            // Re-order the selected columns
            const unpinnedColumns = selectedColumns.filter(
                (col) =>
                    !newPinnedColumns.left.includes(col) &&
                    !newPinnedColumns.right.includes(col),
            );

            const newSelectedColumns = [
                ...newPinnedColumns.left,
                ...unpinnedColumns,
                ...newPinnedColumns.right,
            ];

            setSelectedColumns(newSelectedColumns); // Update the selected columns
            return newPinnedColumns;
        });
    };

    // Toggle more menu
    const toggleMoreMenu = (column: string) => {
        setOpenedMoreMenus((prev) => ({
            ...prev,
            [column]: !prev[column],
        }));
    };

    // Close all more menus
    const closeAllMoreMenus = () => {
        setOpenedMoreMenus({});
    };

    // Handle column menu change
    const handleColumnMenuChange = (value: string[]) => {
        if (!value.includes(sortingColumnKey as string)) {
            setSortingColumnKey(null);
            setSortingColumnDirection(undefined);
        }
        setSelectedColumns(value);
    };

    // Clear the selected columns
    const clearColumnMenu = () => {
        setSelectedColumns([]);
    };

    // Handle close
    const handleClose = useCallback(() => {
        setPolygonCoordinates(null);
        setObjectSelectorList([]);
        clearIfLayerExist();
        if (active) {
            controller.state.deactivate();
        }
    }, [active, controller.state, clearIfLayerExist]);

    const renderToolbarPopup = () => {
        if (polygonCoordinates === null) {
            return null;
        }
        const position = controller.map.getPixelFromCoordinate(
            polygonCoordinates?.[0]?.[0],
        );
        const [x, y] = position ?? [0, 0];
        const style = {
            width: '348px',
            left: `${x}px`,
            top: `${y}px`,
            transition: !isLoading
                ? 'top 300ms ease-in-out left 300ms ease-in-out'
                : 'none',
        };
        const [lon, lat] = polygonCoordinates?.[0]?.[0] ?? [0, 0];
        const coordinateString = `N: ${lon.toFixed(2)}, E: ${lat.toFixed(2)}`;

        return (
            <MoveableContainer
                style={style}
                data-testid="node-duct-toolbar-popup"
            >
                <DraggableHeader closeFunction={handleClose} />
                {isLoading ? (
                    <ToolbarPopupLoading>
                        <Icons.Spinner />
                    </ToolbarPopupLoading>
                ) : (
                    renderResults()
                )}
                <ToolbarPopupDraggableFooter>
                    {coordinateString}
                    <ToolbarPopupCopyBtn
                        kind="ghost"
                        onClick={() => {
                            if (lon && lat) {
                                navigator.clipboard
                                    .writeText(`${lon}, ${lat}`)
                                    .then(() => {
                                        toast.info(t('coordinatesCopied'));
                                    })
                                    .catch((err) => {
                                        console.error(err);
                                        toast.error(t('copyError'));
                                    });
                            }
                        }}
                        data-testid="node-duct-toolbar-popup-copy-coordinates"
                    >
                        <Icons.Copy />
                    </ToolbarPopupCopyBtn>
                </ToolbarPopupDraggableFooter>
            </MoveableContainer>
        );
    };

    useEffect(() => {
        const onToolStateChange = (isOpen: boolean) => {
            if (!isOpen) {
                handleClose();
            }
        };

        useToolsStore
            .getState()
            .registerStateChangeCallback(
                'nodeDuctsAndElevations',
                onToolStateChange,
            );

        return () => {
            useToolsStore
                .getState()
                .unregisterStateChangeCallback(
                    'nodeDuctsAndElevations',
                    onToolStateChange,
                );
        };
    }, [handleClose]);

    // Close the export menu when clicking outside of it
    useEffect(() => {
        return handleOutsideClick(exportBtnRef, setIsExportOpen, isExportOpen);
    }, [exportBtnRef, isExportOpen]);

    // Close the table column menu when clicking outside of it
    useEffect(() => {
        return handleOutsideClick(
            columnMenuBtnRef,
            setIsColumnMenuOpen,
            isColumnMenuOpen,
        );
    }, [columnMenuBtnRef, isColumnMenuOpen]);

    // Close all more menus when clicking outside of them
    useEffect(() => {
        const handleOutsideClick = (event: MouseEvent) => {
            if (
                columnMenuBtnRef.current &&
                !columnMenuBtnRef.current.contains(event.target as Node)
            ) {
                closeAllMoreMenus();
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [columnMenuBtnRef]);

    // Format the header text
    const formatHeader = (header: string) => {
        const headerLabel = columnsMenuOptions.find(
            (option) => option.value === header,
        )?.label;
        // Add spaces between camelCase words
        const formatted = (headerLabel ?? header).replace(
            /([a-z])([A-Z])/g,
            '$1 $2',
        );
        // Capitalize only the first letter and make the rest lowercase
        return (
            formatted.charAt(0).toUpperCase() + formatted.slice(1).toLowerCase()
        );
    };

    // Handle save
    const handleSave = (
        rowId: number,
        column: string,
        newValue: string | boolean,
    ) => {
        setDuctData((prevData) =>
            prevData.map((row) =>
                row.mslink === rowId ? { ...row, [column]: newValue } : row,
            ),
        );
    };

    // Handle sort
    const handleSort = (key: string, isTrgiggerBySortMenu: boolean) => {
        if (sortingColumnKey === key) {
            // Toggle sorting direction
            if (sortingColumnDirection === 'asc') {
                setSortingColumnDirection('desc');
            } else if (sortingColumnDirection === 'desc') {
                setSortingColumnKey(isTrgiggerBySortMenu ? key : null);
                setSortingColumnDirection(
                    isTrgiggerBySortMenu ? 'asc' : undefined,
                );
            }
        } else {
            // Set new column key and default sorting direction
            setSortingColumnKey(key);
            setSortingColumnDirection('asc');
        }
    };

    // Handle render pin column menu
    const renderPinColumnMenu = (column: string) => (
        <>
            <StyledMenuItem onMouseDown={() => handlePinColumn(column, 'none')}>
                <StyledCheckIcon
                    $isNoPin={
                        !pinnedColumns.left.includes(column) &&
                        !pinnedColumns.right.includes(column)
                    }
                />
                {t('pinColumn.noPin')}
            </StyledMenuItem>
            <StyledMenuItem onMouseDown={() => handlePinColumn(column, 'left')}>
                <StyledCheckIcon
                    $isPinnedLeft={pinnedColumns.left.includes(column)}
                />
                {t('pinColumn.toLeft')}
            </StyledMenuItem>
            <StyledMenuItem
                onMouseDown={() => handlePinColumn(column, 'right')}
            >
                <StyledCheckIcon
                    $isPinnedRight={pinnedColumns.right.includes(column)}
                />
                {t('pinColumn.toRight')}
            </StyledMenuItem>
        </>
    );

    // Render sort menu
    const renderSortMenu = (column: string) => (
        <StyledMenuItem onMouseDown={() => handleSort(column, true)}>
            {sortingColumnKey === column && sortingColumnDirection === 'asc'
                ? `${t('sort')} Z → A`
                : `${t('sort')} A → Z`}
        </StyledMenuItem>
    );

    // Handle remove column
    const handleRemoveColumn = (column: string) => {
        // Update pinned columns
        setPinnedColumns((prev) => ({
            left: prev.left.filter((col) => col !== column),
            right: prev.right.filter((col) => col !== column),
        }));

        // Update selected columns
        setSelectedColumns((prev) => prev.filter((col) => col !== column));
    };

    // Render remove column menu
    const renderRemoveColumnMenu = (column: string) => (
        <StyledMenuItem onMouseDown={() => handleRemoveColumn(column)}>
            {t('removeColumn')}
        </StyledMenuItem>
    );

    // Handle useEffect for sorting
    useEffect(() => {
        if (sortingColumnKey && sortingColumnDirection) {
            setDuctData(() => {
                const sortedData = [...ductData].sort((a, b) => {
                    const aValue = a[sortingColumnKey as keyof DuctRpcResult];
                    const bValue = b[sortingColumnKey as keyof DuctRpcResult];

                    if ((aValue ?? '') < (bValue ?? ''))
                        return sortingColumnDirection === 'asc' ? -1 : 1;
                    if ((aValue ?? '') > (bValue ?? ''))
                        return sortingColumnDirection === 'asc' ? 1 : -1;
                    return 0;
                });
                return sortedData;
            });
        }
    }, [sortingColumnKey, sortingColumnDirection, ductData]);

    // Handle return cell content
    const handleReturnCellContent = (
        row: DuctRpcResult,
        column: string,
        rowIndex: number,
        columnIndex: number,
    ) => {
        const value = row[column as keyof DuctRpcResult];
        let errorMessage: string | undefined;
        if (typeof value === 'string' && value.trim() === '') {
            errorMessage = `${formatHeader(column)} cannot be empty`;
        }

        if (typeof value === 'boolean') {
            return (
                <StyledTableCell
                    key={`${rowIndex}-${column}`}
                    colSpan={columnIndex === selectedColumns.length - 1 ? 2 : 1}
                >
                    <StyledEstimatedCheckbox
                        checked={value}
                        onChange={() =>
                            row.mslink !== undefined &&
                            row.mslink !== null &&
                            handleSave(row.mslink, column, !value)
                        }
                    />
                </StyledTableCell>
            );
        }

        return (
            <StyledTableCell
                key={`${rowIndex}-${column}`}
                isEditable={column !== 'mslink' && column !== 'netType'}
                value={String(value ?? '')}
                colSpan={columnIndex === selectedColumns.length - 1 ? 2 : 1}
                onSave={(newValue: string | boolean) =>
                    row.mslink !== undefined &&
                    row.mslink !== null &&
                    handleSave(row.mslink, column, newValue)
                }
                errorMessage={errorMessage}
            >
                {String(value ?? '')}
            </StyledTableCell>
        );
    };

    const type = useMemo(() => {
        if (!selectedNode) return '';

        return SEWER_TYPE.some((type) =>
            selectedNode?.label?.toLocaleLowerCase().includes(type),
        )
            ? 'Sewer'
            : 'Water';
    }, [selectedNode]);

    // Fetch node data
    useEffect(() => {
        const fetchNodeData = async () => {
            if (!selectedNode) return;

            try {
                const response = await nodeRPC({
                    encodedPk: selectedNode?.pk?.toString() ?? '',
                    type: type.toLowerCase(),
                });

                if (response.success) {
                    setNodeData(response.data?.items?.[0] || null);
                }
            } catch (error) {
                console.error('Error fetching node data:', error);
            }
        };

        fetchNodeData();
    }, [selectedNode, type]);

    // Fetch duct data
    useEffect(() => {
        const fetchDuctData = async () => {
            if (!selectedNode) return;

            try {
                const response = await ductRPC({
                    encodedPk: selectedNode?.pk?.toString() ?? '',
                    type: type.toLowerCase(),
                });

                if (response.success) {
                    setDuctData(
                        (response.data?.items ?? []).filter(
                            Boolean,
                        ) as DuctRpcResult[],
                    );
                }
            } catch (error) {
                console.error('Error fetching duct data:', error);
            }
        };

        fetchDuctData();
    }, [selectedNode, type]);

    // Render the cell menu
    const renderToolbarPopupResult = (
        result: NodeObjectSelectorRpcResult,
        index: number,
    ) => {
        // Parse the geometry JSON string
        const locationGeoJSON = result.geometry
            ? JSON.parse(result.geometry)
            : null;
        if (!locationGeoJSON) {
            return null;
        }
        const geometry = new GeoJSON().readGeometry(locationGeoJSON);
        return (
            <div key={result.pk} data-testid={`toolbarPopup-result-${index}`}>
                <StyledListResultContainer
                    text={result.label ?? ''}
                    onClickAction={() => {
                        setSelectedNode(result);
                        highlightMapObject(geometry);
                    }}
                />
                {index < objectSelectorList.length - 1 && <ObjectDivider />}
            </div>
        );
    };

    // Render the results
    const renderResults = () => {
        if (objectSelectorList.length === 0) {
            return (
                <ToolbarPopupNoResult>
                    <div>{t('objectOverlay.noResult')}</div>
                    <div>{t('objectOverlay.tryOtherPlace')}</div>
                </ToolbarPopupNoResult>
            );
        }

        return (
            <>
                {objectSelectorList.map((value, i) =>
                    renderToolbarPopupResult(value, i),
                )}
            </>
        );
    };

    return (
        <>
            <StyledNodeDuctsAndElevations
                id="nodetoolbar"
                data-testid="node-ducts-and-elevations"
            >
                <StyledHeader>
                    <StyledHeaderTitleAndIcon>
                        <StyledHeaderIcon>
                            <Icons.NodeDuct />
                        </StyledHeaderIcon>
                        <StyledHeaderTitle>
                            {t('nodeDuctsAndElevations')}
                        </StyledHeaderTitle>
                    </StyledHeaderTitleAndIcon>
                    <StyledHeaderButtons>
                        <Button
                            label={t('pickNode')}
                            kind="secondary"
                            onClick={toggleDrawing}
                        />
                        <StyledHeaderButtonExport ref={exportBtnRef}>
                            <Button
                                label={t('export')}
                                kind="primary"
                                iconPosition="right"
                                onClick={() => setIsExportOpen(!isExportOpen)}
                            >
                                <Icons.Down />
                            </Button>
                            <StyledExportsContainer
                                $isExportOpen={isExportOpen}
                            >
                                <StyledExportAction>
                                    <Button
                                        label="Excel"
                                        kind="ghost"
                                        iconPosition="left"
                                    >
                                        <Icons.ExcelFile />
                                    </Button>
                                </StyledExportAction>
                                <StyledExportAction>
                                    <Button
                                        label="PDF"
                                        kind="ghost"
                                        iconPosition="left"
                                    >
                                        <Icons.PdfFile />
                                    </Button>
                                </StyledExportAction>
                            </StyledExportsContainer>
                        </StyledHeaderButtonExport>
                        <StyledHeaderButtonClose
                            kind="ghost"
                            onClick={() => {
                                handleClose();
                                setNodeDuctsAndElevationsOpen(false);
                            }}
                            data-testid="node-ducts-and-elevations-close"
                        >
                            <Icons.Cross2 />
                        </StyledHeaderButtonClose>
                    </StyledHeaderButtons>
                </StyledHeader>
                <StyledContent>
                    <StyledDiagram />
                    <StyledInfo>
                        <StyledInfoNode>
                            <StyledInfoNodeIndicator $type={type} />
                            <StyledNameTypeAndAmount>
                                <StyledNameAndAmount>
                                    <StyledName>{nodeData?.mslink}</StyledName>
                                    <StyledAmount>
                                        {nodeData ? ductData.length : ''}
                                    </StyledAmount>
                                </StyledNameAndAmount>
                                <StyledType>
                                    {type === 'Sewer' ? t('sewer') : t('water')}
                                </StyledType>
                            </StyledNameTypeAndAmount>
                        </StyledInfoNode>
                        <StyledElevation>
                            <StyledElevationInfo>
                                <StyledElevationIconAndElevation>
                                    <StyledElevationIcon>
                                        <Icons.LidHeight />
                                    </StyledElevationIcon>
                                    <StyledEle>{nodeData?.zCoord1}</StyledEle>
                                </StyledElevationIconAndElevation>
                                <StyledElevationCaption>
                                    {t('lidElevation')}
                                </StyledElevationCaption>
                            </StyledElevationInfo>
                            <StyledEstimated>
                                <StyedCheckboxContainer
                                    onClick={(e) => {
                                        setLidElevationChecked(
                                            !lidElevationChecked,
                                        );
                                        e.preventDefault();
                                    }}
                                >
                                    <StyledEstimatedCheckbox
                                        checked={
                                            nodeData?.zCoord1Estimated ?? false
                                        }
                                    />
                                </StyedCheckboxContainer>
                                <StyledEstimatedCaption>
                                    {t('estimated')}
                                </StyledEstimatedCaption>
                            </StyledEstimated>
                        </StyledElevation>
                        <StyledElevation>
                            <StyledElevationInfo>
                                <StyledElevationIconAndElevation>
                                    <StyledElevationIconReverse>
                                        <Icons.LidHeight />
                                    </StyledElevationIconReverse>
                                    <StyledEle>{nodeData?.zCoord2}</StyledEle>
                                </StyledElevationIconAndElevation>
                                <StyledElevationCaption>
                                    {t('btmElevation')}
                                </StyledElevationCaption>
                            </StyledElevationInfo>
                            <StyledEstimated>
                                <StyedCheckboxContainer
                                    onClick={(e) => {
                                        setBtmElevationChecked(
                                            !btmElevationChecked,
                                        );
                                        e.preventDefault();
                                    }}
                                >
                                    <StyledEstimatedCheckbox
                                        checked={
                                            nodeData?.zCoord2Estimated ?? false
                                        }
                                    />
                                </StyedCheckboxContainer>
                                <StyledEstimatedCaption>
                                    {t('estimated')}
                                </StyledEstimatedCaption>
                            </StyledEstimated>
                        </StyledElevation>
                    </StyledInfo>
                    <StyledTableContainer
                        $selectedColumns={selectedColumns.length}
                    >
                        <TableRoot>
                            <Table.Head>
                                <Table.Row>
                                    {selectedColumns.map((column: string) => (
                                        <Table.Cell key={column}>
                                            <TableCellMenu
                                                sortable
                                                sortDirection={
                                                    sortingColumnKey === column
                                                        ? sortingColumnDirection
                                                        : undefined
                                                }
                                                onClickSorting={() =>
                                                    handleSort(column, false)
                                                }
                                                onClickMoreAction={() =>
                                                    toggleMoreMenu(column)
                                                }
                                                $isMoreMenuOpen={
                                                    openedMoreMenus[column]
                                                }
                                            >
                                                {formatHeader(column)}
                                            </TableCellMenu>
                                            {openedMoreMenus[column] && (
                                                <StyledMoreMenu
                                                    $isLastColumn={
                                                        selectedColumns[
                                                            selectedColumns.length -
                                                                1
                                                        ] === column
                                                    }
                                                >
                                                    <StyledMenuSection>
                                                        {renderSortMenu(column)}
                                                    </StyledMenuSection>
                                                    <StyledMenuSection>
                                                        <StyledMenuTitle>
                                                            {t(
                                                                'pinColumn.title',
                                                            )}
                                                        </StyledMenuTitle>
                                                        {renderPinColumnMenu(
                                                            column,
                                                        )}
                                                    </StyledMenuSection>
                                                    <StyledMenuSection>
                                                        {renderRemoveColumnMenu(
                                                            column,
                                                        )}
                                                    </StyledMenuSection>
                                                </StyledMoreMenu>
                                            )}
                                        </Table.Cell>
                                    ))}
                                    <Table.Cell>
                                        <StyledColumnMenuContainer
                                            ref={columnMenuBtnRef}
                                        >
                                            <Button
                                                kind={
                                                    isColumnMenuOpen
                                                        ? 'primary'
                                                        : 'ghost'
                                                }
                                                onClick={() => {
                                                    setIsColumnMenuOpen(
                                                        !isColumnMenuOpen,
                                                    );
                                                    closeAllMoreMenus();
                                                }}
                                            >
                                                <Icons.Settings2 />
                                            </Button>
                                            <StyledColumnMenu
                                                key={selectedColumns.join('-')}
                                                $isColumnMenuOpen={
                                                    isColumnMenuOpen
                                                }
                                                $selectedColumnsLength={
                                                    selectedColumns.length
                                                }
                                                filterLabels={{
                                                    addedFiltersText: t(
                                                        'advancedSearch.addedFilters',
                                                    ),
                                                    allFiltersText: t(
                                                        'advancedSearch.allFilters',
                                                    ),
                                                    clearSelectionText: t(
                                                        'advancedSearch.clearSelection',
                                                    ),
                                                    noResultsFound:
                                                        t('noSearchResults'),
                                                }}
                                                isFilterable
                                                isMultiSelect
                                                defaultValue={selectedColumns}
                                                onChangeValue={(
                                                    value: string | string[],
                                                ) =>
                                                    handleColumnMenuChange(
                                                        Array.isArray(value)
                                                            ? value
                                                            : [value],
                                                    )
                                                }
                                                onClearSelection={
                                                    clearColumnMenu
                                                }
                                                options={columnsMenuOptions}
                                                placeholder={t('searchColumn')}
                                            />
                                            <StyledConfigSearchMenuIcon
                                                $isColumnMenuOpen={
                                                    isColumnMenuOpen
                                                }
                                                $selectedColumnsLength={
                                                    selectedColumns.length
                                                }
                                            >
                                                <Icons.Search />
                                            </StyledConfigSearchMenuIcon>
                                        </StyledColumnMenuContainer>
                                    </Table.Cell>
                                </Table.Row>
                            </Table.Head>
                            <Table.Body>
                                {ductData?.map(
                                    (row: DuctRpcResult, rowIndex: number) => (
                                        <Table.Row key={row.mslink ?? rowIndex}>
                                            {selectedColumns.map(
                                                (
                                                    column: string,
                                                    columnIndex: number,
                                                ) =>
                                                    handleReturnCellContent(
                                                        row,
                                                        column,
                                                        rowIndex,
                                                        columnIndex,
                                                    ),
                                            )}
                                        </Table.Row>
                                    ),
                                )}
                            </Table.Body>
                        </TableRoot>
                    </StyledTableContainer>
                </StyledContent>
            </StyledNodeDuctsAndElevations>
            {renderToolbarPopup()}
        </>
    );
};

const StyledNodeDuctsAndElevations = styled.div`
    position: absolute;
    z-index: 1;
    top: 56px;
    left: 56px;
    width: 736px;
    height: calc(100vh - ${HEADER_HEIGHT}px);
    background-color: ${(props) => props.theme.colors.neutral['10']};
`;

const StyledHeader = styled.div`
    display: flex;
    justify-content: space-between;
    background-color: ${(props) => props.theme.colors.neutral[20]};
    padding: 12px 8px 12px 18px;
`;

const StyledHeaderTitleAndIcon = styled.div`
    display: flex;
    gap: 14px;
    align-items: center;
`;

const StyledHeaderIcon = styled.div`
    background: rgba(12, 128, 205, 1);
    width: 32px;
    height: 32px;
    border-radius: 8px;
    & svg {
        margin-left: 4px;
        margin-top: 4px;
    }
`;

const StyledHeaderTitle = styled.div`
    ${(props) => props.theme.fonts['18px Bold']};
`;

const StyledHeaderButtons = styled.div`
    display: flex;
    gap: 8px;
`;

const StyledHeaderButtonExport = styled.div`
    position: relative;
    & svg {
        width: 16px;
        height: 16px;
    }
`;

const StyledExportsContainer = styled.div<{ $isExportOpen?: boolean }>`
    display: ${(props) => (props.$isExportOpen ? 'block' : 'none')};
    width: 160px;
    border-radius: 8px;
    border: 1px solid ${(props) => props.theme.colors.neutral['50']};
    background-color: ${(props) => props.theme.colors.neutral['10']};
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
    box-sizing: border-box;
    position: absolute;
    z-index: 100;
    top: 40px;
    right: -10px;

    padding-top: 4px;
    padding-bottom: 4px;

    &::after {
        display: inline-block;
        width: 14px;
        height: 4px;
        position: absolute;

        transform: translate(-50%, -10px);
        top: auto;
        left: 90%;
        bottom: 100%;

        content: url('data:image/svg+xml;utf8,<svg viewBox="0 0 15 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.5 2.00024L1.5 6.00024L13.5 6.00024L7.5 2.00024Z" fill="${(
            props,
        ) =>
            encodeURIComponent(
                props.theme.colors.neutral['10'],
            )}" /><path d="M0.5 5.50024L1.5 5.50024L7.5 1.50024L13.5 5.50024L14.5 5.50024" stroke="${(
            props,
        ) => encodeURIComponent(props.theme.colors.neutral['50'])}" /></svg>');
    }
`;

const StyledExportAction = styled.div`
    padding-left: 4px;
    padding-right: 4px;
    & > button {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        border-radius: 4px;
        ${(props) => props.theme.fonts['14px Regular']};
        color: ${(props) => props.theme.colors.neutral['90']};
        & > svg {
            width: 20px;
            height: 20px;
        }
    }
`;

const StyledHeaderButtonClose = styled(Button)`
    color: ${(props) => props.theme.colors.neutral[90]};
    width: 32px;
    height: 32px;
    & svg {
        width: 16px;
        height: 16px;
    }
`;

const StyledContent = styled.div`
    background-color: ${(props) => props.theme.colors.neutral[10]};
`;

const StyledDiagram = styled.div`
    height: 224px;
    background-color: ${(props) => props.theme.colors.neutral[100]};
    margin: 16px;
    border-radius: 8px;
    & div {
        height: 224px !important;
    }
`;

const StyledInfo = styled.div`
    display: flex;
    gap: 8px;
    margin-left: 16px;
    margin-right: 16px;
`;

const StyledInfoNode = styled.div`
    background-color: ${(props) => props.theme.colors.neutral[20]};
    border-radius: 8px;
    display: flex;
    gap: 8px;
    width: 100%;
`;

const StyledInfoNodeIndicator = styled.div<{
    $type: 'Sewer' | 'Water' | '';
}>`
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: ${(props) => getIndicatorColor(props.$type)};
    border: 1px solid ${(props) => props.theme.colors.neutral[20]};
    margin-left: 16px;
    margin-top: 14px;
`;

const StyledNameTypeAndAmount = styled.div``;

const StyledNameAndAmount = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

const StyledName = styled.div`
    ${(props) => props.theme.fonts['16px Bold']};
    color: ${(props) => props.theme.colors.neutral[100]};
    padding-top: 12px;
`;

const StyledAmount = styled.div`
    padding: 0 4px;
    background-color: ${(props) => props.theme.colors.neutral[60]};
    font-family: Inter;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    color: ${(props) => props.theme.colors.neutral[100]};
    margin-top: 14px;
    border-radius: 4px;
`;

const StyledType = styled.div`
    ${(props) => props.theme.fonts['16px Regular']};
    color: ${(props) => props.theme.colors.neutral[80]};
`;

const StyledElevation = styled.div`
    display: flex;
    gap: 2px;
    width: 100%;
`;

const StyledElevationInfo = styled.div`
    background-color: ${(props) => props.theme.colors.neutral[20]};
    border-radius: 8px 0 0 8px;
    min-width: 124px;
`;

const StyledElevationIconAndElevation = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

const StyledElevationIcon = styled.div`
    margin-top: 14px;
    margin-left: 12px;
    & svg {
        width: 20px;
        height: 20px;
    }
`;

const StyledElevationIconReverse = styled.div`
    margin-top: 14px;
    margin-left: 12px;
    & svg {
        width: 20px;
        height: 20px;
        transform: rotate(180deg);
    }
`;

const StyledEle = styled.div`
    ${(props) => props.theme.fonts['14px Medium']};
    color: ${(props) => props.theme.colors.neutral[100]};
    margin-top: 12px;
`;

const StyledElevationCaption = styled.div`
    ${(props) => props.theme.fonts['13px Medium']};
    color: ${(props) => props.theme.colors.neutral[80]};
    margin-left: 12px;
`;

const StyledEstimated = styled.div`
    background-color: ${(props) => props.theme.colors.neutral[20]};
    border-radius: 0 8px 8px 0;
    padding: 8px 12px;
    width: 100%;
`;

const StyedCheckboxContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 8px;
    cursor: pointer;
`;

const StyledEstimatedCheckbox = styled(Checkbox)`
    border-radius: 4px;
    width: 14px;
    height: 14px;
`;

const StyledEstimatedCaption = styled.div`
    display: flex;
    justify-content: center;
    ${(props) => props.theme.fonts['13px Medium']};
    color: ${(props) => props.theme.colors.neutral[80]};
    margin-top: 4px;
`;

const StyledTableContainer = styled.div<{
    $selectedColumns: number;
}>`
    position: relative;
    margin-top: 16px;
    margin-bottom: 16px;
    height: calc(100vh - 506px);
    overflow-y: ${(props) => (props.$selectedColumns > 0 ? 'auto' : 'none')};
    max-width: 100%;
    overflow-x: auto;
    display: block;
    & table {
        width: 100%;
    }
    & td {
        min-width: 100px;
        width: 100px;
    }
    & td:first-child {
        width: auto;
    }

    & table > tbody > tr > td:first-child {
        padding-left: 12px;
        width: auto;
    }

    & table > thead > tr > td:last-child {
        min-width: 32px;
        width: 32px;
    }
    & table > tbody > tr > td:last-child {
        padding-right: 0;
        width: auto;
    }
    & table > tbody td {
        max-width: 100px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
        padding-top: 7px;
        padding-bottom: 7px;
    }
    & table > thead > tr > td > div {
        padding: 1px 8px 0 12px;
    }
`;

const TableCellMenu = styled(Table.CellMenu)<{
    $isMoreMenuOpen?: boolean;
}>`
    & button:last-child {
        ${(props) =>
            props.$isMoreMenuOpen
                ? `
                    visibility: visible;
                    background-color: ${props.theme.colors.neutral[60]};
                `
                : ''}
    }
                & button:first-child {
        ${(props) =>
            props.$isMoreMenuOpen
                ? `
                    right: 34px;
                `
                : ''}
`;

const StyledColumnMenuContainer = styled.div`
    position: relative;
`;

const StyledColumnMenu = styled(Select)<{
    $isColumnMenuOpen?: boolean;
    $selectedColumnsLength: number;
}>`
    display: ${(props) => (props.$isColumnMenuOpen ? 'block' : 'none')};
    text-transform: none;
    position: absolute;
    z-index: 100;
    min-width: 220px;
    width: 220px;
    top: 32px;
    right: 0;
    left: ${(props) => (props.$selectedColumnsLength > 0 ? 'unset' : '0')};
    & ul {
        display: ${(props) => (props.$isColumnMenuOpen ? 'block' : 'none')};
        margin-top: -2px;
        border-radius: 0 0 8px 8px;
    }
    & > div {
        border-radius: 8px 8px 0 0;
        background-color: ${(props) => props.theme.colors.neutral[10]};
        & span {
            display: none;
        }
        & > input {
            &::placeholder {
                color: ${(props) => props.theme.colors.neutral[80]};
            }
        }
        &:hover,
        &:focus,
        &:focus-within {
            box-shadow: none;
            border-color: ${(props) => props.theme.colors.neutral[60]};
        }
    }
`;

const StyledConfigSearchMenuIcon = styled.div<{
    $isColumnMenuOpen?: boolean;
    $selectedColumnsLength: number;
}>`
    position: absolute;
    display: ${(props) => (props.$isColumnMenuOpen ? 'block' : 'none')};
    right: 12px;
    top: 52px;
    left: ${(props) => (props.$selectedColumnsLength > 0 ? 'unset' : '192px')};
    z-index: 100;
    cursor: pointer;
    & svg {
        width: 16px;
        height: 16px;
        path {
            fill: ${(props) => props.theme.colors.neutral[80]};
            stroke: ${(props) => props.theme.colors.neutral[80]};
        }
    }
`;

const StyledMoreMenu = styled.div<{
    $isLastColumn: boolean;
}>`
    position: absolute;
    top: 24px;
    right: ${(props) => (props.$isLastColumn ? '0' : '-168px')};
    width: 200px;
    background-color: ${(props) => props.theme.colors.neutral['10']};
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 100;
    border: 1px solid ${(props) => props.theme.colors.neutral['50']};
`;

const StyledMenuItem = styled.div`
    display: flex;
    align-items: center;
    padding: 6px 12px;
    margin: 8px 4px;
    border-radius: 4px;
    cursor: pointer;
    gap: 8px;
    ${(props) => props.theme.fonts['14px Regular']};
    color: ${(props) => props.theme.colors.neutral['90']};
    &:hover {
        background-color: ${(props) => props.theme.colors.neutral['20']};
    }
`;

const StyledMenuSection = styled.div`
    &:not(:last-child) {
        border-bottom: 1px solid ${(props) => props.theme.colors.neutral['30']};
    }
    > ${StyledMenuItem}:not(:first-child) {
        margin-top: 0;
        margin-bottom: 0;
    }
`;

const StyledCheckIcon = styled(Icons.Check)<{
    $isPinnedLeft?: boolean;
    $isPinnedRight?: boolean;
    $isNoPin?: boolean;
}>`
    width: 20px;
    height: 20px;
    color: ${(props) => props.theme.colors.neutral['90']};
    visibility: ${(props) =>
        props.$isPinnedLeft || props.$isPinnedRight || props.$isNoPin
            ? 'visible'
            : 'hidden'};
`;

const StyledMenuTitle = styled.div`
    ${(props) => props.theme.fonts['12px Medium']};
    color: ${(props) => props.theme.colors.neutral['80']};
    padding: 12px 16px 8px 16px;
    text-transform: uppercase;
`;

const StyledTableCell = styled(Table.Cell)`
    min-width: 100px;
    max-width: 200px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const StyledListResultContainer = styled(ToggleObjectItem)`
    cursor: pointer;
    & button {
        display: none;
    }
`;
