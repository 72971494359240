import { TxtConstant } from '@generated';

export class ColorService {
    ductStates: { [key: string]: number | null } = {}; // Maps state id -> num_value
    ductColors: { [key: string]: string | null } = {}; // Maps state id -> txt_value

    // Colors used as fallback in case they're not defined in text constants
    defaultColors: { [key: number]: string } = {
        1: '0 185 0', // in use
        2: '255 0 0', // planned
        3: '190 190 190', // discarded
    };

    // Color used as fallback in case the duct type doesn't specify a color of
    // its own.
    unknownColor = '99 99 99';

    constructor(ductStates: TxtConstant[]) {
        for (const state of ductStates) {
            this.ductStates[state.id] = state.numValue ?? null;
            this.ductColors[state.id] = state.txtValue ?? null;
        }
    }

    /**
     * Build a string representing RGB value such as "rgb(127, 0, 255)".
     *
     * @param {string|array} components: either a string presentation of the
     * color or an array of color components.
     */
    makeRGB(components: string | string[]) {
        let splitComponents = components;

        if (typeof components === 'string') {
            splitComponents = components.split(' ');
        }

        return (
            'rgb(' +
            splitComponents[0] +
            ', ' +
            splitComponents[1] +
            ', ' +
            splitComponents[2] +
            ')'
        );
    }

    /**
     * Returns color string (in the format 'r g b') from txt_constants, or from the fallback colors if needed.
     * @param {number} state - Usage state of the cable as txt_constant id.
     */
    getColorStringFromState(state: number) {
        // Get primarily based on text constant id. If that fails, try based on num_value. If even that fails, use black.
        const colorString =
            this.ductColors[state] ||
            (this.ductStates[state] &&
                this.defaultColors[this.ductStates[state]]) ||
            this.unknownColor;
        // Make sure the format is okay
        if (/^\d+ \d+ \d+/.test(colorString)) {
            return colorString;
        } else {
            console.warn('Bad color format: %s', colorString);
            return this.unknownColor;
        }
    }
}
