import { t } from 'i18next';
import { useToast } from '@keypro/2nd-xp';
import { ErrorBoundary, ErrorBoundaryProps } from './ErrorBoundary';

/**
 * Custom error boundary component that shows a toast with a generic error message.
 */
export const ToastErrorBoundary = (props: ErrorBoundaryProps) => {
    const { fallback, onError, children, ...rest } = props;
    const toast = useToast();

    const handleErrors = (
        error: unknown,
        componentStack: string,
        eventId: string,
    ) => {
        toast.error(t('genericError'));
        onError?.(error, componentStack, eventId);
    };

    return (
        <ErrorBoundary fallback={fallback} onError={handleErrors} {...rest}>
            {children}
        </ErrorBoundary>
    );
};
