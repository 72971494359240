import { Icons, styled } from '@keypro/2nd-xp';
import { WATER_COLOR } from '../keyduct/icons.consts';

export const FreeLineIcon = styled(Icons.Cable)`
    path {
        fill: ${({ theme }) => theme.colors.neutral['100']};
        stroke: ${({ theme }) => theme.colors.neutral['100']};
    }
`;

export const AquaFreeLineIcon = styled(Icons.Cable)`
    path {
        fill: ${WATER_COLOR};
        stroke: ${WATER_COLOR};
    }
`;

export const ColoredAreaIcon = styled(Icons.Area)`
    color: ${(props) => props.theme.colors.neutral['60']};
`;
