// Libraries
import { t } from 'i18next';

// Component Library
import { Icons } from '@keypro/2nd-xp';

// Types
import { FormConfig, FormConfigs, FormData } from './types';

// Components
import { toCamelCase } from '@components/FormBuilder';

// Generated
import { ProductType } from '@generated';

// KeyCore
import Address from './keycore/Address';
import Apartment from './keycore/Apartment';
import Document from './keycore/Document';
import FreeArea from './keycore/FreeArea';
import FreeLine from './keycore/FreeLine';
import Plan from './keycore/Plan';
import Point from './keycore/Point';
import TxtConstant from './keycore/TxtConstant';

// KeyCom
import Cable from './keycom/Cable';
import Conduit from './keycom/Conduit';
import ExchangeArea from './keycom/ExchangeArea';
import Splice from './keycom/Splice';
import Manhole from './keycom/Manhole';
import Pole from './keycom/Pole';
import ServiceArea from './keycom/ServiceArea';
import TelecomArea from './keycom/TelecomArea';
import TelecomPremise from './keycom/TelecomPremise';

// KeyDuct
import ConsumerPoint from './keyduct/ConsumerPoint';
import Customer from './keyduct/Customer';
import SnConstant from './keyduct/SnConstant';
import DhConduit from './keyduct/DhConduit';
import SewerManhole from './keyduct/SewerManhole';
import SewerBranch from './keyduct/SewerBranch';
import WaterBranch from './keyduct/WaterBranch';
import SewerDuct from './keyduct/SewerDuct';
import WaterDuct from './keyduct/WaterDuct';
import SewerValve from './keyduct/SewerValve';
import WaterValve from './keyduct/WaterValve';
import SewerPumpingStation from './keyduct/SewerPumpingStation';
import WaterPumpingStation from './keyduct/WaterPumpingStation';

/**
 * All available form configurations.
 */
export const formConfigs = [
    // Keycore form configs
    Address,
    Apartment,
    Document,
    FreeArea,
    FreeLine,
    Plan,
    Point,
    TxtConstant,

    // Keycom form configs
    Cable,
    Conduit,
    ExchangeArea,
    Splice,
    Manhole,
    Pole,
    ServiceArea,
    TelecomArea,
    TelecomPremise,

    // Keyduct form configs
    ConsumerPoint,
    Customer,
    SnConstant,
    DhConduit,
    SewerManhole,
    SewerBranch,
    WaterBranch,
    SewerDuct,
    WaterDuct,
    SewerValve,
    WaterValve,
    SewerPumpingStation,
    WaterPumpingStation,
].reduce((acc, config) => {
    acc[config.gqlType] = config;
    return acc;
}, {} as FormConfigs);

/**
 * Get the form config for a given GraphQL type.
 * @param gqlType The GraphQL type to get the form config for.
 * @returns The form config for the given GraphQL type.
 */
export const getFormConfig = (gqlType: string): FormConfig => {
    if (formConfigs[gqlType]) {
        return formConfigs[gqlType];
    }
    throw new Error(`Missing form config for type: ${gqlType}`);
};

/**
 * Get the form config for a given model name.
 * @param model The model name to get the form config for.
 * @returns The form config for the given model name.
 */
export const getFormConfigByModel = (model: string): FormConfig => {
    const gqlType = getTypeByModel(model);
    return getFormConfig(gqlType);
};

/**
 * Get the field config for a given field name.
 * @param form The form config to get the field from.
 * @param fieldName The field name to get the config for.
 * @returns The field config for the given model name or null if not found.
 */
export const getFormField = (form: FormConfig, fieldName: string) => {
    for (const group of form.groups) {
        for (const field of group.fields) {
            if (typeof field === 'string') {
                if (field === fieldName) {
                    return { name: field };
                }
            } else if (field.name === fieldName) {
                return field;
            }
        }
    }

    return null;
};

/**
 * Get the GraphQL type name for a given model name.
 * @param model The model name.
 * @returns The GraphQL type name for the given model name.
 */
export const getTypeByModel = (model: string): string => {
    for (const key in formConfigs) {
        if (
            formConfigs[key].model === model ||
            formConfigs[key].modelAliases?.includes(model)
        ) {
            return key;
        }
    }
    throw new Error(`Missing type for model: ${model}`);
};

/**
 * Check if a given model is supported.
 * @param model The model name to check.
 * @returns True if the model is supported.
 */
export const isModelSupported = (model: string): boolean => {
    try {
        const type = getTypeByModel(model);
        return hasFormConfig(type);
    } catch {
        return false;
    }
};

/**
 * Check if a form config exists for a given GraphQL type.
 * @param gqlType The GraphQL type to check for a form config.
 * @returns True if a form config exists for the given GraphQL type.
 */
export const hasFormConfig = (gqlType: string): boolean => {
    return !!formConfigs[gqlType];
};

/**
 * Get the translated form title for a given GraphQL type.
 * Defaults to gqlType related title if no translation is found for alias.
 * @param gqlType The GraphQL type to get the translated title for.
 * @param alias The alias of the field to get the translated title for.
 * @returns The translated title for the given GraphQL type.
 */
export const getTranslatedTitle = (gqlType: string, alias?: string): string => {
    // Put translation key into a variable to prevent i18next-parser from gathering it
    const defaultKey = `${gqlType}.$title`;
    const key = alias ? `${gqlType}.$title.${alias}` : defaultKey;
    const translation = t(key);

    if (translation === '' || translation === key) {
        return t(defaultKey);
    }
    return translation;
};

/**
 * Get the translated form title in plural form for a given GraphQL type.
 * @param gqlType The GraphQL type to get the translated title for.
 * @returns The translated plural title for the given GraphQL type.
 */
export const getTranslatedPluralTitle = (gqlType: string): string => {
    // Put translation key into a variable to prevent i18next-parser from gathering it
    const key = `${gqlType}.$titlePlural`;
    return t(key);
};

/**
 * Get the translation key for a given field name.
 * @param gqlType The GraphQL type to get the translation key for.
 * @param fieldName The field name to get the translation key for.
 * @returns The translation key for the given field name.
 */
export const getTranslationKey = (gqlType: string, fieldName: string) => {
    const form = getFormConfig(gqlType);
    const fieldConfig = getFormField(form, fieldName);

    return fieldConfig?.translationKey
        ? fieldConfig.translationKey
        : toCamelCase(fieldName);
};

/**
 * Check if a given value is empty.
 * @param value The value to check.
 * @returns True if the value is empty.
 */
export const isEmpty = (value: unknown) => {
    return value === null || value === undefined || value === '';
};

/**
 * Generates a human-readable label based on the given object type and object data.
 * If label processor is not defined in the form configuration or it doesn't return
 * a non-empty string, "identification" or "name" field is used. If neither of
 * those fields are present or they are null, the label will be "ModelName ID".
 * @param gqlType The GraphQL type to get the label for.
 * @param data The object data.
 * @param model The model/modelAlias name.
 * @returns The label for the given model and data.
 */
export const getLabel = (gqlType: string, data: FormData, model?: string) => {
    const form = getFormConfig(gqlType);
    let label = null;

    if (form.labelFormatter) {
        label = form.labelFormatter(data);
    }

    if (isEmpty(label)) {
        label = data.identification ?? data.name;
    }

    if (isEmpty(label)) {
        label = `${getTranslatedTitle(gqlType, model)} ${data.id ?? data.pk ?? data.mslink}`;
    }

    return label as string;
};

/**
 * Generates a generic label based on the given model and ID.
 * @param model The model name.
 * @param id The object ID.
 * @returns The generic label for the given model and ID.
 */
export const getGenericLabel = (
    model: string | null | undefined,
    id: number | string,
) => {
    let name;

    if (model && isModelSupported(model)) {
        name = getTranslatedTitle(getTypeByModel(model), model);
    } else if (model) {
        name = model;
    } else {
        name = t('unknownObjectType');
    }

    return `${name} ${id}`;
};

/**
 * Get icon for the model or the default icon if there's no icon defined for the model.
 * @param model The model name.
 * @returns The icon component.
 */
export const getModelIcon = (
    model?: string | null,
    productType?: ProductType,
) => {
    if (model && isModelSupported(model)) {
        const form = getFormConfigByModel(model);
        const aliasIcon = form.aliasIcons?.[model];

        switch (productType) {
            case ProductType.Keycom:
                return aliasIcon ?? form.icon;
            case ProductType.Keyaqua:
                return aliasIcon ?? form.aquaIcon ?? form.icon;
            default:
                return aliasIcon ?? <Icons.Fat />;
        }
    }

    return <Icons.Fat />;
};

/**
 * Gets the geometry WKT string from the form data.
 * @param data The form data.
 * @returns The geometry WKT string or null if not found.
 */
export const getWKT = (data: FormData): string | null => {
    for (const key in data) {
        if (typeof data[key] === 'string') {
            const valueParts = data[key].split(';');
            const value = valueParts[valueParts.length - 1].trim();

            if (
                value.startsWith('POINT') ||
                value.startsWith('LINESTRING') ||
                value.startsWith('POLYGON') ||
                value.startsWith('MULTIPOINT') ||
                value.startsWith('MULTILINESTRING') ||
                value.startsWith('MULTIPOLYGON') ||
                value.startsWith('GEOMETRYCOLLECTION')
            ) {
                return value;
            }
        }
    }

    return null;
};
