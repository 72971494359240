import { Node } from './Node';
import { NodeChildData, NodesData } from '@generated';
import { DiagramOptions } from '../types';

export abstract class NodeGroup extends Node {
    /** Center point of the node group */
    cx: number;
    cy: number;

    /** Origin point of the node group (bottom left corner when angle is 0) */
    ox: number;
    oy: number;

    angle: number = 0;

    /** Child nodes of the group */
    childNodes: Node[] = [];

    constructor(options: DiagramOptions, data: NodesData) {
        super(options, data);

        /* Common attributes for all node groups */
        this.cx = data.x;
        this.cy = data.y;
        this.ox = data.ox;
        this.oy = data.oy;
        this.angle = data.angle;

        data.children?.forEach((child) => {
            const childNode = this.createChildNode(child);
            this.childNodes.push(childNode);
        });
    }

    abstract createChildNode(data: NodeChildData): Node;

    render() {
        this.childNodes.forEach((child) => {
            child.render();

            child.getElemSet().forEach((elem) => {
                this.elemSet.push(elem);
            });
        });

        super.render();
    }

    getChildNodes() {
        return this.childNodes;
    }

    getXY() {
        return [this.cx, this.cy];
    }

    getOXY() {
        return [this.ox, this.oy];
    }

    getAngle() {
        return this.angle;
    }
}
