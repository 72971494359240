// Generated
import {
    GetTxtConstantsQuery,
    TxtConstant,
    TxtConstantsFilter,
} from '@generated';

// Utils
import { sendGraphQLRequest } from '@utils';

// Queries
import GetTxtConstants from './queries/GetTxtConstants.graphql';

export const getTxtConstants = async (
    filter?: TxtConstantsFilter,
    limit?: number,
): Promise<TxtConstant[]> => {
    try {
        const queryVariables = {
            filter,
            limit,
        };
        const response = await sendGraphQLRequest<GetTxtConstantsQuery>(
            GetTxtConstants,
            queryVariables,
        );

        if (!response.data.getTxtConstants) {
            throw Error('No data returned');
        }

        return response.data.getTxtConstants.txtConstants?.filter(
            Boolean,
        ) as TxtConstant[];
    } catch (error) {
        throw Error('Failed to get txt constants: ' + error);
    }
};
