import { getSnConstants } from '@apis/keyduct';
import { FormConfig } from '../types';

const config: FormConfig = {
    gqlType: 'SnConstant',
    model: 'snconstant',
    groups: [],
    functions: {
        get: getSnConstants,
    },
    queryCollection: 'snConstants',
};

export default config;
