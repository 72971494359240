import { getSewerBranches } from '@apis/keyduct';
import { FormConfig } from '../types';
import { SewerBranchIcon } from './icons';

const config: FormConfig = {
    gqlType: 'SewerBranch',
    model: 'SnSewerBranch',
    modelAliases: ['snsewerbranch'],
    icon: <SewerBranchIcon />,
    groups: [
        {
            name: 'generalInformation',
            fields: ['feature'],
        },
    ],
    functions: {
        get: getSewerBranches,
    },
    queryCollection: 'sewerBranches',
};

export default config;
