import { NodeChildData } from '@generated';
import { Node } from './Node';
import { DiagramOptions } from '../types';

export class ConduitCable extends Node {
    /**
     * Default color for free cable.
     */
    cableColor: string = '#0000FF';

    fontSize: number = 200;

    cableNumber: string | null;
    scale: number;

    constructor(options: DiagramOptions, data: NodeChildData) {
        super(options, data);

        this.cableNumber = data.ductNumber ?? null; // here 'duct_number' actually means cableNumber
        this.scale = data.scale;

        /**
         * Try to preserve the conduit profile styles (line-width, font-size) while scaling up for diagram
         * In conduit profile, lines are in base unit, and duct number are proportional to the square root
         * of the actual diameter in milimetre
         */
        this.lineWidth = this.scale;
        this.fontSize = Math.floor(
            Math.sqrt((this.radius / this.scale) * 2) * 2 * this.scale,
        );
    }

    /**
     * Render free cable.
     */
    render() {
        this.renderCable();
        this.renderCableNumber();

        super.render();
    }

    /**
     * Render cable.
     */
    renderCable() {
        const circle = this.paper.circle(this.cx, this.cy, this.radius);
        circle.attr('fill', this.cableColor);
        this.elemSet.push(circle);
    }

    /**
     * Render cable number.
     */
    renderCableNumber() {
        const text = this.paper.text(
            this.cx - this.radius,
            this.cy - this.radius,
            this.cableNumber!,
        );
        text.attr('font-size', this.fontSize);
        this.elemSet.push(text);
    }
}
