// Libraries
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { t } from 'i18next';

// Component Library
import {
    Toolbar,
    ToolbarGroup,
    Button,
    Icons,
    PreviousMapHistoryButton,
    NextMapHistoryButton,
    HelpButton,
    useIsMobile,
    MapContext,
    styled,
    MoreMenu,
    useToast,
    StateEvent,
    PanButton,
    InfoToolMenu,
    MeasureToolMenu,
    ZoomToAreaButton,
} from '@keypro/2nd-xp';

// Components
import { PrintMenu, PrintPreview } from '@components/PrintMapOverlay';
import SearchField from '@components/Search/SearchField';
import { QuickSearchResults } from '@components/Search/search-components/QuickSearchResult';
import { NodeDuctsAndElevations } from '@components/Tools/NodeDuctsAndElevations';
import MobileDetailedToolbar from './MobileDetailedToolbar';
import { UserWidget } from './UserWidget/UserWidget';

// Assets
import Bell from '@assets/mockups/Bell.svg';
import KeyproNexusLogoBeta from '@assets/icons/KeyproNexusLogoBeta.svg';

// Stores
import {
    useLeftMenu,
    useRightMenu,
    useCenterMenu,
    useMobileMenu,
    useToolsStore,
} from '@stores';

// Common
import {
    getInfoToolMenuProps,
    getMeasureToolMenuProps,
    getMoreMenuProps,
    getZoomToAreaButtonProps,
    helpUrl,
} from './Common';

// Styles
import {
    DraggableHeaderMapTools as FloatingToolbarTools,
    StyledDraggableHeader,
} from '@components/ObjectOverlay/ObjectOverlay.styles';
import { StyledMoveableContainer as FloatingToolbarContainer } from '@components/MoveableContainer/MoveableContainer.styles';

// Constants
import { TOOL_BAR_SIZE } from '@components/ObjectOverlay/ObjectOverlay.consts';
import {
    LEFT_MENU_WIDTH,
    LEFT_OFFSET,
    NODE_DUCT_TOOL_WIDTH,
} from './AppToolbar.const';

/**
 * The topmost horizontal menu that contains most of the app's tools.
 * It consists of three tool groups, with content varying based on Desktop, Tablet, or Mobile mode.
 */
const AppToolbar = (): JSX.Element => {
    const { setMenuContent: setRightMenuContent } = useRightMenu();
    const {
        setMenuContent: setCenterMenuContent,
        isMenuOpen: isCenterMenuOpen,
    } = useCenterMenu();
    const { isMenuOpen, detailedMenu, setDetailedMenu } = useLeftMenu();
    const {
        setMenuHeight,
        setMenuContent: setMobileMenuContent,
        detailedMenu: detailedMobileMenu,
        setDetailedMenu: setDetailedMobileMenu,
        isDetailedToolbar,
        setIsDetailedToolbar,
    } = useMobileMenu();
    const controller = useContext(MapContext)!;
    const [isMapInitialized, setIsMapInitialized] = useState(
        controller.initialized,
    );

    const { isNodeDuctsAndElevationsOpen } = useToolsStore();
    const moreMenuProps = getMoreMenuProps();
    const toast = useToast();

    useEffect(() => {
        const handleInitialize = () => {
            setIsMapInitialized(true);
        };

        controller.on('initialize', handleInitialize);

        return () => {
            controller.off('initialize', handleInitialize);
        };
    }, [controller]);

    useEffect(() => {
        const onStateChange = (event: StateEvent) => {
            const state = event.newState;

            if (state?.hint && state.hint.type !== React.Fragment) {
                toast.info(<Text>{state.hint}</Text>);
            }
        };

        controller.on('stateChange', onStateChange);

        return () => {
            controller.off('stateChange', onStateChange);
        };
    }, [controller, toast]);

    const isMobile = useIsMobile();

    const toggleDetailedNavbar = () =>
        isMobile
            ? setDetailedMobileMenu(!detailedMobileMenu)
            : setDetailedMenu(!detailedMenu);

    const openPrintMenus = () => {
        setRightMenuContent(t('print'), <PrintMenu />);
        setCenterMenuContent(t('print'), <PrintPreview />);
    };

    const openMobileSearch = () => {
        setIsDetailedToolbar(true);
        setMenuHeight(100);
        setMobileMenuContent('QuickSearchResult', <QuickSearchResults />);
    };

    const renderToolsMenu = useCallback(() => {
        let left;

        if (isNodeDuctsAndElevationsOpen) {
            left = `${NODE_DUCT_TOOL_WIDTH + LEFT_OFFSET}px`;
        } else if (isMenuOpen) {
            left = `${LEFT_MENU_WIDTH + LEFT_OFFSET}px`;
        } else {
            left = `${TOOL_BAR_SIZE + LEFT_OFFSET}px`;
        }

        const containerStyle = {
            border: 'none',
            width: '260px',
            top: `${TOOL_BAR_SIZE + LEFT_OFFSET}px`,
            left: left,
            transition: 'top 0.3s ease, left 0.3s ease',
        };

        return (
            <FloatingToolbarContainer
                style={containerStyle}
                data-testid="app-map-toolbar-popup"
            >
                <FloatingToolbarWrapper>
                    <FloatingToolbar
                        moreTools={
                            <MoreMenu
                                {...moreMenuProps}
                                key={'toolbar-moreMenu'}
                            />
                        }
                    />
                </FloatingToolbarWrapper>
            </FloatingToolbarContainer>
        );
    }, [isMenuOpen, isNodeDuctsAndElevationsOpen, moreMenuProps]);

    const leftToolGroup = (): JSX.Element[] => {
        const toolGroup = [
            <ToolbarGroupMenu key={'toolbar-detailedNavbar'}>
                <Button
                    kind="ghost"
                    data-tooltip={t('menu')}
                    data-testid="toolbar-detailedNavbar"
                    onClick={toggleDetailedNavbar}
                >
                    <Icons.Burger />
                </Button>
            </ToolbarGroupMenu>,
            <LogoInMobile
                src={KeyproNexusLogoBeta}
                alt="Keypro Nexus logo"
                key="nexus-logo"
            />,
        ];

        return toolGroup as JSX.Element[];
    };

    const showNotificationButton =
        import.meta.env.VITE_SHOW_NOTIFICATION_BUTTON === 'true';

    const rightToolGroup = (): JSX.Element[] => {
        const toolGroup = [
            !isMobile && (
                <StyledPrintButton
                    data-testid="toolbar-print"
                    kind="ghost"
                    data-tooltip={t('printTooltip')}
                    onClick={openPrintMenus}
                    key={'toolbar-print'}
                    disabled={!isMapInitialized}
                >
                    <Icons.Printer />
                </StyledPrintButton>
            ),

            <Icons.Divider
                style={{ height: '32px', width: '2px' }}
                key={'toolbar-divider'}
            />,
            showNotificationButton && (
                <Button
                    kind="ghost"
                    data-tooltip={t('notificationsTooltip')}
                    key={'toolbar-notification'}
                >
                    <img alt="Notifications" src={Bell} />
                </Button>
            ),
            <HelpButton
                url={helpUrl}
                data-tooltip={t('helpTooltip')}
                key={'toolbar-helpButton'}
            />,
        ];
        if (isMobile) {
            toolGroup.length = 0;
            toolGroup.push(
                <Button
                    kind="ghost"
                    data-tooltip={t('openFullSearch')}
                    data-testid="toolbar-searchBtn"
                    key={'toolbar-mobileSearchBtn'}
                    onClick={openMobileSearch}
                >
                    <Icons.Search />
                </Button>,
            );
        }
        toolGroup.push(<UserWidget key={'toolbar-userWidget'} />);

        return toolGroup as JSX.Element[];
    };

    const renderToolGroup = (toolGroup: JSX.Element[], gap?: number) =>
        toolGroup.length ? (
            <ToolbarGroup style={{ gap: gap ?? undefined }}>
                {toolGroup}
            </ToolbarGroup>
        ) : null;

    if (isMobile && isDetailedToolbar) return <MobileDetailedToolbar />;

    return (
        <ToolbarContainer>
            <Toolbar data-testid="app-toolbar" id="app-toolbar">
                {renderToolGroup(leftToolGroup(), 20)}
                <LogoInDesktop
                    src={KeyproNexusLogoBeta}
                    alt="Keypro Nexus logo"
                    key="nexus-logo"
                />
                {!isMobile && (
                    <SearchWrapper>
                        <ToolbarGroup
                            style={{ gap: 0 }}
                            key={'toolbar-history'}
                        >
                            <PreviousMapHistoryButton
                                data-tooltip={t('prevMapHistoryTooltip')}
                            />
                            <NextMapHistoryButton
                                data-tooltip={t('nextMapHistoryTooltip')}
                            />
                        </ToolbarGroup>
                        <ToolbarGroup key={'toolbar-searchField'}>
                            <SearchField data-testid="toolbar-searchField" />
                        </ToolbarGroup>
                    </SearchWrapper>
                )}
                {renderToolGroup(rightToolGroup())}
                {isNodeDuctsAndElevationsOpen && <NodeDuctsAndElevations />}
            </Toolbar>
            {!isMobile && !isCenterMenuOpen && renderToolsMenu()}
        </ToolbarContainer>
    );
};

export default AppToolbar;

interface FloatingToolbarProps {
    moreTools?: React.ReactNode;
}

/**
 * All the map tools are placed in this component.
 */
export const FloatingToolbar = ({ moreTools }: FloatingToolbarProps) => {
    const infoToolMenuProps = getInfoToolMenuProps();
    const measureToolMenuProps = getMeasureToolMenuProps();
    const zoomToAreaButtonProps = getZoomToAreaButtonProps();

    return (
        <FloatingToolbarTools>
            <PanButton
                data-tooltip={t('panTooltip')}
                key={'toolbarPopup-panButton'}
            />
            <InfoToolMenu
                {...infoToolMenuProps}
                data-testid="toolbarPopup-infoToolMenu"
                key={'toolbarPopup-infoToolMenu'}
            />
            <MeasureToolMenu
                {...measureToolMenuProps}
                key={'toolbarPopup-measureToolMenu'}
            />
            <ZoomToAreaButton
                {...zoomToAreaButtonProps}
                label=""
                key={'toolbarPopup-zoomToAreaButton'}
            />
            {moreTools}
        </FloatingToolbarTools>
    );
};

const FloatingToolbarWrapper = styled(StyledDraggableHeader)`
    border-radius: 12px;
    cursor: pointer;
`;

const ToolbarContainer = styled.div`
    position: relative;
`;

const SearchWrapper = styled.div`
    display: flex;
    gap: 16px;
`;

const ToolbarGroupMenu = styled(ToolbarGroup)`
    display: none;
    @media (max-width: 1280px) {
        display: flex;
    }
`;

const StyledPrintButton = styled(Button)`
    margin-right: 8px;
    & > svg {
        color: ${({ theme }) => theme.colors.neutral['90']};
    }
`;

const createStyledLogo = (
    displayCondition: string,
    displayValue: string,
) => styled.img`
    width: 36px;
    height: 32px;
    margin-left: 0px;
    display: ${displayValue};
    @media (${displayCondition}) {
        display: ${displayValue === 'none' ? 'block' : 'none'};
    }
`;

const Text = styled.div`
    display: flex;
    gap: 12px;
    justify-content: center;
    align-items: center;
    background-color: #4cc32733;
    border-radius: 24px;
    padding: 3px 24px;
    font-size: 12px;
`;

const LogoInMobile = createStyledLogo('min-width: 1281px', 'none');
const LogoInDesktop = createStyledLogo('max-width: 1280px', 'none');
