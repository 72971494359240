// Constants
import {
    DEFAULT_SIDE_MENU_WIDTH,
    ORIENTATION_TOOLS_TOP_MARGIN,
} from './AppMenus.consts';
import { COMPASS_TOTAL_TOP_OFFSET } from '../AppMap/AppMap.consts';

// Component Library
import { styled } from '@keypro/2nd-xp';

interface StyledToolsWrapperProps {
    $display: string;
}

export const StyledMenuContainer = styled.div`
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: calc(100% - 56px);
    height: 100%;
    z-index: 1;
    right: 0;
    pointer-events: none;
`;

export const StyledSideMenuContainer = styled.div`
    display: contents;
    min-width: ${DEFAULT_SIDE_MENU_WIDTH}px;
    & > * {
        pointer-events: auto;
        min-width: ${DEFAULT_SIDE_MENU_WIDTH}px;
    }
`;

export const StyledCenterMenuContainer = styled.div`
    flex-grow: 1;
    overflow-y: hidden;
    & > * {
        pointer-events: auto;
    }
`;

export const StyledRightMenuContainer = styled.div`
    position: relative;
    display: contents;
`;

export const StyledToolsWrapper = styled.div<StyledToolsWrapperProps>`
    display: ${(props) => props.$display};
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
`;

export const StyledOrientationToolsWrapper = styled.div`
    position: relative;
    pointer-events: auto;
    margin-right: 26px;
    justify-self: flex-end;
    margin-top: ${COMPASS_TOTAL_TOP_OFFSET + ORIENTATION_TOOLS_TOP_MARGIN}px;
`;
