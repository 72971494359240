query nodeDataRPC($encodedPk: String!, $type: String!) {
    nodeDataRPC(encodedPk: $encodedPk, type: $type) {
        success
        message
        data {
            totalCount
            items {
                mslink
                buildYear
                jsForm
                nodeTypeNetworkTypeTxt
                nodeTypeNodeTypeTxt
                location {
                    type
                    geometry {
                        type
                        coordinates
                    }
                    properties
                }
                zCoord1
                zCoord1Estimated
                zCoord2
                zCoord2Estimated
                nodeDiameter
                nodeMaterialTxt
            }
        }
    }
}
