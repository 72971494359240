// Libraries
import { styled } from '@keypro/2nd-xp';

interface StyledMobileToolButtonsProps {
    $isMobileToolButtonsHidden: boolean;
}

interface StyledMobileMenuToolsContainerProps {
    $resolvedMenuHeight: number;
}

export const StyledMobileMenuToolsContainer = styled.div<StyledMobileMenuToolsContainerProps>`
    position: absolute;
    bottom: 0;
    width: 100%;
    height: ${(props) => props.$resolvedMenuHeight}%;
`;

export const StyledMobileToolButtons = styled.div<StyledMobileToolButtonsProps>`
    position: relative;
    display: ${(props) => (props.$isMobileToolButtonsHidden ? 'none' : '')};
`;

export const StyledMobileMenuContainer = styled.div`
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    z-index: 1;
    bottom: 0;
    pointer-events: auto;
    background-color: ${(props) => props.theme.colors.neutral[20]};
    transition: height 250ms;
    overflow-y: auto;
`;

export const StyledDragger = styled.div`
    z-index: 1001;
    position: absolute;
    top: -4px;
    cursor: ns-resize;
    & > svg {
        width: 20px;
        height: 20px;
        transform: rotate(90deg);
        & path[stroke] {
            stroke: ${(props) => props.theme.colors.neutral['70']};
            stroke-width: 4px;
        }
    }
`;

export const StyledOverlayContainer = styled.div`
    z-index: 2;
    position: absolute;
    width: 100%;
`;
