// Libraries
import { useContext, useEffect, useState } from 'react';
import { t } from 'i18next';

// Components
import { ObjectOverlay } from '@components';

// Component library
import { MapContext, OpenLayersMap, Icons, useIsMobile } from '@keypro/2nd-xp';
import {
    MapConfig,
    OpenLayersMapProps,
} from '@keypro/2nd-xp/dist/components/map/OpenLayersMap';

// Hooks
import { useGetSpatialReference, useGetMapConfig } from '@hooks/map';

// Styles
import {
    StyledLoading,
    mapContainerStyles,
    mapMobileContainerStyles,
} from './AppMap.styles';

const AppMap = (): JSX.Element => {
    const controller = useContext(MapContext)!;
    const [mapProps, setMapProps] = useState<OpenLayersMapProps | null>(null);
    const isMobile = useIsMobile();

    const containerStyles = isMobile
        ? mapMobileContainerStyles
        : mapContainerStyles;

    // Fetch mapconfig and spatialreference from backend
    const { data: mapConfig } = useGetMapConfig();
    const { data: spatialReference } = useGetSpatialReference(
        mapConfig?.optionsV2.projection,
    );

    useEffect(() => {
        if (mapConfig && spatialReference) {
            const newMapProps: OpenLayersMapProps = {
                spatialReference: spatialReference,
                mapConfig: mapConfig as unknown as MapConfig,
                compassTooltip: t('compassTooltip'),
                compassResetTooltip: t('compassResetTooltip'),
            };
            setMapProps(newMapProps);
        }
    }, [spatialReference, mapConfig]);

    useEffect(() => {
        const onInitialize = () => {
            console.log('Map initialized');
            const srs = document.getElementById('srs');
            if (srs) {
                srs.innerHTML = controller.map
                    .getView()
                    .getProjection()
                    .getCode();
            }
        };

        controller.on('initialize', () => onInitialize);

        return () => {
            controller.off('initialize', onInitialize);
        };
    }, [controller]);

    // Load spinner while fetching mapConfig and spatialReference, show map when fetched
    if (!mapProps || !spatialReference) {
        return (
            <StyledLoading>
                <Icons.Spinner />
            </StyledLoading>
        );
    } else {
        return (
            <>
                <OpenLayersMap
                    {...mapProps}
                    containerStyles={containerStyles}
                />
                <ObjectOverlay />
            </>
        );
    }
};

export default AppMap;
