/**
 * Limits the number of times a function can be executed in a given time frame.
 * Useful for performance-heavy operations like window resizing, scrolling,
 * or other high-frequency event handlers where repeated triggers can lead to performance issues.
 *
 * The last invocation is scheduled after the delay, ensuring the most recent event is handled.
 *
 * @param callback - The function to throttle
 * @param delay - The delay time in milliseconds
 * @returns A throttled function that respects the delay
 */
export function throttle<T extends (...args: never[]) => void>(
    callback: T,
    delay: number,
) {
    let lastCall = 0;
    let timeout: ReturnType<typeof setTimeout> | null = null;
    let lastArgs: Parameters<T> | null = null;

    return (...args: Parameters<T>) => {
        const now = Date.now();

        if (now - lastCall >= delay) {
            // Execute immediately if enough time has passed
            callback(...args);
            lastCall = now;
        } else {
            // Store last arguments and schedule final execution
            lastArgs = args;
            if (timeout) clearTimeout(timeout);

            timeout = setTimeout(() => {
                if (lastArgs) callback(...lastArgs);
                lastArgs = null;
            }, delay);
        }
    };
}
