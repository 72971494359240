// Types
import { TxtConstant } from '@generated';

// Function to get the prefixes for the coordinates system
export const getCoordinatesSystemPrefixes = (
    coordinatesSystem?: TxtConstant,
) => {
    if (coordinatesSystem) {
        const isMeterBased =
            coordinatesSystem?.additionalData?.includes('+units=m');
        if (isMeterBased) {
            return {
                firstPrefix: 'N:',
                secondPrefix: 'E:',
            };
        } else {
            return {
                firstPrefix: 'Lat:',
                secondPrefix: 'Long:',
            };
        }
    }
    return { firstPrefix: '', secondPrefix: '' };
};
