import { GetSnConstantsQuery, SnConstant, SnConstantsFilter } from '@generated';
import { sendGraphQLRequest } from '@utils';
import GetSnConstants from './queries/GetSnConstants.graphql';
import { useSnConstants as snConstantsStore } from '@stores';

/**
 * Get the sn constants.
 * @param filter The GraphQL filter to apply to the query.
 * @returns The sn constants.
 */
export const getSnConstants = async (
    filter?: SnConstantsFilter,
    limit?: number,
): Promise<SnConstant[]> => {
    const { snConstants, getByGroupName } = snConstantsStore.getState();
    if (filter?.groupname?.eq && snConstants.length > 0) {
        const snconstants = getByGroupName(filter.groupname.eq);
        if (snconstants.length > 0) {
            return snconstants;
        }
    }
    try {
        const queryVariables = {
            filter: filter,
            limit: limit,
        };
        const response = await sendGraphQLRequest<GetSnConstantsQuery>(
            GetSnConstants,
            queryVariables,
        );

        if (!response.data.getSnConstants) {
            throw Error('No data returned');
        }

        return response.data.getSnConstants.snConstants?.filter(
            Boolean,
        ) as SnConstant[];
    } catch (error) {
        throw Error('Failed to get sn constants: ' + error);
    }
};
