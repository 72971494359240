import { getWaterBranches } from '@apis/keyduct';
import { FormConfig } from '../types';
import { WaterBranchIcon } from './icons';
import { createComboField } from '../field-utils';

const config: FormConfig = {
    gqlType: 'WaterBranch',
    model: 'SnWaterBranch',
    modelAliases: ['snwaterbranch'],
    icon: <WaterBranchIcon />,
    groups: [
        {
            name: 'generalInformation',
            fields: [createComboField('typeId', 'BRANCH_TYPE', false, 'type')],
        },
    ],
    functions: {
        get: getWaterBranches,
    },
    queryCollection: 'waterBranches',
};

export default config;
