import { DiagramOptions } from '../types';
import { ConduitCable } from './ConduitCable';
import { ConduitOeObject } from './ConduitOeObject';
import { Duct } from './Duct';
import { NodeGroup } from './NodeGroup';
import { NodeChildData, NodesData, OtherendData } from '@generated';

export class Conduit extends NodeGroup {
    /**
     * Other end object shape
     */
    otherendObject: ConduitOeObject | null = null;

    constructor(options: DiagramOptions, data: NodesData) {
        super(options, data);

        if (data.otherend) {
            this.otherendObject = this.createOeObject(data.otherend);
        }
    }

    /**
     * Create child node.
     * @param data Child node data
     * @return Child node
     */
    createChildNode(data: NodeChildData) {
        const dataType = data.dataType;
        if (dataType === 'duct') {
            return new Duct(this.options, data);
        } else {
            return new ConduitCable(this.options, data);
        }
    }

    /**
     * Create other end object
     * @param data Other end object data
     * @return Other end object shape
     */
    createOeObject(data: OtherendData) {
        return new ConduitOeObject(this.options, data);
    }

    render() {
        super.render();

        if (this.otherendObject) {
            /* Note other end shape must be rendered after calling parent becasue, unlike child nodes on
             * which the contex menu will show information about node groups, the otherend object has its
             * own context menu behavior, thus it should pass along its own object as context menu parameter.
             * Moreover, the otherend object should not affect the conduit bounding box calculation to make
             * sure the drag and rotate handles do not appear in strange places.
             */
            this.otherendObject.render();
        }
    }
}
