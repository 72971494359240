import { getWaterValves } from '@apis/keyduct';
import { FormConfig } from '../types';
import { WaterValveIcon } from './icons';
import { createComboField } from '../field-utils';

const config: FormConfig = {
    gqlType: 'WaterValve',
    model: 'SnWaterValve',
    modelAliases: ['snwatervalve'],
    icon: <WaterValveIcon />,
    groups: [
        {
            name: 'generalInformation',
            fields: ['diameter', createComboField('handedness', 'VALVE_HAND')],
        },
    ],
    functions: {
        get: getWaterValves,
    },
    queryCollection: 'waterValves',
};

export default config;
