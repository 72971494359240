query nodeObjectSelectorRPC($input: NodeObjectSelectorRPCFilterParams!) {
    nodeObjectSelectorRPC(input: $input) {
        id
        success
        message
        result {
            contentType
            label
            pk
            source
            geometry
            menuData {
                acceptCallback
                acceptItemLabel
            }
        }
    }
}
