import { DuctTypeFilter } from '@generated';
import { useQuery } from '@tanstack/react-query';
import { getDuctTypes } from 'src/apis/keycom';

export interface GetDuctTypeOptions {
    filter?: DuctTypeFilter;
}

export function useGetDuctTypes(options?: GetDuctTypeOptions) {
    return useQuery({
        queryKey: [options?.filter],
        queryFn: async () => {
            return getDuctTypes(options?.filter);
        },
    });
}
