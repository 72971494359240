query GetDuctTypes($filter: DuctTypeFilter) {
  getDuctTypes(filter: $filter, limit: 1000) {
     ductTypes {
      id
      type
      color
      sheathColour { id }
      stripeColour { id }
      stripeOtherColour { id }
    }
  }
}