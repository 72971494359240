import { getWaterDucts } from '@apis/keyduct';
import { FormConfig } from '../types';
import { ductGroups } from './groups';
import { WaterDuctIcon } from './icons';
import { createComboField } from '../field-utils';

const config: FormConfig = {
    gqlType: 'WaterDuct',
    model: 'snwaterduct',
    icon: <WaterDuctIcon />,
    groups: [
        {
            name: 'generalInformation',
            fields: [
                createComboField('type.netType', 'NET_TYPE'),
                createComboField('ductType.ductType', 'DUCT_TYPE'),
            ],
        },
        ...ductGroups,
    ],
    functions: {
        get: getWaterDucts,
    },
    queryCollection: 'waterDucts',
};

export default config;
