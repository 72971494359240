import { getWaterPumpingStations } from '@apis/keyduct';
import { FormConfig } from '../types';
import { WaterPumpingStationIcon } from './icons';

const config: FormConfig = {
    gqlType: 'WaterPumpingStation',
    model: 'SnWaterPumpingstation',
    modelAliases: ['snwaterpumpingstation'],
    icon: <WaterPumpingStationIcon />,
    groups: [
        {
            name: 'generalInformation',
            fields: ['name', 'productivity'],
        },
    ],
    functions: {
        get: getWaterPumpingStations,
    },
    queryCollection: 'waterPumpingStations',
};

export default config;
