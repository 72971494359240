import { useState, useCallback, useEffect } from 'react';
import { useLogin, useGetUser, useCheckAuth } from '@hooks/user';
import { GraphQLRequestError } from '@utils';
import { AuthMethodHook } from './useAuth';
import { logout as logoutRequest } from '@apis/user';

/**
 * Hook that provides the user's authentication state and user data to the application,
 * as well as functions to log the user in and out.
 * It uses our own authenitcation methods to manage the user's authentication state and user data.
 * @returns AuthMethodHook
 */
export const useDefaultAuth = (): AuthMethodHook => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const { mutateAsync: loginMutation, isPending: isLoading } = useLogin();
    const { data: dataCheck, error: errorCheckAuth } = useCheckAuth();
    const { data: returnUser, refetch: refetchUser } = useGetUser({
        enabled: false,
    });

    const login = useCallback(
        async (username?: string, password?: string) => {
            if (!username || !password) {
                throw new Error('Unauthorized');
            }
            try {
                const status = await loginMutation({ username, password });
                if (status) {
                    setIsAuthenticated(true);
                    return;
                }
            } catch (error) {
                setIsAuthenticated(false);
                if (
                    error instanceof GraphQLRequestError &&
                    error.errors?.[0]?.message === 'Unauthorized'
                ) {
                    throw new Error('Unauthorized');
                } else {
                    throw error;
                }
            }
        },
        [loginMutation],
    );

    const logout = useCallback(async () => {
        await logoutRequest();
        setIsAuthenticated(false);
    }, []);

    useEffect(() => {
        if (isAuthenticated) {
            refetchUser();
        }
    }, [isAuthenticated, refetchUser]);

    useEffect(() => {
        setIsAuthenticated(dataCheck ?? false);
    }, [dataCheck]);

    useEffect(() => {
        if (
            errorCheckAuth &&
            errorCheckAuth instanceof GraphQLRequestError &&
            errorCheckAuth.errors?.[0]?.message === 'Unauthorized'
        ) {
            setIsAuthenticated(false);
        }
    }, [errorCheckAuth]);

    const user = returnUser
        ? { ...returnUser, permissions: { edit: false, view: true } }
        : null;

    return { login, logout, isAuthenticated, isLoading, user };
};
