// Libraries
import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';

// Hooks
import { useAuth, AuthMethod } from '@hooks/auth/useAuth';
import { useGetAuth0Config } from '@hooks/user';

// Utilities
import { buildTenantURL } from '@utils';

// Context
import { AuthContext } from './AuthContext';

type AuthProviderProps = {
    auth0Available: boolean;
    children: React.ReactNode;
};

type CombinedAuthProviderProps = {
    children: React.ReactNode;
};

/**
 * AuthProvider is a context provider that handles the authentication
 * and user information by using different available authentication methods.
 * @param children The children of the AuthProvider
 * @returns The AuthProvider component
 */
export const AuthProvider = ({
    auth0Available,
    children,
}: AuthProviderProps) => {
    const authMethods = useAuth();
    const queryClient = useQueryClient();
    const [authMethod, setAuthMethod] = useState<AuthMethod>(() => {
        const storedAuthMethod = localStorage.getItem(
            'authMethod',
        ) as AuthMethod;
        const initialAuthMethod = storedAuthMethod || 'default';
        if (!storedAuthMethod) {
            localStorage.setItem('authMethod', initialAuthMethod);
        }
        return initialAuthMethod;
    });

    const login = useCallback(
        async (
            method: AuthMethod,
            termsChecked: boolean,
            username?: string,
            password?: string,
        ) => {
            if (!termsChecked) {
                return t('acceptTerms');
            }
            try {
                setAuthMethod(method);
                await authMethods[method].login(username ?? '', password ?? '');
            } catch (error) {
                if (
                    error instanceof Error &&
                    error.message === 'Unauthorized'
                ) {
                    return t('invalidUsernameOrPassword');
                }
                return t('internalServerError');
            }
        },
        [authMethods],
    );

    const logout = useCallback(async () => {
        queryClient.clear();
        queryClient.invalidateQueries();
        // Wrap the logout function in a try-catch block to handle case where session is already expired
        try {
            await authMethods[authMethod].logout();
        } catch (error) {
            console.error('Logout failed with error: ', error);
        } finally {
            window.location.href = buildTenantURL();
        }
    }, [authMethods, authMethod, queryClient]);

    const value = useMemo(
        () => ({
            login,
            logout,
            authMethod,
            isAuthenticated: authMethods[authMethod].isAuthenticated,
            isLoading: authMethods[authMethod].isLoading,
            user: authMethods[authMethod].user || null,
            isAuth0Available: auth0Available || false,
        }),
        [authMethods, authMethod, login, logout, auth0Available],
    );

    useEffect(() => {
        localStorage.setItem('authMethod', authMethod);
    }, [authMethod]);

    return (
        <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    );
};

/**
 * Combined context provider that combines the AuthProvider
 * and Auth0Provider to provide the user's authentication state and user data
 * to the application.
 * @param children The children of the CombinedAuthProvider
 * @returns The CombinedAuthProvider component
 */
export const CombinedAuthProvider = ({
    children,
}: CombinedAuthProviderProps): JSX.Element => {
    const { i18n } = useTranslation();
    const { data: auth0Config } = useGetAuth0Config();
    const auth0Available = !!(auth0Config?.domain && auth0Config?.clientId);

    if (!auth0Available) {
        return (
            <AuthProvider auth0Available={auth0Available}>
                {children}
            </AuthProvider>
        );
    }

    return (
        <Auth0Provider
            key={i18n.language}
            domain={auth0Config?.domain ?? ''}
            clientId={auth0Config?.clientId ?? ''}
            authorizationParams={{
                redirect_uri: buildTenantURL(),
                ui_locales: i18n.languages.toString().replace(/,/g, ' '),
                audience: `https://${auth0Config?.domain}/api/v2/`,
            }}
        >
            <AuthProvider auth0Available={auth0Available}>
                {children}
            </AuthProvider>
        </Auth0Provider>
    );
};
