import {
    FullSearch,
    pointToPolygon,
    toggleSelectedObjectType,
    coordinateRegex,
    getTranslatedSearchFields,
    sortResultsByDistance,
    convertInfoToolResultToSearchResult,
} from '@components';
import {
    LoadingMask,
    QuickSearchResultContainer as DefaultQuickSearchResultContainer,
    styled,
    useIsMobile,
    Coordinates,
    MapContext,
} from '@keypro/2nd-xp';
import {
    useMobileMenu,
    useLeftMenu,
    useRecentObjects,
    useSearchStore,
    useProductStore,
} from '@stores';
import { t } from 'i18next';
import { HTMLAttributes, useContext, useMemo } from 'react';
import { SearchResultCard } from './SearchResultCard';
import { useSearch } from '@hooks/keycore/search';
import { useGetInfoToolResults } from '@hooks/map';
import { SearchArea, SearchResult } from '@generated';
import { SearchTypeFilters, SearchTypeFilterButton } from './SearchFilters';

/**
 * Diameter of the search area around the given coordinates in meters.
 */
const COORDINATE_SEARCH_DIAMETER = 100;

/**
 * Initial number of objects shown below the search field.
 */
const INITIAL_OBJECT_COUNT = 10;

export const QuickSearchResults = () => {
    const isMobile = useIsMobile();
    const { product } = useProductStore();
    const menuContext = isMobile ? useMobileMenu : useLeftMenu;
    const {
        selectedObjectType,
        searchTerm: searchValue,
        setSearchFieldActive: setActive,
        setIsFullsearch,
        setSearchResults,
        setSearchTerm,
    } = useSearchStore();
    const { recentObjects } = useRecentObjects();
    const { setMenuContent } = menuContext();
    const mapController = useContext(MapContext)!;

    const showFullSearch = () => {
        setActive(false);
        setSearchTerm(searchValue);
        setSearchResults(results ?? []);
        setMenuContent('FullSearch', <FullSearch />, false);
        setIsFullsearch(true);
    };

    const showCoordinateSearchResults = () => {
        setActive(false);
        setSearchResults(sortedCoordResults);
        setMenuContent(
            'FullSearch',
            <FullSearch limitAdvancedSearch={true} />,
            false,
        );
    };

    const showRecentObjects = () => {
        setActive(false);
        setSearchTerm('');
        setSearchResults(recentObjects);
        setMenuContent(
            'FullSearch',
            <FullSearch limitAdvancedSearch={true} />,
            false,
        );
    };

    // Match search value to comma separated coordinates
    const coordinateMatches = !!coordinateRegex.exec(searchValue);

    let isCoordinateSearch = false;
    let coordinates: number[] = [];
    let searchArea: SearchArea | undefined;
    let layers: string[] = [];

    if (coordinateMatches) {
        isCoordinateSearch = true;
        coordinates = searchValue.split(',').map((coord) => parseFloat(coord));
        layers = mapController.layers.getActiveLayerNames();

        const polygon = pointToPolygon(
            coordinates as Coordinates,
            COORDINATE_SEARCH_DIAMETER,
        );

        searchArea = {
            type: 'Feature',
            properties: null,
            geometry: {
                type: 'Polygon',
                coordinates: polygon.getCoordinates(),
            },
        } as SearchArea;
    }

    const { data: results, isFetching: searching } = useSearch(searchValue, {
        enabled: !isCoordinateSearch,
    });

    const { data: coordResults, isFetching: coordSearching } =
        useGetInfoToolResults(
            {
                layers: layers,
                searchArea: searchArea,
            },
            {
                enabled: isCoordinateSearch,
            },
        );

    let sortedCoordResults: SearchResult[] = [];

    if (isCoordinateSearch && coordResults) {
        sortedCoordResults = sortResultsByDistance(
            mapController,
            coordResults.results,
            coordinates,
        ).map((result) => convertInfoToolResultToSearchResult(result));
    }

    const resultsForQuickSearch = useMemo(() => {
        if (searchValue && results) {
            return results;
        }

        return [];
    }, [searchValue, results]);

    const filterBySelectedModel = (results: SearchResult[]) => {
        if (!selectedObjectType) {
            return results;
        }

        let selectedModel = selectedObjectType;

        // Address results from quick search API use 'street' model instead of 'address'
        if (selectedModel === 'address') {
            selectedModel = 'street';
        }

        return results.filter((result) => result.modelName === selectedModel);
    };

    const translatedSearchFields = getTranslatedSearchFields(product);

    const filterButtons = (
        <SearchTypeFilters $isMobile={isMobile} style={{ paddingTop: 8 }}>
            {translatedSearchFields.map(({ value, label }) => (
                <SearchTypeFilterButton
                    key={value}
                    label={label}
                    $isSelected={selectedObjectType === value}
                    onClick={() =>
                        toggleSelectedObjectType(useSearchStore, value)
                    }
                    data-testid={`searchFilter-${label}`}
                />
            ))}
        </SearchTypeFilters>
    );

    /**
     * Get content for quick search results.
     * @returns Quick search content.
     */
    const getQuickSearchContent = () => {
        if (resultsForQuickSearch?.length) {
            return (
                <QuickSearchResultContainer
                    title={t('keycomSuggestions')}
                    data-testid="search-results"
                >
                    {filterBySelectedModel(resultsForQuickSearch)
                        .slice(0, INITIAL_OBJECT_COUNT)
                        .map((result, index) => (
                            <SearchResultCard
                                result={result}
                                index={index}
                                key={result.id}
                            />
                        ))}
                    <ShowMoreResults>
                        <button
                            onClick={showFullSearch}
                            data-testid="show-more-results"
                        >
                            {t('showMoreResults')}
                        </button>
                    </ShowMoreResults>
                </QuickSearchResultContainer>
            );
        } else {
            return (
                <QuickSearchResultContainer
                    title={t('noSearchResults')}
                ></QuickSearchResultContainer>
            );
        }
    };

    /**
     * Get content for coordinate search results.
     * @returns Coordinate search content.
     */
    const getCoordinateSearchContent = () => {
        if (sortedCoordResults.length) {
            return (
                <QuickSearchResultContainer
                    title={t('nearbyObjects')}
                    data-testid="coord-search-results"
                >
                    {filterBySelectedModel(sortedCoordResults)
                        .slice(0, INITIAL_OBJECT_COUNT)
                        .map((result, index) => (
                            <SearchResultCard
                                result={result}
                                index={index}
                                key={result.id}
                            />
                        ))}
                    <ShowMoreResults>
                        <button
                            onClick={showCoordinateSearchResults}
                            data-testid="show-more-coord-results"
                        >
                            {t('moreFromNearbyObjects')}
                        </button>
                    </ShowMoreResults>
                </QuickSearchResultContainer>
            );
        } else {
            return (
                <QuickSearchResultContainer
                    title={t('noNearbyObjects')}
                ></QuickSearchResultContainer>
            );
        }
    };

    /**
     * Get content for recently viewed objects.
     * @returns Recently viewed content.
     */
    const getRecentlyViewedContent = () => (
        <QuickSearchResultContainer
            title={t('recentlyViewed')}
            data-testid="recent-objects"
        >
            {filterBySelectedModel(recentObjects)
                .slice(0, INITIAL_OBJECT_COUNT)
                .map((result, index) => (
                    <SearchResultCard
                        result={result}
                        index={index}
                        key={result.id}
                    />
                ))}
            <ShowMoreResults>
                <button
                    onClick={showRecentObjects}
                    data-testid="show-more-history"
                >
                    {t('moreFromRecentHistory')}
                </button>
            </ShowMoreResults>
        </QuickSearchResultContainer>
    );

    let content: JSX.Element;

    if (searching || coordSearching) {
        content = (
            <QuickSearchResultContainer>
                <LoadingMask iconSize={24} />
            </QuickSearchResultContainer>
        );
    } else if (!isCoordinateSearch && searchValue) {
        content = getQuickSearchContent();
    } else if (isCoordinateSearch) {
        content = getCoordinateSearchContent();
    } else if (recentObjects.length) {
        content = getRecentlyViewedContent();
    } else {
        content = (
            <QuickSearchResultContainer
                title={t('noRecentObjects')}
            ></QuickSearchResultContainer>
        );
    }

    return (
        <QuickSearchResultsContainer
            $isMobile={isMobile}
            id="QuickSearchResults"
        >
            {!isMobile && filterButtons}
            {content}
        </QuickSearchResultsContainer>
    );
};

const QuickSearchResultContainer = ({
    children,
    style,
    ...rest
}: HTMLAttributes<HTMLDivElement>) => {
    const { menuHeight } = useMobileMenu();

    return (
        <DefaultQuickSearchResultContainer
            {...rest}
            style={{ height: menuHeight + '%', ...style }}
        >
            {children}
        </DefaultQuickSearchResultContainer>
    );
};

const ShowMoreResults = styled.div`
    border-top: 1px solid ${(props) => props.theme.colors.neutral[30]};

    display: flex;
    justify-content: center;
    padding: 12px 12px 8px 12px;
    margin-left: 8px;

    button {
        border: 0;
        background-color: transparent;
        color: ${(props) => props.theme.colors.accents.blue[10]};
        ${(props) => props.theme.fonts['14px Regular']};
        cursor: pointer;
    }
`;

const QuickSearchResultsContainer = styled.div<{ $isMobile?: boolean }>`
    position: absolute;
    top: 100%;

    z-index: 10002;
    width: 100%;

    & > div,
    & > div > div {
        background-color: ${(props) => props.theme.colors.neutral[20]};
    }

    & > div {
        border-top: 1px solid ${(props) => props.theme.colors.neutral[30]};
    }

    & > div:last-of-type {
        border-radius: 0 0 8px 8px;
    }

    ${(props) =>
        props.$isMobile &&
        `
        top: 0;
        background-color: ${props.theme.colors.neutral[20]};
        overflow-y: auto;
    `}
`;
