import { ConsumerPointCustomer, Customer, GetCustomersQuery } from '@generated';
import GetCustomers from './queries/GetCustomers.graphql';
import { sendGraphQLRequest } from '@utils';
import { GraphQLFilter } from '@apis/utils';

type CustomerFormData = Customer & {
    consumerPoints: ConsumerPointCustomer[];
};

/**
 * Gets the customers.
 * @param filter The GraphQL filter to apply to the query.
 * @returns The sewer ducts.
 * @throws {Error} If the request fails.
 */
export const getCustomers = async (filter: GraphQLFilter) => {
    try {
        const id = filter?.id.eq;
        const payload = {
            filter,
            filterCPCustomer: { customer: { id: { eq: id } } },
        };
        const response = await sendGraphQLRequest<GetCustomersQuery>(
            GetCustomers,
            payload,
        );

        if (!response.data.getCustomers?.customers) {
            throw Error('No data returned');
        }
        return [
            {
                ...response.data.getCustomers.customers[0],
                consumerPoints:
                    response.data.getConsumerPointCustomers
                        ?.consumerPointCustomers,
            },
        ].filter(Boolean) as CustomerFormData[];
    } catch (error) {
        throw Error('Failed to get customers: ' + error);
    }
};
