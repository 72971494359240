query getSewerValves($filter: SewerNodeFilter) {
    getSewerValves(filter: $filter) {
        metadata {
            totalCount
        }
        sewerValves {
            id
            address {
                id
            }
            angle
            branchType {
                id
            }
            buildYear
            createdBy
            createdTs
            dateAccuracy {
                groupname
                txt
            }
            establishMethod {
                groupname
                txt
            }
            feature
            faultCode {
                id
                groupname
            }
            heightAccuracy {
                groupname
                txt
            }
            historyDate
            internalNodeId
            landxmlName
            location
            locationAccuracy {
                groupname
                txt
            }
            manholeDesign {
                groupname
                txt
            }
            mapper
            mappingMethod {
                groupname
                txt
            }
            measuringpointType {
                id
            }
            network
            note
            owner {
                groupname
                txt
            }
            plan {
                id
            }
            planDate
            planNumber
            position
            pressure
            removalYear
            safetyDistance {
                groupname
                txt
            }
            scale
            showOnMap
            status {
                groupname
                txt
            }
            surveyNumber
            terrainHeight
            updatedBy
            updatedTs
            usageState {
                groupname
                txt
            }
            userId
            zCoord1
            zCoord1Estimated
            zCoord2
            zCoord2Estimated

            manhole {
                id
            }
            manholeType {
                id
            }
            nodeType
            objectType {
                id
            }
            pumpType {
                id
            }
            valveType {
                id
            }

            lblAngle
            lblAngleExp
            lblColor
            lblColorExp
            lblSize
            lblSizeExp
            symAngle
            symAngleExp
            symCodeGt
            symColor
            symColorExp
            symName
            symNameDwg
            symNameExp
            symSize
            symSizeExp
            symText
            symTextExp
            symWidth
            symWidthExp
        }
    }
}
