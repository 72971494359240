import { EdgesData } from '@generated';
import { Node } from './Node';
import { Edge } from './Edge';
import { DiagramOptions } from '../types';

export class ConnectionCable extends Edge {
    cableTech: string | null;

    constructor(
        options: DiagramOptions,
        data: EdgesData,
        startNode: Node,
        endNode: Node,
    ) {
        super(options, data, startNode, endNode);

        this.cableTech = data.tech ?? null;

        this.lineColor = this.getEdgeStateColor(data['state']);
        this.textColor = this.getFontStateColor(data['state']);
    }

    getCableTech() {
        return this.cableTech;
    }

    getEdgeStateColor(state: number) {
        return state === 2 ? '#FF0000' : '#008000';
    }

    getFontStateColor(state: number) {
        return state === 2 ? '#FF0000' : '#000000';
    }
}
