import { Button, styled, ToggleObjectItem } from '@keypro/2nd-xp';

export const StyledSimplePopup = styled.div`
    position: absolute;
    width: 268px;
    height: fit-content;
    border-radius: 12px;
    border: 4px solid ${(props) => props.theme.colors.neutral['50']};
    background-color: ${(props) => props.theme.colors.neutral['10']};
`;

export const StyledSimplePopupItem = styled.button`
    background-color: ${(props) => props.theme.colors.neutral['10']};
    border: none;
    width: 100%;
`;

export const StyledDraggableHeader = styled.div`
    height: 40px;
    background-color: ${(props) => props.theme.colors.neutral[20]};
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    cursor: move;
`;

export const DraggableHeaderMapTools = styled.div`
    display: flex;
    direction: row;
    padding: 0px 4px;
    gap: 8px;
`;

export const DraggableHeaderCloseBtn = styled(Button)`
    margin-right: 6px;
    color: ${(props) => props.theme.colors.neutral['90']};
    width: 32px;
    height: 32px;
    & > svg {
        width: 24px;
        height: 24px;
        & path[stroke] {
            stroke: ${({ theme }) => theme.colors.neutral['90']};
            stroke-width: 1.75px;
        }
    }
`;

export const ToolbarPopupNoResult = styled.div`
    display: flex;
    flex-direction: column;
    height: 96px;
    padding: 0px 6px;
    align-items: center;
    justify-content: space-evenly;
`;

export const ToolbarPopupListResultContainer = styled(ToggleObjectItem)`
    cursor: pointer;
`;

export const ToolbarPopupFirstListResult = styled.div`
    padding-top: 8px;
`;

export const ToolbarPopupNearbyObjects = styled.div`
    font-family: Inter;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0%;
    color: ${(props) => props.theme.colors.neutral['80']};
    padding-left: 16px;
    padding-top: 9px;
    padding-bottom: 9px;
`;

export const ToolbarPopupDraggableFooter = styled.div`
    height: 28px;
    background-color: ${(props) => props.theme.colors.neutral[20]};
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;

    padding: 0px 6px;
    gap: 4px;

    color: ${(props) => props.theme.colors.neutral['100']};
    font-family: Inter;
    font-size: 11px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
`;

export const ToolbarPopupCopyBtn = styled(Button)`
    color: ${(props) => props.theme.colors.neutral['90']};
    width: 32px;
    height: 32px;
    & > svg {
        width: 12px;
        height: 12px;
        & path[fill],
        & g[fill] {
            stroke: ${({ theme }) => theme.colors.neutral['80']};
            fill: ${({ theme }) => theme.colors.neutral['80']};
            stroke-width: 0.5px;
        }
    }
`;

export const ToolbarPopupLoading = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
`;

export const ToolbarPopupButtons = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px;
    gap: 4px;
    & button {
        width: 50%;
    }
    margin-right: 6px;
`;
