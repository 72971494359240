import { Table } from '@keypro/2nd-xp';
import { CustomComponentProps } from '@form-configs';
import { t } from 'i18next';
import {
    Apartment,
    ConsumerPoint,
    ConsumerPointAreaRpc,
    ConsumerPointCustomer,
} from '@generated';
import {
    StyledTableRoot,
    StyledTableCell,
    TableContainer,
} from './styledTableParts';
import { CustomerReference } from './CustomerReference';

interface TableProps<T> {
    headers: string[];
    data: T[];
    getRowValues: (item: T) => (string | number | React.ReactNode)[];
}

export const createTable = <T,>({
    headers,
    data,
    getRowValues,
}: TableProps<T>) => {
    if (!data) {
        return <></>;
    }
    return (
        <td>
            <TableContainer className="ignore-gridding">
                <StyledTableRoot>
                    <Table.Head>
                        <Table.Row>
                            {headers.map((header) => (
                                <Table.Cell key={header}>
                                    <Table.CellMenu>{header}</Table.CellMenu>
                                </Table.Cell>
                            ))}
                        </Table.Row>
                    </Table.Head>
                    <Table.Body>
                        {data.map((item, index) => (
                            <Table.Row key={`${headers[index]}-${index}`}>
                                {getRowValues(item).map((value, cellIndex) => (
                                    <StyledTableCell
                                        key={`${cellIndex}-${value?.toString}`}
                                    >
                                        {value}
                                    </StyledTableCell>
                                ))}
                            </Table.Row>
                        ))}
                    </Table.Body>
                </StyledTableRoot>
            </TableContainer>
        </td>
    );
};

export const addressTable = (props: CustomComponentProps): JSX.Element => {
    const value = props.value as Apartment[];
    const formData = props.formData as ConsumerPoint;

    const headers = [
        t('consumerPoint.street'),
        t('consumerPoint.zipcode'),
        t('consumerPoint.city'),
        t('consumerPoint.primary'),
    ];

    const getRowValues = (address: Apartment) => [
        `${address.address?.streetname} ${address.address?.streetno}`,
        address.address?.zipcode,
        address.address?.city,
        address === formData.address ? t('yes') : t('no'),
    ];

    return createTable({ headers, data: value, getRowValues });
};

export const areaTable = (props: CustomComponentProps): JSX.Element => {
    const value = props.value as ConsumerPointAreaRpc[];

    const headers = [
        t('consumerPoint.primaryType'),
        t('consumerPoint.subType'),
        t('consumerPoint.name'),
    ];

    const getRowValues = (area: ConsumerPointAreaRpc) => [
        area.mainType,
        area.subType,
        area.name,
    ];

    return createTable({ headers, data: value, getRowValues });
};

export const customerReference = (props: CustomComponentProps): JSX.Element => {
    const customer = props.value as ConsumerPointCustomer;
    return <CustomerReference name={props.name} customer={customer} />;
};
