import { create } from 'zustand';
import { ReactNode } from 'react';

export interface MenuState {
    menuContentId: string | null;
    menuContent: ReactNode;
    isMenuOpen: boolean;
    setMenuContent: (
        contentID: string,
        content: ReactNode,
        toggle?: boolean,
    ) => void;
    toggleMenu: () => void;
    detailedMenu: boolean;
    setDetailedMenu: (detailedMenu: boolean) => void;
    menuWidth?: number;
    setMenuWidth?: (menuWidth: number) => void;
}

const createMenuStore = ({ menuWidth }: { menuWidth?: number }) =>
    create<MenuState>((set) => ({
        menuContentId: null,
        menuContent: null,
        isMenuOpen: false,
        // This menuWidth is a workaround to position the compass element from OL when the menu is open/closed.
        // We should build our own compass element and remove this
        menuWidth,
        setMenuWidth: (menuWidth: number) => set({ menuWidth }),
        setMenuContent: (contentID, content, toggle = true) =>
            set((state) => {
                let isMenuOpen;
                if (contentID === state.menuContentId) {
                    isMenuOpen = toggle ? !state.isMenuOpen : true;
                } else {
                    isMenuOpen = true;
                }
                return {
                    menuContentId: contentID,
                    menuContent: content,
                    isMenuOpen,
                };
            }),
        toggleMenu: () => set((state) => ({ isMenuOpen: !state.isMenuOpen })),
        detailedMenu: false,
        setDetailedMenu: (detailedMenu) => set({ detailedMenu }),
    }));

export const useLeftMenu = createMenuStore({});
export const useRightMenu = createMenuStore({});
export const useCenterMenu = createMenuStore({});
