// Libraries
import { useContext } from 'react';
import { t } from 'i18next';

// Component Library
import {
    Icons,
    InfoToolMenu,
    MoreMenu,
    MenuDivider,
    MeasureToolMenu,
    DisplayScaleText,
    MapContext,
    LocateMeButton,
} from '@keypro/2nd-xp';

// Stores
import { useMobileMenu, useSearchStore } from '@stores';

// Components
import {
    getInfoToolMenuProps,
    getMeasureToolMenuProps,
    getMoreMenuProps,
} from './Common';

// Styles
import {
    StyledDisplayScale,
    MapLocationMenuWrapper,
    StyledMenuTools,
} from './MobileToolButtons.styles';

/**
 * The bottom horizontal floating buttons that contain most of the app's tools in Mobile mode.
 * It also includes the DisplayScaleText.
 * The visibility and position of this component depend entirely on the Mobile MenuHeight.
 */
const MobileToolButtons = (): JSX.Element => {
    const measureToolMenuProps = getMeasureToolMenuProps();
    const { 'data-tooltip': moreMenuTooltip, ...moreMenuProps } =
        getMoreMenuProps();
    const infoToolMenuProps = getInfoToolMenuProps();

    const { isMenuOpen, menuOverlayContent } = useMobileMenu();

    const { locationFilter } = useSearchStore();
    const controller = useContext(MapContext)!;

    return (
        <div id="mobile-toolmenu">
            <StyledDisplayScale data-testid="displayScaleText">
                <DisplayScaleText />
            </StyledDisplayScale>
            <MapLocationMenuWrapper>
                <LocateMeButton
                    data-testid="mobile-tool-buttons-locate-me-button"
                    label=""
                />
                <StyledMenuTools
                    $isMenuOpen={isMenuOpen}
                    data-tooltip-left={t(moreMenuTooltip)}
                    onClick={() => {
                        if (menuOverlayContent !== null) {
                            useMobileMenu.setState(() => ({
                                menuContent: null,
                                menuContentId: '',
                                menuHeight: 0,
                                menuOverlayContent: null,
                                isDetailedToolbar: false,
                                isMenuOpen: false,
                            }));
                            useSearchStore.setState(() => ({
                                isAdvancedSearchOpen: false,
                                isFullsearch: false,
                                isUsingAdvancedSearch: false,
                            }));
                            locationFilter?.clearIfLayerExist();
                            controller.state.deactivate();
                        }
                    }}
                >
                    <MoreMenu
                        {...moreMenuProps}
                        align="right"
                        buttonIcon={<Icons.MapTools />}
                        upward
                    >
                        <InfoToolMenu
                            {...infoToolMenuProps}
                            asMenuItems
                            menuItemsGroupTitle={t('Info')}
                        />
                        <MenuDivider />
                        <MeasureToolMenu
                            {...measureToolMenuProps}
                            asMenuItems
                            menuItemsGroupTitle={t('measure')}
                        />
                        <MenuDivider />
                    </MoreMenu>
                </StyledMenuTools>
            </MapLocationMenuWrapper>
        </div>
    );
};

export default MobileToolButtons;
