import { ConsumerPointCustomer } from '@generated';
import { useRightMenu } from '@stores';
import { InfoObject } from '@components';
import { Button, Input } from '@keypro/2nd-xp';
import { t } from 'i18next';

export const CustomerReference = ({
    name,
    customer,
}: {
    name: string;
    customer: ConsumerPointCustomer;
}) => {
    const { setMenuContent } = useRightMenu();
    const openCustomerForm = () => {
        if (!customer?.customer) {
            return;
        }
        setMenuContent(
            `InfoObject-${name}-${customer.customer.id}`,
            <InfoObject model="customer" id={customer.customer.id} />,
        );
    };
    return (
        <td>
            <div style={{ marginBottom: '8px' }}>{t(name)}</div>
            <div className="same-row">
                <Input
                    inputProps={{
                        defaultValue: customer?.customer?.customerName ?? '',
                        readOnly: true,
                    }}
                />
                <Button kind="secondary" onClick={() => openCustomerForm()}>
                    {t('openForm')}
                </Button>
            </div>
        </td>
    );
};
