query ductDataRPC($encodedPk: String!, $type: String!) {
    ductDataRPC(encodedPk: $encodedPk, type: $type) {
        success
        message
        data {
            totalCount
            items {
                mslink
                mhId
                buildYear
                ductTypeDuctTypeTxt
                ductTypeNetTypeTxt
                ductTypeNetworkTypeTxt
                typeDiameterIdTxt
                typeMaterialTxt
                location {
                    type
                    geometry {
                        type
                        coordinates
                    }
                    properties
                }
                beginZCoord
                endZCoord
                fromLid
                zCoord
                zEstimated
            }
        }
    }
}
