import { getSewerDucts } from '@apis/keyduct';
import { FormConfig } from '../types';
import { ductGroups } from './groups';
import { SewerDuctIcon, RainSewerDuctIcon, PressureDuctIcon } from './icons';
import { createComboField } from '../field-utils';

const config: FormConfig = {
    gqlType: 'SewerDuct',
    model: 'snsewerduct',
    modelAliases: ['snsewerductinfo', 'snrainductinfo', 'snpressureductinfo'],
    icon: <SewerDuctIcon />,
    aliasIcons: {
        snrainductinfo: <RainSewerDuctIcon />,
        snpressureductinfo: <PressureDuctIcon />,
    },
    groups: [
        {
            name: 'generalInformation',
            fields: [
                createComboField('type.netType', 'NET_TYPE'),
                createComboField('ductType.networkType', 'NETWORK_TYPE'),
                createComboField('ductType.ductType', 'DUCT_TYPE'),
            ],
        },
        {
            name: 'technicalInfo',
            fields: [
                createComboField(
                    'type.material',
                    'DUCT_MATERIAL',
                    false,
                    'material',
                ),
                createComboField(
                    'type.diameterId',
                    'DUCT_DIAMETER',
                    false,
                    'diameter',
                ),
                createComboField(
                    'type.firmnessClass',
                    'DUCT_FIRMNESS_CLASS',
                    false,
                    'firmness',
                    ['snsewerductinfo', 'snrainductinfo'],
                ),
                createComboField(
                    'type.pressureClass',
                    'DUCT_PRESSURE_CLASS',
                    false,
                    'pressure',
                    ['snpressureductinfo'],
                ),
            ],
        },
        ...ductGroups.slice(1), // Add all groups except the first one
    ],
    functions: {
        get: getSewerDucts,
    },
    queryCollection: 'sewerDucts',
};

export default config;
