import { Plan } from '@generated';
import { TreeNodeType } from '@keypro/2nd-xp';

/**
 * Generates tree nodes from plans.
 * @param plans The plans to generate tree data from.
 * @returns The tree nodes.
 */
export const generateTreeData = (plans: Plan[]): TreeNodeType[] => {
    const nodes: TreeNodeType[] = [];
    const sortedPlans = [...plans];

    // Sort plans by name
    sortedPlans.sort((a, b) => a.name.localeCompare(b.name));

    // Place plans without a parent first
    sortedPlans.sort((a, b) => {
        if (!a.parentplan) {
            return -1;
        } else if (!b.parentplan || a.parentplan.id > b.parentplan.id) {
            return 1;
        } else {
            return 0;
        }
    });

    // Create nodes
    sortedPlans
        .filter((plan) => !plan.parentplan)
        .forEach((plan) => {
            nodes.push(createNode(sortedPlans, plan));
        });

    // Place nodes with children first
    nodes.sort((a, b) => (a.children && !b.children ? -1 : 1));

    // Sort nodes with children by name
    nodes.sort((a, b) => {
        if (!a.children || !b.children) {
            return 0;
        } else {
            return a.name.localeCompare(b.name);
        }
    });

    // Sort nodes without children by name
    nodes.sort((a, b) => {
        if (a.children || b.children) {
            return 0;
        } else {
            return a.name.localeCompare(b.name);
        }
    });

    return nodes;
};

/**
 * Creates a tree node from a plan.
 * @param allPlans All plans.
 * @param plan The plan.
 * @returns The tree node.
 */
const createNode = (allPlans: Plan[], plan: Plan): TreeNodeType => {
    const node: TreeNodeType = {
        id: plan.id,
        name: plan.name,
        selected: false,
    };

    // Check if plan has children
    const children = allPlans.filter((p) => p.parentplan?.id === plan.id);

    if (children.length > 0) {
        node.children = children.map((child) => createNode(allPlans, child));
    }

    return node;
};
