import { WaterDuct, GetWaterDuctsQuery } from '@generated';
import GetWaterDucts from './queries/GetWaterDucts.graphql';
import { sendGraphQLRequest } from '@utils';
import { GraphQLFilter, isFilterEmpty } from '@apis/utils';

/**
 * Gets the water ducts.
 * @param filter The GraphQL filter to apply to the query.
 * @returns The water ducts.
 * @throws {Error} If the request fails.
 */
export const getWaterDucts = async (filter: GraphQLFilter) => {
    try {
        const payload = !isFilterEmpty(filter)
            ? { filter: filter }
            : { filter: null, limit: 1 };
        const response = await sendGraphQLRequest<GetWaterDuctsQuery>(
            GetWaterDucts,
            payload,
        );

        if (!response.data.getWaterDucts) {
            throw Error('No data returned');
        }

        return response.data.getWaterDucts.waterDucts?.filter(
            Boolean,
        ) as WaterDuct[];
    } catch (error) {
        throw Error('Failed to get water ducts: ' + error);
    }
};
