// Libraries
import { useCallback, useEffect, useRef } from 'react';

// Component Library
import { Icons } from '@keypro/2nd-xp';

// Stores
import { useMobileMenu } from '@stores';

// Components
import MobileToolButtons from '../AppToolbar/MobileToolButtons';

// Constants
import { MOBILE_MENU_MAX_HEIGHT_PERCENTAGE } from './AppMenus.consts';

// Styles
import {
    StyledOverlayContainer,
    StyledMobileMenuContainer,
    StyledDragger,
    StyledMobileMenuToolsContainer,
    StyledMobileToolButtons,
} from './AppMobileMenu.styles';

/**
 * The `AppMobileMenus` component provides a single resizable menu system for mobile
 * devices. Renders mobile menu content and an optional overlay. Supports resizing of
 * the menu by dragging a handle and snaping to predefined values (15%, 50%, 75%, 100%).
 * @returns The `AppMobileMenus` component for mobile devices.
 */

const AppMobileMenus = (): JSX.Element => {
    const {
        isMenuOpen,
        menuHeight,
        menuOverlayContent,
        menuContent,
        setMenuHeight,
    } = useMobileMenu();
    const draggerRef = useRef<HTMLDivElement>(null);

    const startDrag = useCallback(
        (startY: number) => {
            const onMove = (moveEvent: MouseEvent | TouchEvent) => {
                const currentY =
                    'clientY' in moveEvent
                        ? moveEvent.clientY
                        : moveEvent.touches[0].clientY;
                const deltaY = currentY - startY;
                const newHeight =
                    menuHeight - (deltaY / window.innerHeight) * 100;
                const allowedHeights = [15, 50, 75, 100];
                const closestHeight = allowedHeights.reduce(
                    (prev, curr) =>
                        Math.abs(curr - newHeight) < Math.abs(prev - newHeight)
                            ? curr
                            : prev,
                    allowedHeights[0],
                );
                setMenuHeight(closestHeight);
            };

            const onEnd = () => {
                window.removeEventListener('mousemove', onMove);
                window.removeEventListener('mouseup', onEnd);
                window.removeEventListener('touchmove', onMove);
                window.removeEventListener('touchend', onEnd);
            };

            window.addEventListener('mousemove', onMove);
            window.addEventListener('mouseup', onEnd);
            window.addEventListener('touchmove', onMove);
            window.addEventListener('touchend', onEnd);
        },
        [menuHeight, setMenuHeight],
    );

    useEffect(() => {
        const draggerElement = draggerRef.current;
        const nonPassiveTouchStart = (event: TouchEvent | MouseEvent) => {
            event.preventDefault(); // Prevent default browser behavior (the drag down to refresh)
            startDrag(
                'clientY' in event ? event.clientY : event.touches[0].clientY,
            );
        };

        if (draggerElement) {
            draggerElement.addEventListener(
                'touchstart',
                nonPassiveTouchStart,
                {
                    passive: false,
                },
            );
            draggerElement.addEventListener('mousedown', nonPassiveTouchStart);
        }

        return () => {
            if (draggerElement) {
                draggerElement.removeEventListener(
                    'touchstart',
                    nonPassiveTouchStart,
                );
                draggerElement.removeEventListener(
                    'mousedown',
                    nonPassiveTouchStart,
                );
            }
        };
    }, [startDrag, isMenuOpen]);

    // Resolving initial height of the menu - should be 0 when not open
    const resolvedMenuHeight = isMenuOpen ? menuHeight : 0;

    // Hides the mobile tool buttons when the menu is fully open
    const isMobileToolButtonsHidden =
        resolvedMenuHeight > MOBILE_MENU_MAX_HEIGHT_PERCENTAGE;

    return (
        <>
            {isMenuOpen && menuOverlayContent && (
                <StyledOverlayContainer
                    style={{
                        bottom: `${menuHeight}%`,
                    }}
                >
                    {menuOverlayContent}
                </StyledOverlayContainer>
            )}
            <StyledMobileMenuToolsContainer
                $resolvedMenuHeight={resolvedMenuHeight}
            >
                <StyledMobileToolButtons
                    $isMobileToolButtonsHidden={isMobileToolButtonsHidden}
                >
                    <MobileToolButtons />
                </StyledMobileToolButtons>
                {isMenuOpen && (
                    <StyledMobileMenuContainer id="menu-container">
                        <StyledDragger
                            data-testid="mobile-menu-dragger"
                            ref={draggerRef}
                        >
                            <Icons.Divider />
                        </StyledDragger>
                        {menuContent}
                    </StyledMobileMenuContainer>
                )}
            </StyledMobileMenuToolsContainer>
        </>
    );
};

export default AppMobileMenus;
