query getWaterBranches($filter: WaterBranchFilter) {
    getWaterBranches(filter: $filter) {
        waterBranches {
            id
            typeId {
                id
                groupname
            }
            node {
                id
                dateAccuracy {
                    groupname
                    txt
                }
                establishMethod {
                    groupname
                    txt
                }
                manholeDesign {
                    groupname
                    txt
                }
                createdBy
                createdTs
                updatedBy
                updatedTs
                nodeType
                feature
                locationAccuracy {
                    txt
                }
                heightAccuracy {
                    groupname
                    txt
                }
                mappingMethod {
                    groupname
                    txt
                }
                safetyDistance {
                    groupname
                    txt
                }
                planNumber
                planDate
                surveyNumber
                mapper
                historyDate
                buildYear
                removalYear
                owner {
                    groupname
                    txt
                }
                position
                zCoord1
                zCoord2
                terrainHeight
                zCoord1Estimated
                zCoord2Estimated

                valveType {
                    id
                }
                fireplugType {
                    id
                }
                hydrantType {
                    id
                }
                fireWaterstationType {
                    id
                }
                measuringpointType {
                    id
                }
                watertankType {
                    id
                }
                branchType {
                    id
                }
                pumpType {
                    id
                }
                manholeType {
                    id
                }
                lidType {
                    id
                }
                objectType {
                    id
                }
                watersourceType {
                    id
                }
                manhole {
                    id
                }
                faultCode {
                    id
                    groupname
                }
                location
                note
                internalNodeId
                userId
                plan {
                    id
                }
                address {
                    id
                }
                status {
                    groupname
                    txt
                }
                statusInfo {
                    groupname
                    txt
                }
                landxmlName
                angle
                scale
                showOnMap
                pressure
                network

                lblAngle
                lblAngleExp
                lblColor
                lblColorExp
                lblSize
                lblSizeExp
                symAngle
                symAngleExp
                symCodeGt
                symColor
                symColorExp
                symName
                symNameExp
                symSize
                symSizeExp
                symText
                symTextExp
                symWidth
                symWidthExp
            }
            materialType {
                id
                groupname
            }
        }
    }
}
