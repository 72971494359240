// Libraries
import { useEffect, useState } from 'react';
import '@i18n';

// Component Libraries
import {
    Wrapper,
    MapWrapper,
    MapContext,
    MapController,
    Tooltip,
    Toast,
    ToastProvider,
} from '@keypro/2nd-xp';

// Components
import {
    AppToolbar,
    AppMap,
    AppMenus,
    AppNavigationBar,
    SessionWrapper,
} from '@components';

// Hooks
import { useGetPlacementScales } from '@hooks/map';

// Pages
import { LoadingPage } from '@pages';

// Stores
import { useTxtConstants, useSnConstants, useProductStore } from '@stores';

const MainPage = () => {
    const [controller, setController] = useState<MapController | undefined>();
    const { data: placementScales } = useGetPlacementScales();
    const { product } = useProductStore();
    const {
        txtConstants,
        getIsLoading: getIsTxtConstantsLoading,
        fetchTxtConstants,
    } = useTxtConstants();

    const {
        snConstants,
        getIsLoading: getIsSnsConstantsLoading,
        fetchSnConstants,
    } = useSnConstants();

    useEffect(() => {
        if (placementScales) {
            setController(
                new MapController({
                    placementScales: placementScales,
                }),
            );
        }
    }, [placementScales]);

    useEffect(() => {
        const fetchTextConstantsFunction = async () => {
            const isTxtConstantsLoading = getIsTxtConstantsLoading();
            if (txtConstants.length === 0 && !isTxtConstantsLoading) {
                await fetchTxtConstants();
            }
        };
        fetchTextConstantsFunction();
    }, [txtConstants, fetchTxtConstants, getIsTxtConstantsLoading]);

    useEffect(() => {
        const fetchSnsConstantsFunction = async () => {
            const isSnsConstantsLoading = getIsSnsConstantsLoading();
            const isKeyAqua = product === 'keyaqua';
            if (
                isKeyAqua &&
                !isSnsConstantsLoading &&
                snConstants.length === 0
            ) {
                await fetchSnConstants();
            }
        };
        fetchSnsConstantsFunction();
    }, [snConstants, product, fetchSnConstants, getIsSnsConstantsLoading]);

    // Prevent rendering if still loading or if controller is not set
    if (
        controller === undefined ||
        getIsTxtConstantsLoading() ||
        getIsSnsConstantsLoading()
    ) {
        return <LoadingPage />;
    }

    return (
        <ToastProvider>
            <Toast />
            <Wrapper style={{ overflow: 'hidden' }}>
                <Tooltip />
                <MapContext.Provider value={controller}>
                    <SessionWrapper />
                    <AppToolbar />
                    <MapWrapper>
                        <AppNavigationBar />
                        <AppMap />
                        <AppMenus />
                    </MapWrapper>
                </MapContext.Provider>
            </Wrapper>
        </ToastProvider>
    );
};

export default MainPage;
