// Component Library
import { styled } from '@keypro/2nd-xp';

interface MobileProps {
    $isMenuOpen?: boolean;
}

export const MapLocationMenuWrapper = styled.div`
    position: absolute;
    bottom: 16px;
    right: 16px;
    display: grid;
    gap: 8px;
`;

export const StyledMenuTools = styled.div<MobileProps>`
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => props.theme.colors.accents.blue['10']};
    width: 36px;
    height: 36px;

    & * > button {
        box-shadow: none;
        border-radius: 50%;
        &:enabled:hover {
            background-color: ${(props) =>
                props.theme.colors.accents.blue['10']};
        }
    }
    & .dropdown > button > svg {
        width: 20px;
        height: 20px;
    }
    // Remove deprecated upward and find a better solution for dropdown, then remove this
    & .dropdown > .dropdown-dropdown {
        ${({ $isMenuOpen }) => $isMenuOpen && 'top: 300px; right: 70px;'};
    }
`;

export const StyledDisplayScale = styled.div`
    position: absolute;
    bottom: 16px;
    left: 16px;
`;
