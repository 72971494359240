import { getCustomers } from '@apis/keyduct';
import { FormConfig } from '../types';
import { Icons } from '@keypro/2nd-xp';
import { createComboField } from '../field-utils';
import { consumerPointTable } from './components/CustomerComponents';

const config: FormConfig = {
    gqlType: 'Customer',
    model: 'customer',
    modelAliases: ['sncustomer'],
    icon: <Icons.PrintLegend />,
    groups: [
        {
            name: 'generalInformation',
            fields: [
                'customerName',
                {
                    name: 'identification',
                    translationKey: 'customerIdentification',
                },
                'customerNameExt',
                'customerNumber',
                createComboField(
                    'customerRestrictedType',
                    'KU_RESTRICTED_TYPE',
                ),
                createComboField('customerType', 'CUSTOMER_TYPE'),
            ],
        },
        {
            name: 'addressGroup',
            fields: [{ name: 'address', component: 'address' }],
        },
        {
            name: 'invoicingInformation',
            fields: [
                'customerPhone',
                'customerEmail',
                createComboField('customerLanguage', 'CUSTOMER_INV_LANG'),
                'phone2',
                createComboField('customerState', 'CUSTOMER_STATE'),
                {
                    name: 'twoSmsNumbers',
                    component: 'checkbox-field',
                },
                {
                    name: 'isEditedByCustomer',
                    component: 'checkbox-field',
                },
            ],
        },

        // Notification - no data in api??

        {
            name: 'invoicingAddress',
            fields: [{ name: 'invoicingAddress', component: 'address' }],
        },
        {
            name: 'ConsumerPoint.$titlePlural',
            fields: [
                {
                    name: 'consumerPoints',
                    customComponent: consumerPointTable,
                    fullWidth: true,
                    translationKey: 'ConsumerPoint.$titlePlural',
                },
            ],
        },
    ],
    functions: {
        get: getCustomers,
    },
    queryCollection: 'sewerDucts',
};

export default config;
