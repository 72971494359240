// Libraries
import { create } from 'zustand';

// APIs
import { getTxtConstants } from '@apis/keycore';

// Generated
import { TxtConstant } from '@generated';

interface TxtConstantState {
    txtConstants: TxtConstant[];
    isLoading: boolean;
    fetchTxtConstants: (limit?: number) => Promise<void>;
    getTxtConstantsBy: ({
        groupname,
        onlyWithAdditionalData,
    }: {
        groupname: string;
        onlyWithAdditionalData?: boolean;
    }) => TxtConstant[];
    getIsLoading: () => boolean;
}

export const useTxtConstants = create<TxtConstantState>((set, get) => ({
    txtConstants: [],
    isLoading: false,
    error: null,
    fetchTxtConstants: async () => {
        set({ isLoading: true });
        try {
            const txtConstants = await getTxtConstants(undefined, 20000);
            set({ txtConstants, isLoading: false });
        } catch (error) {
            set({ isLoading: false });
            throw error;
        }
    },
    getIsLoading: () => get().isLoading,
    getTxtConstantsBy: ({
        groupname,
        onlyWithAdditionalData = false,
    }: {
        groupname: string;
        onlyWithAdditionalData?: boolean;
    }) => {
        return get().txtConstants.filter(
            (txtConstant) =>
                txtConstant.groupname === groupname &&
                (!onlyWithAdditionalData ||
                    txtConstant.additionalData !== null),
        );
    },
}));
