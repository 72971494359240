query getSnConstants($filter: SnConstantsFilter $limit: Int) {
	getSnConstants(filter: $filter limit: $limit) {
        snConstants {
    	    id
            groupname
            numValue
            orderno
            txt
            txt2
            value
        }
    }
}
