import { DuctDataRpcResponse, DuctDataRpcQuery } from '@generated';
import { sendGraphQLRequest } from '@utils';
import DuctRPC from './queries/DuctRPC.graphql';

/**
 * Search using duct RPC.
 * @returns {Promise<DuctDataRpcResponse>} The duct rpc response.
 * @throws {Error} If the request fails.
 */
export const ductRPC = async (args: {
    encodedPk: string;
    type: string;
}): Promise<DuctDataRpcResponse> => {
    try {
        const response = await sendGraphQLRequest<DuctDataRpcQuery>(DuctRPC, {
            encodedPk: args.encodedPk,
            type: args.type,
        });

        if (!response.data.ductDataRPC) {
            throw Error('No duct data returned');
        }

        return response.data.ductDataRPC;
    } catch (error) {
        throw Error('Failed duct data rpc: ' + error);
    }
};
