import React from 'react';
import { useFloating, offset, autoUpdate, flip } from '@floating-ui/react';
import {
    styled,
    Menu,
    MenuItem,
    MenuDivider,
    Switch,
    Icons,
} from '@keypro/2nd-xp';
import { t } from 'i18next';

const StyledMapViewSettingsFloating = styled.div`
    z-index: 1;
    padding: 20px;
    cursor: default;
`;

interface MapViewSettingsFloatingProps {
    /** Name of the MapView */
    name: string | null;
    /** Reference element for Floating UI */
    referenceElement: HTMLElement | null;
    /** Function to close the floating element */
    handleClose: () => void;
    /** Function to call when the item is deleted */
    onDelete: () => void;
    /** Function to set the editing state */
    setEditingView: React.Dispatch<React.SetStateAction<string | null>>;
}

/**
 * Floating element for the settings of a MapView
 */
export const MapViewSettingsFloating = ({
    name,
    referenceElement,
    handleClose,
    onDelete,
    setEditingView,
}: MapViewSettingsFloatingProps) => {
    const { floatingStyles, refs } = useFloating({
        placement: 'bottom-start',
        middleware: [
            offset({
                crossAxis: -20,
                mainAxis: -(referenceElement
                    ? referenceElement.offsetHeight + 20
                    : 0),
            }),
            flip(),
        ],
        whileElementsMounted: autoUpdate,
        elements: { reference: referenceElement },
    });

    return (
        <StyledMapViewSettingsFloating
            ref={refs.setFloating}
            data-testid={`mapview-menu-${name}`}
            style={floatingStyles}
            onMouseLeave={handleClose}
        >
            <Menu>
                <MenuItem
                    data-testid="mapview-menu-rename-button"
                    leftContent={t('renameMapView')}
                    iconLeft={<Icons.Edit />}
                    onClick={() =>
                        setEditingView(
                            referenceElement?.parentElement?.id ?? null,
                        )
                    }
                />
                <MenuItem
                    iconLeft={<Icons.Restore />}
                    leftContent={t('restoreMapView')}
                    disabled={true}
                />
                <MenuDivider />
                <MenuItem
                    iconLeft={<Icons.Locate />}
                    leftContent={t('rememberMyPosition')}
                    rightContent={<Switch disabled={true} />}
                    disabled={true}
                />
                <MenuItem
                    data-testid="mapview-menu-delete-button"
                    iconLeft={<Icons.Trash />}
                    leftContent={t('delete')}
                    onClick={() => onDelete()}
                />
            </Menu>
        </StyledMapViewSettingsFloating>
    );
};
