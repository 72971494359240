// Libraries
import { Button, styled } from '@keypro/2nd-xp';

export const Coordinates = styled.div`
    background-color: black;
    border-radius: 12px;
    height: 36px;
    display: flex;
    gap: 32px;
    padding-right: 4px;
    padding-left: 4px;
`;

export const MainContainer = styled.div`
    margin-bottom: 20px;
    margin-right: 20px;
    margin-left: 20px;
    pointer-events: auto;
    position: relative;
    display: flex;
    gap: 8px;
    flex-direction: row;
`;

export const CoordinateItem = styled.span`
    padding-left: 8px;
    margin: auto;
    width: 164px;
    white-space: nowrap;
    font-variant-numeric: tabular-nums;
    font-feature-settings: 'tnum';
    ${(props) => props.theme.fonts['11px Medium']};
`;

export const CoordinateBtn = styled(Button)`
    margin: auto;
    & > svg {
        width: 16px;
        height: 16px;
    }
`;
