import { t } from 'i18next';
import {
    NumberInput,
    Input,
    Button,
    SelectOptionItem,
    colorPickerDefaultColors,
    Checkbox,
} from '@keypro/2nd-xp';
import {
    TextArea,
    LazySelect,
    LazyRadioGroup,
    Checkbox as FormCheckbox,
    DateInput,
    AddressInput,
    Link,
    RangeNumberInput,
    FormColorPicker,
} from './form-components';
import { ObjectReference } from './FormBuilder';
import { FormData, FormField, LabelFormatter } from '@form-configs';
import { Address, Apartment } from '@generated';
import { useState } from 'react';

export interface FormRowComponentProps {
    name: string;
    label: string;
    value: unknown;
    formData: FormData;
    trClassName: string;
    config?: FormField;
    onChangeValue?: (name: string, value: unknown) => void;
    queryFunction?: (
        labelFormatter?: LabelFormatter,
    ) => Promise<SelectOptionItem[]>;
    getFixedOptions?: (
        labelFormatter?: LabelFormatter,
    ) => Promise<SelectOptionItem[]>;
    returnRawComponents?: boolean;
}

export const customComponent = ({
    name,
    trClassName,
    formData,
    value,
    config,
}: FormRowComponentProps) => (
    <tr key={name} className={trClassName}>
        {config?.customComponent?.({ formData, value, name })}
    </tr>
);

export const textInput = ({
    name,
    label,
    value,
    onChangeValue,
}: FormRowComponentProps) => (
    <tr key={name}>
        {label && <th data-testid={`form-row-header-${label}`}>{label}</th>}
        <td data-testid={`form-row-input-${label}`}>
            <Input
                inputProps={{
                    name: name,
                    defaultValue: value as string,
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                        onChangeValue?.(name, e.target.value),
                }}
            />
        </td>
    </tr>
);

export const textArea = ({
    name,
    value,
    config,
    onChangeValue,
}: FormRowComponentProps) => (
    <tr key={name} className="form-row-full-width">
        <td colSpan={2}>
            <TextArea
                inputProps={{
                    name: name,
                    placeholder: config?.translationKey
                        ? t(config.translationKey)
                        : t('addNote'),
                    defaultValue: value as string,
                    rows: 3,
                    onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) =>
                        onChangeValue?.(name, e.target.value),
                }}
                multiline
            />
        </td>
    </tr>
);

export const numberInput = ({
    name,
    label,
    formData,
    value,
    config,
    onChangeValue,
}: FormRowComponentProps) => (
    <tr key={name}>
        {label && <th data-testid={`form-row-header-${label}`}>{label}</th>}
        <td data-testid={`form-row-input-${label}`}>
            <NumberInput
                value={
                    (config?.valueFormatter?.(
                        formData,
                        value,
                        name,
                    ) as number) ?? (value as number | null)
                }
                className="form-number-input"
                onChange={(value) =>
                    onChangeValue?.(name, value ? value.toString() : null)
                }
            />
        </td>
    </tr>
);

export const comboBox = ({
    name,
    label,
    queryFunction,
    value,
    config,
    onChangeValue,
}: FormRowComponentProps) => (
    <tr key={name}>
        {label && <th data-testid={`form-row-header-${label}`}>{label}</th>}
        <td>
            <LazySelect
                queryFunction={queryFunction!}
                initialValue={(value as ObjectReference)?.id}
                labelFormatter={config?.labelFormatter}
                onChange={(value) => onChangeValue?.(name, value)}
            />
        </td>
    </tr>
);

export const comboBoxMulti = ({
    name,
    label,
    queryFunction,
    value,
    onChangeValue,
    returnRawComponents,
}: FormRowComponentProps) => {
    if (returnRawComponents)
        return (
            <LazySelect
                queryFunction={queryFunction!}
                initialValue={(value as ObjectReference)?.id}
                isMultiSelect={true}
                onChange={(value) => onChangeValue?.(name, value)}
                placeholder={t('allTypes')}
                key={name}
            />
        );
    return (
        <tr key={name}>
            {label && <th data-testid={`form-row-header-${label}`}>{label}</th>}
            <td>
                <LazySelect
                    queryFunction={queryFunction!}
                    initialValue={(value as ObjectReference)?.id}
                    isMultiSelect={true}
                    onChange={(value) => onChangeValue?.(name, value)}
                />
            </td>
        </tr>
    );
};

export const radioGroup = ({
    name,
    label,
    queryFunction,
    value,
    onChangeValue,
}: FormRowComponentProps) => (
    <tr key={name}>
        {label && <th data-testid={`form-row-header-${label}`}>{label}</th>}
        <td data-testid={`form-row-input-${label}`}>
            <LazyRadioGroup
                name={name}
                queryFunction={queryFunction!}
                initialValue={(value as ObjectReference)?.id}
                onChange={(value) => onChangeValue?.(name, value)}
            />
        </td>
    </tr>
);

export const checkbox = ({
    name,
    label,
    value,
    onChangeValue,
}: FormRowComponentProps) => (
    <tr key={name}>
        <td colSpan={2}>
            <div className="same-row" data-testid={`form-row-input-${label}`}>
                <FormCheckbox
                    name={name}
                    type="checkbox"
                    label={label}
                    checked={value as boolean}
                    inputProps={{
                        onChange: (event: React.FormEvent<HTMLInputElement>) =>
                            onChangeValue?.(
                                name,
                                (event.target as HTMLInputElement).value,
                            ),
                    }}
                />
            </div>
        </td>
    </tr>
);

export const dateInput = ({
    name,
    label,
    value,
    onChangeValue,
}: FormRowComponentProps) => (
    <tr key={name}>
        {label && <th data-testid={`form-row-header-${label}`}>{label}</th>}
        <td data-testid={`form-row-input-${label}`}>
            <DateInput
                placeholder={t('selectDate')}
                initialValue={value ? new Date(value as string) : undefined}
                onChange={(date) => onChangeValue?.(name, date?.toISOString())}
            />
        </td>
    </tr>
);

export const addressInput = ({ name, value }: FormRowComponentProps) => (
    <tr key={name} className="form-row-full-width">
        <td colSpan={2}>
            <AddressInput data={(value as Apartment)?.address as Address} />
        </td>
    </tr>
);

export const link = ({
    name,
    label,
    formData,
    value,
    config,
}: FormRowComponentProps) => (
    <tr key={name}>
        {label && <th>{label}</th>}
        <td>
            {config?.valueFormatter?.(formData, value, name) || (
                <Link
                    url={value as string}
                    text={value as string}
                    data-tooltip-left={value as string}
                />
            )}
        </td>
    </tr>
);

export const objectReference = ({
    name,
    label,
    queryFunction,
    value,
    getFixedOptions,
    onChangeValue,
    config,
}: FormRowComponentProps) => (
    <tr key={name} className="form-row-full-width">
        {label && <th>{label}</th>}
        <td style={{ width: label ? 'auto' : '100%' }}>
            <div className="same-row">
                <LazySelect
                    queryFunction={
                        Array.isArray(value) ? getFixedOptions! : queryFunction!
                    }
                    initialValue={(value as ObjectReference)?.id}
                    labelFormatter={config?.labelFormatter}
                    onChange={(value) => onChangeValue?.(name, { id: value })}
                />
                <Button kind="secondary">{t('openForm')}</Button>
            </div>
        </td>
    </tr>
);

export const rangeNumberInput = ({
    name,
    label,
    value,
    onChangeValue,
}: FormRowComponentProps) => (
    <tr key={name}>
        {label && <th data-testid={`form-row-header-${label}`}>{label}</th>}
        <td data-testid={`form-row-input-${label}`}>
            <RangeNumberInput
                initialMinValue={(value as { min: number })?.min}
                initialMaxValue={(value as { max: number })?.max}
                onChange={(value) => onChangeValue?.(name, value)}
            />
        </td>
    </tr>
);

export const colorPicker = ({
    name,
    label,
    value,
    onChangeValue,
}: FormRowComponentProps) => (
    <tr key={name}>
        {label && <th data-testid={`form-row-header-${label}`}>{label}</th>}
        <td data-testid={`form-row-input-${label}`} style={{ zIndex: 1 }}>
            <FormColorPicker
                initialValue={value as string}
                onChange={(value) => onChangeValue?.(name, value)}
                colors={colorPickerDefaultColors}
            />
        </td>
    </tr>
);

export const checkboxField = ({
    name,
    label,
    value,
}: FormRowComponentProps) => {
    const CheckboxField = () => {
        const [state, setState] = useState<boolean>(!!value);
        const toggleState = () => {
            setState(!state);
        };
        return (
            <tr key={name}>
                {label && (
                    <th data-testid={`form-row-header-${label}`}>{label}</th>
                )}
                <td data-testid={`form-row-input-${label}`}>
                    <div style={{ height: '30px' }}>
                        <Checkbox
                            id={name}
                            style={{
                                borderRadius: 4,
                                height: '22px',
                                width: '22px',
                                boxSizing: 'border-box',
                            }}
                            type="checkbox"
                            checked={state}
                            onChange={toggleState}
                        />
                    </div>
                </td>
            </tr>
        );
    };
    return <CheckboxField />;
};
