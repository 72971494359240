import { GetInfoToolResultsQuery, SearchArea } from '@generated';
import GetInfoToolResults from './queries/GetInfoToolResults.graphql';
import { sendGraphQLRequest } from '@utils';

/**
 * Gets the info tool results.
 * @returns {Promise<InfoToolResult[]>} The info tool result.
 * @throws {Error} If the request fails.
 */
export const fetchInfoToolResults = async (options: {
    layers: string[];
    searchArea: SearchArea;
}) => {
    const result = await sendGraphQLRequest<GetInfoToolResultsQuery>(
        GetInfoToolResults,
        {
            filter: {
                layers: options.layers,
                searchArea: options.searchArea,
            },
        },
    );

    if (!result.data.getInfoToolResults) {
        throw new Error('Failed to get info tools');
    }

    return {
        total: result.data.getInfoToolResults.total,
        results: result.data.getInfoToolResults.results,
    };
};
