/**
 * Profile diagram data coordinates has three digits precision
 */
export const EPSILON = 0.001;

/**
 * Get radius of circumcircle from triangle (given by three pionts)
 */
export const circumRadius = (s: number[], m: number[], e: number[]) => {
    const a = Math.sqrt(
        (m[0] - s[0]) * (m[0] - s[0]) + (m[1] - s[1]) * (m[1] - s[1]),
    );
    const b = Math.sqrt(
        (e[0] - s[0]) * (e[0] - s[0]) + (e[1] - s[1]) * (e[1] - s[1]),
    );
    const c = Math.sqrt(
        (e[0] - m[0]) * (e[0] - m[0]) + (e[1] - m[1]) * (e[1] - m[1]),
    );
    const p = (a + b + c) / 2;
    const S = Math.sqrt(p * (p - a) * (p - b) * (p - c)); // area of the triangle (Hero's formula)
    const r = (a * b * c) / (4 * S); // radius of circumcircle
    return r;
};

/**
 * Turn direction from a given vector (given by start and end point) to a point.
 * @param {Array} p - The point to be checked
 * @param {Array} s - The start point of the vector
 * @param {Array} e - The end point of the vector
 * @return 1 - clockwise, 0 - co-linear and -1 - counterclockwise
 */
export const pointTurnDir = (p: number[], s: number[], e: number[]) => {
    const u = [e[0] - s[0], e[1] - s[1]],
        v = [p[0] - s[0], p[1] - s[1]]; // points formed vectors
    const uLen = Math.sqrt(u[0] * u[0] + u[1] * u[1]),
        vLen = Math.sqrt(v[0] * v[0] + v[1] * v[1]);
    const uNorm = [u[0] / uLen, u[1] / uLen],
        vNorm = [v[0] / vLen, v[1] / vLen]; // normalized vectors

    const cp = uNorm[0] * vNorm[1] - uNorm[1] * vNorm[0];
    if (Math.abs(cp) < EPSILON) {
        return 0;
    } else {
        return cp > 0 ? -1 : 1;
    }
};

/**
 * Rotate vector with given angle (2d plane)
 * @param {Array} x, y - coordinate of the vector
 * @angle {Number} angle - angle in radian
 * @returns {Array} result vector
 */
export const rotateVector = (x: number, y: number, angle: number) => {
    const rx = x * Math.cos(angle) - y * Math.sin(angle);
    const ry = x * Math.sin(angle) + y * Math.cos(angle);
    return [rx, ry];
};
