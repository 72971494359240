query getSewerDucts($filter: SewerDuctFilter) {
  getSewerDucts(filter: $filter) {
     sewerDucts {
      id
      createdBy
      createdTs
      type {
        netType {id}
        material {id}
        diameterId {id}
        pressureClass {id}
        firmnessClass {id}
        technicalLifespan
      }
      ductType {
        ductType{id}
        networkType{id}
      }
      buildYear
      removalYear
      state {id}
      length2D
      length
      installLocation {id}
      owner {id}
      ductCategory {id}
      locationAccuracy {id}
      heightAccuracy {id}
      mappingMethod {id}
      safetyDistance {id}
      surveyNumber
      dateAccuracy {id}
      connectionPermission {id}
      mapper
      beginZCoord
      endZCoord
      plan {id}
      address {
        id
        address {
          id
          streetno
          streetname
          streetnameAlt
          district
          city
          province
          municipality
          country
          zipcode
          location
        }
      }
      note
    }
  }
}