import React, { useState } from 'react';
import {
    Wrapper,
    styled,
    Button,
    Icons,
    Checkbox,
    Tooltip,
} from '@keypro/2nd-xp';

import backgroundImage from '@assets/images/login-bg.webp';
import KeyproNexusLogoLoginBeta from '@assets/icons/KeyproNexusLogoLoginBeta.svg';
import { LanguageSelector } from '@components';

import { useTranslation, Trans } from 'react-i18next';
import { AuthContext } from '@providers';
import { AuthMethod } from '@hooks/auth/useAuth';
import { useProductStore } from '@stores';

const StyledBackground = styled.div`
    background-image: url(${backgroundImage});
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    display: flex;
    @media (max-width: 768px) {
        background-image: none;
        background-color: ${(props) => props.theme.colors.neutral[100]};
        overflow: hidden;
    }
`;
const LoginBoxWrapper = styled.div`
    margin: 0 auto;
    display: flex;
    max-width: 516px;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (max-width: 1280px) {
        max-width: 368px;
    }

    @media (max-width: 768px) {
        padding-bottom: 10px;
    }
`;
const Logo = styled.img`
    position: relative;
    top: 36px;
    width: 84px;
    height: 84px;
    @media (max-width: 1280px) {
        width: 64px;
        height: 64px;
        top: 32px;
    }
    @media (max-width: 768px) {
        top: 20px;
    }
`;

const StyledLoginBox = styled.form`
    display: flex;
    height: 100%;
    width: 100%;
    padding-bottom: 32px;
    align-items: center;
    flex-direction: column;
    max-height: 684px;
    justify-content: center;
    border-radius: 36px;
    background-color: ${(props) => props.theme.colors.neutral[100]};

    button {
        width: 340px;
        height: 56px;
        font-size: 20px;
    }

    @media (max-width: 1280px) {
        max-height: 526px;
        padding-bottom: 48px;
        button {
            width: 256px;
            height: 40px;
            font-size: 16px;
        }
    }
`;
const StyledHeader = styled.h1`
    color: ${(props) => props.theme.colors.neutral[10]};
    text-align: center;
    font-style: normal;
    font-size: 28px;
    line-height: 56px;
    padding-top: 78px;
    padding-bottom: 16px;
    @media (max-width: 1280px) {
        font-size: 20px;
        line-height: 52px;
    }
`;

const Frame = styled.div`
    display: flex;
    max-width: 340px;
    width: 100%;
    padding-bottom: 28px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    flex-direction: column;
    @media (max-width: 1280px) {
        max-width: 256px;
    }
`;

const Label = styled.div`
    display: flex;
    width: 100%;
    margin-bottom: 8px;
    color: ${(props) => props.theme.colors.neutral[10]};

    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    justify-content: space-between;

    @media (max-width: 1280px) {
        font-size: 14px;
        line-height: 20px;
    }
`;

const Plate = styled.div`
    display: flex;
    background-color: #f6f6f6;
    align-items: center;
    width: 306px;
    padding: 0 16px;
    height: 56px;
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px solid #dadde0;

    &:focus-within {
        border: 1px solid ${(props) => props.theme.colors.accents.blue[10]};
        box-shadow: 0px 0px 0px 4px #81bdff;
    }

    @media (max-width: 1280px) {
        max-width: 234px;
        padding: 0 10px;
        height: 40px;
        svg {
            width: 20px;
            height: 20px;
        }
    }

    & svg:last-child {
        cursor: pointer;
    }
`;

const InputField = styled.input`
    background: transparent;
    color: ${(props) => props.theme.colors.neutral[10]};
    width: 75%;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    display: flex;
    height: 24px;
    margin: 0 8px;
    border: none;
    caret-color: ${(props) => props.theme.colors.accents.blue[10]};

    &:focus {
        outline: none;
    }
`;

const RegisterField = styled.div`
    display: flex;
    margin-top: 16px;
    width: 340px;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
`;

const TermsBox = styled.label`
    display: flex;
    width: 340px;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    margin-top: 16px;
    @media (max-width: 1280px) {
        width: 256px;
    }
`;
const TermsText = styled.div`
    max-width: 300px;
    width: 100%;
    align-self: stretch;
    color: #6a6d78;

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;

    a {
        text-decoration: underline;
        color: #6a6d78;
    }
    @media (max-width: 1280px) {
        max-width: 220px;
        font-size: 12px;
        line-height: 16px;
    }
`;

const HelperText = styled.div`
    ${(props) => props.theme.fonts['14px Medium']};
    display: flex;
    height: 20px;
    color: ${(props) => props.theme.colors.accents.red[10]};
    width: 100%;
`;

const Footer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;

    @media (max-width: 768px) {
        align-items: center;
        flex-direction: column;
        position: relative;
        bottom: 0;
    }
`;

const StyledA = styled.a`
    color: ${(props) => props.theme.colors.accents.blue[10]};

    font-style: normal;
    font-weight: 400;

    text-decoration: none;
`;

const CopyRight = styled.p`
    color: ${(props) => props.theme.colors.neutral[100]};
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;

    @media (max-width: 768px) {
        color: #6a6d78;
    }
`;

const getLoginTranslation = (product: string) => {
    switch (product) {
        case 'keycom':
            return 'KeyCom Nexus';
        case 'keyaqua':
            return 'KeyAqua Nexus';
        default:
            return 'KeyCom Nexus';
    }
};

const LoginPage = () => {
    const { t } = useTranslation();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [termsChecked, setTermsChecked] = useState(false);
    const [loginError, setLoginError] = useState('');
    const { login, isLoading, isAuth0Available } =
        React.useContext(AuthContext);
    const [showPassword, setShowPassword] = useState(false);
    const { product } = useProductStore();

    const handleLogin = async (
        event: React.MouseEvent<HTMLButtonElement>,
        method: AuthMethod,
    ) => {
        event.preventDefault();
        const response = await login(method, termsChecked, username, password);
        if (response) {
            setLoginError(response);
        }
    };

    return (
        <Wrapper>
            <Tooltip />
            <StyledBackground data-testid="login-page">
                <LoginBoxWrapper>
                    <Logo
                        src={KeyproNexusLogoLoginBeta}
                        alt="Keypro Nexus logo"
                    />
                    <StyledLoginBox>
                        <StyledHeader data-testid="login-header">
                            {getLoginTranslation(product ?? '')}
                        </StyledHeader>
                        <Frame>
                            <Label data-testid="login-username-label">
                                {t('usernameOrEmail')}
                            </Label>
                            <Plate data-tooltip={t('username')}>
                                <Icons.User />
                                <InputField
                                    type="text"
                                    value={username}
                                    onChange={(e) =>
                                        setUsername(e.target.value)
                                    }
                                    style={{ width: '85%' }}
                                    disabled={isLoading}
                                    data-testid="login-username-textbox"
                                />
                            </Plate>
                        </Frame>
                        <Frame style={{ paddingBottom: '8px' }}>
                            <Label data-testid="login-password-label">
                                {t('password')}
                                <StyledA
                                    href="#"
                                    data-testid="login-password-forgot"
                                >
                                    {t('forgotPassword')}
                                </StyledA>
                            </Label>
                            <Plate data-tooltip={t('password')}>
                                <Icons.Lock />
                                <InputField
                                    type={showPassword ? 'text' : 'password'}
                                    value={password}
                                    onChange={(e) =>
                                        setPassword(e.target.value)
                                    }
                                    disabled={isLoading}
                                    data-testid="login-password-textbox"
                                />
                                {showPassword ? (
                                    <Icons.EyeClose
                                        data-tooltip={t('showPassword')}
                                        onClick={() => setShowPassword(false)}
                                    />
                                ) : (
                                    <Icons.Eye
                                        data-tooltip={t('showPassword')}
                                        onClick={() => setShowPassword(true)}
                                    />
                                )}
                            </Plate>
                            <HelperText data-testid="login-error">
                                {loginError}
                            </HelperText>
                        </Frame>
                        <Button
                            style={{
                                marginTop: '16px',
                            }}
                            disabled={isLoading}
                            onClick={(e) => handleLogin(e, AuthMethod.Default)}
                            data-testid="login-button"
                        >
                            {t('login')}
                        </Button>
                        {isAuth0Available && (
                            <Button
                                kind="secondary"
                                style={{
                                    marginTop: '16px',
                                }}
                                onClick={(e) =>
                                    handleLogin(e, AuthMethod.Auth0)
                                }
                                data-testid="login-auth0-button"
                            >
                                Auth0
                            </Button>
                        )}
                        <RegisterField>
                            <Label
                                style={{
                                    justifyContent: 'center',
                                    color: '#6A6D78',
                                }}
                                data-testid="login-register-label"
                            >
                                {t('dontHaveAccount')}&nbsp;
                                <StyledA
                                    href="#"
                                    data-testid="login-register-button"
                                >
                                    {t('register')}
                                </StyledA>
                            </Label>
                        </RegisterField>
                        <TermsBox>
                            <Checkbox
                                data-testid="term-check-box"
                                data-tooltip={t('termsOfServiceCheckbox')}
                                checked={termsChecked}
                                onChange={() => setTermsChecked(!termsChecked)}
                                style={{ width: '20px', height: '20px' }}
                            ></Checkbox>

                            <TermsText data-testid="login-termsOfService">
                                <Trans i18nKey="termsOfService">
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="https://www.keypro.fi/terms-of-service"
                                    >
                                        Terms of Service
                                    </a>
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="https://www.keypro.fi/privacy-policy"
                                    >
                                        Privacy Policy
                                    </a>
                                </Trans>
                            </TermsText>
                        </TermsBox>
                    </StyledLoginBox>
                    <Footer>
                        <CopyRight data-testid="login-copyright">
                            {t('copyright')}
                        </CopyRight>
                        <LanguageSelector />
                    </Footer>
                </LoginBoxWrapper>
            </StyledBackground>
        </Wrapper>
    );
};

export default LoginPage;
