// Component Library
import { Icons } from '@keypro/2nd-xp';

// Apis
import { getManholes, getProfileDiagramRPC } from '@apis/keycom';

// Types
import { DIVIDER, FormConfig, FormContext } from '../types';

// Groups
import {
    addressGroup,
    commonGroup,
    planGroup,
    telecomAreaGroup,
} from '../groups';

// Actions
import {
    defaultTopActions,
    defaultMiddleActions,
    defaultBottomActions,
} from '../actions';

// Utils
import { createComboField } from '../field-utils';

// Components
import { ManholeDiagram } from '@components';

// Consts
import { centerMenuManholeDiagramContentId } from './Manhole.consts';

/**
 * Opens the manhole diagram.
 * @param context The form context.
 */
const openDiagram = (context: FormContext) => {
    context.setCenterContent('ManholeDiagramLoading', <ManholeDiagram />);

    getProfileDiagramRPC({
        id: context.data.id as string,
        type: context.form.model,
    })
        .then((response) => {
            context.setCenterContent(
                centerMenuManholeDiagramContentId,
                <ManholeDiagram
                    data={response.data!}
                    data-test-id="manhole-diagram"
                />,
            );
        })
        .catch((error) => {
            context.setCenterContent('', <></>);
            console.error('Diagram error: ', error);
        });
};

const config: FormConfig = {
    gqlType: 'Manhole',
    model: 'manhole',
    icon: <Icons.Manhole />,
    groups: [
        {
            name: 'objectData',
            fields: [
                createComboField('owner', 'MANHOLE_OWNER'),
                createComboField('renter', 'MANHOLE_OWNER'),
                createComboField('type', 'MANHOLE_TYPE'),
                'identification',
                'installYear',
                createComboField('usageState', 'MANHOLE_STATE'),
                {
                    name: 'diagramConfirm',
                    component: 'radio',
                    filter: {
                        groupname: { eq: 'MANHDIAG_CONFIRMED' },
                    },
                },
                {
                    name: 'remarks',
                    component: 'textarea',
                },
            ],
        },
        addressGroup,
        telecomAreaGroup,
        planGroup,
        {
            name: 'common',
            fields: [
                ...commonGroup.fields,
                createComboField('safetyDistance', 'SAFETY_DISTANCE'),
            ],
        },
    ],
    actions: [
        ...defaultTopActions,
        {
            icon: <Icons.Diagram />,
            translationKey: 'manholeDiagram',
            onClick: openDiagram,
        },
        ...defaultMiddleActions,
        DIVIDER,
        'connectionsManagement',
        {
            translationKey: 'physical',
            onClick: () => console.log('Not implemented'),
            shouldBeEnabled: () => false,
        },
        {
            translationKey: 'logical',
            onClick: () => console.log('Not implemented'),
            shouldBeEnabled: () => false,
        },
        {
            translationKey: 'ductConnections',
            onClick: () => console.log('Not implemented'),
            shouldBeEnabled: () => false,
        },
        DIVIDER,
        'export',
        {
            icon: <Icons.ExcelFile />,
            translationKey: 'billOfMaterials',
            onClick: () => console.log('Not implemented'),
            shouldBeEnabled: () => false,
        },
        {
            icon: <Icons.ExcelFile />,
            translationKey: 'ductConnectionReport',
            onClick: () => console.log('Not implemented'),
            shouldBeEnabled: () => false,
        },
        {
            icon: <Icons.AdvancedFile />,
            translationKey: 'advancedExport',
            onClick: () => console.log('Not implemented'),
            shouldBeEnabled: () => false,
        },
        ...defaultBottomActions,
    ],
    excludeFromSearch: [
        'address',
        'addressBind',
        'telecomArea',
        'telecomAreaBind',
        'plan',
        'planIncludeCompleted',
        'historyDate',
    ],
    functions: {
        get: getManholes,
    },
    queryCollection: 'manholes',
};

export default config;
