import { useEffect, useState } from 'react';
import { Button, Icons, Input, styled } from '@keypro/2nd-xp';
import { useSearchStore } from '@stores';
import { t } from 'i18next';

const ResultsFilter = styled.div`
    padding: 10px 9px 10px 9px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    gap: 5px;
`;

/**
 * Search Input and Pick from map button for FullSearch component
 */
export const FullSearchResultsFilter = (): JSX.Element => {
    const { setSearchFilterTerm } = useSearchStore();
    const [isSearchOpen, setIsSearchOpen] = useState<boolean>(false);

    useEffect(() => {
        if (!isSearchOpen) {
            setSearchFilterTerm('');
        }
    }, [isSearchOpen, setSearchFilterTerm]);

    return (
        <ResultsFilter>
            {isSearchOpen ? (
                <>
                    <Input
                        data-testid="FullSearch-SearchInput"
                        inputProps={{
                            type: 'search',
                            onChange: (
                                e: React.ChangeEvent<HTMLInputElement>,
                            ) => setSearchFilterTerm(e.target.value),
                        }}
                    />
                    <Button
                        kind="ghost"
                        data-testid="FullSearch-CloseSearch"
                        onClick={() => {
                            setIsSearchOpen(false);
                        }}
                    >
                        <Icons.Cross />
                    </Button>
                </>
            ) : (
                <>
                    <Button
                        kind="outline"
                        disabled={true}
                        iconPosition="left"
                        label={t('pickFromMap')}
                        data-testid="FullSearch-PickFromMap"
                    >
                        <Icons.PolygonSelection />
                    </Button>
                    <Button
                        kind="outline"
                        data-testid="FullSearch-OpenSearch"
                        onClick={() => {
                            setIsSearchOpen(true);
                        }}
                    >
                        <Icons.Search />
                    </Button>
                </>
            )}
        </ResultsFilter>
    );
};
