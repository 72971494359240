import { DragContext } from '@components/MoveableContainer';
import { useContext } from 'react';

// Custom hook to use draggableRef inside child components
export const useDraggableHandle = () => {
    const ref = useContext(DragContext);
    if (!ref)
        throw new Error(
            'useDraggableHandle must be used within a MoveableContainer',
        );
    return ref;
};
