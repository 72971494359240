import { FilterObject } from '@components';
import { SearchResult } from '@generated';
import { create } from 'zustand';
import { Coordinate } from 'ol/coordinate';
import { ReactNode } from 'react';

export interface SearchState {
    // ------- COMMON STATES --------
    /** The searching term */
    searchTerm: string;
    /** The search results */
    searchResults: SearchResult[];
    /** Function to set the search term */
    setSearchTerm: (term: string) => void;
    /** Additional term to filter search results */
    searchFilterTerm: string;
    /** Function to set the result filter term */
    setSearchFilterTerm: (term: string) => void;
    // ----- SEARCHFIELD STATES -----
    highlightPosition: number[];
    /** Is the search input field active */
    searchFieldActive: boolean;
    setHighlightPosition: (position: number[]) => void;
    /** Function to set the search input field active state */
    setSearchFieldActive: (active: boolean) => void;
    // ----- FULLSEARCH STATES ------
    /** The selected object type e.g. manhole or plan */
    selectedObjectType: string;
    /** Is the advanced search tool open */
    isAdvancedSearchOpen: boolean;
    /** Is using the advanced search tool */
    isUsingAdvancedSearch: boolean;
    /** The selected advanced filters */
    selectedAdvancedFilters: FilterObject;
    dataAdvancedSearch: SearchResult[];
    isHighlighted: boolean;
    highlightPositions: { id: string; position: number[] }[];
    /** Is in fullsearch mode */
    isFullsearch: boolean;
    modelAdvancedSearch: string;
    setSearchResults: (results: SearchResult[]) => void;
    setSelectedObjectType: (type: string) => void;
    setIsAdvancedSearchOpen: (isOpen: boolean) => void;
    /** Function to set the advanced search state */
    setIsUsingAdvancedSearch: (isUsing: boolean) => void;
    /** Function to set the selected filters */
    setSelectedAdvancedFilters: (filters: FilterObject) => void;
    /** Function to set the advanced search data */
    setDataAdvancedSearch: (data: SearchResult[]) => void;
    setIsHighlighted: (highlighted: boolean) => void;
    setHighlightPositions: (
        positions: { id: string; position: number[] }[],
    ) => void;
    /** Function to set the isFullsearch active state */
    setIsFullsearch: (isOpen: boolean) => void;
    setModelAdvancedSearch: (type: string) => void;
    // ---- ADVANCEDFIELD STATES ----
    selectedGroups: string[];
    locationFilter: {
        coordinate: Coordinate[];
        clearIfLayerExist: () => void;
        textDescription?: string;
    } | null;
    quickFilterComponent: ReactNode;
    setSelectedGroups: (selectedGroups: string[]) => void;
    setLocationFilter: (
        locationFilter: {
            coordinate: Coordinate[];
            clearIfLayerExist: () => void;
            textDescription?: string;
        } | null,
    ) => void;
    setQuickFilterComponent: (quickFilterComponent: ReactNode) => void;
}

export const useSearchStore = create<SearchState>((set) => ({
    // ------- COMMON STATES --------
    searchTerm: '',
    searchResults: [],
    searchFilterTerm: '',
    setSearchTerm: (term) => set({ searchTerm: term }),
    setSearchFilterTerm: (term) => set({ searchFilterTerm: term }),
    // ----- SEARCHFIELD STATES ------
    highlightPosition: [0, 0],
    searchFieldActive: false,
    setHighlightPosition: (position) => set({ highlightPosition: position }),
    setSearchFieldActive: (active) => set({ searchFieldActive: active }),
    // ----- FULLSEARCH STATES ------
    selectedObjectType: '',
    isAdvancedSearchOpen: false,
    isUsingAdvancedSearch: false,
    selectedAdvancedFilters: {},
    dataAdvancedSearch: [],
    isHighlighted: false,
    highlightPositions: [],
    isFullsearch: false,
    modelAdvancedSearch: '',
    setSearchResults: (results) => set({ searchResults: results }),
    setSelectedObjectType: (type) => set({ selectedObjectType: type }),
    setIsAdvancedSearchOpen: (isOpen) => set({ isAdvancedSearchOpen: isOpen }),
    setIsUsingAdvancedSearch: (isUsing) =>
        set({ isUsingAdvancedSearch: isUsing }),
    setSelectedAdvancedFilters: (filters) =>
        set({
            selectedAdvancedFilters: Object.fromEntries(
                Object.entries(filters).filter(([key]) => key !== 'id'),
            ),
        }),
    setDataAdvancedSearch: (data) => set({ dataAdvancedSearch: data }),
    setIsHighlighted: (highlighted) => set({ isHighlighted: highlighted }),
    setHighlightPositions: (positions) =>
        set({ highlightPositions: positions }),
    setIsFullsearch: (isOpen) => set({ isFullsearch: isOpen }),
    setModelAdvancedSearch: (modelAdvancedSearch: string) =>
        set({ modelAdvancedSearch }),
    // ---- ADVANCEDFIELD STATES ----
    selectedGroups: [],
    locationFilter: null,
    quickFilterComponent: null,
    setSelectedGroups: (selectedGroups) =>
        set({ selectedGroups: selectedGroups }),
    setLocationFilter: (locationFilter) => set({ locationFilter }),
    setQuickFilterComponent: (quickFilterComponent: ReactNode) =>
        set({ quickFilterComponent }),
}));
