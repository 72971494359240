query getSewerManholes($filter: SewerNodeFilter) {
    getSewerManholes(filter: $filter) {
        sewerManholes {
            id
            dateAccuracy {
                groupname
                txt
            }
            establishMethod {
                groupname
                txt
            }
            manholeDesign {
                groupname
                txt
            }
            createdBy
            createdTs
            updatedBy
            updatedTs
            nodeType
            feature
            locationAccuracy {
                txt
            }
            heightAccuracy {
                groupname
                txt
            }
            mappingMethod {
                groupname
                txt
            }
            planNumber
            planDate
            surveyNumber
            mapper
            historyDate
            buildYear
            removalYear
            owner {
                groupname
                txt
            }
            position
            zCoord1
            zCoord2
            terrainHeight
            zCoord1Estimated
            zCoord2Estimated
            valveType {
                id
            }
            pumpType {
                id
            }
            branchType {
                id
            }
            manholeType {
                id
            }
            lidType {
                id
            }
            objectType {
                id
            }
            measuringpointType {
                id
            }
            manhole {
                id
            }
            faultCode {
                id
                groupname
            }
            location
            note
            internalNodeId
            userId
            plan {
                id
            }
            address {
                id
            }
            status {
                groupname
                txt
            }
            terrainHeight
            angle
            scale
            showOnMap
            pressure
            network
            lblAngle
            lblAngleExp
            lblColor
            lblColorExp
            lblSize
            lblSizeExp
            symAngle
            symAngleExp
            symCodeGt
            symColor
            symColorExp
            symName
            symNameDwg
            symNameExp
            symSize
            symSizeExp
            symText
            symTextExp
            symWidth
            symWidthExp
        }
    }
}
