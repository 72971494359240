import { ColorPicker, styled } from '@keypro/2nd-xp';
import { useState } from 'react';

/**
 * Props for FormColorPicker.
 */
export interface FormColorPickerProps {
    /** Initial color to show */
    initialValue?: string;
    /** List of colors to show */
    colors: string[];
    /** Callback to call when the color is changed */
    onChange?: (color: string | undefined) => void;
}

/**
 * A variant of ColorPicker component that can be used in a form.
 * @returns The JSX element for the component.
 */
const FormColorPicker = ({
    initialValue,
    colors,
    onChange,
    ...rest
}: FormColorPickerProps): JSX.Element => {
    const [value, setValue] = useState<string | undefined>(initialValue);
    return (
        <StyledFormColorPicker $selectedColor={value}>
            <ColorPicker
                {...rest}
                data-testid="form-color-picker"
                color={value}
                colors={colors}
                onColorChange={(color) => {
                    setValue(color);
                    onChange?.(color);
                }}
            />
        </StyledFormColorPicker>
    );
};

const StyledFormColorPicker = styled.div<{ $selectedColor?: string }>`
    margin-left: 3px;
    & > div:first-child {
        width: 100% !important;
        // make sure the input field takes all styles
        ${(props) => props.theme.fonts['14px Regular']}
        color: ${(props) => props.theme.colors.neutral['100']};
        --selected-color: ${(props) => props.$selectedColor};
        width: 100%;
        height: 34px;
        border-radius: 8px;
        border: 1px solid ${(props) => props.theme.colors.neutral['60']};
        background-color: ${(props) => props.theme.colors.neutral['20']};
        display: flex;
        align-items: center;
        padding: 4px;
        gap: 8px;
        box-sizing: border-box;
        position: relative;
        cursor: pointer;

        &:hover {
            border-color: ${(props) => props.theme.colors.neutral['70']};
        }

        &:focus,
        &:focus-within,
        &:hover:focus,
        &:hover:focus-within {
            border-color: ${(props) => props.theme.colors.accents.blue['10']};
            box-shadow: 0px 0px 0px 3px
                ${(props) =>
                    props.theme.colors.accents.blue['10-transparent-30']};
        }
    }
`;

export default FormColorPicker;
