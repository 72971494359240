// Libraries
import { useContext, useEffect, useState } from 'react';

// Component library
import {
    styled,
    LocateMeButton,
    ZoomInOutButton,
    MapContext,
} from '@keypro/2nd-xp';

// Consts
import { orientationToolsTestIds } from './OrientationTools.consts';

export const ZoomInOut = () => {
    const controller = useContext(MapContext)!;

    const [isZoomInDisabled, setIsZoomInDisabled] = useState(false);
    const [isZoomOutDisabled, setIsZoomOutDisabled] = useState(false);

    useEffect(() => {
        const onZoom = () => {
            setIsZoomOutDisabled(!controller.canZoomIn);
            setIsZoomInDisabled(!controller.canZoomOut);
        };
        controller.on('zoom', onZoom);
        return () => {
            controller.off('zoom', onZoom);
        };
    }, [controller]);

    const handleOnZoomInClick = () => {
        controller?.skipTransition();
        controller?.zoomOut();
    };

    const handleOnZoomOutClick = () => {
        controller?.skipTransition();
        controller?.zoomIn();
    };

    return (
        <ZoomInOutButtonWrapper>
            <ZoomInOutButton
                testIds={{
                    zoomInButton: orientationToolsTestIds.zoomInButton,
                    zoomOutButton: orientationToolsTestIds.zoomOutButton,
                }}
                onZoomInClick={handleOnZoomInClick}
                onZoomOutClick={handleOnZoomOutClick}
                isZoomInDisabled={isZoomInDisabled}
                isZoomOutDisabled={isZoomOutDisabled}
            />
        </ZoomInOutButtonWrapper>
    );
};

export const OrientationTools = () => {
    return (
        <>
            <ZoomInOut />
            <LocateMeButton key="toolbar-locateMe" label={''} />
        </>
    );
};

const ZoomInOutButtonWrapper = styled.div`
    margin-bottom: 8px;
`;
