import { GetSewerPumpingStationsQuery, SewerPumpingStation } from '@generated';
import GetSewerPumpingStations from './queries/GetSewerPumpingStations.graphql';
import { sendGraphQLRequest } from '@utils';
import { GraphQLFilter, isFilterEmpty } from '@apis/utils';

/**
 * Gets the sewer pumping stations.
 * @param filter The GraphQL filter to apply to the query.
 * @returns The sewer pumping stations.
 * @throws {Error} If the request fails.
 */
export const getSewerPumpingStations = async (filter: GraphQLFilter) => {
    try {
        const payload = !isFilterEmpty(filter)
            ? { filter: filter }
            : { filter: null, limit: 1 };
        const response = await sendGraphQLRequest<GetSewerPumpingStationsQuery>(
            GetSewerPumpingStations,
            payload,
        );

        if (!response.data.getSewerPumpingStations) {
            throw Error('No data returned');
        }

        return response.data.getSewerPumpingStations.sewerPumpingStations?.filter(
            Boolean,
        ) as SewerPumpingStation[];
    } catch (error) {
        throw Error('Failed to get sewer pumping stations: ' + error);
    }
};
