query GetInfoToolResults($filter: InfoToolFilter!) {
    getInfoToolResults(filter: $filter) {
        total,
        results {
            pk
            identification
            model
            modelIdentity
            location
            angle
        }
    }
}
