// Libraries
import { useEffect, useRef, useState, useCallback, useMemo } from 'react';
import Raphael, { RaphaelPaper } from 'raphael';
import { t } from 'i18next';

// Component library
import { Button, Icons, LoadingMask, styled } from '@keypro/2nd-xp';

// Generated
import { DuctType, ProfileDiagramRpcData, TxtConstant } from '@generated';

// Stores
import { useCenterMenu, useTxtConstants } from '@stores';

// Components
import { reduceById } from '@components';

// Hooks
import { useGetDuctTypes } from '@hooks/keycom/ductType';

// Legacy
import { ProfileDiagram } from './legacy/ProfileDiagram';
import { ColorService } from './legacy/ColorService';

// Constants
import { centerMenuManholeDiagramContentId } from 'src/form-configs/keycom/Manhole.consts';

/**
 * Step size for zooming.
 */
const ZOOM_STEP = 0.1;

/**
 * Props for the manhole diagram component.
 */
export interface ManholeDiagramProps {
    /** Diagram data */
    data?: ProfileDiagramRpcData;
}

/**
 * Component for displaying a manhole diagram.
 * @param data Diagram data
 */
export const ManholeDiagram = ({ data }: ManholeDiagramProps) => {
    const { setMenuContent: setCenterContent } = useCenterMenu();
    const ref = useRef<HTMLDivElement>(null);
    const [paper, setPaper] = useState<RaphaelPaper | undefined>();

    const [zoom, setZoom] = useState(1);
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const [offsetX, setOffsetX] = useState(0);
    const [offsetY, setOffsetY] = useState(0);

    const { data: ductTypes, isFetching: isLoadingDuctTypes } =
        useGetDuctTypes();

    const { getTxtConstantsBy } = useTxtConstants();

    const ductStates = useMemo(
        () =>
            getTxtConstantsBy({
                groupname: 'DUCT_STATE',
            }),
        [getTxtConstantsBy],
    );

    const ductColors = useMemo(
        () =>
            getTxtConstantsBy({
                groupname: 'KEYCOM_DUCT_COLOR',
            }),
        [getTxtConstantsBy],
    );

    const onDrag = useCallback(
        (e: MouseEvent) => {
            e.preventDefault();

            const multiplier = width / ref.current!.clientWidth;

            setOffsetX((prevOffsetX) => prevOffsetX - e.movementX * multiplier);
            setOffsetY((prevOffsetY) => prevOffsetY - e.movementY * multiplier);
        },
        [width],
    );

    useEffect(() => {
        const element = ref.current!;

        const onWheel = (e: WheelEvent) => {
            e.preventDefault();

            const dir = e.deltaY < 0 ? 1 : -1;
            setZoom((prevZoom) => prevZoom - dir * ZOOM_STEP);
        };

        const onMouseDown = (e: MouseEvent) => {
            e.preventDefault();
            element.addEventListener('mousemove', onDrag);
            element.addEventListener('mouseup', onMouseUp);
        };

        const onMouseUp = (e: MouseEvent) => {
            e.preventDefault();
            element.removeEventListener('mousemove', onDrag);
        };

        element.addEventListener('wheel', onWheel);
        element.addEventListener('mousedown', onMouseDown);

        return () => {
            element.removeEventListener('wheel', onWheel);
            element.removeEventListener('mousedown', onMouseDown);
            element.removeEventListener('mousemove', onDrag);
            element.removeEventListener('mouseup', onMouseUp);
        };
    }, [onDrag]);

    useEffect(() => {
        let p: RaphaelPaper | undefined;

        if (data && ductTypes && ductStates && ductColors && ref.current) {
            const width = ref.current.clientWidth;
            const height = ref.current.clientHeight;

            p = Raphael(ref.current, width, height);

            const diagram = new ProfileDiagram({
                colorService: new ColorService(ductStates),
                ductTypes: reduceById<DuctType>(ductTypes),
                ductColors: reduceById<TxtConstant>(ductColors),
                paper: p,
                data: data,
            });
            diagram.initialize();

            const bbox = diagram.elemSet.getBBox();

            setOffsetX(bbox.x - bbox.width / 4);
            setOffsetY(bbox.y - bbox.height / 4);
            setWidth(bbox.width * 1.5);
            setHeight(bbox.height * 1.5);
            setPaper(p);
        }

        return () => {
            p?.remove();
        };
    }, [data, ductStates, ductTypes, ductColors, ref]);

    useEffect(() => {
        if (!paper || !ref.current) return;

        paper.setViewBox(
            offsetX * zoom,
            offsetY * zoom,
            width * zoom,
            height * zoom,
            true,
        );
    }, [paper, offsetX, offsetY, zoom, width, height]);

    return (
        <OuterContainer>
            <InnerContainer>
                {(!data || isLoadingDuctTypes) && <LoadingMask iconSize={48} />}
                <DiagramContent ref={ref} data-testid="manhole-diagram" />
                <BackButton
                    kind="secondary"
                    label={t('back')}
                    iconPosition="left"
                    onClick={() => {
                        setCenterContent(
                            centerMenuManholeDiagramContentId,
                            <></>,
                        );
                    }}
                >
                    <Icons.ArrowLeft />
                </BackButton>
            </InnerContainer>
        </OuterContainer>
    );
};

const BackButton = styled(Button)`
    position: absolute;
    color: inherit;
    top: 5px;
    left: 5px;
    gap: 2px;
`;

const OuterContainer = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    background-color: ${(props) => props.theme.colors.neutral['10']};
`;

const InnerContainer = styled.div`
    position: relative;
    margin-left: 2px;
    width: calc(100% - 4px);
    height: 100%;
    display: flex;
    border-radius: 8px;
    background-color: ${(props) => props.theme.colors.neutral['20']};
`;

const DiagramContent = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        width: auto;
        height: auto;
        background-color: white;
    }
`;
