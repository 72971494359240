// Types
import { FormConfig } from '../types';

// Stores
import { useTxtConstants } from '@stores';

// Generated
import { TxtConstant } from '@generated';
const config: FormConfig = {
    gqlType: 'TxtConstant',
    model: 'txtconstant',
    groups: [],
    functions: {
        // The actual get function expects a promise but the actual
        // implementation is synchronous - no actual data fetching
        get: (filter) => {
            return new Promise((resolve) => {
                const { getTxtConstantsBy } = useTxtConstants.getState();
                const groupname = filter?.groupname.eq;
                if (groupname) {
                    const result: TxtConstant[] | undefined = getTxtConstantsBy(
                        {
                            groupname,
                        },
                    );
                    resolve(result);
                }
            });
        },
    },
    queryCollection: 'txtConstants',
};

export default config;
