import { CustomComponentProps } from '@form-configs';
import { t } from 'i18next';
import { ConsumerPointCustomer } from '@generated';
import { createTable } from './ConsumerPointComponents';

export const consumerPointTable = (
    props: CustomComponentProps,
): JSX.Element => {
    const value = props.value as ConsumerPointCustomer[];

    const headers = [
        t('consumerPoint.number'),
        t('consumerPoint.address'),
        t('consumerPoint.type'),
        t('consumerPoint.role'),
    ];

    const getRowValues = (customer: ConsumerPointCustomer) => {
        const address = customer.consumerPoint?.address?.address;
        return [
            `${customer.consumerPoint?.consumerNumber}`,
            `${address?.streetname ?? ''} ${address?.streetno ?? ''} ${address?.zipcode ?? ''} ${address?.city ?? ''}`,
            `${customer.consumerPoint?.cpType?.txt}`,
            `${customer.customerRole?.txt}`,
        ];
    };
    return createTable({ headers, data: value, getRowValues });
};
