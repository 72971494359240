import { useCallback, useState } from 'react';

/**
 * Custom hook for throwing errors in async functions to error boundary.
 * @returns Function that throws the given error.
 */
export const useAsyncError = () => {
    const [, setError] = useState(); // NOSONAR
    return useCallback(
        (e: Error | string) => {
            setError(() => {
                throw e instanceof Error ? e : new Error(e);
            });
        },
        [setError],
    );
};
