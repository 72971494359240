// Libraries
import { t } from 'i18next';
import { useState, useMemo } from 'react';

// Component Library
import { DisplayScaleText, Icons, useIsMobile } from '@keypro/2nd-xp';

// Components
import { CoordinatesTool } from './CoordinatesTool';

// Styles
import {
    CoordinateBtn,
    CoordinateItem,
    Coordinates,
    MainContainer,
} from './AppFooter.styles';

// Constants
import { DEFAULT_COORDINATES_SYSTEM_TXT_VALUE } from './CoordinatesTool/CoordinatesTool.consts';

// Hooks
import { useTxtConstants } from '@stores';

const AppFooter = (): JSX.Element => {
    const [isCoordinatesToolOpen, setIsCoordinatesToolOpen] = useState(false);
    const isTablet = useIsMobile({ isTablet: true });

    const { getTxtConstantsBy } = useTxtConstants();

    const coordinatesSystemList = useMemo(
        () =>
            getTxtConstantsBy({
                groupname: 'KEYCORE_IMPORT_SRS',
                onlyWithAdditionalData: true,
            }),
        [getTxtConstantsBy],
    );

    const defaultCoordinatesSystem = coordinatesSystemList?.find(
        (item) =>
            item?.txtValue?.toString() === DEFAULT_COORDINATES_SYSTEM_TXT_VALUE,
    );

    return (
        <MainContainer>
            <DisplayScaleText />
            <Coordinates>
                {!isTablet && (
                    <CoordinateItem
                        data-tooltip-top={t('coordinateTooltip')}
                        id="coordinates"
                        data-testid="coordinates"
                    >
                        N: 557506.55, E: 3765366.80
                    </CoordinateItem>
                )}
                <CoordinateBtn
                    size="small"
                    kind={isCoordinatesToolOpen ? 'primary' : 'ghost'}
                    id="coordinate-button"
                    data-tooltip-left={t('srsTooltip')}
                    onClick={() => {
                        setIsCoordinatesToolOpen(!isCoordinatesToolOpen);
                    }}
                >
                    <Icons.PinWithBase />
                </CoordinateBtn>
                {coordinatesSystemList && coordinatesSystemList.length > 0 && (
                    <CoordinatesTool
                        defaultCoordinatesSystem={defaultCoordinatesSystem}
                        coordinatesSystemList={coordinatesSystemList}
                        isOpenTool={isCoordinatesToolOpen}
                        onActionClickTool={setIsCoordinatesToolOpen}
                    />
                )}
            </Coordinates>
        </MainContainer>
    );
};

export default AppFooter;
