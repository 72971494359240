// Libraries
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { MapBrowserEvent } from 'ol';
import { t } from 'i18next';
import proj4 from 'proj4';

// Component Library
import {
    Button,
    Coordinates,
    Icons,
    MapContext,
    PositionOverlay,
    useToast,
} from '@keypro/2nd-xp';

// Constants
import { coordinatesToolTestIds } from './CoordinatesTool.consts';

// Stores
import { useCoordinatesSystem } from '@stores';

import { getCoordinatesSystemPrefixes, throttle } from '@utils';

// Types
import { TxtConstant } from '@generated';

// Styles
import {
    StyledCoordinatesTool,
    StyledHeader,
    StyledTitle,
    StyledCloseBtn,
    StyledBody,
    StyledDivider,
    StyledSystem,
    StyledSystemLabel,
    StyledSystemSelect,
    StyledLocation,
    StyledSystemLabelBtn,
    StyledCommonTitle,
    StyledLocationBtn,
    StyledLocationTextField,
    StyledLocationInputLabel,
    StyledLocationInput,
    StyledInfo,
    StyledCoordinatesInfo,
    StyledCoordinates,
    StyledCoordinatesCaption,
    StyledCoordinatesValue,
    StyledShowCursor,
    StyledShowCursorCheckBox,
    StyledCheckBox,
    StyledCheckBoxTitle,
    StyledShowCursorDesc,
    StyledFooter,
    StyledFooterButton,
} from './CoordinatesTool.styles';

// Components
import { getCoordinateSystemDecimalPrecision } from '@components';

interface CoordinatesToolProps {
    coordinatesSystemList: TxtConstant[];
    isOpenTool?: boolean;
    onActionClickTool?: (isOpen: boolean) => void;
    defaultCoordinatesSystem?: TxtConstant;
}

/**
 * The CoordinatesTool component displays the coordinates tool.
 * @param isOpenTool - The state of the tool
 * @param onActionClickTool - The function to handle the tool action
 * @returns JSX.Element
 */
export const CoordinatesTool = ({
    isOpenTool,
    onActionClickTool,
    coordinatesSystemList,
    defaultCoordinatesSystem,
}: CoordinatesToolProps) => {
    const controller = useContext(MapContext)!;

    const [isHighlight, setIsHighlight] = useState(false);
    const [isLoadingUserLocation, setIsLoadingUserLocation] = useState(false);

    const [isShowCursorCoordinates, setIsShowCursorCoordinates] =
        useState(true);

    const [position, setPosition] = useState<Coordinates | undefined>(
        undefined,
    );

    const [coordinatesSystem, setCoordinatesSystem] = useState('4');

    const [positionInputCoordinates, setPositionInputCoordinates] = useState<
        Coordinates | undefined
    >(undefined);

    const [positionSectionFirstCoordinate, setPositionSectionFirstCoordinate] =
        useState('');
    const [
        positionSectionSecondCoordinate,
        setPositionSectionSecondCoordinate,
    ] = useState('');
    const toast = useToast();

    const {
        selectedCoordinatesSystem,
        isPickLocationEnabled,
        setSelectedCoordinatesSystem,
        setIsPickLocationEnabled,
    } = useCoordinatesSystem();

    const [locationSectionPrefixes, setLocationSectionPrefixes] = useState(
        getCoordinatesSystemPrefixes(selectedCoordinatesSystem),
    );

    const coordinatesSystemItems = useMemo(() => {
        return coordinatesSystemList
            .filter((item) => item?.orderno !== null)
            .map((item) => ({
                label: item?.txt ?? '',
                value: item?.orderno?.toString() ?? '',
            }));
    }, [coordinatesSystemList]);

    const getSelectedCoordinateSystem = useCallback(
        (coordinatesSystemValue: string) => {
            return coordinatesSystemList.find(
                (item) => item?.orderno?.toString() === coordinatesSystemValue,
            );
        },
        [coordinatesSystemList],
    );

    const convertCurrentUserFromWSG84 = useCallback(
        (xCoordinate: number, yCoordinate: number) => {
            const temp = controller.convertFromWSG84([
                yCoordinate,
                xCoordinate,
            ]);
            return temp;
        },
        [controller],
    );

    const transformCoordinates = useCallback(
        (coordinates: Coordinates, toCoordinateSystem: string) => {
            if (
                !coordinates ||
                !getSelectedCoordinateSystem(toCoordinateSystem) ||
                !defaultCoordinatesSystem ||
                !getSelectedCoordinateSystem?.(toCoordinateSystem)
                    ?.additionalData ||
                !defaultCoordinatesSystem.additionalData
            ) {
                return;
            }

            const newCoordinates = proj4(
                defaultCoordinatesSystem?.additionalData ?? '',
                getSelectedCoordinateSystem?.(toCoordinateSystem)
                    ?.additionalData ?? '',
                coordinates,
            );

            return newCoordinates;
        },
        [getSelectedCoordinateSystem, defaultCoordinatesSystem],
    );

    useEffect(() => {
        const handleMouseMovePosition = (event: MapBrowserEvent<UIEvent>) => {
            setPosition([event.coordinate[0], event.coordinate[1]]);
        };

        if (isShowCursorCoordinates) {
            controller.on('mouseMove', handleMouseMovePosition);
        } else {
            controller.on('mouseClick', handleMouseMovePosition);
        }
        return () => {
            controller.off('mouseMove', handleMouseMovePosition);
        };
    }, [controller, isShowCursorCoordinates, position]);

    useEffect(() => {
        if (!isPickLocationEnabled) return;

        const handleMapClick = (event: MapBrowserEvent<UIEvent>) => {
            const coordinates = controller.map.getCoordinateFromPixel(
                event.pixel,
            );
            const mousePosition: Coordinates = [coordinates[0], coordinates[1]];
            const coordinateSystemDecimalPrecision =
                getCoordinateSystemDecimalPrecision(selectedCoordinatesSystem);
            setPositionInputCoordinates(mousePosition);
            setPositionSectionFirstCoordinate(
                transformCoordinates?.(
                    mousePosition,
                    coordinatesSystem,
                )?.[0].toFixed(coordinateSystemDecimalPrecision) ?? '',
            );
            setPositionSectionSecondCoordinate(
                transformCoordinates(
                    mousePosition,
                    coordinatesSystem,
                )?.[1].toFixed(coordinateSystemDecimalPrecision) ?? '',
            );
            setIsPickLocationEnabled(false);
        };

        controller.on('mouseClick', handleMapClick);
        return () => {
            controller.off('mouseClick', handleMapClick);
        };
    }, [
        controller,
        coordinatesSystem,
        isPickLocationEnabled,
        selectedCoordinatesSystem,
        getSelectedCoordinateSystem,
        setIsPickLocationEnabled,
        transformCoordinates,
    ]);

    const handleMouseMove = useCallback(
        (event: MapBrowserEvent<UIEvent>) => {
            const mousePosition: Coordinates = [
                event.coordinate[0],
                event.coordinate[1],
            ];
            const coordinates = document.getElementById('coordinates');
            if (mousePosition && coordinates) {
                const { firstPrefix, secondPrefix } =
                    getCoordinatesSystemPrefixes(selectedCoordinatesSystem);
                const coordinateSystemDecimalPrecision =
                    getCoordinateSystemDecimalPrecision(
                        selectedCoordinatesSystem,
                    );
                coordinates.innerHTML =
                    firstPrefix +
                    ' ' +
                    transformCoordinates?.(
                        mousePosition,
                        coordinatesSystem,
                    )?.[0].toFixed(coordinateSystemDecimalPrecision) +
                    ', ' +
                    secondPrefix +
                    ' ' +
                    transformCoordinates(
                        mousePosition,
                        coordinatesSystem,
                    )?.[1].toFixed(coordinateSystemDecimalPrecision);
            }
        },
        [coordinatesSystem, selectedCoordinatesSystem, transformCoordinates],
    );

    useEffect(() => {
        const throttledMouseMove = throttle(handleMouseMove, 200);
        const onMouseMove = (event: MapBrowserEvent<UIEvent>) =>
            throttledMouseMove(event);
        controller.on('mouseMove', onMouseMove);

        return () => {
            controller.off('mouseMove', onMouseMove);
        };
    }, [controller, handleMouseMove]);

    const updateCoordinatesSystemPrefixes = useCallback(
        (changedCoordinatesSystem: string | string[]) => {
            if (!Array.isArray(changedCoordinatesSystem)) {
                setCoordinatesSystem(changedCoordinatesSystem);
                const updatedCoordinatesSystem = getSelectedCoordinateSystem(
                    changedCoordinatesSystem,
                );
                const { firstPrefix, secondPrefix } =
                    getCoordinatesSystemPrefixes(updatedCoordinatesSystem);
                const coordinateSystemDecimalPrecision =
                    getCoordinateSystemDecimalPrecision(
                        updatedCoordinatesSystem,
                    );
                // Updates selected coordinates system
                if (updatedCoordinatesSystem) {
                    setSelectedCoordinatesSystem(updatedCoordinatesSystem);
                    setLocationSectionPrefixes({ firstPrefix, secondPrefix });
                }
                return {
                    firstPrefix,
                    secondPrefix,
                    coordinateSystemDecimalPrecision,
                };
            }
            return {
                firstPrefix: '',
                secondPrefix: '',
                coordinateSystemDecimalPrecision: 2,
            };
        },
        [getSelectedCoordinateSystem, setSelectedCoordinatesSystem],
    );

    const handleOnCoordinatesSystemChange = (
        changedCoordinatesSystem: string | string[],
    ) => {
        if (!Array.isArray(changedCoordinatesSystem)) {
            setIsHighlight(false);
            const {
                firstPrefix,
                secondPrefix,
                coordinateSystemDecimalPrecision,
            } = updateCoordinatesSystemPrefixes(changedCoordinatesSystem);

            if (positionInputCoordinates) {
                const firstTransformedCoordinate = transformCoordinates?.(
                    positionInputCoordinates,
                    changedCoordinatesSystem,
                )?.[0];
                const secondTransformedCoordinate = transformCoordinates?.(
                    positionInputCoordinates,
                    changedCoordinatesSystem,
                )?.[1];
                setPositionSectionFirstCoordinate(
                    firstTransformedCoordinate?.toFixed(
                        coordinateSystemDecimalPrecision,
                    ) ?? '',
                );
                setPositionSectionSecondCoordinate(
                    secondTransformedCoordinate?.toFixed(
                        coordinateSystemDecimalPrecision,
                    ) ?? '',
                );
            }

            // Updates coordinates floating above the map
            const coordinatesFloatingElement =
                document.getElementById('coordinates');
            if (position && coordinatesFloatingElement) {
                coordinatesFloatingElement.innerHTML =
                    firstPrefix +
                    ' ' +
                    transformCoordinates?.(
                        position,
                        changedCoordinatesSystem,
                    )?.[0].toFixed(coordinateSystemDecimalPrecision) +
                    ', ' +
                    secondPrefix +
                    ' ' +
                    transformCoordinates(
                        position,
                        changedCoordinatesSystem,
                    )?.[1].toFixed(coordinateSystemDecimalPrecision);
            }
        }
    };

    const handleOnLocationButtonClick = () => {
        setIsLoadingUserLocation(true);
        // Coordinates come with WSG84 format
        navigator.geolocation.getCurrentPosition((position) => {
            const { latitude, longitude } = position.coords;
            const convertedCoordinates = convertCurrentUserFromWSG84(
                latitude,
                longitude,
            ) as [number, number];

            setPositionInputCoordinates([
                convertedCoordinates[0],
                convertedCoordinates[1],
            ]);

            const transformedFirstCoordinate =
                transformCoordinates?.(
                    convertedCoordinates,
                    coordinatesSystem,
                )?.[0] ?? 0;
            const transformedSecondCoordinate =
                transformCoordinates?.(
                    convertedCoordinates,
                    coordinatesSystem,
                )?.[1] ?? 0;

            const coordinateSystemDecimalPrecision =
                getCoordinateSystemDecimalPrecision(selectedCoordinatesSystem);

            const firstCoordinate = transformedFirstCoordinate.toFixed(
                coordinateSystemDecimalPrecision,
            );
            const secondCoordinate = transformedSecondCoordinate.toFixed(
                coordinateSystemDecimalPrecision,
            );

            setPositionSectionFirstCoordinate(firstCoordinate.toString());
            setPositionSectionSecondCoordinate(secondCoordinate.toString());

            setIsLoadingUserLocation(false);
        });
    };

    const handleOnPickLocationClick = () => {
        const updatedIsPickingLocation = !isPickLocationEnabled;
        setIsPickLocationEnabled(updatedIsPickingLocation);

        if (updatedIsPickingLocation) {
            controller.map.getTargetElement().style.cursor = 'crosshair';
        } else {
            controller.map.getTargetElement().style.cursor = '';
        }
    };

    return (
        <>
            <StyledCoordinatesTool $isOpenTool={isOpenTool}>
                <StyledHeader>
                    <StyledTitle>{t('coordinateTooltip')}</StyledTitle>
                    <StyledCloseBtn
                        kind="ghost"
                        data-testid="close-coordinates-tool"
                        onClick={() => {
                            onActionClickTool?.(!isOpenTool);
                            setIsHighlight(false);
                        }}
                    >
                        <Icons.Cross2 />
                    </StyledCloseBtn>
                </StyledHeader>
                <StyledBody>
                    <StyledDivider />
                    <StyledSystem>
                        <StyledSystemLabel>
                            {t('displayCoordinateSystem')}
                        </StyledSystemLabel>
                        <div data-testid="coordinates-select">
                            <StyledSystemSelect
                                options={coordinatesSystemItems}
                                onChangeValue={handleOnCoordinatesSystemChange}
                                defaultValue={coordinatesSystem}
                            />
                        </div>
                    </StyledSystem>
                    <StyledDivider />
                    <StyledLocation>
                        <StyledSystemLabelBtn>
                            <StyledCommonTitle>
                                {t('location')}
                            </StyledCommonTitle>
                            <div style={{ gap: 8, display: 'flex' }}>
                                <Button
                                    kind={
                                        isPickLocationEnabled
                                            ? 'primary'
                                            : 'secondary'
                                    }
                                    size="small"
                                    data-testid={
                                        coordinatesToolTestIds.pickLocationButton
                                    }
                                    onClick={handleOnPickLocationClick}
                                >
                                    {t('coordinatesTool.pickLocationButton')}
                                </Button>
                                <StyledLocationBtn
                                    data-testid={
                                        coordinatesToolTestIds.locationButton
                                    }
                                    disabled={isLoadingUserLocation}
                                    size="small"
                                    kind="secondary"
                                    label=""
                                    onClick={handleOnLocationButtonClick}
                                >
                                    {isLoadingUserLocation ? (
                                        <Icons.Spinner />
                                    ) : (
                                        <Icons.Locate />
                                    )}
                                </StyledLocationBtn>
                            </div>
                        </StyledSystemLabelBtn>
                        <StyledLocationTextField>
                            <StyledLocationInputLabel>
                                {locationSectionPrefixes.firstPrefix}
                            </StyledLocationInputLabel>
                            <StyledLocationInput
                                rightIcon={
                                    <div
                                        data-tooltip-top={t(
                                            'copyToClipboardTooltip',
                                        )}
                                        data-testid="copy-coordinate-x"
                                    >
                                        <Icons.Copy />
                                    </div>
                                }
                                onRightIconClick={() => {
                                    if (
                                        positionSectionFirstCoordinate &&
                                        positionSectionSecondCoordinate
                                    ) {
                                        navigator.clipboard
                                            .writeText(
                                                `${positionSectionFirstCoordinate}, ${positionSectionSecondCoordinate}`,
                                            )
                                            .then(() => {
                                                toast.info(
                                                    t('coordinatesCopied'),
                                                );
                                            })
                                            .catch((err) => {
                                                console.error(err);
                                                toast.error(t('copyError'));
                                            });
                                    }
                                }}
                                inputProps={{
                                    value: positionSectionFirstCoordinate,
                                    onChange: (
                                        e: React.ChangeEvent<HTMLInputElement>,
                                    ) => {
                                        const newValue =
                                            e.target.value.toString();
                                        // Regex for float numbers
                                        if (
                                            /^-?(\d+(\.\d*)?|\.\d+)$/.test(
                                                newValue,
                                            ) ||
                                            newValue === ''
                                        ) {
                                            setPositionSectionFirstCoordinate(
                                                newValue,
                                            );
                                        }
                                    },
                                }}
                            />
                        </StyledLocationTextField>
                        <StyledLocationTextField>
                            <StyledLocationInputLabel>
                                {locationSectionPrefixes.secondPrefix}
                            </StyledLocationInputLabel>
                            <StyledLocationInput
                                rightIcon={
                                    <div
                                        data-tooltip-top={t(
                                            'copyToClipboardTooltip',
                                        )}
                                        data-testid="copy-coordinate-y"
                                    >
                                        <Icons.Copy />
                                    </div>
                                }
                                onRightIconClick={() => {
                                    if (
                                        positionSectionFirstCoordinate &&
                                        positionSectionSecondCoordinate
                                    ) {
                                        navigator.clipboard
                                            .writeText(
                                                `${positionSectionFirstCoordinate}, ${positionSectionSecondCoordinate}`,
                                            )
                                            .then(() => {
                                                toast.info(
                                                    t('coordinatesCopied'),
                                                );
                                            })
                                            .catch((err) => {
                                                console.error(err);
                                                toast.error(t('copyError'));
                                            });
                                    }
                                }}
                                inputProps={{
                                    value: positionSectionSecondCoordinate,
                                    onChange: (
                                        e: React.ChangeEvent<HTMLInputElement>,
                                    ) => {
                                        const newValue =
                                            e.target.value.toString();
                                        // Regex for float numbers
                                        if (
                                            /^-?(\d+(\.\d*)?|\.\d+)$/.test(
                                                newValue,
                                            ) ||
                                            newValue === ''
                                        ) {
                                            setPositionSectionSecondCoordinate(
                                                newValue,
                                            );
                                        }
                                    },
                                }}
                            />
                        </StyledLocationTextField>
                    </StyledLocation>
                    <StyledDivider />
                    <StyledInfo>
                        <StyledCommonTitle>
                            {t('defaultCoordinateSystem')}: ETRS89 /
                            ETRS-TM35FIN (EPSG:3067)
                        </StyledCommonTitle>
                        <StyledCoordinatesInfo>
                            <StyledCoordinates>
                                <StyledCoordinatesCaption>
                                    X
                                </StyledCoordinatesCaption>
                                <StyledCoordinatesValue>
                                    {position?.[0].toFixed(2)}
                                </StyledCoordinatesValue>
                            </StyledCoordinates>
                            <StyledCoordinates>
                                <StyledCoordinatesCaption>
                                    Y
                                </StyledCoordinatesCaption>
                                <StyledCoordinatesValue>
                                    {position?.[1].toFixed(2)}
                                </StyledCoordinatesValue>
                            </StyledCoordinates>
                        </StyledCoordinatesInfo>
                    </StyledInfo>
                    <StyledShowCursor>
                        <StyledShowCursorCheckBox data-testid="show-cursor-coordinates">
                            <StyledCheckBox
                                checked={isShowCursorCoordinates}
                                onChange={() =>
                                    setIsShowCursorCoordinates(
                                        !isShowCursorCoordinates,
                                    )
                                }
                            />
                            <StyledCheckBoxTitle
                                onClick={() =>
                                    setIsShowCursorCoordinates(
                                        !isShowCursorCoordinates,
                                    )
                                }
                            >
                                {t('showCursorCoordinates')}
                            </StyledCheckBoxTitle>
                        </StyledShowCursorCheckBox>
                        <StyledShowCursorDesc>
                            {t('showCursorCoordinatesDesc')}
                        </StyledShowCursorDesc>
                    </StyledShowCursor>
                    <StyledDivider />
                </StyledBody>
                <StyledFooter>
                    <StyledFooterButton
                        kind="secondary"
                        label={t('highlight')}
                        data-testid="highlight-button"
                        onClick={() => {
                            setIsHighlight(true);
                            if (
                                positionSectionFirstCoordinate &&
                                positionSectionSecondCoordinate
                            ) {
                                const temp = proj4(
                                    getSelectedCoordinateSystem(
                                        coordinatesSystem,
                                    )?.additionalData ?? '',
                                    defaultCoordinatesSystem?.additionalData ??
                                        '',
                                    [
                                        parseFloat(
                                            positionSectionFirstCoordinate,
                                        ),
                                        parseFloat(
                                            positionSectionSecondCoordinate,
                                        ),
                                    ],
                                );
                                const mapExtent = controller.getExtent();
                                if (temp && mapExtent) {
                                    if (
                                        temp[0] < mapExtent[0] ||
                                        temp[0] > mapExtent[2] ||
                                        temp[1] < mapExtent[1] ||
                                        temp[1] > mapExtent[3]
                                    ) {
                                        controller.map
                                            .getView()
                                            .setCenter(temp);
                                    }
                                }
                            }
                        }}
                    />
                    <StyledFooterButton
                        kind="primary"
                        label={t('go')}
                        data-testid="go-button"
                        onClick={() => {
                            if (
                                !positionSectionFirstCoordinate &&
                                !positionSectionSecondCoordinate
                            ) {
                                return;
                            }
                            const temp = proj4(
                                getSelectedCoordinateSystem(coordinatesSystem)
                                    ?.additionalData ?? '',
                                defaultCoordinatesSystem?.additionalData ?? '',
                                [
                                    parseFloat(positionSectionFirstCoordinate),
                                    parseFloat(positionSectionSecondCoordinate),
                                ],
                            );
                            controller.map.getView().setCenter(temp);
                        }}
                    />
                </StyledFooter>
            </StyledCoordinatesTool>
            <PositionOverlay
                data-testid="highlight-overlay-coordinates-tool"
                position={
                    isHighlight &&
                    positionSectionFirstCoordinate &&
                    positionSectionSecondCoordinate
                        ? (() => {
                              const coords = proj4(
                                  getSelectedCoordinateSystem(coordinatesSystem)
                                      ?.additionalData ?? '',
                                  defaultCoordinatesSystem?.additionalData ??
                                      '',
                                  [
                                      parseFloat(
                                          positionSectionFirstCoordinate,
                                      ),
                                      parseFloat(
                                          positionSectionSecondCoordinate,
                                      ),
                                  ],
                              );
                              // Check the number of elements
                              if (coords.length === 2) {
                                  return coords as [number, number];
                              }
                              throw new Error(
                                  'Invalid coordinates returned from proj4',
                              );
                          })()
                        : [0, 0]
                }
                positioning="center-center"
            >
                <Icons.LocationMark style={{ width: 40, height: 40 }} />
            </PositionOverlay>
        </>
    );
};
