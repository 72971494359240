import { FormConfig } from '../types';
import { getFreeLines } from '@apis/keycore';
import { planGroup } from '../groups';
import { createComboField } from '../field-utils';
import { AquaFreeLineIcon, FreeLineIcon } from './icons';

const config: FormConfig = {
    gqlType: 'FreeLine',
    model: 'freeline',
    icon: <FreeLineIcon />,
    aquaIcon: <AquaFreeLineIcon />,
    groups: [
        {
            name: 'objectData',
            fields: [
                { name: 'identification', translationKey: 'identification' },
                { name: 'color', component: 'color-picker' },
                'lineWidth',
                createComboField('type', 'FREE_LINE_TYPE'),
                createComboField('state', 'FREELINE_STATE'),
                { name: 'description', component: 'textarea' },
            ],
        },
        planGroup,
    ],
    excludeFromSearch: ['identification'],
    functions: {
        get: getFreeLines,
    },
    queryCollection: 'freeLines',
};

export default config;
