import { DuctType, DuctTypeFilter, GetDuctTypesQuery } from '@generated';
import { sendGraphQLRequest } from '@utils';
import GetDuctTypes from './queries/GetDuctTypes.graphql';

/**
 * Gets the duct types.
 * @returns The duct types.
 * @throws {Error} If the request fails.
 */
export const getDuctTypes = async (
    filter?: DuctTypeFilter,
): Promise<DuctType[]> => {
    try {
        const response = await sendGraphQLRequest<GetDuctTypesQuery>(
            GetDuctTypes,
            filter ? { filter: filter } : {},
        );

        if (!response.data.getDuctTypes) {
            throw Error('No data returned');
        }

        return response.data.getDuctTypes.ductTypes?.filter(
            Boolean,
        ) as DuctType[];
    } catch (error) {
        throw Error('Failed to get duct types: ' + error);
    }
};
