import { SearchArea } from '@generated';
import { useQuery } from '@tanstack/react-query';
import { fetchInfoToolResults } from '@apis/map/infoTool';

export interface UseGetInfoToolResultsOptions {
    layers?: string[];
    searchArea?: SearchArea;
}

export interface UseGetInfoToolResultsConfig {
    enabled?: boolean;
}

/**
 * Info tool hook to use react-query.
 */
export function useGetInfoToolResults(
    options: UseGetInfoToolResultsOptions,
    { enabled = true }: UseGetInfoToolResultsConfig = {},
) {
    const { layers, searchArea } = options;
    return useQuery({
        queryKey: ['map', 'infoToolResults', { layers, searchArea }],
        queryFn: async () => {
            return fetchInfoToolResults({
                layers: layers!,
                searchArea: searchArea!,
            });
        },
        enabled: !!layers && !!searchArea && enabled,
    });
}
