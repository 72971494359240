/**
 * Build tenant specific URL for requests to gateway
 * @param path full path to append to the base URL (e.g. /graphql)
 * @returns URL string with tenant identifier if available (e.g. `http://localhost/tenant/graphql`)
 */
export const buildTenantURL = (path?: string) => {
    const { protocol, host, pathname } = window.location;
    const tenant = pathname.split('/')[1];
    // Remove leading slashes from path
    path = path ? path.replace(/^\/+/, '') : '';
    if (tenant) {
        if (!path) {
            return `${protocol}//${host}/${tenant}`;
        }
        return `${protocol}//${host}/${tenant}/${path}`;
    } else {
        if (!path) {
            return `${protocol}//${host}`;
        }
        return `${protocol}//${host}/${path}`;
    }
};

/**
 * Get tenant identifier from URL
 * @param addUnderscore add underscore before tenant identifier. Useful for example when saving to local storage.
 * @returns tenant identifier if available, otherwise empty string
 */
export const getTenant = (addUnderscore?: boolean): string => {
    const { pathname } = window.location;
    const tenant = pathname.split('/')[1];
    if (addUnderscore) {
        return tenant ? `_${tenant}` : '';
    }
    return tenant ?? '';
};
