import {
    NavigationBar,
    NavigationButton,
    Icons,
    Button,
    styled,
    useIsMobile,
} from '@keypro/2nd-xp';
import { useLeftMenu, useMobileMenu, useToolsStore } from '@stores';
import { t } from 'i18next';
import { useEffect } from 'react';

import { DummyLeftMenu } from './NavigationBarCommon';
import { LayersMenu } from './Layers/LayersMenu';
import { MapViewMenu } from './MapViews/MapViewMenu';
import { PlansMenu } from './Plans/PlansMenu';
import { ToolsMenu } from './Tools/ToolsMenu';
import { ToastErrorBoundary } from '@components/ErrorBoundary';
import { FullSearch } from '@components/Search';

const DetailedBtn = styled(Button)`
    width: calc(100% - 16px);
    justify-content: flex-start;
    height: 40px;
    border-radius: 6px;
    --active-color: ${(props) => props.theme.colors.accents.blue['50']};

    &.active,
    &:active {
        color: var(--active-color) !important;
        background-color: ${(props) => props.theme.colors.neutral['50']} !important;
        & path[fill], & g[fill] {
            fill: var(--active-color);
        }
        & path[stroke], & circle {
            stroke: var(--active-color);
        }
`;

const DefaultdBtn = styled(NavigationButton)`
    height: 40px;
    width: 40px;
    border-radius: 6px;

    &.active,
    &:active {
        &:before {
            height: 40px;
            margin-top: 0;
            top: 0;
        }
`;

const DetailedNavigationBar = styled(NavigationBar)`
    width: 200px;
`;

const DefaultNavigationBar = styled(NavigationBar)`
    gap: 12px;
    padding-top: 8px;
`;

const StyledToolButton = styled.div`
    width: 24px;
    height: 24px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.neutral[50]};
    & svg {
        width: 16px;
        height: 16px;
    }
`;

const Divider = styled.div`
    width: 20px;
    height: 1px;
    margin-top: 12px;
    margin-bottom: 12px;
    background-color: ${({ theme }) => theme.colors.neutral[50]};
`;

const AppNavigationBar = (): JSX.Element => {
    const isMobile = useIsMobile();
    const menuContext = isMobile ? useMobileMenu : useLeftMenu;
    const {
        setMenuContent,
        toggleMenu,
        menuContentId,
        isMenuOpen,
        detailedMenu,
        setDetailedMenu,
    } = menuContext();
    const { setMenuHeight } = useMobileMenu();

    const {
        pinnedTools,
        isNodeDuctsAndElevationsOpen,
        isHeightProfileDiagramOpen,
        initializePinnedTools,
        closeAllTools,
    } = useToolsStore();

    // Initialize pinned tools from localStorage when component mounts
    useEffect(() => {
        initializePinnedTools();
    }, [initializePinnedTools]);

    useEffect(() => {
        if (isMenuOpen) {
            setDetailedMenu(false);
        }
    }, [menuContentId, isMenuOpen, setDetailedMenu]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                !(
                    document
                        .getElementById('navigation-bar')
                        ?.contains(event.target as Node) ||
                    document
                        .getElementById('app-toolbar')
                        ?.contains(event.target as Node)
                )
            ) {
                setDetailedMenu(false);
            }
        };

        if (detailedMenu) {
            document.addEventListener('click', handleClickOutside);
        }
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [detailedMenu, setDetailedMenu]);

    // just a temporary function to set the active class on the navigation buttons
    // until each button has actually been implemented
    const getClassName = (label: string): string => {
        return menuContentId === label && isMenuOpen ? 'active' : '';
    };

    const buttonConfig = [
        {
            label: t('network'),
            icon: <Icons.Net />,
            contentId: 'FullSearch',
            content: <FullSearch />,
            testId: 'navigationbar-network',
            tooltip: t('network'),
        },
        {
            label: t('layers'),
            icon: <Icons.Layers />,
            contentId: 'Layers',
            content: <LayersMenu />,
            testId: 'navigationbar-layers',
            tooltip: t('layers'),
        },
        {
            label: t('plans'),
            icon: <Icons.Plan />,
            contentId: 'Plans',
            content: <PlansMenu />,
            testId: 'navigationbar-plans',
            tooltip: t('plans'),
        },
        {
            label: t('saved'),
            icon: <Icons.BookmarkSave />,
            contentId: 'Saved',
            content: <DummyLeftMenu />,
            testId: 'navigationbar-saved',
            tooltip: t('saved'),
        },
        {
            label: t('views'),
            icon: <Icons.MapView />,
            contentId: 'views',
            content: <MapViewMenu />,
            testId: 'navigationbar-views',
            tooltip: t('views'),
        },
        {
            label: t('tools'),
            icon: <Icons.NetTools />,
            contentId: 'Tools',
            content: <ToolsMenu />,
            testId: 'navigationbar-tools',
            tooltip: t('tools'),
        },
    ];

    const ButtonComponent = detailedMenu ? DetailedBtn : DefaultdBtn;

    const renderButton = ({
        label,
        icon,
        contentId,
        content,
        testId,
        tooltip,
    }: (typeof buttonConfig)[0]) => {
        return (
            <ButtonComponent
                key={testId}
                data-testid={testId}
                iconPosition={detailedMenu ? 'left' : undefined}
                kind="ghost"
                label={detailedMenu ? label : undefined}
                className={getClassName(contentId)}
                onClick={() => {
                    closeAllTools();
                    setMenuContent(
                        contentId,
                        <ToastErrorBoundary onError={() => toggleMenu()}>
                            {content}
                        </ToastErrorBoundary>,
                    );
                    if (isMobile) {
                        setMenuHeight(50);
                    }
                }}
                data-tooltip-right={tooltip}
            >
                {icon}
            </ButtonComponent>
        );
    };

    const MoreButton = (
        <ButtonComponent
            iconPosition={detailedMenu ? 'left' : undefined}
            kind="ghost"
            data-testid="navigationbar-more"
            label={detailedMenu ? t('more') : undefined}
            data-tooltip-right={t('moreTooltip')}
            onClick={() => toggleMenu()}
        >
            <Icons.MoreHollow />
        </ButtonComponent>
    );

    const renderPinnedTools = () => {
        // Helper function to check if tool is active
        const isToolActive = (toolId: string) => {
            switch (toolId) {
                case 'nodeDuctsAndElevations':
                    return isNodeDuctsAndElevationsOpen;
                case 'heightProfileDiagram':
                    return isHeightProfileDiagramOpen;
                default:
                    return false;
            }
        };

        return (
            <>
                {pinnedTools.map((tool) => (
                    <ButtonComponent
                        key={tool.id}
                        iconPosition={detailedMenu ? 'left' : undefined}
                        kind="ghost"
                        label={detailedMenu ? tool.id : undefined}
                        className={isToolActive(tool.id) ? 'active' : ''}
                        onClick={() => {
                            tool.onClick();
                            if (isMenuOpen && !isToolActive(tool.id)) {
                                toggleMenu();
                            }
                        }}
                        data-tooltip-right={tool.tooltip}
                        data-testid={`pinned-tool-${tool.id}`}
                    >
                        <StyledToolButton>{tool.icon}</StyledToolButton>
                    </ButtonComponent>
                ))}
            </>
        );
    };

    const NavBarContainer = detailedMenu
        ? DetailedNavigationBar
        : DefaultNavigationBar;

    return isMobile && !detailedMenu ? (
        <></>
    ) : (
        <NavBarContainer
            id="navigation-bar"
            data-testid={`navigationbar-${detailedMenu ? 'detailed' : 'default'}`}
        >
            {buttonConfig.map((config) => renderButton(config))}
            {pinnedTools?.length > 0 && (
                <>
                    <Divider />
                    {renderPinnedTools()}
                    <Divider />
                </>
            )}
            {MoreButton}
        </NavBarContainer>
    );
};

export default AppNavigationBar;
