import { createContext } from 'react';
import { AuthMethodHook, AuthMethod } from '@hooks/auth/useAuth';

interface AuthContextType {
    /** user information */
    user: AuthMethodHook['user'];
    /** whether the user is authenticated */
    isAuthenticated: AuthMethodHook['isAuthenticated'];
    /** whether the authentication is in progress */
    isLoading: AuthMethodHook['isLoading'];
    /** the authentication method */
    authMethod: AuthMethod;
    /** login function */
    login: (
        method: AuthMethod,
        termsChecked: boolean,
        username?: string,
        password?: string,
    ) => Promise<void | string>;
    /** logout function */
    logout: AuthMethodHook['logout'];
    isAuth0Available: boolean;
}

/**
 * AuthContext is a context that provides the user's authentication state and user data
 * to the application. It also provides functions to log the user in and out of the application.
 */
export const AuthContext = createContext<AuthContextType>({
    user: null,
    isAuthenticated: false,
    isLoading: false,
    authMethod: AuthMethod.Default,
    login: async () => {},
    logout: async () => {},
    isAuth0Available: false,
});
