import { SewerDuct, GetSewerDuctsQuery } from '@generated';
import GetSewerDucts from './queries/GetSewerDucts.graphql';
import { sendGraphQLRequest } from '@utils';
import { GraphQLFilter, isFilterEmpty } from '@apis/utils';

/**
 * Gets the sewer ducts.
 * @param filter The GraphQL filter to apply to the query.
 * @returns The sewer ducts.
 * @throws {Error} If the request fails.
 */
export const getSewerDucts = async (filter: GraphQLFilter) => {
    try {
        const payload = !isFilterEmpty(filter)
            ? { filter: filter }
            : { filter: null, limit: 1 };
        const response = await sendGraphQLRequest<GetSewerDuctsQuery>(
            GetSewerDucts,
            payload,
        );

        if (!response.data.getSewerDucts) {
            throw Error('No data returned');
        }

        return response.data.getSewerDucts.sewerDucts?.filter(
            Boolean,
        ) as SewerDuct[];
    } catch (error) {
        throw Error('Failed to get sewer ducts: ' + error);
    }
};
