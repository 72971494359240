import { getDhConduits } from '@apis/keyduct';
import { FormConfig } from '../types';
import { Icons } from '@keypro/2nd-xp';

const config: FormConfig = {
    gqlType: 'DhConduit',
    model: 'KeydhConduit',
    modelAliases: ['keydhconduit'],
    icon: <Icons.Cable />,
    groups: [
        {
            name: 'generalInformation',
            fields: ['identification', 'info'],
        },
    ],
    functions: {
        get: getDhConduits,
    },
    queryCollection: 'dhConduits',
};

export default config;
