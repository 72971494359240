// External libraries
import { styled, ToolCard, Icons, useIsMobile } from '@keypro/2nd-xp';
import { t } from 'i18next';
import { useMemo } from 'react';

// Internal modules
import { ResponsiveSideMenu } from '../NavigationBarCommon';
import { ToolType, useLeftMenu, useMobileMenu, useToolsStore } from '@stores';

// Local files
import { BASE_TOOL_CONFIGS } from './ToolsConfigure';

// Styled divider component for visual separation between tools
const Divider = styled.div`
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.neutral[30]};
`;

// Custom hook to manage tool configuration and click handling
const useToolConfig = (type: ToolType) => {
    const { toggleTool } = useToolsStore();

    // Memoize the configuration to prevent unnecessary re-renders
    return useMemo(
        () => ({
            ...BASE_TOOL_CONFIGS[type],
            onClick: () => {
                toggleTool(type);
            },
        }),
        [toggleTool, type],
    );
};

// Props interface for the Tool component
interface ToolCardProps {
    type: ToolType; // Type of tool
    title: string; // Tool title
    description: string; // Tool description
    imageBgColor: string; // Background color for tool icon
}

// Generic Tool component that renders a tool card with pin functionality
const Tool = ({
    type,
    title,
    description,
    imageBgColor,
}: ToolCardProps): JSX.Element => {
    const isMobile = useIsMobile();
    const menuContext = isMobile ? useMobileMenu : useLeftMenu;
    const { toggleMenu } = menuContext();
    const toolConfig = useToolConfig(type);
    const { pinnedTools, setPinnedTools } = useToolsStore();

    // Determine if this tool is currently pinned
    const isPinned = useMemo(
        () => pinnedTools.some((tool) => tool.id === toolConfig.id),
        [pinnedTools, toolConfig.id],
    );

    // Handle pinning/unpinning of tools
    const handlePin = () => {
        const isToolPinned = pinnedTools.some(
            (tool) => tool.id === toolConfig.id,
        );

        if (isToolPinned) {
            // Remove tool if it's already pinned
            setPinnedTools(
                pinnedTools.filter((tool) => tool.id !== toolConfig.id),
            );
        } else if (pinnedTools.length < 3) {
            // Add tool if less than 3 tools are pinned
            setPinnedTools([...pinnedTools, toolConfig]);
        } else {
            // Warn if maximum number of tools are already pinned
            console.warn('Maximum 3 tools can be pinned');
        }
    };

    return (
        <ToolCard
            title={title}
            description={description}
            image={toolConfig.icon}
            imageBgColor={imageBgColor}
            onClick={() => {
                toggleMenu();
                toolConfig.onClick();
            }}
            buttonIcon={<Icons.Pin />}
            buttonTooltip={isPinned ? t('unpinTool') : t('pinTool')}
            onClickButtonAction={handlePin}
            isPinned={isPinned}
        />
    );
};

// Specific implementation of Node Ducts and Elevations tool
const NodeDuctsAndElevationsTool = (): JSX.Element => (
    <Tool
        type="nodeDuctsAndElevations"
        title={t('nodeDuctsAndElevations')}
        description={t('NodeDuctsElevationsDesc')}
        imageBgColor="rgba(12, 128, 205, 1)"
    />
);

// Specific implementation of Height Profile Diagram tool
const HeightProfileDiagramTool = (): JSX.Element => (
    <Tool
        type="heightProfileDiagram"
        title={t('heightProfileDiagram')}
        description={t('heightProfileDiagramDesc')}
        imageBgColor="rgba(146, 38, 209, 1)"
    />
);

/**
 * Main ToolsMenu component that displays available tools
 * Renders a responsive side menu containing all available tools
 * @returns JSX.Element
 */
export const ToolsMenu = (): JSX.Element => {
    return (
        <ResponsiveSideMenu title={t('tools')}>
            <HeightProfileDiagramTool />
            <Divider />
            <NodeDuctsAndElevationsTool />
        </ResponsiveSideMenu>
    );
};
