import { GetWaterValvesQuery, WaterValve } from '@generated';
import GetWaterValves from './queries/GetWaterValves.graphql';
import { sendGraphQLRequest } from '@utils';
import { GraphQLFilter, isFilterEmpty } from '@apis/utils';

/**
 * Gets the water valves.
 * @param filter The GraphQL filter to apply to the query.
 * @returns The water valves.
 * @throws {Error} If the request fails.
 */
export const getWaterValves = async (filter: GraphQLFilter) => {
    try {
        const payload = !isFilterEmpty(filter)
            ? { filter: filter }
            : { filter: null, limit: 1 };
        const response = await sendGraphQLRequest<GetWaterValvesQuery>(
            GetWaterValves,
            payload,
        );

        if (!response.data.getWaterValves) {
            throw Error('No data returned');
        }

        return response.data.getWaterValves.waterValves?.filter(
            Boolean,
        ) as WaterValve[];
    } catch (error) {
        throw Error('Failed to get water valves: ' + error);
    }
};
