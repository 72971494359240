import {
    ConsumerPoint,
    GetConsumerPointsQuery,
    ConsumerPointCustomer,
    ConsumerPointAreaRpc,
} from '@generated';
import GetConsumerPoints from './queries/GetConsumerPoints.graphql';
import { sendGraphQLRequest } from '@utils';
import { GraphQLFilter, isFilterEmpty } from '@apis/utils';
import { t } from 'i18next';

type ConsumerPointFormData = ConsumerPoint & {
    customers: {
        owner: ConsumerPointCustomer | null;
        tenant: ConsumerPointCustomer | null;
    };
    areas: ConsumerPointAreaRpc[];
};

const getRoleMapping = (): { [key: string]: 'owner' | 'tenant' } => ({
    [t('consumerPoint.owner')]: 'owner',
    [t('consumerPoint.tenant')]: 'tenant',
});

// Function to categorize customers
const categorizeCustomers = (customers: ConsumerPointCustomer[]) => {
    const roleMapping = getRoleMapping();
    return customers.reduce(
        (acc, customer) => {
            const role = customer?.customerRole?.txt;
            const category = roleMapping[role as string];
            if (category) {
                acc[category] = customer;
            }
            return acc;
        },
        { owner: null, tenant: null } as {
            owner: ConsumerPointCustomer | null;
            tenant: ConsumerPointCustomer | null;
        },
    );
};

/**
 * Gets ConsumerPoint data for form.
 * @param filter The GraphQL filter to apply to the query.
 * @returns The consumer point with customers and areas.
 * @throws {Error} If the request fails.
 */
export const getConsumerPoints = async (filter: GraphQLFilter) => {
    try {
        const cpId = filter?.id?.eq;

        const payload = !isFilterEmpty(filter)
            ? {
                  filterCP: filter,
                  filterCPCustomer: cpId
                      ? {
                            consumerPoint: { id: { eq: cpId } },
                        }
                      : undefined,
                  cpId,
              }
            : { filter: null, limit: 1 };

        const response = await sendGraphQLRequest<GetConsumerPointsQuery>(
            GetConsumerPoints,
            payload,
        );

        if (!response.data.getConsumerPoints?.consumerPoints) {
            throw Error('No data returned');
        }

        const customers =
            response.data.getConsumerPointCustomers?.consumerPointCustomers ||
            [];
        const areas =
            response.data.getConsumerPointAreasRPC?.consumerPointAreas || [];

        const categorizedCustomers = categorizeCustomers(
            customers as ConsumerPointCustomer[],
        );

        const consumerPoints =
            response.data.getConsumerPoints.consumerPoints.map(
                (consumerPoint) => ({
                    ...consumerPoint,
                    customers: categorizedCustomers,
                    areas,
                }),
            );

        return consumerPoints.filter(Boolean) as ConsumerPointFormData[];
    } catch (error) {
        throw Error('Failed to get consumer points: ' + error);
    }
};
