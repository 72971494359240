import { HTMLAttributes, useEffect, useRef } from 'react';
import { DragContext } from './MoveableContext';
import { StyledMoveableContainer } from './MoveableContainer.styles';

/**
 * MoveableContainer component.
 */

export const MoveableContainer = ({
    children,
    style,
    ...rest
}: HTMLAttributes<HTMLDivElement>): JSX.Element => {
    const containerRef = useRef<HTMLDivElement>(null);
    const draggableRef = useRef<HTMLDivElement>(null);
    const positionRef = useRef({ newX: 0, newY: 0, startX: 0, startY: 0 });

    useEffect(() => {
        if (!containerRef.current || !draggableRef.current) return;
        const container = containerRef.current;
        const handle = draggableRef.current;
        const position = positionRef.current;

        const mouseDown = (e: MouseEvent) => {
            position.startX = e.clientX;
            position.startY = e.clientY;
            document.addEventListener('mousemove', mouseMove);
            document.addEventListener('mouseup', mouseUp);
        };

        const mouseMove = (e: MouseEvent) => {
            if (!container) return;
            position.newX = position.startX - e.clientX;
            position.newY = position.startY - e.clientY;
            position.startX = e.clientX;
            position.startY = e.clientY;

            const maxX = window.innerWidth - container.offsetWidth;
            const maxY = window.innerHeight - 55 - container.offsetHeight;
            // Calculate new position with constraints
            const newLeft = Math.max(
                55,
                Math.min(container.offsetLeft - position.newX, maxX),
            );
            const newTop = Math.max(
                55,
                Math.min(container.offsetTop - position.newY, maxY),
            );

            container.style.left = `${newLeft}px`;
            container.style.top = `${newTop}px`;
        };

        const mouseUp = () => {
            document.removeEventListener('mousemove', mouseMove);
            document.removeEventListener('mouseup', mouseUp);
        };
        handle.addEventListener('mousedown', mouseDown);

        return () => {
            handle.removeEventListener('mousedown', mouseDown);
            document.removeEventListener('mousemove', mouseMove);
            document.removeEventListener('mouseup', mouseUp);
        };
    }, []);

    // Fallback display position if no style is provided
    const fallbackStyle = { top: '60px', left: '60px' };

    return (
        <DragContext.Provider value={draggableRef}>
            <StyledMoveableContainer
                ref={containerRef}
                {...rest}
                style={style ?? fallbackStyle}
            >
                {children}
            </StyledMoveableContainer>
        </DragContext.Provider>
    );
};
