import { ToastErrorBoundary } from '@components';
import { InfoObjectContainer } from './InfoObjectMenu/InfoObjectContainer';
import { useMobileMenu, useRightMenu } from '@stores';
import { useIsMobile } from '@keypro/2nd-xp';

/**
 * The props of InfoObject component.
 * @param model The model of the object.
 */
export interface InfoObjectProps {
    /** Info object model */
    model: string;
    /** Info object id */
    id: string;
}

/**
 * The InfoObject component displays menu object on right of the map.
 * @param model The model of the object.
 * @returns
 */
export const InfoObject = ({ model, id }: InfoObjectProps) => {
    const isMobile = useIsMobile();
    const { toggleMenu: toggleMobileMenu } = useMobileMenu();
    const { setMenuContent, menuContentId } = useRightMenu();

    const onError = () => {
        if (isMobile) {
            toggleMobileMenu();
        } else {
            const contentId = menuContentId ?? '';
            setMenuContent(contentId, <></>);
        }
    };

    return (
        <ToastErrorBoundary onError={onError}>
            <InfoObjectContainer model={model} id={id} />
        </ToastErrorBoundary>
    );
};
