import { create } from 'zustand';
import { getTenant } from '@utils';
import { BASE_TOOL_CONFIGS } from '@components/AppNavigationBar/Tools/ToolsConfigure';

// Storage key with tenant
const STORAGE_KEY = `pinned-tools-${getTenant(true)}`;

// Get saved tool IDs from localStorage
const getSavedToolIds = (): string[] => {
    try {
        const saved = localStorage.getItem(STORAGE_KEY);
        return saved ? JSON.parse(saved) : [];
    } catch (error) {
        console.error('Error loading pinned tools:', error);
        return [];
    }
};

// Save tool IDs to localStorage
const saveToolIds = (toolIds: string[]) => {
    try {
        localStorage.setItem(STORAGE_KEY, JSON.stringify(toolIds));
    } catch (error) {
        console.error('Error saving pinned tools:', error);
    }
};

// Types of tools available in the application
export type ToolType = 'nodeDuctsAndElevations' | 'heightProfileDiagram';

// Interface defining the structure of a pinned tool
export interface PinnedTool {
    id: ToolType; // Change from string to ToolType to ensure type safety
    icon: JSX.Element; // React element representing the tool's icon
    tooltip: string; // Tooltip text to display on hover
    onClick: () => void; // Handler function when tool is clicked
}

// Add a new event type for tool state changes
type ToolStateChangeCallback = (isOpen: boolean) => void;

// Interface defining the complete state and actions for the tools store
interface ToolsState {
    // State and setter for Node Ducts and Elevations tool
    isNodeDuctsAndElevationsOpen: boolean;
    setNodeDuctsAndElevationsOpen: (isOpen: boolean) => void;

    // State and setter for Height Profile Diagram tool
    isHeightProfileDiagramOpen: boolean;
    setHeightProfileDiagramOpen: (isOpen: boolean) => void;

    // Array of currently pinned tools and function to update them
    pinnedTools: PinnedTool[];
    setPinnedTools: (tools: PinnedTool[]) => void;

    // Function to toggle a tool's open/closed state
    toggleTool: (toolId: ToolType) => void;

    // Function to initialize pinned tools from localStorage
    initializePinnedTools: () => void;

    // Add callback registry
    stateChangeCallbacks: Record<ToolType, ToolStateChangeCallback[]>;
    registerStateChangeCallback: (
        tool: ToolType,
        callback: ToolStateChangeCallback,
    ) => void;
    unregisterStateChangeCallback: (
        tool: ToolType,
        callback: ToolStateChangeCallback,
    ) => void;

    // Close all currently open tools
    closeAllTools: () => void;
}

// Create store with localStorage support
export const useToolsStore = create<ToolsState>((set, get) => ({
    isNodeDuctsAndElevationsOpen: false,
    setNodeDuctsAndElevationsOpen: (isOpen) => {
        set({ isNodeDuctsAndElevationsOpen: isOpen });
    },

    isHeightProfileDiagramOpen: false,
    setHeightProfileDiagramOpen: (isOpen) => {
        set({ isHeightProfileDiagramOpen: isOpen });
    },

    // Initialize pinnedTools as empty array
    pinnedTools: [],

    // Set pinned tools and save to localStorage
    setPinnedTools: (tools) => {
        set({ pinnedTools: tools });
        saveToolIds(tools.map((tool) => tool.id));
    },

    // Initialize callback registry
    stateChangeCallbacks: {
        nodeDuctsAndElevations: [],
        heightProfileDiagram: [],
    },

    registerStateChangeCallback: (tool, callback) => {
        set((state) => ({
            stateChangeCallbacks: {
                ...state.stateChangeCallbacks,
                [tool]: [...state.stateChangeCallbacks[tool], callback],
            },
        }));
    },

    unregisterStateChangeCallback: (tool, callback) => {
        set((state) => ({
            stateChangeCallbacks: {
                ...state.stateChangeCallbacks,
                [tool]: state.stateChangeCallbacks[tool].filter(
                    (cb) => cb !== callback,
                ),
            },
        }));
    },

    // Toggle tool state
    toggleTool: (toolId: ToolType) => {
        if (toolId === 'nodeDuctsAndElevations') {
            set((state) => {
                const newState = !state.isNodeDuctsAndElevationsOpen;
                // Notify callbacks
                state.stateChangeCallbacks[toolId].forEach((cb) =>
                    cb(newState),
                );
                return { isNodeDuctsAndElevationsOpen: newState };
            });
        } else if (toolId === 'heightProfileDiagram') {
            set((state) => {
                const newState = !state.isHeightProfileDiagramOpen;
                // Notify callbacks
                state.stateChangeCallbacks[toolId].forEach((cb) =>
                    cb(newState),
                );
                return { isHeightProfileDiagramOpen: newState };
            });
        }
    },

    // Initialize tools from localStorage with proper typing
    initializePinnedTools: () => {
        const savedIds = getSavedToolIds();
        const tools = savedIds
            .map((id) => {
                const config = BASE_TOOL_CONFIGS[id as ToolType];
                if (!config) return null;

                const tool: PinnedTool = {
                    id: id as ToolType, // Explicitly type as ToolType
                    icon: config.icon,
                    tooltip: config.tooltip,
                    onClick: () => get().toggleTool(id as ToolType),
                };

                return tool;
            })
            .filter((tool): tool is NonNullable<typeof tool> => tool !== null); // Use NonNullable instead

        set({ pinnedTools: tools });
    },

    closeAllTools: () => {
        set({ isHeightProfileDiagramOpen: false });
        set({ isNodeDuctsAndElevationsOpen: false });
    },
}));
