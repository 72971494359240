import { Button, Icons, styled } from '@keypro/2nd-xp';
import NoItemsImage from '@assets/images/no-items.webp';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    height: 70%;
`;

const Text = styled.span`
    color: ${({ theme }) => theme.colors.neutral['100']};
    ${({ theme }) => theme.fonts['16px Bold']};
    text-align: center;
`;

const Description = styled.span`
    color: ${({ theme }) => theme.colors.neutral['80']};
    ${({ theme }) => theme.fonts['14px Regular']};
    text-align: center;
`;

const CreateButton = styled(Button)`
    margin-top: 20px;

    svg {
        width: 20px;
        height: 20px;
    }
`;

/**
 * Props for the NoItems component.
 */
export interface NoItemsProps {
    /** Main text */
    text: string;
    /** Additional description */
    description?: string;
    /** Create button label */
    createButtonLabel?: string;
    /** Create button click handler */
    onCreateButtonClick?: () => void;
}

/**
 * Content to show when there are no items to display.
 */
export const NoItems = (props: NoItemsProps): JSX.Element => {
    const { text, description, createButtonLabel, onCreateButtonClick } = props;
    return (
        <Container data-testid="no-items">
            <img src={NoItemsImage} alt={text} />
            <Text>{text}</Text>
            <Description>{description}</Description>

            {createButtonLabel && (
                <CreateButton
                    kind="primary"
                    label={createButtonLabel}
                    iconPosition="left"
                    onClick={onCreateButtonClick}
                >
                    <Icons.Plus />
                </CreateButton>
            )}
        </Container>
    );
};
