import { UserBadge, styled, Button } from '@keypro/2nd-xp';
import { useTranslation } from 'react-i18next';
import React, { useContext } from 'react';
import { AuthContext } from '@providers';

const UserImg = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 50%;
`;

const MenuUserBadge = styled(UserBadge)`
    ${(props) => props.theme.fonts['14px Bold']}
    width: 40px;
    height: 40px;
`;

const Account = styled.div`
    display: flex;
    flex-direction: column;
    background-color: transparent;
`;

const AccountItem = styled.div`
    padding: 8px;
    gap: 12px;
    margin-left: 8px;
    display: flex;
    flex-direction: row;
    svg {
        width: 40px;
        height: 40px;
    }
`;

const AccountInfo = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 180px;
    .name {
        ${(props) => props.theme.fonts['16px Medium']}
        color: ${(props) => props.theme.colors.neutral['100']};
    }
    .email {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        ${(props) => props.theme.fonts['12px Regular']}
        color: ${(props) => props.theme.colors.neutral['80']};
    }
`;

interface UserImageProps {
    style?: React.CSSProperties;
}

export const UserImage = ({ style }: UserImageProps): JSX.Element => {
    const { user } = useContext(AuthContext);
    if (user?.picture) {
        return <UserImg src={user.picture} style={style} />;
    } else {
        return <MenuUserBadge initials={user?.initials ?? ''} style={style} />;
    }
};

/**
 * AccountElement is a component that displays the user's name, email, and icon with initials
 * and provides a button to change the user's password
 * @param user The user data of the currently logged in user
 * @returns The AccountElement component
 */
export const AccountElement = () => {
    const { user } = useContext(AuthContext);
    const { t } = useTranslation();

    return (
        <Account>
            <AccountItem>
                <UserImage />
                <AccountInfo>
                    <div className="name">{user?.name}</div>
                    <div className="email">{user?.email}</div>
                    <Button
                        kind="primary"
                        label={t('changePassword')}
                        style={{
                            width: '145px',
                            height: '32px',
                            marginTop: '8px',
                        }}
                        disabled
                    />
                </AccountInfo>
            </AccountItem>
        </Account>
    );
};
