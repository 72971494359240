import { getConsumerPoints } from '@apis/keyduct';
import { FormConfig } from '../types';
import { Icons } from '@keypro/2nd-xp';
import { createComboField } from '../field-utils';
import { planGroup } from '../groups';
import {
    addressTable,
    areaTable,
    customerReference,
} from './components/ConsumerPointComponents';

const config: FormConfig = {
    gqlType: 'ConsumerPoint',
    model: 'snconsumerpoint',
    icon: <Icons.AddressPoint />,
    groups: [
        {
            name: 'objectData',
            fields: [
                'identification',
                createComboField('group', 'CONSUMERPOINT_GROUP'),

                'realEstateName',
                'nameExtend',

                createComboField('criticalCustomer', 'CRITICAL_CUSTOMER'),
                'residents',

                'criticalCustomerRemarks',
                createComboField('restrictedType', 'KU_RESTRICTED_TYPE'),
            ],
        },
        {
            name: 'Customers',
            fields: [
                {
                    name: 'customers.owner',
                    customComponent: customerReference,
                },
                {
                    name: 'customers.tenant',
                    customComponent: customerReference,
                },
            ],
        },
        {
            name: 'propertyDetails',
            fields: [
                {
                    name: 'realEstateInfo',
                    translationKey: 'propertyId',
                },
                createComboField('cpState', 'CONSUMERPOINT_STATE'),

                createComboField('cpType', 'CONSUMER_POINT_TYPE'),
                createComboField('type', 'CP_TYPE', false, 'subscriptionType'),

                'joiningDate',
                'buildYear',

                createComboField('heatingForm', 'HEATING_FORM'),
                'finalApprovalDate',
            ],
        },
        {
            name: 'addresses',
            translationKey: 'Address.$titlePlural',
            fields: [
                {
                    name: 'addresses',
                    customComponent: addressTable,
                    fullWidth: true,
                },
            ],
        },
        {
            name: 'areasGroup',
            translationKey: 'areasGroup',
            fields: [
                {
                    name: 'areas',
                    customComponent: areaTable,
                    fullWidth: true,
                },
            ],
        },
        planGroup,
        {
            name: 'notes',
            fields: [
                {
                    name: 'consumerNameExt',
                    translationKey: 'notes',
                    component: 'textarea',
                },
            ],
        },
        {
            name: 'water',
            fields: [
                { name: 'consumerNumber', translationKey: 'number' },
                { name: 'waterJunction', component: 'checkbox-field' },
                { name: 'sewerJunction', component: 'checkbox-field' },
                { name: 'stormWaterJunction', component: 'checkbox-field' },
            ],
        },
        {
            name: 'districtHeating',
            fields: [{ name: 'dhNumber', translationKey: 'number' }],
        },
        {
            name: 'gas',
            fields: [{ name: 'gasNumber', translationKey: 'number' }],
        },
    ],
    functions: {
        get: getConsumerPoints,
    },
    queryCollection: 'consumerPoints',
};

export default config;
