query quickAddressSearchRPC($term: String!) {
    quickAddressSearchRPC(term: $term) {
        totalCount
        addresses {
            id
            city
            lat
            lon
            name
            source
            sourceId
            streetname
            streetno
        }
    }
}
