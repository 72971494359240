import { Icons, styled, Button, Input } from '@keypro/2nd-xp';
import mapviewImage from '@assets/images/mapview.webp';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { MapViewSettingsFloating } from './MapViewSettingsFloating';
import { t } from 'i18next';

const StyledItem = styled.div<{
    $editing?: boolean;
    $menuvisible?: boolean;
}>`
    padding: 12px;
    gap: 12px;
    display: flex;
    position: relative;
    border-radius: 8px;
    background-color: ${({ $editing, $menuvisible, theme }) =>
        $editing || $menuvisible ? theme.colors.neutral['30'] : 'transparent'};
    &.active {
        &:before {
            content: '';
            position: absolute;
            margin-top: 4px;
            margin-left: 2px;
            height: 48px;
            top: 0;
            left: 0;
            width: 2px;
            background-color: ${({ theme }) => theme.colors.accents.blue['10']};
        }
        .drag-icon {
            display: none;
        }
    }
    button {
        visibility: ${({ $editing }) => ($editing ? 'visible' : 'hidden')};
    }

    &.dragging,
    &.hovered {
        background-color: ${({ theme }) => theme.colors.neutral['30']};
        border-radius: 8px;
        svg,
        button {
            visibility: visible;
        }
        .more-button {
            background-color: ${({ theme }) => theme.colors.neutral['30']};
        }
    }
`;

const ItemTextContainer = styled.div`
    flex: 1;
    overflow: hidden;
`;

const NameField = styled.div`
    ${({ theme }) => theme.fonts['14px Regular']}
    color: ${({ theme }) => theme.colors.neutral['100']};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

const DateField = styled.div`
    ${({ theme }) => theme.fonts['12px Regular']}
    color: ${({ theme }) => theme.colors.neutral['80']};
`;

const DragIcon = styled.div`
    width: 16px;
    height: 24px;
    position: absolute;
    left: -4px;
    top: 50%;
    transform: translateY(-50%);

    svg {
        width: 16px;
        height: 24px;
        transform: scale(1.5);
        visibility: hidden;
        path {
            fill: ${({ theme }) => theme.colors.neutral['80']};
        }
    }
`;

const MapImage = styled.img`
    width: 40px;
    height: 32px;
    border-radius: 6px;
`;

const EditingContainer = styled.div`
    width: 100%;
    height: 76px;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    gap: 12px;
`;

const EditingButtonContainer = styled.div`
    display: flex;
    height: 32px;
    gap: 8px;
`;

const MoreButtonContainer = styled.div.attrs({
    className: 'more-button',
})`
    position: absolute;
    right: 12px;
    top: 12px;
`;

interface MapViewListItemProps extends React.HTMLAttributes<HTMLDivElement> {
    /** Name of the MapView */
    name: string;
    /** Creation date of the MapView */
    date: string;
    /** Function to call when the item is clicked */
    onSave: (name: string) => void;
    /** Function to call when the item is deleted */
    onDelete: () => void;
    editing: boolean;
    setEditingView: React.Dispatch<React.SetStateAction<string | null>>;
}

interface ListItemEditingProps extends React.HTMLAttributes<HTMLDivElement> {
    /** Function to save the new name */
    handleSave: (newName: string) => void;
    /** Function to discard the changes */
    handleDiscard?: () => void;
    /** Initial name */
    name: string;
}

interface MapViewProps extends React.HTMLAttributes<HTMLDivElement> {
    /** Function to set reference element for "settings" menu */
    setReferenceElement: (element: HTMLDivElement | null) => void;
    /** Function to toggle the "settings" menu */
    toggleMenu: (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ) => void;
    /** Name of the MapView */
    name: string;
    /** creation date of the MapView */
    date: string;
    /** Is the "settings" menu visible */
    menuVisible: boolean;
}

/**
 * Component for displaying a single MapView item in the list
 */
export const MapViewListItem = ({
    name,
    date,
    onSave,
    onDelete,
    editing: isEditing,
    setEditingView,
    ...rest
}: MapViewListItemProps) => {
    const { i18n } = useTranslation();
    const locale = i18n.language;
    const dateObj = DateTime.fromISO(date, { locale: locale });
    const [displayDate, setDisplayDate] = useState('');
    const [displayName, setDisplayName] = useState(name ?? '');
    const [menuVisible, setMenuVisible] = useState(false);
    const [refElement, setRefElement] = useState<HTMLElement | null>(null);

    const handleSave = (newName: string) => {
        setDisplayName(newName);
        onSave(newName);
        setEditingView(null);
    };

    const toggleMenu = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ) => {
        event.stopPropagation();
        setMenuVisible(!menuVisible);
    };

    const handleMouseLeave = () => {
        setMenuVisible(false);
    };

    useEffect(() => {
        const dateFormat: Intl.DateTimeFormatOptions = {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
        };

        const updateDisplayDate = () => {
            const now = DateTime.now();
            const daysDifference = now.diff(dateObj, 'days').days;
            const minutesDifference = now.diff(dateObj, 'minutes').minutes;

            if (minutesDifference < 1) {
                const relativeDate = dateObj.minus({ minutes: 1 }).toRelative();
                setDisplayDate(relativeDate ?? '');
            } else if (daysDifference < 7) {
                const relativeDate = dateObj.toRelative();
                setDisplayDate(relativeDate ?? '');
            } else {
                setDisplayDate(dateObj.toLocaleString(dateFormat));
            }
        };

        updateDisplayDate();
        const intervalId = setInterval(updateDisplayDate, 60000); // Update every minute

        return () => clearInterval(intervalId); // Cleanup interval on component unmount
    }, [dateObj]);

    useEffect(() => {
        if (isEditing) {
            setMenuVisible(false);
        }
    }, [isEditing]);

    return (
        <>
            {isEditing ? (
                <MapViewEditing
                    handleDiscard={() => setEditingView(null)}
                    name={displayName}
                    handleSave={(newName: string) => {
                        handleSave(newName);
                        setEditingView(null);
                    }}
                />
            ) : (
                <>
                    <MapViewItem
                        setReferenceElement={setRefElement}
                        toggleMenu={toggleMenu}
                        name={displayName}
                        date={displayDate}
                        menuVisible={menuVisible}
                        {...rest}
                    />
                    {menuVisible && (
                        <MapViewSettingsFloating
                            name={displayName}
                            referenceElement={refElement}
                            handleClose={handleMouseLeave}
                            onDelete={onDelete}
                            setEditingView={setEditingView}
                        />
                    )}
                </>
            )}
        </>
    );
};

/**
 * Editing view for the MapView item
 */
const MapViewEditing = ({
    handleSave,
    handleDiscard,
    name,
}: ListItemEditingProps) => {
    const [newName, setNewName] = useState<string>(name);
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewName(event.target.value);
    };
    return (
        <StyledItem draggable={false} $editing={true}>
            <EditingContainer>
                <Input
                    inputProps={{
                        value: newName,
                        onChange: handleInputChange,
                        autoFocus: true,
                    }}
                />
                <EditingButtonContainer>
                    <Button
                        kind="secondary"
                        label={t('discard')}
                        style={{ width: '77px', height: '32px' }}
                        onClick={handleDiscard}
                        data-testid="mapview-menu-new-discardBtn"
                    />
                    <Button
                        kind="primary"
                        label={t('save')}
                        style={{ width: '75px', height: '32px' }}
                        onClick={() => handleSave(newName)}
                        data-testid="mapview-menu-new-saveBtn"
                    />
                </EditingButtonContainer>
            </EditingContainer>
        </StyledItem>
    );
};

/**
 * View for the MapView item
 */
const MapViewItem = ({
    setReferenceElement,
    menuVisible,
    toggleMenu,
    name,
    date,
    ...rest
}: MapViewProps) => {
    const nameRef = React.useRef<HTMLDivElement>(null);
    const [isOverflowing, setIsOverflowing] = useState(false);

    // Checks if the name is overflowing the container, tooltip is shown if it is
    useEffect(() => {
        if (nameRef.current) {
            setIsOverflowing(
                nameRef.current.scrollWidth > nameRef.current.clientWidth,
            );
        }
    }, [name]);

    return (
        <StyledItem {...rest} $menuvisible={menuVisible}>
            <DragIcon className="drag-icon">
                <Icons.Drag />
            </DragIcon>
            <MapImage src={mapviewImage} alt="mapview" />
            <ItemTextContainer>
                <NameField
                    ref={nameRef}
                    data-tooltip={isOverflowing ? name : undefined}
                >
                    {name}
                </NameField>
                <DateField>{date}</DateField>
            </ItemTextContainer>
            <MoreButtonContainer ref={setReferenceElement}>
                <Button
                    data-tooltip={t('moreActions')}
                    kind="ghost"
                    style={{
                        width: '32px',
                        height: '32px',
                        top: '-4px',
                        position: 'relative',
                    }}
                    onClick={(e) => toggleMenu(e)}
                >
                    <Icons.More />
                </Button>
            </MoreButtonContainer>
        </StyledItem>
    );
};
