import { styled } from '@keypro/2nd-xp';

export const StyledMoveableContainer = styled.div`
    position: fixed;
    width: 268px;
    height: fit-content;
    border-radius: 12px;
    border: 4px solid ${(props) => props.theme.colors.neutral['50']};
    background-color: ${(props) => props.theme.colors.neutral['10']};
    z-index: 10001; // Above toast to prevent toast from covering the possible draggable handle
`;
