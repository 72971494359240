import { RaphaelPaper, RaphaelSet } from 'raphael';

import {
    EdgesData,
    NodeChildData,
    NodesData,
    ProfileDiagramRpcData,
    TextsData,
} from '@generated';
import { DiagramOptions, Transform } from './types';
import { rotateVector } from './utils';
import {
    Node,
    Text,
    Conduit,
    ConnectionCable,
    Cable,
    Splice,
    NodeGroup,
    Edge,
} from './shapes';

export class ProfileDiagram {
    options: DiagramOptions;

    x: number = 0;
    y: number = 0;
    ox: number = 0;
    oy: number = 0;
    paper: RaphaelPaper;

    data: ProfileDiagramRpcData;

    diagramNodes: Node[] = [];
    diagramEdges: Edge[] = [];
    diagramTexts: Text[] = [];

    public elemSet: RaphaelSet;

    constructor(options: DiagramOptions) {
        this.options = options;
        this.paper = options.paper;
        this.elemSet = options.paper.set();
        this.data = options.data;
    }

    initialize() {
        this.processData(this.data);
        this.render(this.data);
    }

    processData(data: ProfileDiagramRpcData) {
        const nodesCount = data.nodes?.length ?? 0;
        for (let i = 0; i < nodesCount; i++) {
            const node = data.nodes[i];
            if (node.connEnd && node.connEnd == 'B') {
                //Rotate whole node
                node.angle = node.angle + 180;
                if (node.otherend?.angle) {
                    node.otherend.angle = node.otherend.angle + 180;
                }
                //T component
                const tangle = ((node.angle + 90) * Math.PI) / 180;
                const titem = node.children;
                const tdistance = Math.max.apply(
                    null,
                    (titem ?? []).map((item) => {
                        return (item?.radius as number) ?? 0;
                    }),
                );
                const tmovex = -tdistance * Math.cos(tangle) * 2;
                const tmovey = -tdistance * Math.sin(tangle) * 2;

                // R T child nodes
                node.children?.forEach((child) => {
                    //R children
                    const tempnode = this.calculateTransform(child, [
                        'R',
                        180,
                        node.x,
                        node.y,
                    ]);
                    child.x = tempnode.x;
                    child.y = tempnode.y;
                    // T children
                    child.x += tmovex;
                    child.y += tmovey;
                });

                if (node.otherend?.x && node.otherend?.y) {
                    //T other to suitable place, try catch to ignore some  objects that don't have other-end.
                    node.otherend.x += tmovex;
                    node.otherend.y += tmovey;
                }

                //T center to suitable place
                node.x += tmovex;
                node.y += tmovey;
                node.ox += tmovex;
                node.oy += tmovey;
                node.tmovex = tmovex;
                node.tmovey = tmovey;
            }
        }
    }

    render(diagramData: ProfileDiagramRpcData) {
        const nodes = diagramData['nodes'];
        const edges = diagramData['edges'];
        const texts = diagramData['texts'];

        let i;
        /* render diagram nodes */
        for (i = 0; i < nodes.length; i++) {
            const diagramNode = this.createNode(nodes[i]);
            diagramNode.render();
            diagramNode.connEnd = nodes[i].connEnd ?? '';
            diagramNode.tmovex = nodes[i].tmovex ?? 0;
            diagramNode.tmovey = nodes[i].tmovey ?? 0;
            this.diagramNodes.push(diagramNode);

            diagramNode.getElemSet().forEach((elem) => {
                this.elemSet.push(elem);
            });
        }

        /* render diagram edges */
        for (i = 0; i < edges.length; i++) {
            const edgeConns = edges[i]['connections'];
            let startNode;
            let endNode;

            if (edgeConns.length === 2) {
                startNode = this.findNodeByShapeId(edgeConns[0]);
                endNode = this.findNodeByShapeId(edgeConns[1]);
            } else {
                throw new Error(
                    'The edge has only one end connected: ' + edges[i],
                );
            }

            const diagramEdge = this.createEdge(edges[i], startNode, endNode);
            diagramEdge.render();
            this.diagramEdges.push(diagramEdge);

            diagramEdge.getElemSet().forEach((elem) => {
                this.elemSet.push(elem);
            });
        }

        /* Render diagram freetexts */
        for (i = 0; i < texts.length; i++) {
            const diagramText = this.createText(texts[i]);
            diagramText.render();
            this.diagramTexts.push(diagramText);

            diagramText.getElemSet().forEach((elem) => {
                this.elemSet.push(elem);
            });
        }
    }

    createNode(nodeData: NodesData) {
        switch (nodeData.dataType) {
            case 'conduit':
                return new Conduit(this.options, nodeData);
            case 'splice':
                return new Splice(this.options, nodeData);
            case 'cable':
                return new Cable(this.options, nodeData);
            default:
                throw new Error(
                    'Node type not supported: ' + nodeData.dataType,
                );
        }
    }

    createEdge(edge: EdgesData, startNode: Node, endNode: Node) {
        if (edge.dataType === 'cable') {
            return new ConnectionCable(this.options, edge, startNode, endNode);
        } else {
            throw new Error('Edge type not supported: ' + edge.dataType);
        }
    }

    createText(text: TextsData) {
        return new Text(this.options, text);
    }

    calculateTransform(node: NodeChildData, transform: Transform) {
        const me = node;

        if (transform[0] === 'T') {
            me.x += transform[1];
            me.y += transform[2];

            if (me.ox && me.oy) {
                me.ox += transform[1];
                me.oy += transform[2];
            }

            return me;
        } else if (transform[0] === 'R') {
            me.angle += transform[1];
            const radAngle = (transform[1] * Math.PI) / 180;

            /* Calculate new center point */
            let dx = me.x - transform[2];
            let dy = me.y - transform[3];
            let rv = rotateVector(dx, dy, radAngle);
            me.x = transform[2] + rv[0];
            me.y = transform[3] + rv[1];

            if (me.ox && me.oy) {
                /* Calculate new origin point */
                dx = me.ox - transform[2];
                dy = me.oy - transform[3];
                rv = rotateVector(dx, dy, radAngle);
                me.ox = transform[2] + rv[0];
                me.oy = transform[3] + rv[1];
            }

            return me;
        } else {
            throw new Error('Unknown transform type: ' + transform[0]);
        }
    }

    findNodeByShapeId(idStr: string) {
        const idArray = idStr.split(':');

        let nodes = this.diagramNodes;
        let foundNode = null;

        let k = 0;
        while (k < idArray.length) {
            const shapeId = idArray[k];
            foundNode = null;
            for (const node of nodes) {
                if (node.getShapeId() === shapeId) {
                    foundNode = node;
                    break;
                }
            }

            if (foundNode instanceof NodeGroup) {
                nodes = foundNode.getChildNodes();
            } else {
                nodes = [];
            }

            k++;
        }

        if (!foundNode) {
            throw new Error('Node not found by shapeId: ' + idStr);
        }

        return foundNode;
    }
}
