import { styled } from '@keypro/2nd-xp';

interface ModalProps extends React.HTMLAttributes<HTMLDivElement> {
    /** The title of the modal */
    title: string;
    /** Optional icon to display next to the title */
    icon?: JSX.Element;
    /** Optional close button of the modal */
    closeButton?: JSX.Element;
    /** The main text content of the modal */
    text: string;
    /** Optional right button of the modal */
    rightButton?: JSX.Element;
    /** Optional left button of the modal */
    leftButton?: JSX.Element;
}

/**
 * Modal component that displays a modal dialog with a title, text, and optional buttons and icons.
 * @returns The modal component.
 */
const Modal = ({
    title,
    icon,
    closeButton,
    text,
    rightButton,
    leftButton,
    ...rest
}: ModalProps): JSX.Element => {
    return (
        <>
            <StyledDarkMask data-testid="modal-darkMask" />
            <StyledModal {...rest} data-testid="modal-container">
                <StyledContainer>
                    <StyledModalHeader>
                        <StyleHeaderContent>
                            {icon && <StyledModalIcon>{icon}</StyledModalIcon>}
                            {title}
                        </StyleHeaderContent>
                        {closeButton && (
                            <StyledModalIcon>{closeButton}</StyledModalIcon>
                        )}
                    </StyledModalHeader>
                    <StyledMessage data-testid="modal-text">
                        {text}
                    </StyledMessage>
                    <StyledButtons>
                        {leftButton}
                        {rightButton}
                    </StyledButtons>
                </StyledContainer>
            </StyledModal>
        </>
    );
};

export default Modal;

const StyledModal = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10003;
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 8px;
    border-radius: 12px;
    background-color: ${(props) => props.theme.colors.neutral['10']};
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    color: ${(props) => props.theme.colors.neutral['100']};
    min-width: min(80%, 307px);
`;

const StyledDarkMask = styled.div`
    position: fixed;
    background-color: rgba(0, 0, 0, 0.6);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10002; // Above MoveableContainer to prevent using the popup while modal is open
`;

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 4px 0px 0px 4px;
`;

const StyledModalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
`;

const StyleHeaderContent = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    ${(props) => props.theme.fonts['14px Bold']};
`;

const StyledModalIcon = styled.div`
    & > svg {
        width: 16px;
        height: 14px;
    }
`;

const StyledMessage = styled.div`
    font-variant-numeric: tabular-nums;
    white-space: pre-line;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 8px;
    padding-bottom: 16px;
    ${(props) => props.theme.fonts['14px Regular']};
`;

const StyledButtons = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
    gap: 8px;
`;
