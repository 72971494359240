import { GetSewerBranchesQuery, SewerNode } from '@generated';
import GetSewerBranches from './queries/GetSewerBranches.graphql';
import { sendGraphQLRequest } from '@utils';
import { GraphQLFilter, isFilterEmpty } from '@apis/utils';

/**
 * Gets the sewer branches.
 * @param filter The GraphQL filter to apply to the query.
 * @returns The sewer branches.
 * @throws {Error} If the request fails.
 */
export const getSewerBranches = async (filter: GraphQLFilter) => {
    try {
        const payload = !isFilterEmpty(filter)
            ? { filter: filter }
            : { filter: null, limit: 1 };
        const response = await sendGraphQLRequest<GetSewerBranchesQuery>(
            GetSewerBranches,
            payload,
        );

        if (!response.data.getSewerBranches) {
            throw Error('No data returned');
        }

        return response.data.getSewerBranches.sewerBranches?.filter(
            Boolean,
        ) as SewerNode[];
    } catch (error) {
        throw Error('Failed to get sewer branches: ' + error);
    }
};
