import { Shape } from './Shape';
import { DiagramData, DiagramOptions } from '../types';

export abstract class Node extends Shape {
    /** Node center point */
    cx: number;
    cy: number;

    /** Node radius */
    radius: number;

    /** Rotated angle */
    angle: number = 0;

    /** Current transform string */
    transformStr: string = '';

    lineWidth: number = 8;

    constructor(options: DiagramOptions, data: DiagramData) {
        super(options, data);

        /* Common attributes for all nodes */
        this.cx = data.x;
        this.cy = data.y;
        this.radius = 'radius' in data ? data.radius : 0;
        this.angle = 'angle' in data ? data.angle : 0;

        if (this.angle !== 0) {
            this.transformStr = 'R' + [this.angle, this.cx, this.cy].join(',');
        }
    }

    public render() {
        if (this.transformStr !== '') {
            this.elemSet.transform(this.transformStr);
        }

        super.render();
    }

    getXY() {
        return [this.cx, this.cy];
    }

    getAngle() {
        return this.angle;
    }
}
