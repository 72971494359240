import { OtherendData } from '@generated';
import { Shape } from './Shape';
import { DiagramOptions } from '../types';

export class ConduitOeObject extends Shape {
    cx: number;
    cy: number;
    angle: number;

    fontSize: number = 150;
    textColor: string = '#FF3300';

    lineColor: string = '#FF3300';

    size: number;

    /**
     * Space between arrow and label
     */
    labelMargin: number = 30;

    transformStr: string = '';

    constructor(options: DiagramOptions, data: OtherendData) {
        super(options, data);

        this.cx = data.x;
        this.cy = data.y;
        this.size = data.size;
        this.angle = data.angle;
        this.connEnd = data.connEnd ?? null; // note it's the other end connection direction

        if (this.angle !== 0) {
            this.transformStr = 'R' + [this.angle, this.cx, this.cy].join(',');
        }
    }

    render() {
        /*
         * Draw other end object arrow, the error should show at bottom of the conduit
         * profile (managed by passed x,y) and draw upside down. Notice that the y-axis
         * in svg is top-down direction.
         */
        const s = -this.size;
        const cx = this.cx,
            cy = this.cy;
        const pathStr = [
            'M',
            cx,
            cy + s / 2,
            'L',
            cx + s / 2,
            cy,
            cx + s / 4,
            cy,
            cx + s / 4,
            cy - s / 2,
            cx - s / 4,
            cy - s / 2,
            cx - s / 4,
            cy,
            cx - s / 2,
            cy,
            'Z',
        ].join(' ');
        const path = this.paper.path(pathStr).attr({
            stroke: this.lineColor,
            'stroke-width': this.lineWidth,
            fill: '#FFFFFF',
            'fill-opacity': 0, // make inside clickable
        });
        this.elemSet.push(path);

        if (this.objectLabel) {
            const label = this.paper
                .text(
                    cx + this.size / 2 + this.labelMargin,
                    cy,
                    this.objectLabel,
                )
                .attr({
                    'font-size': this.fontSize,
                    fill: this.textColor,
                    'text-anchor': 'start',
                });
            this.elemSet.push(label);
        }

        if (this.transformStr !== '') {
            this.elemSet.transform(this.transformStr);
        }

        super.render();
    }
}
