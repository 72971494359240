import { Node } from './Node';
import { NodesData } from '@generated';
import { DiagramOptions } from '../types';

export class Splice extends Node {
    fillColor: string = '#00FFFF';

    constructor(options: DiagramOptions, data: NodesData) {
        super(options, data);

        this.lineColor = this.getStateColor(data['state']);
        this.textColor = this.lineColor; // splice text and shape are using same color
        this.fillColor = this.lineColor;
    }

    render() {
        /* splice is a diamond shape */
        const s = this.radius,
            l = this.radius * 1.618; // short radius and long radius
        const pathStr =
            'M' +
            [this.cx - l, this.cy].join(',') +
            'L' +
            [
                this.cx,
                this.cy - s,
                this.cx + l,
                this.cy,
                this.cx,
                this.cy + s,
            ].join(',') +
            'Z';
        const path = this.paper.path(pathStr).attr({
            stroke: this.lineColor,
            'stroke-width': this.lineWidth,
            fill: this.fillColor,
        });
        this.elemSet.push(path);

        if (this.objectLabel) {
            this.labelElem = this.paper
                .text(this.cx + l, this.cy - s, this.objectLabel)
                .attr({
                    'font-size': this.fontSize,
                    'text-anchor': 'start',
                    fill: this.textColor,
                });

            this.elemSet.push(this.labelElem);
        }

        super.render();
    }

    /**
     * Splice state color (red for planned splices)
     */
    getStateColor(state: number) {
        return state === 2 ? '#FF0000' : '#00FFFF';
    }
}
