import { styled, Table } from '@keypro/2nd-xp';

export const StyledTableCell = styled(Table.Cell)`
    padding: 4px 12px;
`;
export const StyledTableRoot = styled(Table.Root)`
    border-spacing: 0;
    width: 100%;
    margin-top: 3px;
`;
export const TableContainer = styled.div`
    overflow: auto;
    width: 100%;
    max-height: 250px;
    height: 150px;
`;
