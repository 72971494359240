import { Icons, styled } from '@keypro/2nd-xp';
import { SEWER_COLOR, WATER_COLOR } from './icons.consts';

export const SewerManholeIcon = styled(Icons.Manhole)`
    rect {
        fill: ${SEWER_COLOR};
        stroke: ${SEWER_COLOR};
    }
`;

export const SewerBranchIcon = styled(Icons.Branch)`
    color: ${SEWER_COLOR};
`;

export const WaterBranchIcon = styled(Icons.Branch)`
    color: ${WATER_COLOR};
`;

export const SewerDuctIcon = styled(Icons.Duct)`
    path {
        stroke: ${SEWER_COLOR};
    }
`;

export const WaterDuctIcon = styled(Icons.Duct)`
    path {
        stroke: ${WATER_COLOR};
    }
`;

export const SewerValveIcon = styled(Icons.Valve)`
    color: ${SEWER_COLOR};
`;

export const WaterValveIcon = styled(Icons.Valve)`
    color: ${WATER_COLOR};
`;

export const SewerPumpingStationIcon = styled(Icons.Droplet)`
    color: ${SEWER_COLOR};
`;

export const WaterPumpingStationIcon = styled(Icons.Droplet)`
    color: ${WATER_COLOR};
`;

export const RainSewerDuctIcon = styled(Icons.Duct)`
    path {
        stroke: #4cc327;
    }
`;

export const PressureDuctIcon = styled(Icons.Duct)`
    path {
        stroke: #ff3f02;
    }
`;
