import { Icons } from '@keypro/2nd-xp';
import { t } from 'i18next';

// Base configurations for all tools
export const BASE_TOOL_CONFIGS = {
    // Configuration for Node Ducts and Elevations tool
    nodeDuctsAndElevations: {
        id: 'nodeDuctsAndElevations' as const,
        icon: <Icons.NodeDuct />,
        tooltip: t('nodeDuctsAndElevations'),
    },
    // Configuration for Height Profile Diagram tool
    heightProfileDiagram: {
        id: 'heightProfileDiagram' as const,
        icon: <Icons.HeightProfile />,
        tooltip: t('heightProfileDiagram'),
    },
} as const;
