/**
 * The size of the toolbar in pixels.
 */
export const TOOL_BAR_SIZE = 56;

/**
 * The time in milliseconds before hover info request is sent.
 */
export const HOVER_TIME = 250;

/**
 * The maximum display scale to enable hovering functionality (in cm).
 */
export const MAX_HOVER_DISPLAY_SCALE = 2000;

/**
 * Display scale based multiplier used for calculating the search area around the point.
 */
export const POINT_DISPLAY_SCALE_MULTIPLIER = 0.005;

/**
 * Priority order of geometry types for the on-hover to check.
 */
export const PRIORITY_ORDER = [
    'POINT',
    'MULTIPOINT',
    'LINESTRING',
    'MULTILINESTRING',
    'POLYGON',
    'MULTIPOLYGON',
    'GEOMETRYCOLLECTION',
];
