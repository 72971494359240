query getConsumerPoints(
    $filterCP: ConsumerPointFilter
    $filterCPCustomer: ConsumerPointCustomerFilter
    $cpId: ID
) {
    getConsumerPoints(filter: $filterCP) {
        consumerPoints {
            id
            identification
            group {
                id
            }

            realEstateName
            nameExtend

            criticalCustomer {
                id
            }
            residents

            criticalCustomerRemarks

            restrictedType {
                id
            }

            realEstateInfo
            cpState {
                id
            }

            cpType {
                id
            }
            type {
                id
            }

            joiningDate
            buildYear

            heatingForm {
                id
            }
            finalApprovalDate

            addresses {
                id
                address {
                    id
                    streetname
                    streetno
                    city
                    zipcode
                }
            }
            plan {
                id
            }
            consumerNameExt

            createdBy
            createdTs
            updatedBy
            updatedTs
            consumerNumber
            waterJunction
            stormWaterJunction
            sewerJunction
            dhNumber
            gasNumber
        }
    }
    getConsumerPointCustomers(filter: $filterCPCustomer) {
        consumerPointCustomers {
            customer {
                id
                customerName
            }
            customerRole {
                id
                txt
            }
        }
    }
    getConsumerPointAreasRPC(input: $cpId) {
        consumerPointAreas {
            id
            areaId
            mainType
            subType
            name
        }
    }
}
