import { useContext, useEffect, useState } from 'react';
import {
    styled,
    Button as DefaultButton,
    Icons,
    MapContext,
    useToast,
} from '@keypro/2nd-xp';
import { t } from 'i18next';
import { AuthContext } from '@providers';
import { checkAuth } from '@apis/user';
import { MapView } from '@components/AppNavigationBar/MapViews/MapViewMenu';
import { getTenant } from '@utils';
import { Modal } from '@components/Modal';

/*
 * The checking interval for the session expiration in milliseconds. Default is 1 second.
 */
const INTERVAL = 1 * 1000;

/*
 * The time left threshold for displaying the session expiration modal in milliseconds. Default is 5 minutes.
 */
const TIME_LEFT_THRESHOLD = 5 * 60 * 1000;

/*
 * The key for the expired session autosaved map view in the local storage.
 */
const AUTOSAVED_ITEM = `autosaved-map-view${getTenant(true)}`;

/**
 * The SessionWrapper component is a modal that will be displayed when the session is about to expire.
 * @returns The SessionWrapper component
 */
const SessionWrapper = () => {
    const [countdown, setCountdown] = useState<number | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const { logout } = useContext(AuthContext);
    const controller = useContext(MapContext)!;
    const toast = useToast();

    const getCookie = (name: string) => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        return parts.length === 2 ? parts.pop()?.split(';').shift() : null;
    };

    useEffect(() => {
        const interval = setInterval(() => {
            const sessionExp = getCookie('sessionexp');
            if (!sessionExp) {
                // Force to display the session expired modal.
                // Due to this, it is important to make sure gateway sends the sessionexp cookie correctly.
                setCountdown(0);
                return;
            }

            const currentTime = Date.now();
            const timeLeft = Number(sessionExp) - currentTime;

            if (timeLeft > -1000 && timeLeft <= TIME_LEFT_THRESHOLD) {
                const timeLeftInSeconds = Math.ceil(timeLeft / 1000);
                setCountdown(timeLeftInSeconds);

                // Autosave the map view when the session is expired.
                if (timeLeftInSeconds === 0) {
                    const newView: MapView = {
                        id: `${sessionExp}`,
                        name: sessionExp,
                        date: new Date().toISOString(),
                        data: controller.getMapParams(),
                    };
                    localStorage.setItem(
                        AUTOSAVED_ITEM,
                        JSON.stringify(newView),
                    );
                }
            } else if (timeLeft < -1000) {
                setCountdown(0);
                return;
            }
        }, INTERVAL);

        return () => clearInterval(interval);
    }, [controller, countdown]);

    useEffect(() => {
        // Restore the autosaved map view from the expired session.
        const handleInitialize = () => {
            const savedViews = localStorage.getItem(AUTOSAVED_ITEM);
            if (savedViews) {
                window.setTimeout(() => {
                    controller.setMapParams(JSON.parse(savedViews).data);
                    localStorage.removeItem(AUTOSAVED_ITEM);
                }, 100);
            }
        };
        controller.on('initialize', handleInitialize);

        return () => {
            controller.off('initialize', handleInitialize);
        };
    }, [controller]);

    const handleButtonClick = async () => {
        setIsLoading(true);
        try {
            // Trigger a harmless request to extend the session.
            const isAuthenticated = await checkAuth();
            if (isAuthenticated) {
                setCountdown(null);
            }
        } catch (error) {
            console.error(error);
            toast.error(t('sessionWrapper.extendSessionError'));
        } finally {
            setIsLoading(false);
        }
    };

    if (countdown === null) return null;

    const minutes = Math.floor(countdown / 60);
    const seconds = countdown % 60;
    const isExpired = countdown < 1;

    const title = isExpired
        ? t('sessionWrapper.sessionExpiredTitle')
        : t('sessionWrapper.sessionExpireSoonTitle');

    const expireSoonText =
        minutes === 0
            ? t('sessionWrapper.sessionExpireSoonShort', {
                  seconds,
              })
            : t('sessionWrapper.sessionExpireSoon', {
                  minutes,
                  seconds,
              });
    const message = isExpired
        ? t('sessionWrapper.sessionExpired')
        : expireSoonText;

    const leftButton = isExpired ? undefined : (
        <StyledLogoutBtn
            onClick={logout}
            kind="ghost"
            data-testid="sessionWrapper-logOutBtn"
        >
            {t('sessionWrapper.logout')}
        </StyledLogoutBtn>
    );
    const rightButton = isExpired ? (
        <StyledLogoutBtn
            onClick={logout}
            data-testid="sessionWrapper-logOutBtn"
        >
            {t('sessionWrapper.login')}
        </StyledLogoutBtn>
    ) : (
        <Button
            onClick={handleButtonClick}
            data-testid="sessionWrapper-extendBtn"
            disabled={isLoading}
        >
            {t('sessionWrapper.extendSession')}
        </Button>
    );

    return (
        <Modal
            title={title}
            icon={<WarningSign />}
            text={message}
            leftButton={leftButton}
            rightButton={rightButton}
        ></Modal>
    );
};

export default SessionWrapper;

const Button = styled(DefaultButton)`
    height: 28px;
    ${(props) => props.theme.fonts['13px Medium']};
`;

const StyledLogoutBtn = styled(Button)`
    border: 1px solid ${(props) => props.theme.colors.neutral['60']};
`;

const WarningSign = styled(Icons.Alert)`
    color: ${({ theme }) => theme.colors.accents.yellow['10']};
`;
