query getPlans($filter: PlanFilter, $limit: Int, $offset: Int) {
  getPlans(filter: $filter, limit: $limit, offset: $offset) {
    plans {
      id
      name
      description
      beginDate
      expireDate
      createdBy
      createdDate
      updatedBy
      updatedDate
      location
      type {id, txt}
      network
      state {id, txt}
      parentplan {id, name}
    }
  }
}
