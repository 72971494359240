import {
    ProfileDiagramRpcResponse,
    ProfileDiagramRpcArgs,
    GetProfileDiagramRpcQuery,
} from '@generated';
import { sendGraphQLRequest } from '@utils';
import GetProfileDiagramRPC from './queries/GetProfileDiagramRPC.graphql';

/**
 * Get profile diagram RPC.
 * @returns {Promise<ProfileDiagramRpcResponse>} The profile diagram rpc response.
 * @throws {Error} If the request fails.
 */
export const getProfileDiagramRPC = async (
    args: ProfileDiagramRpcArgs,
): Promise<ProfileDiagramRpcResponse> => {
    try {
        const response = await sendGraphQLRequest<GetProfileDiagramRpcQuery>(
            GetProfileDiagramRPC,
            {
                input: args,
            },
        );

        if (!response.data.getProfileDiagramRPC?.data) {
            throw Error('No profile diagram rpc data returned');
        }

        return response.data.getProfileDiagramRPC;
    } catch (error) {
        throw Error('Failed profile diagram rpc: ' + error);
    }
};
