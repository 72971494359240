import { Button, Icons, SideMenu, styled, useIsMobile } from '@keypro/2nd-xp';
import { useLeftMenu, useMobileMenu } from '@stores';

const FullwidthSideMenu = styled(SideMenu)`
    width: 100%;
    border-radius: 8px;
`;

const SideMenuHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    ${({ theme }) => theme.fonts['18px Bold']};
    font-weight: 700;
    max-height: 32px;

    svg {
        width: 16px;
        height: 16px;

        path {
            stroke: ${({ theme }) => theme.colors.neutral['90']};
        }
    }
`;

/**
 * The props of InfoObjectSetting component.
 * @param title The title of the setting.
 * @param children The children of the setting.
 */
export interface ResponsiveSideMenuProps {
    title?: string;
    children?: React.ReactNode;
}
export const ResponsiveSideMenu = ({
    title,
    children,
}: ResponsiveSideMenuProps) => {
    const isMobile = useIsMobile();
    const Component = isMobile ? FullwidthSideMenu : SideMenu;
    const menuContext = isMobile ? useMobileMenu : useLeftMenu;
    const { toggleMenu } = menuContext();

    return (
        <Component data-testid={`navigationbar-menu-${title}`}>
            {title && (
                <SideMenuHeader>
                    {title}
                    <Button
                        kind="ghost"
                        onClick={() => toggleMenu()}
                        data-testid="navigationbar-menu-closeBtn"
                    >
                        <Icons.Cross />
                    </Button>
                </SideMenuHeader>
            )}
            {children}
        </Component>
    );
};

/**
 * Placeholder menu for buttons in the navigation bar.
 */
export const DummyLeftMenu = (): JSX.Element => {
    const isMobile = useIsMobile();
    const menuContext = isMobile ? useMobileMenu : useLeftMenu;
    const { menuContentId } = menuContext();
    return (
        <ResponsiveSideMenu title={`${menuContentId} WIP`}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                To be implemented...
            </div>
        </ResponsiveSideMenu>
    );
};
