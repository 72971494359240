import { Node } from './Node';
import { DiagramData, DiagramOptions } from '../types';

export class Cable extends Node {
    fillColor: string = '#0000FF';

    constructor(options: DiagramOptions, data: DiagramData) {
        super(options, data);
        this.textColor = this.getFontStateColor(data['state']);
    }

    render() {
        const cableCircle = this.paper
            .circle(this.cx, this.cy, this.radius)
            .attr({
                stroke: this.lineColor,
                fill: this.fillColor,
                'stroke-width': this.lineWidth,
            });
        this.elemSet.push(cableCircle);

        if (this.objectLabel) {
            this.labelElem = this.paper
                .text(
                    this.cx + this.radius,
                    this.cy - this.radius,
                    this.objectLabel,
                )
                .attr({
                    'font-size': this.fontSize,
                    'text-anchor': 'start',
                    fill: this.textColor,
                });
            this.elemSet.push(this.labelElem);
        }

        super.render();
    }

    getFontStateColor(state: number) {
        return state === 2 ? '#FF0000' : '#000000';
    }
}
