// Libraries
import { create } from 'zustand';

// Types
import { TxtConstant } from '@generated';

export interface CoordinatesSystemState {
    selectedCoordinatesSystem?: TxtConstant;
    isPickLocationEnabled?: boolean;
    setSelectedCoordinatesSystem: (
        selectedCoordinatesSystem: TxtConstant,
    ) => void;
    setIsPickLocationEnabled: (isPickLocationEnabled: boolean) => void;
}

const createCoordinatesSystemStore = () =>
    create<CoordinatesSystemState>((set) => ({
        selectedCoordinatesSystem: undefined,
        isPickLocationEnabled: false,
        setSelectedCoordinatesSystem: (
            selectedCoordinatesSystem: TxtConstant,
        ) => {
            set({
                selectedCoordinatesSystem,
            });
        },
        setIsPickLocationEnabled: (isPickLocationEnabled: boolean) => {
            set({
                isPickLocationEnabled,
            });
        },
    }));

export const useCoordinatesSystem = createCoordinatesSystemStore();
