import { InfoToolResult, SearchResult } from '@generated';
import { MapController } from '@keypro/2nd-xp';
import { SearchState } from '@stores';
import { StoreApi, UseBoundStore } from 'zustand';
import { getDistance } from 'ol/sphere';
import { getGenericLabel, isEmpty } from '@form-configs';

/**
 * Toggles the selected object type in the search store.
 * @param searchStore The search store.
 * @param objectTypeValue The object type value.
 */
export const toggleSelectedObjectType = (
    searchStore: UseBoundStore<StoreApi<SearchState>>,
    objectTypeValue: string,
) => {
    searchStore.setState((state) => ({
        selectedObjectType:
            state.selectedObjectType === objectTypeValue ? '' : objectTypeValue,
        isUsingAdvancedSearch: false,
        selectedAdvancedFilters: {},
    }));
};

export const coordinateRegex = /^(-?\d+(\.\d+)?),\s*(-?\d+(\.\d+)?)$/;

type ResultDistance = [number | null, InfoToolResult];

/**
 * Sorts search results by distance to the given coordinates.
 * @param results Search results.
 * @param coordinates Coordinates to sort by.
 * @param searchDiameter Optional search diameter. If provided, only results within this distance will be included.
 * @returns Sorted search results.
 */
export const sortResultsByDistance = (
    mapController: MapController,
    results: InfoToolResult[],
    coordinates: number[],
    searchDiameter?: number,
): InfoToolResult[] => {
    const resultsByDistance: ResultDistance[] = results.map((result) => {
        if (!result.location) {
            return [null, result];
        }

        const geometry = mapController.wktToGeometry(result.location);
        const point1 = mapController.convertToWSG84(coordinates);
        const point2 = mapController.convertToWSG84(
            geometry.getClosestPoint(coordinates),
        );

        return [getDistance(point1, point2), result];
    });

    resultsByDistance.sort((a: ResultDistance, b: ResultDistance) => {
        if (a[0] == null || b[0] == null) {
            return 0;
        }

        return a[0] - b[0];
    });

    const searchDistance = searchDiameter ?? Infinity;

    return resultsByDistance
        .filter((result) => result[0] != null && result[0] <= searchDistance)
        .map((result) => result[1]);
};

/**
 * Converts an InfoTool result to a search result.
 * @param result InfoTool result.
 * @returns Search result.
 */
export const convertInfoToolResultToSearchResult = (
    result: InfoToolResult,
): SearchResult => {
    return {
        ...result,
        id: result.pk ? parseInt(result.pk) : null,
        modelName: result.model,
        identification: isEmpty(result.identification)
            ? getGenericLabel(result.model, result.pk!)
            : result.identification,
    } as SearchResult;
};
