import {
    ErrorBoundary as SentryErrorBoundary,
    ErrorBoundaryProps as SentryErrorBoundaryProps,
} from '@sentry/react';

export type ErrorBoundaryProps = SentryErrorBoundaryProps;

/**
 * Error boundary component with Sentry integration.
 */
export const ErrorBoundary = SentryErrorBoundary;
