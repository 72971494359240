import { getSewerPumpingStations } from '@apis/keyduct';
import { FormConfig } from '../types';
import { SewerPumpingStationIcon } from './icons';

const config: FormConfig = {
    gqlType: 'SewerPumpingStation',
    model: 'SnPumpingstation',
    modelAliases: ['snpumpingstation'],
    icon: <SewerPumpingStationIcon />,
    groups: [
        {
            name: 'generalInformation',
            fields: ['name', 'productivity'],
        },
    ],
    functions: {
        get: getSewerPumpingStations,
    },
    queryCollection: 'sewerPumpingStations',
};

export default config;
