import {
    QuickPrintRpcResponse,
    QuickPrintRpcArgs,
    QuickPrintRpcQuery,
} from '@generated';
import { sendGraphQLRequest } from '@utils';
import QuickPrintRPC from './queries/QuickPrintRPC.graphql';

/**
 * Quick print RPC.
 * @returns {Promise<QuickPrintRpcResponse>} The quick print rpc response.
 * @throws {Error} If the request fails.
 */
export const quickPrintRPC = async (
    args: QuickPrintRpcArgs,
): Promise<QuickPrintRpcResponse> => {
    try {
        const response = await sendGraphQLRequest<QuickPrintRpcQuery>(
            QuickPrintRPC,
            {
                input: args,
            },
        );

        if (!response.data.quickPrintRPC) {
            throw Error('No quick print rpc data returned');
        }

        return response.data.quickPrintRPC;
    } catch (error) {
        throw Error('Failed quick print rpc: ' + error);
    }
};
