import { TextsData } from '@generated';
import { DiagramOptions } from '../types';
import { RaphaelPaper, RaphaelElement, RaphaelSet } from 'raphael';

export class Text {
    options: DiagramOptions;
    paper: RaphaelPaper;

    x: number;
    y: number;
    angle: number;

    label: string = '';
    fontSize: number = 250;
    textColor: string = '#0000FF';
    elemSet: RaphaelSet;

    /**
     * label elment
     */
    labelElem: RaphaelElement | null = null;

    /**
     * Current transform string
     */
    transformStr: string = '';

    constructor(options: DiagramOptions, data: TextsData) {
        this.options = options;
        this.paper = options.paper;
        this.elemSet = this.paper.set();

        this.label = data.label ?? '';
        this.x = data.x;
        this.y = data.y;
        this.angle = data.angle;

        if (this.angle !== 0) {
            this.transformStr = 'R' + [this.angle, this.x, this.y].join(',');
        }
    }

    render() {
        this.labelElem = this.paper.text(this.x, this.y, this.label).attr({
            fill: this.textColor,
            'font-size': this.fontSize,
            'text-anchor': 'start',
        });
        this.elemSet.push(this.labelElem);

        if (this.transformStr !== '') {
            this.elemSet.transform(this.transformStr);
        }
    }

    getLabel() {
        return this.labelElem!.attr('text');
    }

    getAngle() {
        return this.angle;
    }

    getXY() {
        return [this.x, this.y];
    }

    getElemSet() {
        return this.elemSet;
    }

    getLabelElem() {
        return this.labelElem;
    }
}
