import { useAuth0, User } from '@auth0/auth0-react';
import { AuthMethodHook } from './useAuth';
import { useEffect } from 'react';
import { graphqlInstance } from '@utils';
import { logout as logoutRequest } from '@apis/user';

/**
 *  Hook that provides the user's authentication state and user data to the application,
 *  as well as functions to log the user in and out.
 *  It uses the Auth0 to manage the user's authentication state and user data.
 * @returns AuthMethodHook
 */
export const useAuth0Auth = (): AuthMethodHook => {
    const {
        isAuthenticated,
        isLoading,
        user: auth0User,
        loginWithRedirect,
        logout: auth0Logout,
        getAccessTokenSilently,
    } = useAuth0();

    const user: User = {
        name: auth0User?.name,
        email: auth0User?.email,
        picture: auth0User?.picture,
        initials: (() => {
            // use name if available
            if (auth0User?.name) {
                const names = auth0User.name.split(' ');
                // check if there are two names
                if (names.length >= 2) {
                    return names[0][0] + names[1][0];
                } else {
                    // return the first two letters of the first name
                    return names[0].slice(0, 2);
                }
            }
            // check if nickname is available
            else if (auth0User?.nickname) {
                return auth0User.nickname.slice(0, 2);
            } else {
                // return the first two letters of the email
                return auth0User?.email?.slice(0, 2);
            }
        })(),
        permissions: {
            edit: false,
            view: true,
        },
    };

    const login = async () => {
        loginWithRedirect();
    };

    const logout = async () => {
        await logoutRequest();
        await auth0Logout({
            logoutParams: { returnTo: window.location.origin },
        });
    };

    useEffect(() => {
        const token = async () => {
            const accessToken = await getAccessTokenSilently();
            graphqlInstance.defaults.headers.Authorization = `Bearer ${accessToken}`;
        };
        if (isAuthenticated) token();
    }, [getAccessTokenSilently, isAuthenticated]);

    return {
        isAuthenticated,
        isLoading,
        user,
        login,
        logout,
    };
};
