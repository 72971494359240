import { GetDhConduitsQuery, DhConduit } from '@generated';
import GetDhConduits from './queries/GetDhConduits.graphql';
import { sendGraphQLRequest } from '@utils';
import { GraphQLFilter, isFilterEmpty } from '@apis/utils';

/**
 * Gets the district heating conduits.
 * @param filter The GraphQL filter to apply to the query.
 * @returns The district heating conduits.
 * @throws {Error} If the request fails.
 */
export const getDhConduits = async (filter: GraphQLFilter) => {
    try {
        const payload = !isFilterEmpty(filter)
            ? { filter: filter }
            : { filter: null, limit: 1 };
        const response = await sendGraphQLRequest<GetDhConduitsQuery>(
            GetDhConduits,
            payload,
        );

        if (!response.data.getDhConduits) {
            throw Error('No data returned');
        }

        return response.data.getDhConduits.dhConduits?.filter(
            Boolean,
        ) as DhConduit[];
    } catch (error) {
        throw Error('Failed to get district heating conduits: ' + error);
    }
};
