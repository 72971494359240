// Libraries
import { Dispatch, RefObject, SetStateAction } from 'react';
import Polygon from 'ol/geom/Polygon';
import { Coordinate } from 'ol/coordinate';
import proj4 from 'proj4';

// Form config utils
import {
    FormGroup,
    getFormConfig,
    createComboField,
    createRangeField,
    getTranslatedPluralTitle,
    getTypeByModel,
} from '@form-configs';

// Apis
import { GraphQLTypeMap } from '@apis/introspection';

// Keycore form configs
import FreeAreaConfig from '../form-configs/keycore/FreeArea';
import AddressConfig from '../form-configs/keycore/Address';
import FreeLineConfig from '../form-configs/keycore/FreeLine';

// Keycom form configs
import TelecomPremiseConfig from '../form-configs/keycom/TelecomPremise';
import CableConfig from '../form-configs/keycom/Cable';
import SpliceConfig from '../form-configs/keycom/Splice';
import ManholeConfig from '../form-configs/keycom/Manhole';
import ConduitConfig from '../form-configs/keycom/Conduit';

// Keyduct form configs
import ConsumerPoint from '../form-configs/keyduct/ConsumerPoint';
import SewerDuctConfig from '../form-configs/keyduct/SewerDuct';
import WaterDuctConfig from '../form-configs/keyduct/WaterDuct';
import SewerBranchConfig from '../form-configs/keyduct/SewerBranch';
import WaterBranchConfig from '../form-configs/keyduct/WaterBranch';
import SewerValveConfig from '../form-configs/keyduct/SewerValve';
import WaterValveConfig from '../form-configs/keyduct/WaterValve';
import SewerManholeConfig from '../form-configs/keyduct/SewerManhole';
import SewerPumpingStationConfig from '../form-configs/keyduct/SewerPumpingStation';
import WaterPumpingStationConfig from '../form-configs/keyduct/WaterPumpingStation';
import DhConduitConfig from '../form-configs/keyduct/DhConduit';

// Generated
import { ProductType, TxtConstant } from '@generated';

/**
 * Adds a click event listener to the document that deactivates the component
 * when clicking outside of it. Should be used in a useEffect hook.
 * @param ref The reference to the component
 * @param setActive The setter for the active state
 * @param isActive The active state
 * @returns The cleanup function for useEffect
 */
export const handleOutsideClick = (
    ref: RefObject<HTMLElement | null>,
    setActive: Dispatch<SetStateAction<boolean>> | ((active: boolean) => void),
    isActive: boolean,
) => {
    const onClick = (event: MouseEvent) => {
        if (ref.current && !ref.current.contains(event.target as Node)) {
            setActive(false);
        }
    };

    if (isActive) {
        document.addEventListener('click', onClick);
    } else {
        document.removeEventListener('click', onClick);
    }

    return () => {
        document.removeEventListener('click', onClick);
    };
};

/**
 * Returns the icon for the given layer name.
 * @param layerName Name of the layer
 */
export const infoToolLayers = {
    freearea: 'freearea',
    address: 'keygwt_address',
    telecompremise: 'prem',
    cable: 'cable',
    splice: 'splice',
    freeline: 'freeline',
    manhole: 'manhole',
    conduit: 'conduit',
};

/**
 * Returns the translated search fields for the given product type.
 * @param productType Product type
 * @returns Translated search field options
 */
export const getTranslatedSearchFields = (
    productType: ProductType | undefined,
) => {
    const filteredModels = getSearchFilterConfigByProduct(productType);
    return filteredModels.map((searchFilterFormConfigModel) => {
        const formConfigGqlType = getTypeByModel(searchFilterFormConfigModel);
        return {
            value: searchFilterFormConfigModel,
            label: getTranslatedPluralTitle(formConfigGqlType),
        };
    });
};

/**
 * Returns the list of form config models to be used as search filters for the given product type.
 */
const getSearchFilterConfigByProduct = (
    productType: ProductType | undefined,
) => {
    switch (productType) {
        case ProductType.Keycom:
            return [
                FreeAreaConfig.model,
                AddressConfig.model,
                FreeLineConfig.model,
                TelecomPremiseConfig.model,
                CableConfig.model,
                SpliceConfig.model,
                ManholeConfig.model,
                ConduitConfig.model,
            ];
        case ProductType.Keyaqua:
            return [
                AddressConfig.model,
                FreeAreaConfig.model,
                ConsumerPoint.model,
                DhConduitConfig.model,
                SewerDuctConfig.model,
                WaterDuctConfig.model,
                SewerBranchConfig.model,
                WaterBranchConfig.model,
                SewerManholeConfig.model,
                SewerValveConfig.model,
                WaterValveConfig.model,
                SewerPumpingStationConfig.model,
                WaterPumpingStationConfig.model,
                FreeLineConfig.model,
            ];
        default:
            return [];
    }
};

/**
 * Creates a rectangular polygon around the given point.
 * @param center Center of the polygon.
 * @param size Desired size of the polygon.
 * @returns Polygon around the given point.
 */
export const pointToPolygon = (center: Coordinate, size: number): Polygon => {
    const [x, y] = center;
    const offset = size / 2;
    const coords = [
        [x - offset, y - offset],
        [x + offset, y - offset],
        [x + offset, y + offset],
        [x - offset, y + offset],
        [x - offset, y - offset],
    ];

    return new Polygon([coords]);
};

export const getFilteredConfig = (
    selectedObjectType: string,
    selectedGroups: string[],
    type: string | null,
    getFieldType: (field: string) => string,
) => {
    const groups: FormGroup[] = [];
    if (type && selectedObjectType) {
        const newGroup: FormGroup = {
            name: type,
            fields: [],
        };
        getFormConfig(type).groups.forEach((group) => {
            group.fields.forEach((field) => {
                if (
                    typeof field === 'string' &&
                    selectedGroups.includes(field)
                ) {
                    const componentType = getFieldType(field);
                    if (componentType === 'text') {
                        newGroup.fields.push(field);
                    } else if (componentType === 'number') {
                        newGroup.fields.push(createRangeField(field));
                    }
                } else if (
                    typeof field !== 'string' &&
                    selectedGroups.includes(field.name)
                ) {
                    const componentType = getFieldType(field.name);
                    if (componentType === 'combobox') {
                        newGroup.fields.push(
                            createComboField(
                                field.name,
                                field.filter?.groupname.eq as string,
                                true,
                            ),
                        );
                    } else {
                        newGroup.fields.push(field);
                    }
                }
            });
        });
        groups.push(newGroup);
    }
    return groups;
};

export const getType = (gqlType: string, types: GraphQLTypeMap) => {
    if (!(gqlType in types)) {
        throw new Error(`Type not found: ${gqlType}`);
    }
    return types[gqlType];
};

/**
 * Converts an array of objects to a dictionary with the object's id as the key.
 * @param arr Array of objects with an id property
 * @returns Dictionary with the object's id as the key
 */
export const reduceById = <T extends { id: string }>(
    arr: T[],
): { [key: string]: T } =>
    arr.reduce(
        (acc, item) => {
            acc[item.id] = item;
            return acc;
        },
        {} as { [key: string]: T },
    );

/**
 * Returns the decimal precision for the selected coordinate system.
 * @param selectedCoordinatesSystem
 * @returns 2 decimals (1 cm precision) and degrees with 6 decimals (10cm precision)
 */
export const getCoordinateSystemDecimalPrecision = (
    selectedCoordinatesSystem: TxtConstant | undefined,
) => {
    const projString = proj4.defs(selectedCoordinatesSystem?.txtValue ?? '');
    if (projString?.units === 'm') {
        return 2;
    } else if (projString?.units === 'degrees') {
        return 6;
    }
    return 2;
};
