import {
    styled,
    Checkbox,
    SelectOptionItem,
    LoadingMask,
} from '@keypro/2nd-xp';
import { useEffect, useState } from 'react';

const StyledRadioGroup = styled.div`
    display: flex;
    gap: 24px;
    align-items: center;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;

    span {
        display: flex;
        gap: 8px;
        align-items: center;
    }
`;

/**
 * Props for LazyRadioGroup.
 */
export interface LazyRadioGroupProps {
    /** Name of the checkbox components in the group. */
    name: string;
    /** The value of the Select component */
    initialValue?: string;
    /** Function that returns the options for Select component */
    queryFunction: () => Promise<SelectOptionItem[]>;
    /** Function for handling state changes */
    onChange?: (value: string) => void;
}

const Container = styled.div`
    position: relative;
`;

/**
 * Radio button group that lazily fetches the options using the provided query function.
 */
const LazyRadioGroup = (props: LazyRadioGroupProps): JSX.Element => {
    const { name, initialValue, queryFunction, onChange } = props;
    const [value, setValue] = useState<string>('');
    const [options, setOptions] = useState<SelectOptionItem[]>([]);

    useEffect(() => {
        queryFunction().then((options) => {
            setOptions(options);

            if (options.length === 0) {
                throw new Error('No options returned from query function');
            }

            setValue(initialValue ?? '');
        });
    }, [queryFunction, initialValue]);

    return (
        <Container>
            {options.length === 0 && <LoadingMask iconSize={24} />}
            <StyledRadioGroup>
                {options.map((option) => (
                    <span key={option.value}>
                        <Checkbox
                            name={name}
                            id={`${name}-${option.value}`}
                            type="radio"
                            checked={value === option.value}
                            inputProps={{
                                onClick: () => {
                                    setValue(option.value);
                                    onChange?.(option.value);
                                },
                            }}
                        />
                        <label htmlFor={`${name}-${option.value}`}>
                            {option.label}
                        </label>
                    </span>
                ))}
            </StyledRadioGroup>
        </Container>
    );
};

export default LazyRadioGroup;
