// Component library
import { styled, Button, Select, Input, Checkbox } from '@keypro/2nd-xp';

export const StyledCoordinatesTool = styled.div<{ $isOpenTool?: boolean }>`
    box-sizing: border-box;
    border-radius: 8px;
    padding-top: 8px;
    padding-bottom: 8px;
    position: absolute;
    background-color: ${(props) => props.theme.colors.neutral['10']};
    border: 1px solid ${(props) => props.theme.colors.neutral['50']};
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
    width: 300px;
    bottom: 60px;
    right: -4px;
    z-index: 1;
    display: ${(props) => (props.$isOpenTool ? 'block' : 'none')};
`;
export const StyledHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 16px;
    padding-right: 8px;
    padding-bottom: 8px;
`;
export const StyledTitle = styled.span`
    font: ${(props) => props.theme.fonts['16px Bold']};
    color: ${(props) => props.theme.colors.neutral['100']};
`;
export const StyledCloseBtn = styled(Button)`
    color: ${(props) => props.theme.colors.neutral['90']};
    width: 32px;
    height: 32px;
    & > svg {
        width: 16px;
        height: 16px;
    }
`;
export const StyledBody = styled.div`
    padding-left: 16px;
    padding-right: 16px;
`;
export const StyledSystem = styled.div`
    padding-top: 14px;
    padding-bottom: 14px;
`;
export const StyledSystemLabel = styled.div`
    font: ${(props) => props.theme.fonts['14px Bold']};
    color: ${(props) => props.theme.colors.neutral['100']};
    padding-bottom: 8px;
`;
export const StyledSystemSelect = styled(Select)`
    width: 100%;
`;
export const StyledLocation = styled.div`
    padding-top: 16px;
    padding-bottom: 16px;
    display: grid;
    gap: 8px;
`;
export const StyledSystemLabelBtn = styled.div`
    display: flex;
    justify-content: space-between;
`;
export const StyledCommonTitle = styled.div`
    padding-top: 6px;
    padding-bottom: 6px;
    font: ${(props) => props.theme.fonts['14px Bold']};
    color: ${(props) => props.theme.colors.neutral['100']};
`;
export const StyledLocationBtn = styled(Button)`
    & > svg {
        color: ${(props) => props.theme.colors.accents.blue['10']};
        width: 16px;
        height: 16px;
    }
`;
export const StyledLocationTextField = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
export const StyledLocationInputLabel = styled.span`
    font: ${(props) => props.theme.fonts['14px Regular']};
    color: ${(props) => props.theme.colors.neutral['90']};
`;
export const StyledLocationInput = styled(Input)`
    width: 192px;
    & svg {
        cursor: pointer;
        color: ${(props) => props.theme.colors.neutral['70']};
        &:hover {
            color: ${(props) => props.theme.colors.neutral['90']};
        }
    }
`;
export const StyledInfo = styled.div`
    padding-top: 16px;
    padding-bottom: 16px;
`;
export const StyledCoordinatesInfo = styled.div`
    display: flex;
    gap: 16px;
`;
export const StyledCoordinates = styled.div`
    display: flex;
    gap: 8px;
`;
export const StyledCoordinatesCaption = styled.div`
    font: ${(props) => props.theme.fonts['14px Bold']};
    color: ${(props) => props.theme.colors.neutral['80']};
`;
export const StyledCoordinatesValue = styled.div`
    font: ${(props) => props.theme.fonts['14px Regular']};
    color: ${(props) => props.theme.colors.neutral['90']};
`;
export const StyledShowCursor = styled.div`
    padding-bottom: 16px;
`;
export const StyledShowCursorCheckBox = styled.div`
    display: flex;
    gap: 12px;
    align-items: center;
    cursor: pointer;
`;
export const StyledCheckBox = styled(Checkbox)`
    border-radius: 4px;
    width: 16px;
    height: 16px;
`;
export const StyledCheckBoxTitle = styled.span`
    font: ${(props) => props.theme.fonts['14px Regular']};
    color: ${(props) => props.theme.colors.neutral['100']};
    padding-top: 6px;
    padding-bottom: 6px;
`;
export const StyledShowCursorDesc = styled.div`
    font: ${(props) => props.theme.fonts['12px Regular']};
    color: ${(props) => props.theme.colors.neutral['80']};
    padding-left: 32px;
`;
export const StyledFooter = styled.div`
    padding: 16px 16px 8px 16px;
    display: flex;
    gap: 8px;
`;
export const StyledFooterButton = styled(Button)`
    width: 130px;
`;
export const StyledDivider = styled.div`
    height: 2px;
    background-color: ${(props) => props.theme.colors.neutral['20']};
    width: 100%;
`;
