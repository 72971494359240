import { getSewerValves } from '@apis/keyduct';
import { FormConfig } from '../types';
import { SewerValveIcon } from './icons';

const config: FormConfig = {
    gqlType: 'SewerValve',
    model: 'SnSewerValve',
    modelAliases: ['snsewervalve'],
    icon: <SewerValveIcon />,
    groups: [
        {
            name: 'generalInformation',
            fields: ['feature'],
        },
    ],
    functions: {
        get: getSewerValves,
    },
    queryCollection: 'sewerValves',
};

export default config;
