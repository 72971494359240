// Libraries
import { useEffect } from 'react';

// Component Library
import { useIsMobile } from '@keypro/2nd-xp';

// Stores
import { useLeftMenu, useRightMenu, useCenterMenu } from '@stores';

// Components
import AppMobileMenus from './AppMobileMenu';
import { OrientationTools } from '../OrientationTools/OrientationTools';
import AppFooter from '../AppFooter/AppFooter';

// Constants
import { COMPASS_RIGHT_OFFSET } from '../AppMap/AppMap.consts';

// Styles
import {
    StyledRightMenuContainer,
    StyledToolsWrapper,
    StyledOrientationToolsWrapper,
    StyledMenuContainer,
    StyledSideMenuContainer,
    StyledCenterMenuContainer,
} from './AppMenus.styles';

/**
 * The AppMenus component is a container for the left, center, and right menus.
 * It has prefixed position and dimensions for each menu, and depending on context provided by MenuProvider, it renders the menu content.
 * @returns The AppMenus component
 */
const AppMenus = (): JSX.Element => {
    const leftMenuContext = useLeftMenu();
    const centerMenuContext = useCenterMenu();
    const rightMenuContext = useRightMenu();
    const isMobile = useIsMobile();

    /**
     * Workaround to position the compass element when the right menu is open/closed.
     */
    useEffect(() => {
        if (rightMenuContext.menuWidth) {
            const rightPosition = rightMenuContext.isMenuOpen
                ? rightMenuContext.menuWidth + COMPASS_RIGHT_OFFSET
                : COMPASS_RIGHT_OFFSET;
            const compass = document.querySelector('.ol-rotate-reset');
            compass?.setAttribute('style', `right: ${rightPosition}px`);
        }
    }, [rightMenuContext.menuWidth, rightMenuContext.isMenuOpen]);

    if (isMobile) return <AppMobileMenus />;

    const rightMenuDisplayStyle = rightMenuContext.isMenuOpen ? 'flex' : 'none';

    const isRightMenuToolsContainerVisible = !centerMenuContext.isMenuOpen;

    const rightMenuToolsContainerDisplayStyle = isRightMenuToolsContainerVisible
        ? 'flex'
        : 'none';

    return (
        <StyledMenuContainer id="menu-container">
            {leftMenuContext.isMenuOpen ? (
                <StyledSideMenuContainer id="left-menu">
                    {leftMenuContext.menuContent}
                </StyledSideMenuContainer>
            ) : null}
            <StyledCenterMenuContainer id="center-menu">
                {centerMenuContext.isMenuOpen
                    ? centerMenuContext.menuContent
                    : null}
            </StyledCenterMenuContainer>
            <StyledRightMenuContainer>
                <StyledToolsWrapper
                    $display={rightMenuToolsContainerDisplayStyle}
                >
                    <StyledOrientationToolsWrapper>
                        <OrientationTools />
                    </StyledOrientationToolsWrapper>
                    <AppFooter />
                </StyledToolsWrapper>
                <StyledSideMenuContainer
                    id="right-menu"
                    style={{ display: rightMenuDisplayStyle }}
                >
                    {rightMenuContext.isMenuOpen
                        ? rightMenuContext.menuContent
                        : null}
                </StyledSideMenuContainer>
            </StyledRightMenuContainer>
        </StyledMenuContainer>
    );
};

export default AppMenus;
