// Libraries
import { css } from 'styled-components';

// Component Library
import { styled } from '@keypro/2nd-xp';

// Constants
import {
    COMPASS_HEIGHT,
    ORIENTATION_TOOLS_TOP_MARGIN,
    COMPASS_RIGHT_OFFSET,
} from './AppMap.consts';

export const StyledLoading = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    svg {
        width: 75px;
        height: 75px;
    }
`;

export const mapContainerStyles = css`
    top: ${ORIENTATION_TOOLS_TOP_MARGIN}px;
    right: ${COMPASS_RIGHT_OFFSET}px;

    & .ol-compass {
        width: 48px;
        height: ${COMPASS_HEIGHT}px;

        & > rect {
            fill: #111415;
        }
    }
`;

export const mapMobileContainerStyles = css`
    display: none;
`;
