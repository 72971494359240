// Libraries
import { t } from 'i18next';
import { useContext, useEffect, useRef, useState } from 'react';

// Component Library
import {
    Button,
    Icons,
    MapContext,
    styled,
    TabSelector,
    LoadingMask,
    Coordinates,
    PositionOverlay,
    useIsMobile,
    ResizableSideMenu,
} from '@keypro/2nd-xp';

// Components
import { ActionsContainer } from './InfoObjectActions';
import { AdditionalInfo } from './AdditionalInfo';
import { ResponsiveSideMenu } from '@components/AppNavigationBar/NavigationBarCommon';
import {
    useAsyncError,
    FormBuilder,
    getData,
    handleOutsideClick,
} from '@components';

// Stores
import { useMobileMenu, useRecentObjects, useRightMenu } from '@stores';

// Form configs
import {
    FormData,
    getFormConfig,
    getLabel,
    getTranslatedTitle,
    getTypeByModel,
    getWKT,
    isModelSupported,
} from '@form-configs';
import { getTenant } from '@utils';

// Hooks
import { useResponsivePan } from '@hooks/map';

// Consts
import { RESIZABLE_CONTAINER_MAX_WIDTH } from './InfoObjectContainer.consts';
import { AuthContext } from '@providers';

/**
 * The props of InfoObjectContainer component.
 */
export interface InfoObjectContainerProps {
    /** Info object model name */
    model: string;
    /** Info object id */
    id: string;
}
interface ExtendedHTMLAttributes extends React.HTMLAttributes<HTMLDivElement> {
    'data-testid'?: string;
}

/**
 * The InfoObjectContainer component displays object container.
 * @param model The model of the object.
 * @returns
 */
export const InfoObjectContainer = (props: InfoObjectContainerProps) => {
    const { model, id } = props;
    const mapController = useContext(MapContext)!;
    const isMobile = useIsMobile();
    const { toggleMenu: toggleRightMenu, setMenuWidth: setRightMenuWidth } =
        useRightMenu();
    const { restorePreviousMenu } = useMobileMenu();
    const [isActionMenuOpen, setIsActionMenuOpen] = useState(false);
    const [data, setData] = useState<FormData | undefined>(undefined);
    const [highlightPosition, setHighlightPosition] = useState<number[]>([
        0, 0,
    ]);
    const [isHighlighted, setIsHighlighted] = useState(false);
    const { addRecentObject } = useRecentObjects();
    const actionsRef = useRef<HTMLDivElement>(null);
    const responsivePan = useResponsivePan(mapController);
    const throwError = useAsyncError();
    const { user } = useContext(AuthContext);

    const resizableDivRef = useRef(null);

    const width = localStorage.getItem(
        `object-preview-width${getTenant(true)}`,
    );

    let type = null;
    let title;
    let form;
    let wkt = null;

    if (isModelSupported(model)) {
        type = getTypeByModel(model);
        form = getFormConfig(type);
        title = <>{getTranslatedTitle(type, model)}</>;

        if (data) {
            wkt = getWKT(data);
        }
    } else {
        console.error(`Unsupported model: ${model}`);
        title = <ErrorTitle>{t('unsupportedObjectType')}</ErrorTitle>;
    }

    useEffect(() => {
        if (!resizableDivRef.current) return;

        const observer = new ResizeObserver((entries) => {
            for (const entry of entries) {
                const width = entry.contentRect.width;
                if (width <= RESIZABLE_CONTAINER_MAX_WIDTH) {
                    setRightMenuWidth?.(entry.contentRect.width);
                    localStorage.setItem(
                        `object-preview-width${getTenant(true)}`,
                        entry.contentRect.width.toString(),
                    );
                }
            }
        });

        observer.observe(resizableDivRef.current);

        return () => observer.disconnect();
    }, [setRightMenuWidth]);

    useEffect(() => {
        if (type) {
            setIsHighlighted(false);
            setData(undefined);

            getData(type, { id: { eq: id } })
                .then((results) => {
                    if (!results?.length) {
                        throwError(`No ${type} object found with id ${id}`);
                    } else if (results.length > 1) {
                        throwError(
                            `Multiple ${type} objects found with id ${id}`,
                        );
                    }

                    addRecentObject(
                        model,
                        parseInt(id),
                        getLabel(type, results[0], model),
                    );
                    setData(results[0]);
                })
                .catch((error) => {
                    throwError(error);
                });
        }
    }, [model, type, id, addRecentObject, throwError]);

    const locate = () => {
        if (!wkt) return;

        const geom = mapController.wktToGeometry(wkt);

        if (geom) {
            const extent = geom.getExtent();
            const center = [
                (extent[0] + extent[2]) / 2,
                (extent[1] + extent[3]) / 2,
            ];
            responsivePan(center);
            setIsHighlighted(true);
            setHighlightPosition(center);
        }
    };
    const handleClose = () => {
        const menuAction = isMobile ? restorePreviousMenu : toggleRightMenu;
        menuAction();
    };

    const Wrapper = isMobile ? ResponsiveSideMenu : StyledInfoObjectContainer;

    // Close the action menu when clicking outside of it
    useEffect(() => {
        return handleOutsideClick(
            actionsRef,
            setIsActionMenuOpen,
            isActionMenuOpen,
        );
    }, [actionsRef, isActionMenuOpen]);

    return (
        <Wrapper
            ref={resizableDivRef}
            {...(width && !isMobile && { width: parseInt(width) })}
            wrapperProps={
                {
                    style: { maxWidth: `${RESIZABLE_CONTAINER_MAX_WIDTH}px` },
                    'data-testid': 'info-object-container',
                } as ExtendedHTMLAttributes
            }
            sidebarProps={
                {
                    'data-testid': 'info-object-container-sidebar',
                } as ExtendedHTMLAttributes
            }
        >
            <InfoObjectHeader>
                <InfoObjectHeaderTitle>{title}</InfoObjectHeaderTitle>
                {type && (
                    <InfoObjectHeaderAdditionalInfo data-testid="info-object-additional-info">
                        <Icons.About />
                        {data && (
                            <AdditionalInfo
                                databaseId={data.id as string}
                                createdByName={data.createdBy as string}
                                createdByEmail={data.createdBy as string}
                                createdDate={
                                    (data.createdTs ??
                                        data.createdDate) as string
                                }
                                updatedByName={data.updatedBy as string}
                                updatedByEmail={data.updatedBy as string}
                                updatedDate={
                                    (data.updatedTs ??
                                        data.updatedDate) as string
                                }
                            />
                        )}
                    </InfoObjectHeaderAdditionalInfo>
                )}
                <InfoObjectHeaderButton
                    data-testid="info-object-right-menu-close-button"
                    kind="ghost"
                    onClick={handleClose}
                >
                    <Icons.Cross />
                </InfoObjectHeaderButton>
            </InfoObjectHeader>
            <InfoObjectContent>
                <InfoObjectActions>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '8px',
                        }}
                    >
                        <LocateButton
                            kind="secondary"
                            onClick={locate}
                            data-tooltip={t('locateAndHighlight')}
                            disabled={!wkt}
                            data-testid="info-object-locate-button"
                        >
                            <Icons.Locate2 />
                        </LocateButton>
                        <StyledInfoObjectActionsSaveEditBtn
                            label={t('saveEdits')}
                            kind="primary"
                            disabled={user?.permissions?.edit === false}
                        />
                    </div>
                    <StyledInfoObjectActionsMoreBtn ref={actionsRef}>
                        <Button
                            iconPosition="right"
                            label={t('actions')}
                            kind="secondary"
                            data-testid="info-object-actions-button"
                            onClick={() =>
                                setIsActionMenuOpen(!isActionMenuOpen)
                            }
                        >
                            <Icons.Arrow />
                        </Button>
                        {form && data && (
                            <ActionsContainer
                                isOpen={isActionMenuOpen}
                                toggleMenu={setIsActionMenuOpen}
                                form={form}
                                data={data}
                                data-testid="info-object-actions-menu"
                            />
                        )}
                    </StyledInfoObjectActionsMoreBtn>
                </InfoObjectActions>
                <InfoObjectTabs>
                    <InfoObjectTabSelector
                        options={[
                            { id: '1', label: t('information') },
                            {
                                id: '2',
                                label: t('attachments'),
                                disabled: true,
                            },
                        ]}
                        selected="1"
                    />
                </InfoObjectTabs>
                {type && data && (
                    <FormBuilder
                        data-testid={`form-${model}`}
                        model={model}
                        gqlType={type}
                        data={data}
                    />
                )}
            </InfoObjectContent>
            <PositionOverlay
                position={highlightPosition as Coordinates}
                positioning="bottom-center"
                style={{
                    display: isHighlighted ? 'block' : 'none',
                }}
            >
                <Icons.LocationMark style={{ width: 40, height: 40 }} />
            </PositionOverlay>
            {type && !data && <LoadingMask />}
        </Wrapper>
    );
};

const ErrorTitle = styled.span`
    color: ${(props) => props.theme.colors.accents.red[10]};
`;

const LocateButton = styled(Button)`
    color: ${(props) => props.theme.colors.neutral['90']};
}`;

const StyledInfoObjectContainer = styled(ResizableSideMenu)`
    & > div:first-child {
        padding: 12px;
    }
    overflow: visible;
    position: relative;
`;

const InfoObjectHeader = styled.div`
    display: flex;
`;

const InfoObjectHeaderAdditionalInfo = styled.div`
    display: flex;
    align-items: center;
    padding: 2px 6px;
    position: relative;
    cursor: pointer;
    z-index: 2;
    & > svg {
        width: 16px;
        height: 16px;
        color: ${(props) => props.theme.colors.neutral['90']};
    }
    &:hover {
        & > div {
            display: block;
        }
    }
`;

const InfoObjectHeaderTitle = styled.div`
    padding-left: 4px;
    padding-top: 6px;
    padding-bottom: 6px;
    ${(props) => props.theme.fonts['18px Bold']};
    color: ${(props) => props.theme.colors.neutral['100']};
`;

const InfoObjectHeaderButton = styled(Button)`
    margin-left: auto;
    padding: 0;
    width: 32px;

    & > svg {
        width: 16px;
        height: 16px;

        path {
            stroke: ${(props) => props.theme.colors.neutral[90]};
            transform: scale(1.14);
            transform-origin: center;
        }
    }
`;

const InfoObjectContent = styled.div`
    overflow-x: hidden;
    overflow-y: scroll;
    height: 100%;
    padding-left: 8px;
    padding-right: 8px;
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

const InfoObjectActions = styled.div`
    padding: 8px;
    display: flex;
    gap: 8px;
    justify-content: space-between;
`;

const StyledInfoObjectActionsSaveEditBtn = styled(Button)`
    width: 176px;
    ${(props) => props.theme.fonts['14px Medium']};
    color: ${(props) => props.theme.colors.neutral['100']};
`;

const StyledInfoObjectActionsMoreBtn = styled.div`
    position: relative;
    & > button {
        width: 92px;
        ${(props) => props.theme.fonts['14px Medium']};
        color: ${(props) => props.theme.colors.neutral['90']};
    }
`;

const InfoObjectTabs = styled.div`
    padding: 0 12px 0px 8px;
`;

const InfoObjectTabSelector = styled(TabSelector)`
    display: none;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
    & > button {
        color: ${(props) => props.theme.colors.neutral['90']};
        ${(props) => props.theme.fonts['13px Bold']};
    }
`;
