import { MapController, useToast } from '@keypro/2nd-xp';
import Style from 'ol/style/Style';
import { Fill, Circle, Stroke } from 'ol/style';
import { Feature } from 'ol';
import { useCallback, useEffect } from 'react';
import { Geometry, Polygon } from 'ol/geom';
import { t } from 'i18next';

/**
 * The layer name for the highlight layer.
 */
const HIGHLIGHT_LAYER = 'ui_highlight';

/**
 * The highlight style for the highlight feature.
 */
const highlightStyle = new Style({
    stroke: new Stroke({
        color: '#00FF00',
        width: 6,
    }),
    fill: new Fill({
        color: 'rgba(238, 153, 0, 0.4)',
    }),
    image: new Circle({
        radius: 6,
        fill: new Fill({ color: 'rgba(238, 153, 0, 0.4)' }),
        stroke: new Stroke({
            color: '#00FF00',
            width: 3,
        }),
    }),
});

interface UseHighlightMapObjectResult {
    /**
     * Highlights a map object on the map.
     * @param location The location of the map object to highlight.
     */
    highlightMapObject: (geometry: Geometry, objItemId?: string) => void;
}

/**
 * Custom hook to return `highlightMapObject` function that highlight a map object.
 * @param mapController The map controller managing the map view.
 * @param isContainerOpen Whether the container calling this hook is open.
 * @param containerId The optional id of the container calling this hook.
 * Used to check if the highlighted object is blocked by the container.
 * @returns A function to trigger highlighting a map object.
 */
export const useHighlightMapObject = (
    mapController: MapController,
    isContainerOpen: boolean,
    containerId: string = '',
): UseHighlightMapObjectResult => {
    const toast = useToast();
    const container = document.getElementById(containerId);
    /**
     * Checks if the highlight layer exists.
     */
    const layerExists = useCallback(() => {
        return mapController?.layers.findLayerByName(HIGHLIGHT_LAYER);
    }, [mapController]);

    /**
     * Checks if the container is blocking the given feature.
     */
    const isContainerBlocking = useCallback(
        (featureGeometry: Geometry) => {
            if (!container) return false;

            const map = mapController.map;
            const { top, left, bottom, right } =
                container.getBoundingClientRect();
            const [realTop, realBottom] = [top - 56, bottom - 56];

            const topLeft = map.getCoordinateFromPixel([left, realTop]);
            const topRight = map.getCoordinateFromPixel([right, realTop]);
            const bottomRight = map.getCoordinateFromPixel([right, realBottom]);
            const bottomLeft = map.getCoordinateFromPixel([left, realBottom]);

            const containerPolygon = new Feature(
                new Polygon([
                    [topLeft, topRight, bottomRight, bottomLeft, topLeft],
                ]),
            );
            const containerGeometry = containerPolygon.getGeometry()!;

            return featureGeometry.intersectsExtent(
                containerGeometry.getExtent(),
            );
        },
        [mapController, container],
    );

    /**
     * Handles if the highlighted feature is blocked.
     */
    const handleFeatureBlockedByContainer = (featureGeometry: Geometry) => {
        const isBlocked = isContainerBlocking(featureGeometry);

        if (isBlocked && container) {
            const originalTransition = container.style.transition;

            container.style.transition = 'opacity 300ms ease-in-out';
            container.style.opacity = '0.2';

            setTimeout(() => {
                container.style.opacity = '1';

                setTimeout(() => {
                    container.style.transition = originalTransition;
                    toast.instruction(
                        t('highlightMapObject.blockedByContainer'),
                    );
                }, 300);
            }, 500);
        }
    };

    const highlightMapObject = (geometry: Geometry) => {
        if (mapController && geometry) {
            const createHighlightLayer = () => {
                if (layerExists())
                    mapController.layers.removeLayer(HIGHLIGHT_LAYER);

                mapController.layers.createVectorLayer(HIGHLIGHT_LAYER);
            };

            createHighlightLayer();

            const feature = new Feature({ geometry });

            feature.setStyle(highlightStyle);
            mapController.layers.addFeature(HIGHLIGHT_LAYER, feature);

            // Handle if highlighted feature blocked by container
            if (containerId) {
                handleFeatureBlockedByContainer(geometry);
            }
        }
    };

    // Hide toast when the highlighted feature is no longer blocked by the container
    useEffect(() => {
        if (!container || !mapController) {
            toast.hide();
            return;
        }

        const checkAndHideToast = () => {
            const highlightLayer =
                mapController.layers.findLayerByName(HIGHLIGHT_LAYER);
            if (!highlightLayer) return;

            const features = mapController.layers.getFeatures(HIGHLIGHT_LAYER);
            if (!features.length) return;

            const featureGeometry = features[0].getGeometry();
            if (!featureGeometry) return;

            if (!isContainerBlocking(featureGeometry)) {
                toast.hide();
            }
        };

        container?.addEventListener('mousemove', checkAndHideToast);
        mapController.map.on('moveend', checkAndHideToast);

        return () => {
            container?.removeEventListener('mousemove', checkAndHideToast);
            mapController.map.un('moveend', checkAndHideToast);
        };
    }, [mapController, container, isContainerBlocking, toast]);

    // Cleanup: Remove highlight layer when the container calling this hook is closed
    useEffect(() => {
        if (!isContainerOpen && mapController && layerExists()) {
            mapController.layers.removeLayer(HIGHLIGHT_LAYER);
        }
    }, [isContainerOpen, mapController, layerExists]);

    useEffect(() => {
        return () => {
            if (mapController && layerExists()) {
                mapController.layers.removeLayer(HIGHLIGHT_LAYER);
            }
        };
    }, [mapController, layerExists]);

    return { highlightMapObject };
};
