// Libraries
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { t } from 'i18next';

// Component Library
import {
    styled,
    Icons,
    FullSearchResultContainer,
    FullSearchResultCard,
    MapContext,
    PositionOverlay,
    Coordinates,
    useIsMobile,
} from '@keypro/2nd-xp';

// Generated
import { Maybe, SearchResult } from '@generated';
import { AdvancedSearch } from './AdvancedSearch';

// Stores
import {
    useSearchStore,
    useRightMenu,
    useMobileMenu,
    useRecentObjects,
    useProductStore,
} from '@stores';

// Components
import { InfoObject } from '@components/InfoObject';
import { SearchFilters } from './search-components/SearchFilters';
import { FullSearchResultsFilter } from './search-components/FullSearchResultsFilter';
import {
    getModelIcon,
    getTranslatedPluralTitle,
    getTranslatedTitle,
    getTypeByModel,
    isModelSupported,
} from '@form-configs';
import { ResponsiveSideMenu } from '../AppNavigationBar/NavigationBarCommon';

// Hooks
import { useResponsivePan } from '@hooks/map';

/**
 * FullSearch component props.
 */
export interface FullSearchProps {
    /** Use ids from searchResults property */
    limitAdvancedSearch?: boolean;
    /** Function for setting quick search highlight state */
    setQuickSearchIsHighlighted?: (isHighlighted: boolean) => void;
}

/**
 * Component for displaying full categorized list of search results.
 */
export const FullSearch = ({
    limitAdvancedSearch = false,
    setQuickSearchIsHighlighted,
}: FullSearchProps) => {
    const {
        selectedObjectType,
        setSelectedObjectType,
        isAdvancedSearchOpen,
        isUsingAdvancedSearch,
        dataAdvancedSearch,
        highlightPositions,
        setHighlightPositions,
        modelAdvancedSearch,
        searchTerm,
        searchResults: results,
        searchFilterTerm,
    } = useSearchStore();
    const { recentObjects } = useRecentObjects();
    const searchResults = results.length > 0 ? results : recentObjects;
    const [isLoading, setIsLoading] = useState(false);
    const [isHighlighted, setIsHighlighted] = useState(false);
    const ref = useRef<HTMLDivElement>(null);
    const refOverlay = useRef<HTMLDivElement>(null);
    const advancedSearchRef = useRef<{
        handleSubmit: (e: React.FormEvent, newFilters?: string[]) => void;
    }>(null);

    const { setMenuContent: setRightMenuContent } = useRightMenu();
    const {
        setMenuContent: setMobileMenuContent,
        setMenuHeight,
        storePreviousMenu,
        setIsDetailedToolbar,
    } = useMobileMenu();
    const mapController = useContext(MapContext)!;
    const isMobile = useIsMobile();
    const responsivePan = useResponsivePan(mapController);
    const { product } = useProductStore();

    useEffect(() => {
        if (isUsingAdvancedSearch && modelAdvancedSearch) {
            setSelectedObjectType(modelAdvancedSearch);
        }
    }, [isUsingAdvancedSearch, modelAdvancedSearch, setSelectedObjectType]);

    const filteredResults = useMemo(() => {
        return searchResults.filter((result) =>
            result?.identification
                ?.toLowerCase()
                .includes(searchFilterTerm.toLowerCase()),
        );
    }, [searchFilterTerm, searchResults]);

    const groupByModelName = useMemo(() => {
        return filteredResults.reduce(
            (acc, item) => {
                if (item.modelName) {
                    if (!acc[item.modelName]) {
                        acc[item.modelName] = [];
                    }
                    acc[item.modelName].push(item);
                }
                return acc;
            },
            {} as { [key: string]: SearchResult[] },
        );
    }, [filteredResults]);

    const locateCenterObject = (location: Maybe<string> | undefined) => {
        if (!location) return;

        const geom = mapController.wktToGeometry(location);

        if (geom) {
            const extent = geom.getExtent();
            const center = [
                (extent[0] + extent[2]) / 2,
                (extent[1] + extent[3]) / 2,
            ];
            return center;
        }
    };

    const handleHighlight = (list: SearchResult[]) => {
        setQuickSearchIsHighlighted?.(false);
        // Highlight all objects in the list
        const newPositions = list
            .map((item) => {
                return {
                    id: item.id,
                    position: locateCenterObject(item.location),
                };
            })
            .filter((item) => item.position !== undefined);
        setHighlightPositions(
            newPositions as unknown as { id: string; position: number[] }[],
        );
        setIsHighlighted(true);
        if (isMobile) setMenuHeight(50);
    };

    const showObject = (model: string, id: number) => {
        if (isMobile) {
            storePreviousMenu();
            setIsDetailedToolbar(false);
            setMenuHeight(50);
            setMobileMenuContent(
                `QuickSearchObject-${model}-${id}`,
                <InfoObject model={model} id={id.toString()} />,
            );
        } else
            setRightMenuContent(
                `QuickSearchObject-${model}-${id}`,
                <InfoObject model={model} id={id.toString()} />,
            );
    };

    const renderSearchResults = () => {
        if (isUsingAdvancedSearch && isLoading) {
            return (
                <StyledLoadingAndNoResults>
                    <Icons.Spinner />
                </StyledLoadingAndNoResults>
            );
        }
        if (isUsingAdvancedSearch && dataAdvancedSearch.length === 0) {
            return (
                <StyledLoadingAndNoResults>
                    {t('noSearchResults')}
                </StyledLoadingAndNoResults>
            );
        }

        return isUsingAdvancedSearch &&
            dataAdvancedSearch.length > 0 &&
            isModelSupported(selectedObjectType) ? (
            <FullSearchResultContainer
                title={getTranslatedPluralTitle(
                    getTypeByModel(selectedObjectType),
                )}
                key={selectedObjectType}
                rightBtnLabel={rightBtnLabel}
                data-testid={`full-search-group-${selectedObjectType}`}
                rightBtnAction={() => handleHighlight(dataAdvancedSearch)}
            >
                {dataAdvancedSearch.map((item, index) => (
                    <FullSearchResultCard
                        key={item.id}
                        objectIcon={getModelIcon(item.modelName, product)}
                        name={item.identification as string}
                        type={
                            item.modelName
                                ? getTranslatedTitle(
                                      getTypeByModel(item.modelName),
                                      item.modelName,
                                  )
                                : t('unknownObjectType')
                        }
                        searchTerm={
                            searchFilterTerm !== ''
                                ? searchFilterTerm
                                : searchTerm
                        }
                        openObjectIcon={
                            <Icons.Open data-tooltip={t('openForm')} />
                        }
                        locateIcon={
                            <Icons.Locate2
                                data-tooltip={t('locateAndHighlight')}
                            />
                        }
                        onLocateAction={() => {
                            const center = locateCenterObject(item.location);
                            if (center) {
                                responsivePan(center);
                                setIsHighlighted(true);
                                setHighlightPositions([
                                    {
                                        id: item.id?.toString() ?? '',
                                        position: center,
                                    },
                                ]);
                                setMenuHeight(50);
                            }
                        }}
                        onOpenObjectAction={() =>
                            showObject(item.modelName!, item.id!)
                        }
                        onClickAction={() =>
                            showObject(item.modelName!, item.id!)
                        }
                        data-testid={`full-search-result-${item.modelName}-${index}`}
                        alwaysShowButtons={!!item.location && isMobile}
                    />
                ))}
            </FullSearchResultContainer>
        ) : (
            Object.entries(groupByModelName).map(([key, value]) =>
                selectedObjectType === '' ||
                key.includes(selectedObjectType) ||
                (key === 'street' && selectedObjectType === 'address')
                    ? renderSearchResultGroup(value, key, rightBtnLabel)
                    : null,
            )
        );
    };

    const renderSearchResultGroup = (
        items: SearchResult[],
        titleKey: string,
        rightBtnLabel: string,
    ) => (
        <FullSearchResultContainer
            title={getTranslatedPluralTitle(getTypeByModel(titleKey))}
            key={titleKey}
            rightBtnLabel={rightBtnLabel}
            data-testid={`full-search-group-${titleKey}`}
            rightBtnAction={() => handleHighlight(items)}
        >
            {items.map((item, index) => (
                <FullSearchResultCard
                    key={item.id}
                    objectIcon={getModelIcon(item.modelName, product)}
                    name={item.identification as string}
                    type={
                        item.modelName
                            ? getTranslatedTitle(getTypeByModel(item.modelName))
                            : t('unknownObjectType')
                    }
                    searchTerm={
                        searchFilterTerm !== '' ? searchFilterTerm : searchTerm
                    }
                    openObjectIcon={<Icons.Open data-tooltip={t('openForm')} />}
                    locateIcon={
                        <Icons.Locate2 data-tooltip={t('locateAndHighlight')} />
                    }
                    onLocateAction={() => {
                        const center = locateCenterObject(item.location);
                        if (center) {
                            responsivePan(center);
                            setIsHighlighted(true);
                            setHighlightPositions([
                                {
                                    id: item.id?.toString() ?? '',
                                    position: center,
                                },
                            ]);
                            setMenuHeight(50);
                        }
                    }}
                    onOpenObjectAction={() =>
                        showObject(item.modelName!, item.id!)
                    }
                    onClickAction={() => showObject(item.modelName!, item.id!)}
                    data-testid={`full-search-result-${item.modelName}-${index}`}
                    alwaysShowButtons={!!item.location && isMobile}
                />
            ))}
        </FullSearchResultContainer>
    );

    const rightBtnLabel = selectedObjectType
        ? t('highlightAll')
        : t('showMore');

    return (
        <ResponsiveSideMenu title={t('network')}>
            <StyledContainer
                ref={ref}
                data-testid="full-search"
                style={{ width: isMobile ? '100%' : undefined }}
            >
                {isAdvancedSearchOpen && !isMobile && (
                    <StyledOverlay
                        ref={refOverlay}
                        onClick={() =>
                            (refOverlay.current!.style.display = 'none')
                        }
                        data-testid="full-search-overlay"
                    />
                )}
                {isHighlighted &&
                    highlightPositions.map((item) => (
                        <PositionOverlay
                            key={item.id}
                            position={item.position as Coordinates}
                            positioning="bottom-center"
                            safeElement={true}
                        >
                            <Icons.LocationMark
                                style={{ width: 40, height: 40 }}
                            />
                        </PositionOverlay>
                    ))}
                <StyledSearchContainer>
                    {!isMobile && (
                        <StyledSearchFilters>
                            <StyledSearchFiltersTypes>
                                <SearchFilters
                                    advancedSearchRef={advancedSearchRef}
                                />
                            </StyledSearchFiltersTypes>
                            <StyledDivider />
                            <FullSearchResultsFilter />
                        </StyledSearchFilters>
                    )}
                    <StyledDivider />
                    <StyledSearchResults data-testid="full-search-results">
                        {renderSearchResults()}
                    </StyledSearchResults>
                    <AdvancedSearch
                        ref={advancedSearchRef}
                        onLoading={setIsLoading}
                        searchResults={
                            limitAdvancedSearch ? searchResults : undefined
                        }
                        data-testid="advanced-search"
                    />
                </StyledSearchContainer>
            </StyledContainer>
        </ResponsiveSideMenu>
    );
};

// Styled components
const StyledContainer = styled.div`
    height: 100%;
    width: 100%;
`;

const StyledOverlay = styled.div`
    position: fixed;
    width: 348px;
    height: calc(100vh - 110px);
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
`;

const StyledSearchContainer = styled.div`
    background-color: ${(props) => props.theme.colors.neutral['20']};
    height: 100%;
    display: flex;
    flex-direction: column;
`;

const StyledSearchFilters = styled.div``;

const StyledSearchFiltersTypes = styled.div`
    padding-top: 8px;
    padding-bottom: 8px;
`;

const StyledDivider = styled.div`
    height: 2px;
    width: 100%;
    background-color: ${(props) => props.theme.colors.neutral['30']};
`;

const StyledSearchResults = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-height: calc(100vh - 270px);
    overflow-y: auto;
`;

const StyledLoadingAndNoResults = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
`;
