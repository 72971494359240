import {
    TreeView,
    TreeNodeType,
    LoadingMask,
    useIsMobile,
    Button,
    Icons,
    TabSelector,
    styled,
} from '@keypro/2nd-xp';
import { t } from 'i18next';
import { useContext, useCallback, useEffect, useState } from 'react';

import { InfoObject, NoItems } from '@components';
import { useGetPlans } from '@hooks/keycore/plan';
import { useMobileMenu, useRightMenu } from '@stores';
import { AuthContext } from '@providers';

import { ResponsiveSideMenu } from '../NavigationBarCommon';
import { generateTreeData } from './PlansMenu.utils';

const Tabs = styled(TabSelector)`
    margin: 0 12px 20px 12px;
    width: auto;
`;

const PlansTree = styled(TreeView)`
    margin: 0 12px;
`;

const ButtonContainer = styled.div`
    display: flex;
    svg {
        width: 20px;
        height: 20px;
    }
`;

const ALL_PLANS = 'all-plans';
const MY_PLANS = 'my-plans';

/**
 * Shows plans in tree view.
 */
export const PlansMenu = () => {
    const [treeData, setTreeData] = useState<TreeNodeType[]>([]);
    const { setMenuContent: setRightMenuContent } = useRightMenu();
    const {
        setMenuContent: setMobileMenuContent,
        setMenuHeight,
        storePreviousMenu,
        setIsDetailedToolbar,
    } = useMobileMenu();
    const isMobile = useIsMobile();
    const [selectedTab, setSelectedTab] = useState<string>(ALL_PLANS);

    const { user } = useContext(AuthContext);
    const { data: plans, isFetching } = useGetPlans({
        filter:
            selectedTab === MY_PLANS
                ? { createdBy: { eq: user?.email } }
                : undefined,
        limit: 1000,
    });

    const setMenuContent = isMobile
        ? setMobileMenuContent
        : setRightMenuContent;

    const openForm = useCallback(
        (id: string) => {
            if (isMobile) {
                storePreviousMenu();
                setIsDetailedToolbar(false);
                setMenuHeight(50);
            }
            setMenuContent(`Plan-${id}`, <InfoObject model="plan" id={id} />);
        },
        [
            isMobile,
            setMenuContent,
            storePreviousMenu,
            setIsDetailedToolbar,
            setMenuHeight,
        ],
    );

    const renderPlanButtons = useCallback(
        (planId: string) => (
            <ButtonContainer>
                <Button
                    kind="ghost"
                    onClick={(e) => {
                        e.stopPropagation();
                        openForm(planId);
                    }}
                    data-tooltip={t('openForm')}
                    data-testid="plan-open"
                >
                    <Icons.Open />
                </Button>
                <Button
                    kind="ghost"
                    disabled={true}
                    data-tooltip={t('locate')}
                    data-testid="plan-locate"
                >
                    <Icons.Locate2 />
                </Button>
                <Button
                    kind="ghost"
                    disabled={true}
                    data-tooltip={t('more')}
                    data-testid="plan-more"
                >
                    <Icons.VerticalEllipsis />
                </Button>
            </ButtonContainer>
        ),
        [openForm],
    );

    useEffect(() => {
        if (plans) {
            setTreeData(generateTreeData(plans));
        } else {
            setTreeData([]);
        }
    }, [openForm, plans]);

    let content = null;

    if (!isFetching) {
        if (plans && plans.length === 0) {
            let description;

            if (selectedTab === MY_PLANS) {
                description = t('noMyPlansDescription');
            } else {
                description = t('noPlansDescription');
            }

            content = (
                <NoItems
                    text={t('noPlans')}
                    description={description}
                    createButtonLabel={t('createPlan')}
                    onCreateButtonClick={() =>
                        console.log('Not implemented yet')
                    }
                />
            );
        } else {
            content = (
                <PlansTree
                    data={treeData}
                    hoverComponent={renderPlanButtons}
                    autoSelectionMode={false}
                    alwaysShowHoverComponent={true}
                />
            );
        }
    }

    return (
        <ResponsiveSideMenu title={t('plans')}>
            {isFetching && <LoadingMask />}
            <Tabs
                options={[
                    { id: ALL_PLANS, label: t('allPlans') },
                    { id: MY_PLANS, label: t('myPlans') },
                ]}
                selected={selectedTab}
                onSelect={(id: string) => setSelectedTab(id)}
            />
            {content}
        </ResponsiveSideMenu>
    );
};
