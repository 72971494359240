import { QueryVariables } from '@apis/utils';
import { PlanFilter } from '@generated';
import { useQuery } from '@tanstack/react-query';
import { getPlans } from 'src/apis/keycore';

export interface UseGetPlansConfig {
    enabled?: boolean;
}

/**
 * Plan hook to use react-query.
 */
export function useGetPlans(
    options: QueryVariables<PlanFilter> = {},
    { enabled = true }: UseGetPlansConfig = {},
) {
    return useQuery({
        queryKey: [
            'map',
            'plans',
            options.filter,
            options.limit,
            options.offset,
        ],
        queryFn: async () => {
            return getPlans(options.filter, options.limit, options.offset);
        },
        enabled,
    });
}
