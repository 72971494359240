query getDhConduits($filter: DhConduitFilter) {
    getDhConduits(filter: $filter) {
        dhConduits {
            id
            length
            length2D
            volume
            width
            ductCount
            identification
            surveyAmount
            mapper
            historyDate
            installYear
            note
            nightlyUpdate
            heatLoss
            location
            createdBy
            createdTs
            updatedBy
            updatedTs
            locationAccuracy {
                id
            }
            owner {
                id
            }
            beginZCoord
            endZCoord
            beginZEstimated
            endZEstimated
            url
            info
            state {
                id
            }
            plan {
                id
            }
            accuracyId {
                id
            }
            hAccuracyId {
                id
            }
            typeId {
                id
            }
            mappingMethodId {
                id
            }
            conduitType {
                id
            }
            conduitClass {
                id
            }
            conduitProfileStatus {
                id
            }
            shellId {
                id
            }
            locationId {
                id
            }
            safetyDistance {
                id
            }

            ducts {
                id
            }

            symCodeGt
            symColorExp
            symAngleExp
            symSizeExp
            symNameExp
            symTextExp
            symWidthExp
            lblColorExp
            lblSizeExp
            lblAngleExp
        }
    }
}
