import {
    QueryQuickAddressSearchRpcArgs,
    QuickAddressSearchRpcResult,
    QuickAddressSearchRpcQuery,
} from '@generated';
import { sendGraphQLRequest } from '@utils';
import QuickAddressSearchRPC from './queries/QuickAddressSearchRPC.graphql';

/**
 * Search using quickAddressSearch RPC.
 * @returns {Promise<QuickAddressSearchRpcResult[]>} The quick address search rpc response.
 * @throws {Error} If the request fails.
 */
export const quickAddressSearchRPC = async (
    term: string,
): Promise<QuickAddressSearchRpcResult[]> => {
    try {
        const variables: QueryQuickAddressSearchRpcArgs = {
            term,
        };
        const response = await sendGraphQLRequest<QuickAddressSearchRpcQuery>(
            QuickAddressSearchRPC,
            variables,
        );

        return (response.data.quickAddressSearchRPC?.addresses ??
            []) as QuickAddressSearchRpcResult[];
    } catch (error) {
        throw Error('Failed quick address search rpc: ' + error);
    }
};
