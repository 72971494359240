import { RaphaelElement, RaphaelPaper, RaphaelSet } from 'raphael';
import { DiagramData, DiagramOptions } from '../types';

export abstract class Shape {
    options: DiagramOptions;

    /** Paper on which the shape will be drawn */
    paper: RaphaelPaper;

    /** Element set of the shape */
    elemSet: RaphaelSet<'SVG' | 'VML'>;

    /** Label element of the shape if there is a label associated with the object */
    labelElem: RaphaelElement<'SVG' | 'VML'> | null = null;

    /** identifier of the shape */
    shapeId: string | null;

    /** objectType the shape represents */
    objectType: string | null;

    /** Content type id of the object */
    objectCtid: number;

    /** Object id of the object */
    objectId: string;

    /** State of the object */
    objectState: number;

    /** Location of the object */
    objectLocation: string | null | undefined;

    /** Label of the object */
    objectLabel: string = '';

    /** Default text style */
    fontSize: number = 200;
    textColor: string = '#000000';

    /** Default line style */
    lineWidth: number = 15;
    lineColor: string = '#000000';

    connEnd: string | null = '';
    tmovex: number = 0;
    tmovey: number = 0;

    constructor(options: DiagramOptions, data: DiagramData) {
        this.options = options;
        this.paper = options.paper;
        this.elemSet = this.paper.set();

        /* Common attributes of all shapes */
        this.shapeId = data.shapeId as string;
        this.objectType = data.dataType ?? null;
        this.objectCtid = data.ctid;
        this.objectId = data.id;
        this.objectState = data.state;
        this.objectLocation = 'location' in data ? data.location : null;
        this.objectLabel = 'label' in data ? (data.label ?? '') : '';
    }

    public render() {
        this.elemSet.mousedown(() => {
            console.log('Shape clicked: ', this);
        });
    }

    /**
     * Getter for elem set
     */
    getElemSet() {
        return this.elemSet;
    }

    /**
     * Getter for label element
     */
    getLabelElem() {
        return this.labelElem;
    }

    /**
     * Getter for shape id
     */
    getShapeId() {
        return this.shapeId;
    }

    getObjectId() {
        return this.objectId;
    }

    getObjectCtid() {
        return this.objectCtid;
    }

    getObjectType() {
        return this.objectType;
    }

    getObjectLabel() {
        return this.objectLabel;
    }
}
